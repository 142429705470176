import React from 'react';
import { UIFilePreview } from '../../common/UIFilePreview';
import { LibraryFileDetails, LibraryFileDetailsProps } from '../edit/LibraryFileDetails';

interface LibraryFilePreviewProps extends LibraryFileDetailsProps {
    file: Blob;
    handleError: (error: string) => void;
}

export const LibraryFilePreview = ({ isEditable, node, file, ...rest }: LibraryFilePreviewProps) => {
    return (
        <>
            <UIFilePreview file={file} fileExtension={node.fileExtension || ''} />
            <LibraryFileDetails isEditable={isEditable} node={node} {...rest} />
        </>
    );
};
