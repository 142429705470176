import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MacroItem } from '../../../domain/macroPack/model/MacroPack';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { UISectionHeader } from '../common/UISectionHeader';
import { UIErrorMessage } from '../common/UIErrorMessage';
import { MacroNodeFields } from './MacroNodeFields';

export interface MacroNodesFormProps {
    isEditable: boolean;
    innerFormRef: any;
    handleSubmit: (_: { macros: MacroItem[] }) => Promise<void>;
    macros: MacroItem[];
}

export const MacroNodesForm = React.memo(({ isEditable, macros, innerFormRef, handleSubmit }: MacroNodesFormProps) => {
    const intl = useIntl();

    const itemSchema = Yup.object().shape({
        title: Yup.string().required(getLocalizedString(intl, 'MACROS.FORM.VALIDATION.TITLE.REQUIRED')).trim(),
        name: Yup.string().required(getLocalizedString(intl, 'MACROS.FORM.VALIDATION.NAME.REQUIRED')).trim(),
        description: Yup.string()
            .required(getLocalizedString(intl, 'MACROS.FORM.VALIDATION.DESCRIPTION.REQUIRED'))
            .trim()
    });

    const validationSchema = Yup.object().shape({
        macros: Yup.array()
            .of(itemSchema)
            .min(1, getLocalizedString(intl, 'MACROS.FORM.VALIDATION.ITEMS.REQUIRED'))
            .test('unique', getLocalizedString(intl, 'MACROS.FORM.VALIDATION.ITEMS.UNIQUE'), items => {
                if (!items || items.length === 0) {
                    return false;
                }

                const nameSet = new Set();
                const titleSet = new Set();
                for (const item of items) {
                    if (nameSet.has(item.name)) {
                        return false;
                    }
                    if (titleSet.has(item.title)) {
                        return false;
                    }
                    nameSet.add(item.name);
                    titleSet.add(item.title);
                }
                return true;
            })
    });

    const handleRemove = useCallback(
        remove => (index: number) => {
            remove(index);
        },
        []
    );

    const handleAdd = useCallback(
        (add: (item: MacroItem) => void) => () => {
            const item = { name: '', title: '', description: '' };
            add(item);
        },
        []
    );

    const onSubmit = async (values: { macros: MacroItem[] }) => {
        //DO nothing, submit is handled in the MacroPackDetails
        await handleSubmit(values);
    };

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ macros: macros }}
            innerRef={innerFormRef}
            enableReinitialize={true}
            onSubmit={onSubmit}>
            {({ values }) => {
                const isDeletable = isEditable && values.macros.length > 1;

                return (
                    <Form>
                        <FieldArray
                            name="macros"
                            render={({ push, remove }) => (
                                <>
                                    {values.macros && values.macros.length > 0 ? (
                                        <>
                                            <UISectionHeader
                                                titleProps={{ variant: 'h6' }}
                                                title={getLocalizedString(intl, 'MACRO.EDIT.MACROS_ITEMS.TITLE')}
                                                isCreateEnabled={false}
                                            />
                                            {values.macros.map((item, index) => (
                                                <MacroNodeFields
                                                    key={`item-${index}`}
                                                    nodeIndex={index}
                                                    isEditable={isEditable}
                                                    isDeletable={isDeletable}
                                                    isLast={index === values.macros.length - 1}
                                                    handleAdd={handleAdd(push)}
                                                    handleRemove={handleRemove(remove)}
                                                />
                                            ))}
                                        </>
                                    ) : (
                                        isEditable && (
                                            <UISectionHeader
                                                titleProps={{ variant: 'h6' }}
                                                title={getLocalizedString(intl, 'MACRO.EDIT.MACROS_ITEMS.TITLE')}
                                                onCreate={handleAdd(push)}
                                                isCreateEnabled={isEditable}
                                            />
                                        )
                                    )}
                                </>
                            )}
                        />

                        <ErrorMessage
                            name={'macros'}
                            render={errorMessage => <UIErrorMessage message={errorMessage} />}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
});
