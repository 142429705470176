import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';
import { NavRoutes } from '../../../navigation/NavRoutes';
import { ImageResolver } from '../../../utils/resolver';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { HEADER_TOP_BAR_HEIGHT } from '../../../res/dimensions';
import { UIScaffold } from '../../scaffolder/UIScaffold';
import { UITopBarItem } from './UITopBarItem';

interface UITopBarProps {
    items: UITopBarItem[];
}

export const UITopBar = ({ items }: UITopBarProps) => {
    const classes = useStyles();
    return (
        <AppBar position="static" className={classes.appBar}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={sx.logo}>
                        <Link to={NavRoutes.Login}>
                            <img alt="app logo" className={classes.logo} src={ImageResolver.MazarsLogo} />
                        </Link>
                    </Box>

                    <UIScaffold>
                        {props => (
                            <Box sx={sx.menu}>
                                {items.map((ItemComponent, index) => (
                                    <ItemComponent key={index} {...props} />
                                ))}
                            </Box>
                        )}
                    </UIScaffold>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const useStyles = makeStyles({
    appBar: {
        height: HEADER_TOP_BAR_HEIGHT,
        boxShadow: 'none',
        backgroundColor: 'white !important'
    },
    logo: {
        maxWidth: '180px'
    }
});

const sx = {
    menu: {
        m: 1,
        p: 1,
        flexGrow: 0,
        display: 'flex',
        flexDirection: 'row'
    },
    logo: { flexGrow: 1, display: { md: 'flex' } }
} as const;
