import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppRoutes, LoginElement, RouteElement } from './navigation/Router';

import { I18nProvider } from './i18n/I18nProvider';
import { Provider, useDispatch, useSelector } from 'react-redux';

import { NavRoutes } from './navigation/NavRoutes';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, RootState, store } from './store';
import { AppContext } from './context/AppContext';
import { removeCurrentOrganization, removeSessionInfo, removeUserInfo } from './store/modules/user';

import {
    AuthSessionInfoSpec,
    initAuthInfo,
    initOrganization,
    initUser,
    Organization,
    User,
    UserOrganizationRole
} from './domain';
import { PageNotFound } from './ui/screens/PageNotFound';
import { userHelper } from './utils/userHelper';
import { isMasterOrganization } from './utils/organizationHelper';
import { UserAccess } from './domain/roles/model/initRole';

function App() {
    const userProfile: User | undefined = useSelector((state: RootState) => state.user.userProfile);
    const userRoles: UserOrganizationRole[] | undefined = useSelector((state: RootState) => state.user.userRoles);
    const sessionInfo: AuthSessionInfoSpec | undefined = useSelector((state: RootState) => state.user.sessionInfo);
    const currentOrganization: Organization | undefined = useSelector(
        (state: RootState) => state.user.currentOrganization
    );
    const dispatch = useDispatch();

    const clearSession = () => {
        dispatch(removeSessionInfo());
        dispatch(removeUserInfo());
        dispatch(removeCurrentOrganization());
    };

    useEffect(() => {
        if (!userProfile && !sessionInfo) {
            clearSession();
        }
    }, [userProfile, sessionInfo]);

    const accessInfo: UserAccess = userProfile
        ? userHelper.getUserRoleLevel(
              userProfile.appRoles,
              userProfile.masterOrganizationRoles,
              userProfile.organizationRoles,
              (currentOrganization || initOrganization).id
          )
        : { roleLevel: 'standard', accessMode: 'Other' };

    return (
        <AppContext.Provider
            value={{
                sessionInfo: sessionInfo || initAuthInfo,
                currentUser: userProfile || initUser,
                currentOrganization: currentOrganization || initOrganization,
                currentUserRoles: userRoles || [],
                isEditableAtOrganizationLevel:
                    accessInfo.accessMode === 'AppAdmin' || accessInfo.roleLevel === 'organizationAdmin',
                isEditableAtMasterOrganization:
                    accessInfo.accessMode === 'AppAdmin' || accessInfo.roleLevel === 'masterOrganizationAdmin',
                isCurrentOrganizationMaster: currentOrganization
                    ? isMasterOrganization(currentOrganization) && accessInfo.roleLevel === 'masterOrganizationAdmin'
                    : false,
                accessData: accessInfo
            }}>
            <div className="App">
                <I18nProvider>
                    <Router basename={process.env.REACT_APP_BASENAME || ''}>
                        <Routes>
                            <Route path={NavRoutes.Login} element={<LoginElement sessionInfo={sessionInfo} />} />

                            {AppRoutes.map((routeInfo, key) => (
                                <Route key={key} path={routeInfo.path} element={<RouteElement {...routeInfo} />} />
                            ))}

                            <Route path="*" element={<PageNotFound />} />
                        </Routes>
                    </Router>
                </I18nProvider>
            </div>
        </AppContext.Provider>
    );
}

const ReduxApp = () => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    );
};

export default ReduxApp;
