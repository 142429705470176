import { Repository } from '../Repository';

import { AuthSessionInfoSpec, HttpRoutes, OrganizationRolesRepositorySpec, UserOrganizationRole } from '..';
import { HttpResponse } from '../mazars-network-http-client';
import { EOrganizationRole } from './model/UserOrganizationRole';

export class MasterOrganizationRolesRepository extends Repository implements OrganizationRolesRepositorySpec {
    async fetchAll(masterOrganizationId: string, sessionInfo: AuthSessionInfoSpec): Promise<EOrganizationRole[]> {
        const params = { masterOrganizationId };
        const response = (await this.datasource.remote.get(
            HttpRoutes.MasterOrganizationRolesEnum,
            sessionInfo.accessToken,
            undefined,
            params,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<EOrganizationRole[], any>;
        return response.data || [];
    }

    async fetchUserRoles(
        masterOrganizationId: string,
        email: string,
        sessionInfo: AuthSessionInfoSpec
    ): Promise<UserOrganizationRole[]> {
        const params = { masterOrganizationId, email };
        const response = (await this.datasource.remote.get(
            HttpRoutes.MasterOrganizationRoles,
            sessionInfo.accessToken,
            undefined,
            params,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<UserOrganizationRole[], any>;
        return response.data || [];
    }

    async fetchOrganizationUsers(
        masterOrganizationId: string,
        filteredERoles: string,
        sessionInfo: AuthSessionInfoSpec
    ): Promise<UserOrganizationRole[]> {
        const params = { masterOrganizationId, EMasterOrganizationRole: filteredERoles };
        const response = (await this.datasource.remote.get(
            HttpRoutes.MasterOrganizationRoles,
            sessionInfo.accessToken,
            undefined,
            params,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<UserOrganizationRole[], any>;

        return response.data || [];
    }

    async attach(roles: UserOrganizationRole[], sessionInfo: AuthSessionInfoSpec): Promise<any> {
        const response = (await this.datasource.remote.post(
            HttpRoutes.MasterOrganizationRoles,
            roles,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<UserOrganizationRole[], any>;

        return response.data || [];
    }

    async remove(rolesIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<any> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.MasterOrganizationRoles,
            rolesIds,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<UserOrganizationRole[], any>;
        return response.data;
    }
}
