import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { Delete, Edit } from '@mui/icons-material';
import { ColorShortcut, ColorShortcutDataSpec, ColorShortcutRepositorySpec } from '../../../../domain';

import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { ColorShortcutsArea } from './ColorShortcutsArea';
import { UIContentScaffold } from '../../common/UIContentScaffold';

interface ColorShortcutActionsProps {
    colorShortcut: ColorShortcutDataSpec;
    handleEdit: (data: ColorShortcutDataSpec) => void;
    handleDelete: (ids: string[]) => void;
}

export const ColorShortcutActions = ({ colorShortcut, handleEdit, handleDelete }: ColorShortcutActionsProps) => {
    const intl = useIntl();

    const onEdit = () => {
        handleEdit(colorShortcut);
    };

    const onDelete = () => {
        if (!colorShortcut.id) {
            throw new Error('id is required for deletion');
        }

        handleDelete([colorShortcut.id]);
    };

    return (
        <Box>
            <Tooltip title={getLocalizedString(intl, 'RESOURCES.COLOR_SHORTCUTS.EDIT')}>
                <IconButton onClick={onEdit} color="primary">
                    <Edit fontSize="small" />
                </IconButton>
            </Tooltip>

            <Tooltip title={getLocalizedString(intl, 'RESOURCES.COLOR_SHORTCUTS.DELETE')}>
                <IconButton onClick={onDelete} color="primary">
                    <Delete fontSize="small" color="error" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export interface ColorShortcutsContentProps {
    colorShortcutRepository: ColorShortcutRepositorySpec;
    isEditable: boolean;
}

export const ColorShortcutsContent = ({
    isEditable,
    colorShortcutRepository,
    setLoading,
    setSessionExpired,
    setToast
}: ColorShortcutsContentProps & UIScaffoldChildContext) => {
    return (
        <UIContentScaffold
            repository={colorShortcutRepository}
            successMessagePrefix="RESOURCES.RESOURCES.COLOR_SHORTCUTS"
            fetchMethodName="fetchAll"
            setToast={setToast}
            setLoading={setLoading}
            setSessionExpired={setSessionExpired}>
            {({ data, handleCreate, handleDelete, handleUpdate }) => (
                <ColorShortcutsArea
                    colorShortcuts={(data as ColorShortcut[]) ?? []}
                    actionsHidden={!isEditable}
                    handleEdit={handleUpdate}
                    handleDelete={handleDelete}
                    handleCreate={handleCreate}
                />
            )}
        </UIContentScaffold>
    );
};
