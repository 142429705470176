import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { UIText } from './UIText';
import { Close, ExpandLess, ExpandMore } from '@mui/icons-material';
import Box from '@mui/material/Box';
import { Palette } from '../../../res/colors';
import { FontsResolver } from '../../../utils/resolver';

type StickyBannerProps = {
    title?: string;
    content: string | React.ReactNode;
};

export const StickyBanner = ({ content, title }: StickyBannerProps) => {
    const [reduce, setReduce] = useState(false);
    const [visible, setVisible] = useState(true);

    const handleReduce = () => {
        setReduce(true);
    };

    const handleShow = () => {
        setReduce(false);
    };

    const handleClose = () => {
        setVisible(false);
    };

    if (!visible) return null;

    return (
        <AppBar sx={styles.stickyBanner} className="container" position="sticky">
            <Toolbar sx={{ width: '100%', padding: 1 }}>
                {!reduce ? (
                    <>
                        {typeof content === 'string' ? (
                            <Box sx={{ p: 1 }}>
                                {title && <UIText text={title} sx={styles.title} />}
                                <UIText text={content} sx={styles.content} />
                            </Box>
                        ) : (
                            content
                        )}

                        <IconButton edge="end" color="inherit" sx={styles.closeButton} onClick={handleReduce}>
                            <ExpandLess />
                        </IconButton>
                    </>
                ) : (
                    <Box sx={styles.showContainer}>
                        <IconButton edge="end" color="inherit" sx={styles.closeButton} onClick={handleClose}>
                            <Close />
                        </IconButton>
                        <IconButton edge="end" color="inherit" sx={styles.closeButton} onClick={handleShow}>
                            <ExpandMore />
                        </IconButton>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

const styles = {
    stickyBanner: {
        position: 'sticky',
        top: 0,
        zIndex: 100,
        backgroundColor: Palette.warningColor,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        gap: 2,
        alignSelf: 'flex-start'
    },
    closeButton: {
        alignSelf: 'flex-start'
    },
    showContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        alignSelf: 'flex-start'
    },
    title: {
        fontFamily: FontsResolver.defaultFontBold,
        fontSize: '1.2rem',
        marginBottom: '0.5rem'
    },
    content: {
        fontSize: '1rem'
    }
} as const;
