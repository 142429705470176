import { Box } from '@mui/material';
import { UIText } from '../components/common/UIText';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../i18n/I18nHelper';
import { UIActionButton } from '../components/common/UIActionButton';
import { NavRoutes } from '../../navigation/NavRoutes';

export const PageNotFound = () => {
    const intl = useIntl();

    const handleGoHome = () => {
        window.location.replace(NavRoutes.Home);
    };

    return <Box
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'space-between' }}>
        <UIText text={getLocalizedString(intl, 'PAGE.NOT.FOUND')} sx={{ fontSize: 24, marginTop: 20 }} />
        <UIActionButton title={'Go Home'} onClick={handleGoHome} sx={{ marginTop: 20 }} />
    </Box>;
};
