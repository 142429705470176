import React, { useEffect, useState } from 'react';
import Select, {
    DropdownIndicatorProps,
    components,
    IndicatorSeparatorProps,
    ControlProps
} from 'react-select';
import { ActionMeta } from 'react-select/dist/declarations/src/types';
import { ArrowDropDown } from '@mui/icons-material';
import { Palette } from '../../../res/colors';

export interface UISelectProps<T> {
    name: string;
    id: string;
    placeholder?: string;
    value?: string | string[] | null; // id of the selected option
    options: T[];
    disabled?: boolean;
    onSelectionChange?: (newValue: T) => void;
    onRemoveValue?: (removedValue: T, remainingValues: T[]) => void;
    getOptionValue?: (item: T) => string;
    getOptionLabel?: (item: T) => string;
    multiple?: boolean;
    onMultiSelectionChange?: (newValue: T[]) => void;
    isClearable?: boolean;
    onClear?: () => void;
}

const defaultGetOptionValue = <T extends { id: string | number }>(item: T) => item.id as string;
const defaultGetOptionLabel = <T extends { name: string }>(item: T) => item.name;

const getSelectedValue = <T extends { id: string | number }>(options: T[], values?: string | string[] | null): T | T[] | null => {

    let mappedValues: string[] | null = values as string[];
    if (values && ['string', 'number'].includes(typeof (values))) {
        mappedValues = [values as string];
    }

    const updatedValues: T | T[] | null = mappedValues && Array.isArray(mappedValues) ? options.filter(option => mappedValues?.includes(option.id as string)) : mappedValues;
    return updatedValues;
};

function DropdownIndicator<T>(props: DropdownIndicatorProps<T>) {
    return (
        <components.DropdownIndicator {...props}>
            <ArrowDropDown />
        </components.DropdownIndicator>
    );
}

function IndicatorSeparator<T>({
                                   innerProps
                               }: IndicatorSeparatorProps<T>) {
    return <span {...innerProps} />;
}

function Control<T>({ children, ...props }: ControlProps<T>) {
    return (
        <components.Control {...props}>
            {children}
        </components.Control>
    );
}


export const UISelect = <T extends { id: string | number, name: string }>({
                                                                              disabled,
                                                                              onClear,
                                                                              isClearable = true,
                                                                              multiple = false,
                                                                              name,
                                                                              id,
                                                                              placeholder,
                                                                              value: externalValues,
                                                                              options,
                                                                              onSelectionChange,
                                                                              onMultiSelectionChange,
                                                                              onRemoveValue,
                                                                              getOptionLabel = defaultGetOptionLabel,
                                                                              getOptionValue = defaultGetOptionValue
                                                                          }: UISelectProps<T>) => {

    //we can manage the selected values from outside the UISelect via externalValues or force externalValues to null and it will be managed by selectedValue
    const [selectedValue, setSelectedValue] = useState<T | T[] | null>(getSelectedValue(options, externalValues));

    useEffect(() => {
        setSelectedValue(getSelectedValue(options, externalValues));

    }, [externalValues]);


    const onChange = (selectedOption: any, triggeredAction: ActionMeta<any>) => {

        switch (triggeredAction.action) {
            case 'select-option':
                setSelectedValue(selectedOption);

                if (!multiple) {
                    selectedOption && onSelectionChange && onSelectionChange(selectedOption);
                } else {
                    selectedOption && onMultiSelectionChange && onMultiSelectionChange(selectedOption);
                }

                break;

            case 'remove-value':
                setSelectedValue(selectedOption);
                onRemoveValue && onRemoveValue(triggeredAction.removedValue, selectedOption);

                break;

            case 'clear':
                onClear && onClear();

                break;
            default:
                break;
        }
    };

    return (
        <>
            <Select
                styles={{ ...styles }}
                components={{ IndicatorSeparator, Control, DropdownIndicator }}
                isDisabled={disabled}
                isClearable={isClearable}
                isMulti={multiple}
                name={name}
                id={id}
                placeholder={placeholder}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                value={selectedValue}
                options={options}
                onChange={onChange}
            />
        </>
    );
};

const styles = {
    control: (base: any) => ({
        ...base,
        borderWidth: 1,
        borderColor: Palette.sonicSilver,
        backgroundColor: 'transparent',
        borderBottomLeftRadius: 20,
        color: Palette.primaryTextColor,
        width: '100%',
        '&:hover': {
            borderColor: Palette.sonicSilver
        },
        '&:focus-within': {
            borderColor: Palette.sonicSilver
        }
    })
};

