import { UITopBar } from '../components/header/UITopBar';
import { LanguageTopBarItem } from '../components/header/LanguageTopBarItem';
import React from 'react';

export const LoginLayout = ({ children }: any) => (
    <div>
        <UITopBar items={[LanguageTopBarItem]} />
        {children}
    </div>
);
