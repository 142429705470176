import { ImageResolver } from '../utils/resolver';

export interface LanguageSpec {
    flag: string;
    name: string;
}

export const languages: { [key: string]: LanguageSpec } = {
    en: {
        flag: ImageResolver.flags.en,
        name: 'English'
    },
    fr: {
        flag: ImageResolver.flags.fr,
        name: 'Français'
    },
    es: {
        flag: ImageResolver.flags.es,
        name: 'Español'
    }
};
