import { UIScaffold } from '../scaffolder/UIScaffold';
import React, { useContext } from 'react';
import { CONTAINER_TOP_MARGIN } from '../../res/dimensions';
import { getLocalizedString } from '../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { AppContext } from '../../context/AppContext';
import { RepositoryProvider } from '../../domain';
import { MasterOrganizationContent } from '../components/organization/MasterOrganizationContent';
import { OrganizationContent } from '../components/organization/OrganizationContent';

export const OrganizationScreen = () => {
    const { currentOrganization, isCurrentOrganizationMaster, isEditableAtOrganizationLevel } = useContext(AppContext);
    const intl = useIntl();

    return (
        <UIScaffold>
            {({ setToast, setSessionExpired, setLoading }) => (
                <Box className="container" sx={styles.container}>
                    {isCurrentOrganizationMaster ? (
                        <MasterOrganizationContent
                            organizationRepository={RepositoryProvider.getProvider().organizationRepository}
                            masterOrganization={currentOrganization}
                            masterOrganizationRepository={RepositoryProvider.getProvider().masterOrganizationRepository}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            setToast={setToast}
                        />
                    ) : (
                        <OrganizationContent
                            isEditable={isEditableAtOrganizationLevel}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            setToast={setToast}
                            organizationRepository={RepositoryProvider.getProvider().organizationRepository}
                            organization={currentOrganization}
                            title={getLocalizedString(intl, 'ORGANIZATION.TITLE')}
                        />
                    )}
                </Box>
            )}
        </UIScaffold>
    );
};

const styles = {
    container: {
        mt: CONTAINER_TOP_MARGIN,
        mb: CONTAINER_TOP_MARGIN,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    }
} as const;
