export const en = {
    'common.master.organization.name': 'Default',
    'COMMON.ADD.TITLE': 'Add',

    'COMMON.EMPTY.DATA.ERROR': 'An error has occurred. There is no data',
    'COMMON.NETWORK.ERROR.0': '',
    'COMMON.NETWORK.ERROR.-1': 'A technical error has occurred.',
    'COMMON.NETWORK.ERROR.TECHNICAL': 'A technical error has occurred.',
    'COMMON.NETWORK.ERROR.400': 'The data sent is not valid. Please retry.',
    'COMMON.NETWORK.ERROR.401': 'You are not authorized to access this resource.',
    'COMMON.NETWORK.ERROR.403': 'This resource is forbidden',
    'COMMON.NETWORK.ERROR.404': 'The requested data does not exist',
    'COMMON.NETWORK.ERROR.500': 'Server error has occurred.',
    'COMMON.VALIDATION.EMAIL.FORMAT': 'Invalid email format',
    'COMMON.EMAIL.FIELD.PLACEHOLDER': 'Email',
    'COMMON.TABLE.NO.RECORDS': 'No records',
    'COMMON.ACTIONS.CANCEL': 'Cancel',
    'COMMON.ACTIONS.SAVE': 'Save',
    'COMMON.ACTIONS.DELETE': 'Delete',
    'COMMON.ACTIONS.EDIT': 'Edit',
    'COMMON.ACTIONS.DOWNLOAD': 'Download',
    'COMMON.ACTIONS.VALIDATE': 'Validate',
    'COMMON.ACTIONS.LOGOUT': 'Log out',
    'COMMON.LOADER.DEFAULT.MESSAGE': 'Loading ...',
    'COMMON.DIALOG.PREVIEW.TITLE': 'Preview file',
    'COMMON.UNKNOWN': 'Unknown',

    'FOOTER.MESSAGE': 'Powered by Forvis Mazars Global Technology & Innovation 2024 | All rights reserved ',

    'INVALID.SESSION.TITLE': 'Invalid Session',
    'INVALID.SESSION.MESSAGE': 'Your session has expired, please log in again to have access to the platform',

    'AUTH.SIGN_IN': 'Login with \nForvis Mazars Connect',
    'AUTH.LOGIN.MESSAGE': 'Connect to my secure space',
    'AUTH.LOGIN.WELCOME.TITLE': 'Smart Office Portal',
    'AUTH.LOGIN.WELCOME.SUBTITLE':
        'Manage and administrate the contents and settings of Smart Office for your organization',
    'AUTH.PASSWORD.FIELD.PLACEHOLDER': 'Password',
    'AUTH.ORGANIZATION.FIELD.PLACEHOLDER': 'Select a country',
    'AUTH.ORGANIZATION_ID.MISSING': 'Please select your country',
    'AUTH.ERROR.400': 'Your credentials are not valid. Please retry.',
    'AUTH.ERROR.401': 'Your credentials are not valid. Please retry.',
    'AUTH.ERROR.403': 'Your credentials are not valid. Please retry.',
    'AUTH.ERROR.UNKNOWN': 'Unknown login error occurred',
    'AUTH.VALIDATION.EMAIL.REQUIRED': 'Please enter your email address',
    'AUTH.VALIDATION.PASSWORD.REQUIRED': 'Please enter your password',
    'AUTH.VALIDATION.ORGANIZATION.REQUIRED':
        'Connection failed, there seems to be a problem linked to your organisation, please contact your admin',

    'AUTH.VALIDATION.INVALID.COUNTRY': 'Invalid country',

    'HEADER.TOP_BAR.ACCOUNT.LOGOUT': 'Logout',
    'HEADER.TOP_BAR.HELP.USE': 'Help & Use',
    'HEADER.MENU.APP.NAME': 'Smart Office | ',
    'HEADER.MENU.PAGE.USERS_ROLES': 'Users & Roles',
    'HEADER.MENU.PAGE.LIBRARY': 'Library Setup',
    'HEADER.MENU.PAGE.CONTENTS': 'Library',
    'HEADER.MENU.PAGE.ACCOUNT': 'Account',
    'HEADER.MENU.PAGE.RESOURCES': 'Resources',
    'HEADER.MENU.PAGE.CONFIGURATION': 'Configuration',
    'HEADER.MENU.PAGE.ORGANIZATION': 'Organization',
    'HEADER.MENU.PAGE.MASTER_ORGANIZATION': 'Master Organization',
    'HEADER.MENU.PAGE.CREDENTIALS': 'Credentials',
    'HEADER.MENU.PAGE.MACRO': 'Macros',

    'ACCOUNT.SIDE_BAR_MENU.OVERVIEW_ENTRY': 'Overview',
    'ACCOUNT.SIDE_BAR_MENU.DEVICES_ENTRY': 'Devices',
    'ACCOUNT.SIDE_BAR_MENU.LICENSE_ENTRY': 'License',
    'ACCOUNT.SIDE_BAR_MENU.SIGN_INS_ENTRY': 'Sign-ins ',

    'ACCOUNT.OVERVIEW.USER.EMAIL': 'Email:',
    'ACCOUNT.OVERVIEW.USER.MASTER.ORGANIZATION': 'Master organization:',
    'ACCOUNT.OVERVIEW.USER.ORGANIZATIONS': 'Organizations:',
    'ACCOUNT.OVERVIEW.USER.ROLES': 'Roles',
    'ACCOUNT.OVERVIEW.USER_AREA.TITLE': 'User',
    'ACCOUNT.OVERVIEW.DEVICES_AREA.TITLE': 'Devices',

    'USERS_ROLES.SPECIAL_USERS.TITLE': 'Special users',
    'USERS_ROLES.USER.ROLES.TITLE': 'User roles',
    'USERS_ROLES.SPECIAL_USERS.MESSAGE':
        'Define special users in your organization and assign them roles within your organization',
    'USERS_ROLES.SPECIAL_USERS.DROP_DOWN.PLACEHOLDER': 'User role',
    'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.EMAIL.REQUIRED': 'Email is required',
    'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.INVALID.ROLE': 'Invalid role',
    'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.ROLE.REQUIRED': 'Role is required',
    'USERS_ROLES.SPECIAL_USERS.CREATE.DIALOG.TITLE': 'Add user',
    'USERS_ROLES.SPECIAL_USERS.CREATE.SUCCESS': 'User created with success',
    'USERS_ROLES.SPECIAL_USERS.CHANGE.ROLES.SUCCESS': 'Roles changed with success',
    'USERS_ROLES.SPECIAL_USERS.REMOVE.ROLES.SUCCESS': 'Roles removed with success',
    'USERS_ROLES.SPECIAL_USERS.DELETE.USER.SUCCESS': 'User deleted with success',

    'LIBRARY.HOME.TITLE': 'Library',
    'LIBRARY.HOME.SUBTITLE': 'Manage the Smart Office library contents for your organization',
    'LIBRARY.FIELD.FILE.DROPZONE.DRAG.MESSAGE': 'Drop the files here ...',
    'LIBRARY.FIELD.FILE.DROPZONE.DEFAULT.MESSAGE': 'Drag and drop files here, or click to select files',

    'LIBRARY.VALIDATION.NAME.REQUIRED': 'Name is required',
    'LIBRARY.VALIDATION.TEXT.REQUIRED': 'Text is required',
    'LIBRARY.VALIDATION.INVALID.CONTENT_TYPE': 'Invalid document type',
    'LIBRARY.VALIDATION.CONTENT_TYPE.REQUIRED': 'Document type required',
    'LIBRARY.VALIDATION.NODE.REQUIRED': 'One node at least is required',
    'LIBRARY.CONTENT_TYPES.FIELD.PLACEHOLDER': 'Select a type',
    'LIBRARY.TOOLBAR.CONTENT_TYPES.TOOLTIP': 'Set a document type',
    'LIBRARY.TOOLBAR.DELETE.TOOLTIP': 'Delete',
    'LIBRARY.TOOLBAR.SELECTED.ITEMS': '{numSelected} selected',

    'LIBRARY.CREATE.SUCCESS.MESSAGE': 'Library nodes created with success',
    'LIBRARY.CREATION.FAILURE.MESSAGE': 'Library nodes creation failed',
    'LIBRARY.TABLE.HEADER.FILE_NAME': 'File name',
    'LIBRARY.TABLE.HEADER.CONTENT_TYPE': 'Type',
    'LIBRARY.TABLE.HEADER.FILE_PATH': 'File path',

    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.PowerPointSlide': 'PPT slide',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.None': 'None',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.PowerPointPresentation': 'PPT Presentation',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.ExcelWorksheet': 'Excel worksheet',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.ExcelWorkbook': 'Excel workbook',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.WordDocument': 'Word document',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Icon': 'Icon',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Text': 'Text',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Shape': 'Sticker shape',

    'LIBRARY.MENU.ADD.FOLDER': 'Add folder',
    'LIBRARY.MENU.ADD.FILES': 'Add files',
    'LIBRARY.MENU.MORE.ACTIONS': 'More actions',
    'LIBRARY.CREATE.FOLDER.SUCCESS': 'Folder created with success',
    'LIBRARY.UPDATE.SUCCESS.MESSAGE': 'Library node updated with success',
    'LIBRARY.DELETE.SUCCESS.MESSAGE': 'Library node deleted with success',
    'LIBRARY.CREATE.FOLDER.NAME.FIELD.PLACEHOLDER': 'Name',
    'LIBRARY.CREATE.FOLDER.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'LIBRARY.CREATE.FOLDER.DIALOG.TITLE': 'New folder',
    'LIBRARY.TREE.ROOT.NODE.PowerPointSlide': 'Slides',
    'LIBRARY.TREE.ROOT.NODE.PowerPointPresentation': 'Presentations',
    'LIBRARY.TREE.ROOT.NODE.ExcelWorksheet': 'Worksheets',
    'LIBRARY.TREE.ROOT.NODE.ExcelWorkbook': 'Workbooks',
    'LIBRARY.TREE.ROOT.NODE.WordDocument': 'Documents',
    'LIBRARY.TREE.ROOT.NODE.Icon': 'Icons',

    'LIBRARY.EDIT.TITLE': 'Update > {name}',
    'LIBRARY.CREATE.TITLE': 'Add files to > {name}',

    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.TITLE': 'Replaceable fields',
    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.NAME.FIELD.PLACEHOLDER': 'Name',
    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.TEXT.FIELD.PLACEHOLDER': 'Value',

    'LIBRARY.UPDATE.MARKETING_VALIDATION': 'Content validated by marketing and communication',

    'LIBRARY.UPDATE.FILTERING_TAGS.TITLE': 'Filtering tags',
    'LIBRARY.UPDATE.FILTERING_TAGS.TAG.FIELD.PLACEHOLDER': 'Tag',
    'LIBRARY.UPDATE.VALIDATION.TAG.REQUIRED': 'Tag is required',
    'LIBRARY.WARNING': 'Warning',
    'LIBRARY.WARNING.MESSAGE':
        'Please be aware that the maps data provided may not reflect the official stance of all countries or regions. Borders, place names, and territorial claims can be sources of dispute and sensitivity. Users are advised to exercise caution and conduct additional research, especially in areas with geopolitical tensions. Respect local laws and regulations while using this information.',

    'PAGE.NOT.FOUND': "404, Sorry the page doen't not exist",

    'RESOURCES.SIDE_BAR_MENU.LINKS.ENTRY': 'Useful links',
    'RESOURCES.LINKS.AREA.TITLE': 'Links',
    'RESOURCES.LINKS.CREATE.SUCCESS.MESSAGE': 'Link created with success',
    'RESOURCES.LINKS.UPDATE.SUCCESS.MESSAGE': 'Link updated with success',
    'RESOURCES.LINKS.DELETE.SUCCESS.MESSAGE': 'Link deleted with success',
    'RESOURCES.LINKS.TOOLBAR.EDIT.TOOLTIP': 'Edit the link',
    'RESOURCES.LINKS.TOOLBAR.DELETE.TOOLTIP': 'Delete the link',
    'RESOURCES.LINKS.CREATE.NAME.FIELD.PLACEHOLDER': 'Name',
    'RESOURCES.LINKS.CREATE.URL.FIELD.PLACEHOLDER': 'Url',
    'RESOURCES.LINKS.CREATE.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'RESOURCES.LINKS.FORM.VALIDATION.NAME.REQUIRED': 'Name is required',
    'RESOURCES.LINKS.FORM.VALIDATION.DESCRIPTION.REQUIRED': 'Description is required',

    'RESOURCES.LINKS.FORM.VALIDATION.URL.REQUIRED': 'Url is required',
    'RESOURCES.LINKS.FORM.VALIDATION.URL.INVALID': 'Invalid url format',
    'RESOURCES.LINKS.DIALOG.TITLE': 'Add/Edit Link',

    'RESOURCES.SIDE_BAR_MENU.LANGUAGE_PACKS.ENTRY': 'Language packs',
    'RESOURCES.LANGUAGE_PACKS.AREA.TITLE': 'Language packs',
    'RESOURCES.LANGUAGE_PACKS.DIALOG.TITLE': 'Create a language pack',

    'RESOURCES.LANGUAGE_PACKS.DOWNLOAD.FILES': 'Download language pack file',

    'RESOURCES.LANGUAGE_PACKS.FORM.TYPE.FIELD.PLACEHOLDER': 'Select a software type',
    'RESOURCES.LANGUAGE_PACKS.FORM.FILE.FIELD.MSG': 'Please choose a file, only .txt is accepted',
    'RESOURCES.LANGUAGE_PACKS.FORM.LANGUAGE_TAG.FIELD.PLACEHOLDER': 'Language tag ex fr-FR',
    'RESOURCES.LANGUAGE_PACKS.FORM.VERSION.FIELD.PLACEHOLDER': 'version ex 1',

    'RESOURCES.SIDE_BAR_MENU.USER_GUIDE.ENTRY': 'User guides',
    'RESOURCES.SIDE_BAR_MENU.COLOR_SHORTCUTS.ENTRY': 'Color shortcuts',
    'RESOURCES.COLOR_SHORT_CUTS.DIALOG.TITLE': 'Add/Edit color shortcut',
    'RESOURCES.COLOR_SHORTCUTS.CREATE.COLOR.FIELD.PLACEHOLDER': 'Color HEX Code',
    'RESOURCES.COLOR_SHORTCUTS.CREATE.NAME.FIELD.PLACEHOLDER': 'Name',
    'RESOURCES.COLOR_SHORTCUTS.EDIT': 'Edit',
    'RESOURCES.COLOR_SHORTCUTS.DELETE': 'Delete',
    'RESOURCES.COLOR_SHORTCUTS.CREATE.SUCCESS.MESSAGE': 'Color created with success',
    'RESOURCES.COLOR_SHORTCUTS.UPDATE.SUCCESS.MESSAGE': 'Color edited with success',
    'RESOURCES.COLOR_SHORTCUTS.DELETE.SUCCESS.MESSAGE': 'Color deleted with success',
    'RESOURCES.SIDE_BAR_MENU.BRANDING_RESOURCES.ENTRY': 'Branding resources',
    'RESOURCES.BRANDING_RESOURCES.DIALOG.TITLE': 'Override branding resource at country level', //TODO one title for master orga and other for orga
    'CONFIGURATION.SIDE_BAR_MENU.FEEDBACK_CONFIG.ENTRY': 'Feedback config',
    'CONFIGURATION.SIDE_BAR_MENU.LEARNING_LINK.ENTRY': 'Learning link',
    'CONFIGURATION.SIDE_BAR_MENU.AGENDA_DESIGN.ENTRY': 'Agenda design',

    'RESOURCES.BRANDING_RESOURCES.FORM.EXISTING.RESOURCE.INFO.MESSAGE':
        'This branding resource has already been customized for the your organization, you could check it in the Branding Resources screen',
    'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.TYPE.REQUIRED': 'The type is required',
    'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.FILE.REQUIRED': 'The file is required',
    'RESOURCES.BRANDING_RESOURCES.FORM.TYPE.FIELD.PLACEHOLDER': 'Select a branding type',
    'RESOURCES.BRANDING_RESOURCES.FORM.FILE.FIELD.MSG':
        'Please choose a file, only .ico, .png, .jpeg, .jpg are accepted',
    'RESOURCES.BRANDING_RESOURCES.CREATE.SUCCESS.MESSAGE': 'Branding resource created with success',
    'RESOURCES.BRANDING_RESOURCES.UPDATE.SUCCESS.MESSAGE': 'Branding resource updated with success',
    'RESOURCES.BRANDING_RESOURCES.DELETE.SUCCESS.MESSAGE': 'Branding resource deleted with success',

    'CONFIGURATION.FEEDBACK.DIALOG.TITLE': 'Edit a feedback config',
    'CONFIGURATION.FEEDBACK.EMPTY.MESSAGE':
        'The feedback configuration has not been set up for your organization, please contact your administrator.',
    'CONFIGURATION.FEEDBACK.FORM.VALIDATION.EMAIL.REQUIRED': 'The email address is required',
    'CONFIGURATION.FEEDBACK.FORM.VALIDATION.BODY.REQUIRED': 'The email body is required',
    'CONFIGURATION.FEEDBACK.FORM.EMAIL.FIELD.PLACEHOLDER': 'Email address',
    'CONFIGURATION.FEEDBACK.FORM.BODY.FIELD.PLACEHOLDER': 'Email body',
    'CONFIGURATION.FEEDBACK.CREATE.DIALOG.TITLE': 'New feedback config',
    'CONFIGURATION.FEEDBACK.EDIT.DIALOG.TITLE': 'Edit feedback config',
    'CONFIGURATION.FEEDBACK_CONFIG.DELETE.SUCCESS.MESSAGE': 'Feedback config deleted with success',
    'CONFIGURATION.FEEDBACK_CONFIG.CREATE.SUCCESS.MESSAGE': 'Feedback config created with success',
    'CONFIGURATION.FEEDBACK_CONFIG.UPDATE.SUCCESS.MESSAGE': 'Feedback config updated with success',
    'CONFIGURATION.FEEDBACK_CONFIG.FORM.EXISTING.CONFIG.INFO.MESSAGE':
        'This feedback configuration has already been customized for the your organization, you could check it in the Feedback config screen under configuration',
    'CONFIGURATION.LEARNING_LINK.DELETE.SUCCESS.MESSAGE': 'Learning link deleted with success',
    'CONFIGURATION.LEARNING_LINK.CREATE.SUCCESS.MESSAGE': 'Learning link created with success',
    'CONFIGURATION.LEARNING_LINK.UPDATE.SUCCESS.MESSAGE': 'Learning link updated with success',
    'CONFIGURATION.LEARNING_LINK.FORM.VALIDATION.NAME.REQUIRED': 'Name is required',
    'CONFIGURATION.LEARNING_LINK.FORM.VALIDATION.DESCRIPTION.REQUIRED': 'Description is required',
    'CONFIGURATION.LEARNING_LINK.FORM.VALIDATION.URL.REQUIRED': 'Url is required',
    'CONFIGURATION.LEARNING_LINK.FORM.NAME.FIELD.PLACEHOLDER': 'Name',
    'CONFIGURATION.LEARNING_LINK.FORM.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'CONFIGURATION.LEARNING_LINK.FORM.URL.FIELD.PLACEHOLDER': 'Url',
    'CONFIGURATION.LEARNING_LINK.DIALOG.TITLE': 'Edit a learning link',

    'COMMON.OVERRIDING.INFO.MESSAGE':
        'The resources listed here are available at Forvis Mazars and could be customized for your country',

    'COMMON.DEFAULT.INFO.MESSAGE':
        'The resources listed here are available globally at Forvis Mazars and could be customized by each country',

    'CONFIGURATION.AGENDA_DESIGN.DIALOG.TITLE': 'Add/Edit an agenda design',
    'CONFIGURATION.AGENDA_DESIGN.PREVIEW.FILE': 'Preview an agenda design file',
    'CONFIGURATION.AGENDA_DESIGN.DOWNLOAD.FILE': 'Download an agenda design file',
    'CONFIGURATION.AGENDA_DESIGN_FORM.EXISTING.VERSION.INFO.MESSAGE':
        'An agenda design has already been set for this version for your country, however you could updated it',
    'CONFIGURATION.AGENDA_DESIGN.FORM.FILE.FIELD.MSG': 'Please choose a file, only .potx files are accepted',
    'CONFIGURATION.AGENDA_DESIGN.EMPTY.MESSAGE':
        'The agenda design configuration has not been set up for your organization yet, please contact your administrator.',
    'CONFIGURATION.LEARNING_LINK.EMPTY.MESSAGE':
        'The learning link configuration has not been set up for your organization yet, please contact your administrator.',

    'CONFIGURATION.AGENDA_DESIGN.DELETE.SUCCESS.MESSAGE': 'Agenda design deleted with success',
    'CONFIGURATION.AGENDA_DESIGN.CREATE.SUCCESS.MESSAGE': 'Agenda design created with success',
    'CONFIGURATION.AGENDA_DESIGN.UPDATE.SUCCESS.MESSAGE': 'Agenda design updated with success',
    'CONFIGURATION.AGENDA_DESIGN.FORM.VALIDATION.VERSION.REQUIRED': 'Version required',
    'CONFIGURATION.AGENDA_DESIGN.FORM.VALIDATION.FILE.REQUIRED': 'File required',
    'CONFIGURATION.AGENDA_DESIGN.FORM.VERSION.FIELD.PLACEHOLDER': 'Version',

    'ORGANIZATION.UPDATE.SUCCESS.MESSAGE': 'Organization is updated with success',
    'ORGANIZATION.TITLE': 'Organization',
    'ORGANIZATION.OVERVIEW.NAME': 'Name',
    'ORGANIZATION.OVERVIEW.USER_DNS_DOMAINS': 'User DNS domains:',
    'ORGANIZATION.OVERVIEW.HOST_DOMAINS': 'Host domains:',
    'ORGANIZATION.OVERVIEW.WINDOWS_REGION': 'Windows region:',
    'ORGANIZATION.OVERVIEW.AREA': 'Area:',
    'MASTER.ORGANIZATION.TITLE': '{name} master organization',
    'MASTER.ORGANIZATION.SUBTITLE': 'Information of your master organization',
    'MASTER.ORGANIZATION.CHILDS.TITLE': 'Child organizations',
    'MASTER.ORGANIZATION.CHILDS.SUBTITLE': 'The list of child organization of {name}',

    'RESOURCES.USER_GUIDE.DIALOG.TITLE': 'User guide',
    'RESOURCES.USER_GUIDE.CREATE.SUCCESS.MESSAGE': 'User guide created with success',
    'RESOURCES.USER_GUIDE.UPDATE.SUCCESS.MESSAGE': 'User guide updated with success',
    'RESOURCES.USER_GUIDE.DELETE.SUCCESS.MESSAGE': 'User guide deleted with success',
    'RESOURCES.USER_GUIDE.FORM.FILE.FIELD.MSG': 'Please choose a file, only .pdf is accepted',

    'RESOURCES.LANGUAGE_PACKS.CREATE.SUCCESS.MESSAGE': 'Language pack created with success',
    'RESOURCES.LANGUAGE_PACKS.UPDATE.SUCCESS.MESSAGE': 'Language pack updated with success',
    'RESOURCES.LANGUAGE_PACKS.DELETE.SUCCESS.MESSAGE': 'Language pack deleted with success',

    'RESOURCES.EXCEL.TREE.TITLE': 'Excel',
    'RESOURCES.WORD.TREE.TITLE': 'Word',
    'RESOURCES.POWERPOINT.TREE.TITLE': 'PowerPoint',

    'CREDENTIALS.HOME.TITLE': 'Credentials',
    'CREDENTIALS.HOME.SUBTITLE': 'Manage credentials for your organization',
    'CREDENTIALS.FORM.NAME.FIELD.PLACEHOLDER': 'Name',
    'CREDENTIALS.FORM.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'CREDENTIALS.FORM.VALIDATION.NAME.REQUIRED': 'Name is required',
    'CREDENTIALS.TABLE.HEADER.DESCRIPTION': 'Description',
    'CREDENTIALS.CREATE.TITLE': 'Create credentials',
    'CREDENTIALS.CREATE.SUCCESS.MESSAGE': 'Credentials packs created with success',
    'CREDENTIALS.CREATION.FAILURE.MESSAGE': 'Credentials packs creation failed',
    'CREDENTIALS.UPDATE.SUCCESS.MESSAGE': 'Credentials pack updated with success',
    'CREDENTIALS.UPDATE.FAILURE.MESSAGE': 'Credentials packs update failed',
    'CREDENTIALS.DELETE.SUCCESS.MESSAGE': 'Credentials pack deleted with success',
    'CREDENTIALS.DELETE.FAILURE.MESSAGE': 'Credentials packs deletion failed',
    'LIBRARY.CREATE.BANNER.TITLE': 'Important Notice',
    'LIBRARY.CREATE.BANNER.MESSAGE':
        'To ensure smooth and efficient performance for all users, please avoid uploading files that are too large. Large files can cause significant slowdowns and disrupt the experience in Excel/PowerPoint/Word for everyone.\n' +
        'Thank you for your cooperation!',

    'MACRO.HOME.TITLE': 'Macros',
    'MACRO.HOME.SUBTITLE': 'Manage VBA macos for your organization',
    'MACRO.HOME.MACROS': '{count} macros: {values} ',
    'MACRO.EDIT.MACROS_ITEMS.TITLE': 'Embedded VBA macros',
    'MACROS.FORM.VALIDATION.TITLE.REQUIRED': 'Title is required',
    'MACROS.FORM.VALIDATION.NAME.REQUIRED': 'Name is required',
    'MACROS.FORM.VALIDATION.DESCRIPTION.REQUIRED': 'Description is required',

    'MACROS.FORM.VALIDATION.ITEMS.REQUIRED': 'Declare at least one VBA Macro',
    'MACROS.FORM.VALIDATION.ITEMS.UNIQUE': 'Title and name must be unique in the list of VBA macro',
    'MACROS.CREATE.TITLE': 'Create macros',
    'MACROS.TABLE.HEADER.MACROS': 'Macros',
    'MACROS.FIELD.FILE.DROPZONE.DEFAULT.MESSAGE':
        'Drag and drop .xlsm files containi VBA macros here, or click to select files',
    'MACROS.TABLE.HEADER.ACTIONS': 'Actions',
    'MACROS.FORM.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'MACROS.FORM.NAME.FIELD.PLACEHOLDER': 'Name',
    'MACROS.FORM.TITLE.FIELD.PLACEHOLDER': 'Title',
    'MACROS.CREATE.SUCCESS.MESSAGE': 'Macros packs created with success',
    'MACROS.CREATION.FAILURE.MESSAGE': 'Macros packs creation failed',
    'MACROS.UPDATE.SUCCESS.MESSAGE': 'Macros pack updated with success',
    'MACROS.UPDATE.FAILURE.MESSAGE': 'Macros packs update failed',
    'MACROS.DELETE.SUCCESS.MESSAGE': 'Macros pack deleted with success',
    'MACROS.DELETE.FAILURE.MESSAGE': 'Macros packs deletion failed'
};
