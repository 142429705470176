import { Organization, User, AuthSessionInfoSpec, UserOrganizationRole } from '../../domain';
import { AuthProvider } from '../../types/types';

export function typedAction<T extends string>(type: T): { type: T };
export function typedAction<T extends string, P extends string>(type: T, payload: P): { type: T; payload: P };
export function typedAction<T extends string, P extends { [key: string]: string | boolean | User | Organization | AuthProvider | AuthSessionInfoSpec | UserOrganizationRole[] | undefined; }>(
    type: T,
    payload: P
): { type: T; payload: P };

export function typedAction(type: string, payload?: any) {
    return { type, payload };
}
