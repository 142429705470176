import { OrganizationRolesRepositorySpec, EOrganizationRole, EMasterOrganizationRole } from '../../../domain';
import { UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import { useIntl } from 'react-intl';
import React, { useContext, useEffect, useState } from 'react';
import { UserRolesPanels } from '../../../types/types';
import { useRepository } from '../../../hooks/useRepository';
import { Box } from '@mui/material';
import { SpecialUsersContainer } from './specialUsers/SpecialUsersContainer';
import { RolesContainer } from './roles/RolesContainer';
import { CONTAINER_TOP_MARGIN } from '../../../res/dimensions';
import { APIScaffoldChildContext } from '../../scaffolder/APIScaffold';
import { AppContext } from '../../../context/AppContext';

interface UsersRolesContentProps {
    rolesRepository: OrganizationRolesRepositorySpec;
}

export const UsersRolesContent = ({
                                      cancelRequest,
                                      rolesRepository,
                                      setSessionExpired,
                                      setToast,
                                      setLoading
                                  }: UsersRolesContentProps & UIScaffoldChildContext & APIScaffoldChildContext) => {

    const intl = useIntl();
    const [organizationRoles, setOrganizationRoles] = useState<EOrganizationRole[] | EMasterOrganizationRole[]>([]);
    const { sessionInfo, currentOrganization } = useContext(AppContext);

    const [expanded, setExpanded] = React.useState<UserRolesPanels | false>(false);

    const { data: fetchedRoles } = useRepository({
            setLoading,
            setSessionExpired,
            setToast
        },
        intl, () => rolesRepository.fetchAll(currentOrganization.id, sessionInfo), [currentOrganization]);

    useEffect(() => {
        setOrganizationRoles(fetchedRoles ?? []);
        return () => {
            cancelRequest(rolesRepository);
        };
    }, [fetchedRoles]);

    const handlePanelChange = (panel: UserRolesPanels) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={styles.container} className='container'>

            <Box>
                <SpecialUsersContainer handlePanelChange={handlePanelChange}
                                          roles={organizationRoles}
                                          isExpanded={expanded === UserRolesPanels.SpecialUsers}
                    //TODO manage who can create new special user
                                          isCreateUserAllowed={true}
                                          isDeleteUserAllowed={true}
                                          setLoading={setLoading}
                                          rolesRepository={rolesRepository}
                                          setSessionExpired={setSessionExpired}
                                          setToast={setToast}
                />
            </Box>
            <Box>
                <RolesContainer handlePanelChange={handlePanelChange}
                                roles={organizationRoles}
                                isExpanded={expanded === UserRolesPanels.Roles} />
            </Box>
        </Box>
    );
};

const styles =
    {
        container: {
            display: 'flex',
            flexDirection: 'column',
            my: CONTAINER_TOP_MARGIN,
            gap: '20px'
        }
    } as const;
