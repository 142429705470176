import FileViewer from 'react-file-viewer';
import React from 'react';

interface UIFilePreviewProps {
    file: Blob;
    fileExtension: string;
}

//TODO use syncfusion instead
//TODO ppt is not supported
export const UIFilePreview = ({ file, fileExtension }: UIFilePreviewProps) => {
    const url = URL.createObjectURL(file);
    const fileType = fileExtension?.replace('.', '');

    return (
        <FileViewer
            fileType={fileType}
            filePath={url}
            onError={(error: unknown) => {
                console.log(error);
            }}
        />
    );
};
