
import { SoftwareType } from '../types/types';
import { groupBy } from './helper';
import { BaseResource } from '../domain/resources/common/BaseResource';

export const format = (languagePacks: BaseResource[]): Record<SoftwareType, Record<string, BaseResource[]>> => {
    const wordPacks = languagePacks.filter(pack => pack.emOfficeSoftware === SoftwareType.Word);
    const excelPacks = languagePacks.filter(pack => pack.emOfficeSoftware === SoftwareType.Excel);
    const powerPointPacks = languagePacks.filter(pack => pack.emOfficeSoftware === SoftwareType.PowerPoint);

    const result: Record<SoftwareType, Record<string, BaseResource[]>> = {
        [SoftwareType.Excel]: groupBy(excelPacks, 'languageTag'),
        [SoftwareType.PowerPoint]: groupBy(powerPointPacks, 'languageTag'),
        [SoftwareType.Word]: groupBy(wordPacks, 'languageTag')
    };

    return result;
};
