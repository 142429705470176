import { Button, ButtonProps} from '@mui/material';
import { Palette } from '../../../res/colors';
import * as React from 'react';
import { UIText } from './UIText';

export type UIButtonDisplay = 'primary' | 'secondary';

export interface UIActionButtonProps {
    display?: UIButtonDisplay;
    loading?: boolean;
    rounded?: boolean;
    title: string | JSX.Element;
}

export const UIActionButton = ({
                                   title,
                                   children,
                                   display = 'primary',
                                   loading = false,
                                   rounded = true,
                                   sx,
                                   ...rest
                               }: UIActionButtonProps & ButtonProps) => {

    const style = { ...styles.button(display, rounded), ...sx };

    return (
        <Button
            startIcon={loading && <span className='mr-6 spinner spinner-white' />}
            sx={style}
            {...rest}
        >
            <UIText text={title} sx={{ color: 'inherit' }} />
            {children}
        </Button>
    );
};


const styles = {
    button: (display: UIButtonDisplay, rounded: boolean) => ({
        minWidth: '24%',
        borderWidth: 1,
        borderColor: Palette.primaryTextColor,
        backgroundColor: display === 'primary' ? Palette.defaultPrimaryColor : 'transparent',
        borderBottomLeftRadius: rounded ? 20 : 0,
        border: display === 'primary' ? '0' : 1,
        color: display === 'primary' ? 'white' : Palette.primaryTextColor,
        '&:hover': {
            backgroundColor: Palette.darkPrimaryColor,
            color: 'white'
        },
        '&:disabled': {
            backgroundColor: Palette.whiteSmoke,
            color: Palette.primaryTextColor
        }
    })
};