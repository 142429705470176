import { EmptyGuid } from '../../../types/types';
import { MacroPack } from './MacroPack';

export type PostMacroPack = Pick<
    MacroPack,
    'organizationId' | 'masterOrganizationId' | 'name' | 'description' | 'fileHash' | 'macros' | 'fileExtension'
>;

export interface MacroPackDataSpec {
    files: Blob[];
    info: PostMacroPack[] | PostMacroPack[];
}

export interface MacroPackFileData extends MacroPack {
    file?: Blob | null;
}

export const initMacroPackInfo: MacroPack = {
    organizationId: null,
    masterOrganizationId: EmptyGuid,
    description: '',
    name: '',
    id: EmptyGuid,
    fileExtension: 'xslm',
    createDate: '',
    updateDate: '',
    macros: []
};
