import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../context/AppContext';
import { useRepository } from '../../../../hooks/useRepository';
import { useIntl } from 'react-intl';
import { Box, IconButton, Tooltip } from '@mui/material';
import { format } from '../../../../utils/languagePacksHelper';
import { saveFileFromBlob } from '../../../../utils/fileHelper';
import { BaseResource, UserGuide, UserGuideRepositorySpec } from '../../../../domain';
import { GuidesArea } from '../common/GuideArea';
import { resourcesStyles } from '../common/ResourcesStyles';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { UIDialog } from '../../common/UIDialog';
import { GetApp } from '@mui/icons-material';
import { UIFilePreview } from '../../common/UIFilePreview';
import { UIContentScaffold } from '../../common/UIContentScaffold';
import { FilesMimeTypes } from '../../../../types/types';
import { getMasterOrganizationId } from '../../../../utils/organizationHelper';

export interface UserGuidesContentProps {
    userGuideRepository: UserGuideRepositorySpec;
    isEditable: boolean;
}

export const UserGuidesContent = ({
    isEditable,
    setSessionExpired,
    setToast,
    setLoading,
    userGuideRepository
}: UserGuidesContentProps & UIScaffoldChildContext) => {
    const [selectedGuide, setSelectedGuide] = useState<BaseResource | null>(null);
    const [downloadedFile, setDownloadedFile] = useState<Blob | null>(null);
    const [openPreviewFile, setOpenPreviewFile] = useState<boolean>(false);

    const intl = useIntl();
    const { sessionInfo, currentOrganization } = useContext(AppContext);

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const updatePreviewFileVisibility = () => {
        setOpenPreviewFile(!openPreviewFile);
    };

    const onDownload = async (selectedPack: BaseResource) => {
        // @ts-ignore
        await callRepository(
            () => userGuideRepository.fetchFile(selectedPack.id, sessionInfo),
            downloadedFile => {
                downloadedFile &&
                    saveFileFromBlob(
                        downloadedFile as Blob,
                        `${selectedPack?.languageTag}-${selectedPack?.version}${selectedPack?.fileExtension}`
                    );
            }
        );
    };

    const onPreview = async (guide: BaseResource) => {
        await callRepository(
            () => userGuideRepository.fetchFile(guide.id, sessionInfo),
            file => {
                if (file) {
                    setSelectedGuide(guide);
                    setDownloadedFile(file);
                    updatePreviewFileVisibility();
                }
            }
        );
    };

    return (
        <>
            <Box sx={resourcesStyles.container}>
                <UIContentScaffold
                    fetchParams={{ masterOrganizationId: getMasterOrganizationId(currentOrganization) }}
                    successMessagePrefix="RESOURCES.USER_GUIDE"
                    repository={userGuideRepository}
                    setToast={setToast}
                    setLoading={setLoading}
                    setSessionExpired={setSessionExpired}>
                    {({ data, handleCreate, handleDelete, handleUpdate }) => (
                        <GuidesArea
                            dialogTitle={getLocalizedString(intl, 'RESOURCES.USER_GUIDE.DIALOG.TITLE')}
                            isVisualizable
                            groupedSoftwareGuides={format((data as UserGuide[]) ?? [])}
                            isEditable={isEditable}
                            handleCreate={handleCreate}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                            handleDownload={onDownload}
                            handlePreview={onPreview}
                            filesInputProps={{
                                filesLimit: 1,
                                dropzoneDefaultText: getLocalizedString(
                                    intl,
                                    'RESOURCES.USER_GUIDE.FORM.FILE.FIELD.MSG'
                                ),
                                acceptedFiles: FilesMimeTypes.Pdf as string[],
                                showPreviews: true
                            }}
                            title={getLocalizedString(intl, 'RESOURCES.SIDE_BAR_MENU.USER_GUIDE.ENTRY')}
                        />
                    )}
                </UIContentScaffold>

                {
                    //TODO put it in guide area file
                    selectedGuide && downloadedFile && openPreviewFile && (
                        <UIDialog
                            open={openPreviewFile}
                            toolbarActions={
                                <IconButton aria-label="download" onClick={() => onDownload(selectedGuide)}>
                                    <Tooltip title={getLocalizedString(intl, 'COMMON.ACTIONS.DOWNLOAD')}>
                                        <GetApp color="primary" />
                                    </Tooltip>
                                </IconButton>
                            }
                            onClose={updatePreviewFileVisibility}
                            title={getLocalizedString(intl, 'COMMON.DIALOG.PREVIEW.TITLE')}>
                            <UIFilePreview fileExtension={selectedGuide.fileExtension} file={downloadedFile} />
                        </UIDialog>
                    )
                }
            </Box>
        </>
    );
};
