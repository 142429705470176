import { DropzoneProps, DropzoneRef, useDropzone } from 'react-dropzone';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CloudUpload } from '@mui/icons-material';
import { Palette } from '../../../res/colors';
import { UIText } from './UIText';
import { Box, Stack } from '@mui/material';

export interface UIDropzoneProps extends DropzoneProps {
    handleUploadFiles: (files: any) => void;
    dropzoneDragText: string;
    dropzoneDefaultText: string;
    showPreviews?: boolean;
    filesLimit?: number;
}

export const UIDropzone = ({
    showPreviews = false,
    handleUploadFiles,
    dropzoneDefaultText,
    dropzoneDragText,
    accept,
    filesLimit
}: UIDropzoneProps & DropzoneProps & React.RefAttributes<DropzoneRef>) => {
    const [files, setFiles] = useState<any[]>([]);

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    const onDrop = useCallback(acceptedFiles => {
        handleUploadFiles(acceptedFiles);
        setFiles(
            acceptedFiles.map((file: any) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            )
        );
    }, []);

    const thumbs = files.map(file => (
        <Box sx={thumb} key={file.name}>
            <Box sx={thumbInner}>
                <img
                    style={image}
                    src={file.preview}
                    alt={file.name}
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview);
                    }}
                />
            </Box>
        </Box>
    ));

    const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        accept: accept,
        maxFiles: filesLimit
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <section>
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <UIText text={!isDragActive ? dropzoneDefaultText : dropzoneDragText} />
                <CloudUpload fontSize="large" />
            </div>
            {showPreviews && (
                <Stack direction="row" spacing={1} sx={thumbsContainer}>
                    {thumbs}
                </Stack>
            )}
        </section>
    );
};

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: Palette.dividerColor,
    borderStyle: 'dashed',
    backgroundColor: Palette.extraLightBlue,
    color: Palette.primaryTextColor,
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: Palette.defaultPrimaryColor
};

const acceptStyle = {
    borderColor: Palette.queenBee
};

const rejectStyle = {
    borderColor: Palette.byzantium
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row' as const,
    flexWrap: 'wrap' as const,
    marginTop: 1
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 1,
    marginRight: 1,
    width: 120,
    height: 120,
    padding: 2,
    boxSizing: 'border-box' as const
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const image = {
    display: 'block',
    width: 'auto',
    height: '100%'
};
