import { ApiDatasource, ApiDatasourceSpec, ErrorCode, MZRHttpClient } from './mazars-network-http-client';
import { AuthSessionInfoSpec, initAuthInfo } from './authentication/model/initAuthInfo';
import { RepositoryProvider } from './RepositoryProvider';
import { appConfiguration } from '../appConfiguration';
import { setSessionExpired, setSessionInfo } from '../store/modules/user';
import { store } from '../store';
import { TheGateAuthResponse } from './authentication/model/TheGateAuthResponse';
import AuthSessionService from '../services/AuthSessionService';

export interface MazarsErrorSpec {
    message?: string;
    status?: number;
    apiError?: string;
    errorCode?: ErrorCode;
}

export interface RepositorySpec {
    datasource: ApiDatasourceSpec;
    refreshAccessToken: (sessionInfoParam?: AuthSessionInfoSpec) => () => Promise<TheGateAuthResponse | null>;
}

export const apiDefaultOptions = new Map<string, any>([
    ['headers', { ['ApiKey']: appConfiguration.API_KEY }],
    ['withResponseInterceptor', true]
]);

export class Repository implements RepositorySpec {
    constructor(
        public datasource: ApiDatasourceSpec = new ApiDatasource(
            new MZRHttpClient(appConfiguration.API_BASE_URL, apiDefaultOptions, 1)
        )
    ) {
        this.datasource = datasource;
    }

    refreshAccessToken = (_?: AuthSessionInfoSpec): (() => Promise<TheGateAuthResponse | null>) => {
        return (): Promise<TheGateAuthResponse | null> => {
            const localSessionInfo = AuthSessionService.getLocalSessionInfo();
            const dispatch = store.dispatch;
            const result = localSessionInfo
                ? RepositoryProvider.getProvider()?.authRepository &&
                  RepositoryProvider.getProvider()
                      ?.authRepository?.refresh(localSessionInfo.accessToken, localSessionInfo.refreshToken)
                      .then(response => {
                          const updatedSessionInfo: AuthSessionInfoSpec = {
                              ...localSessionInfo,
                              accessToken: response?.token ?? '',
                              refreshToken: response?.refreshToken ?? ''
                          };

                          AuthSessionService.setLocalSessionInfo(updatedSessionInfo);
                          dispatch(setSessionExpired(false));
                          // dispatch is asynchronous, if many calls are failed with 401 at the same time, not all of them will have the updated sessionInfo
                          dispatch(setSessionInfo(updatedSessionInfo, 'theGate')); //TODO don't hard code theGate here

                          return Promise.resolve(response);
                      })
                      .catch(e => {
                          // the refresh api failed, it can be a problem of sync of dispatch of redux or a real case where tne token can't be refreshed by api
                          dispatch(setSessionExpired(true));
                          return Promise.reject(e);
                      })
                : Promise.reject(initAuthInfo);

            return result;
        };
    };
}
