import React, { Fragment, useContext, useRef, useState } from 'react';
import { MacroPack } from '../../../domain/macroPack/model/MacroPack';
import { initMacroPackInfo, MacroPackFileData } from '../../../domain/macroPack/model/initMacroPack';
import { UISectionHeader } from '../common/UISectionHeader';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { Box, Divider, IconButton, List, Paper, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { UIText } from '../common/UIText';
import { CONTAINER_TOP_MARGIN } from '../../../res/dimensions';
import { NavRoutes } from '../../../navigation/NavRoutes';
import { useNavigate } from 'react-router-dom';
import { MacroListItem } from './MacroListItem';
import { FormikProps } from 'formik/dist/types';
import { saveFileFromUrl } from '../../../utils/fileHelper';
import { AppContext } from '../../../context/AppContext';
import { getMasterOrganizationId, getOrganizationId } from '../../../utils/organizationHelper';
import { UIDialog } from '../common/UIDialog';
import { Delete, GetApp } from '@mui/icons-material';
import { Palette } from '../../../res/colors';
import { UIActionButton } from '../common/UIActionButton';
import { MacroPackPreview } from './MacroPackPreview';

type MacroPacksAreaProps = {
    packs: MacroPack[];
    isEditable: boolean;
    handleEdit: (pack: MacroPackFileData[]) => Promise<boolean>;
    handleDelete: (ids: string[]) => void;
    handlePreview: (pack: MacroPack) => Promise<Blob | undefined>;
    handleError: (error: string) => void;
};
export const MacroPacksArea: React.FC<MacroPacksAreaProps> = ({
    isEditable,
    packs,
    handleEdit,
    handleDelete,
    handlePreview,
    handleError
}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    const [selectedMacroPack, setSelectedMacroPack] = useState<MacroPack | null>(null);

    const [downloadedFile, setDownloadedFile] = useState<Blob | undefined>(undefined);

    const updateFormRef = useRef();

    const onCreateRequested = () => {
        navigate(NavRoutes.CreateMacroPack);
    };

    const onEditRequested = async (pack: MacroPack) => {
        const file = await handlePreview(pack);
        if (!file) {
            return;
            //TODO throw an error
        }

        setDownloadedFile(file);

        setSelectedMacroPack({
            ...initMacroPackInfo,
            ...pack,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });

        setOpenDialog(!openDialog);
    };

    const onCloseDialog = () => {
        setOpenDialog(!openDialog);
    };

    const doTriggerSubmit = () => {
        const formik = updateFormRef.current ? (updateFormRef.current as FormikProps<MacroPackFileData>) : null;
        formik?.handleSubmit();
    };

    const onUpdateSubmit = async (packData: MacroPackFileData) => {
        const isSuccess = await handleEdit([packData]);
        setOpenDialog(!isSuccess);
    };

    const onDelete = async (pack: MacroPack) => {
        await handleDelete([pack.id]);
    };

    const handleDownload = (file: Blob | undefined, fileName?: string) => {
        if (file && fileName) {
            const url = URL.createObjectURL(file);
            saveFileFromUrl(url, fileName);
        }
    };

    return (
        <Box className="container" sx={styles.container}>
            <Paper elevation={3} sx={{ px: 3, py: 1, mt: 1 }}>
                <UISectionHeader
                    title={getLocalizedString(intl, 'MACRO.HOME.TITLE')}
                    onCreate={onCreateRequested}
                    isCreateEnabled={isEditable}
                />

                {isEditable && <UIText text={getLocalizedString(intl, 'MACRO.HOME.SUBTITLE')} />}

                <List style={{ maxHeight: '100%' }} sx={{ flexGrow: 1, overflowY: 'auto' }}>
                    <>
                        {packs && packs.length > 0
                            ? packs.map(pack => (
                                  <Fragment key={pack.id}>
                                      <MacroListItem
                                          item={pack}
                                          isEditable={isEditable}
                                          onDelete={onDelete}
                                          onEdit={onEditRequested}
                                      />
                                      <Divider />
                                  </Fragment>
                              ))
                            : null}
                    </>
                </List>
                <UIDialog
                    open={openDialog}
                    toolbarActions={
                        <>
                            <IconButton
                                aria-label="download"
                                onClick={() => handleDownload(downloadedFile, selectedMacroPack?.name)}>
                                <Tooltip title={getLocalizedString(intl, 'COMMON.ACTIONS.DOWNLOAD')}>
                                    <GetApp color="primary" />
                                </Tooltip>
                            </IconButton>

                            {isEditable && selectedMacroPack && (
                                <IconButton aria-label="delete" onClick={() => handleDelete([selectedMacroPack.id])}>
                                    <Tooltip title={getLocalizedString(intl, 'COMMON.ACTIONS.DELETE')}>
                                        <Delete sx={{ color: Palette.red }} />
                                    </Tooltip>
                                </IconButton>
                            )}
                        </>
                    }
                    onClose={onCloseDialog}
                    title={selectedMacroPack?.name ?? ''}
                    actions={
                        isEditable ? (
                            <UIActionButton
                                title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                                onClick={doTriggerSubmit}
                            />
                        ) : undefined
                    }>
                    <>
                        {selectedMacroPack && (
                            <MacroPackPreview
                                isEditable={isEditable}
                                pack={selectedMacroPack}
                                file={downloadedFile}
                                formRef={updateFormRef}
                                handleUpdate={onUpdateSubmit}
                                handleError={handleError}
                            />
                        )}
                    </>
                </UIDialog>
            </Paper>
        </Box>
    );
};

const styles = {
    container: {
        mt: CONTAINER_TOP_MARGIN,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    }
} as const;
