import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { CreateNewFolder, Delete, Edit, MoreVert, PostAdd, SvgIconComponent } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { UIDropDownMenu, UIDropDownMenuEntryProps } from '../../common/UIDropDownMenu';
import { UIText } from '../../common/UIText';
import { Library } from '../../../../domain';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { LibraryTreeItemProps } from '..';
import { LibraryNodeType } from '../../../../types/types';

const MoreActionTitleComponent = () => {
    return (
        <IconButton>
            <MoreVert />
        </IconButton>
    );
};

export const LibraryTreeItemActions = ({ node, onAdd, onDelete, onEdit }: LibraryTreeItemProps) => {
    const intl = useIntl();

    const isDirectory = node.eLibraryNodeType === LibraryNodeType.Directory;

    const handleEdit = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        onEdit(node);
    };

    const handleDelete = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        node.id && onDelete(node.id);
    };

    const makeDownMenuEntry = (
        identifier: string,
        Icon: SvgIconComponent,
        onClick: (event: React.MouseEvent) => void
    ) => {
        return {
            id: identifier,
            titleComponent: () => (
                <Box onClick={onClick} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Icon sx={{ mr: 1 }} />
                    <UIText text={getLocalizedString(intl, `COMMON.ACTIONS.${identifier}`)} />
                </Box>
            )
        };
    };

    //Build the menu actions according to the type of the node
    const menuEntries: UIDropDownMenuEntryProps[] = [makeDownMenuEntry('EDIT', Edit, handleEdit)];

    menuEntries.push(makeDownMenuEntry('DELETE', Delete, handleDelete));

    const handleAddFolder = (node: Library) => (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        onAdd(node, LibraryNodeType.Directory);
    };

    const handleAddFiles = (node: Library) => (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        onAdd(node, LibraryNodeType.File);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {isDirectory ? (
                <>
                    <Tooltip title={getLocalizedString(intl, 'LIBRARY.MENU.ADD.FOLDER')}>
                        <IconButton color="inherit" onClick={handleAddFolder(node)}>
                            <CreateNewFolder />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={getLocalizedString(intl, 'LIBRARY.MENU.ADD.FILES')}>
                        <IconButton color="inherit" onClick={handleAddFiles(node)}>
                            <PostAdd />
                        </IconButton>
                    </Tooltip>
                </>
            ) : null}
            {menuEntries && (
                <Tooltip title={getLocalizedString(intl, 'LIBRARY.MENU.MORE.ACTIONS')}>
                    <UIDropDownMenu entries={menuEntries} id={'menu'} titleComponent={MoreActionTitleComponent} />
                </Tooltip>
            )}
        </Box>
    );
};
