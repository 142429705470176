export const fr = {
    'common.master.organization.name': 'Default',

    'COMMON.ADD.TITLE': 'Ajouter',
    'COMMON.EMPTY.DATA.ERROR': 'Une erreur est survenue. Aucunes données.',
    'COMMON.NETWORK.ERROR.0': '',
    'COMMON.NETWORK.ERROR.-1': 'Une erreur technique est survenue.',
    'COMMON.NETWORK.ERROR.TECHNICAL': 'Une erreur technique est survenue.',
    'COMMON.NETWORK.ERROR.400': 'Les données envoyées ne sont pas valides. Merci de réessayer.',
    'COMMON.NETWORK.ERROR.401': "Vous n'avez pas accès à cette ressource.",
    'COMMON.NETWORK.ERROR.403': "Cette ressource n'est pas autorisée.",
    'COMMON.NETWORK.ERROR.404': "Les données demandées n'existent pas.",
    'COMMON.NETWORK.ERROR.500': 'Une erreur serveur est survenue.',
    'COMMON.VALIDATION.EMAIL.FORMAT': 'Format de courriel invalide.',
    'COMMON.EMAIL.FIELD.PLACEHOLDER': 'Courriel',
    'COMMON.TABLE.NO.RECORDS': 'Aucune entrée',
    'COMMON.ACTIONS.CANCEL': 'Annuler',
    'COMMON.ACTIONS.SAVE': 'Sauvegarder',
    'COMMON.ACTIONS.DELETE': 'Supprimer',
    'COMMON.ACTIONS.EDIT': 'Modifier',
    'COMMON.ACTIONS.DOWNLOAD': 'Télécharger',
    'COMMON.ACTIONS.VALIDATE': 'Valider',
    'COMMON.ACTIONS.LOGOUT': 'Déconnexion',
    'COMMON.LOADER.DEFAULT.MESSAGE': 'Chargement ...',
    'COMMON.DIALOG.PREVIEW.TITLE': 'Visualiser fichier',
    'COMMON.UNKNOWN': 'Inconnu',

    'FOOTER.MESSAGE': 'Réalisé par Forvis Mazars Global Technology & Innovation 2024 | Tous droits réservés',

    'INVALID.SESSION.TITLE': 'Session invalide',
    'INVALID.SESSION.MESSAGE': 'Votre session à expiré, reconnectez vous pour avoir accès au logiciel.',

    'AUTH.SIGN_IN': "S'identifier avec \nForvis Mazars Connect",

    'AUTH.LOGIN.MESSAGE': 'Se connecter à mon espace sécurisé',
    'AUTH.LOGIN.WELCOME.TITLE': 'Portail Smart Office',

    'AUTH.LOGIN.WELCOME.SUBTITLE':
        'Gérer et administrer le contenu et les paramètres de Smart Office pour votre organisation',
    'AUTH.PASSWORD.FIELD.PLACEHOLDER': 'Mot de passe',
    'AUTH.ORGANIZATION.FIELD.PLACEHOLDER': 'Sélectionner un pays Mazars',
    'AUTH.ORGANIZATION_ID.MISSING': 'Merci de sélectionner votre pays',
    'AUTH.ERROR.400': 'Identifiants de connection invalides. Merci de réessayer.',
    'AUTH.ERROR.401': 'Identifiants de connection invalides. Merci de réessayer.',
    'AUTH.ERROR.403': 'Identifiants de connection invalides. Merci de réessayer.',
    'AUTH.ERROR.UNKNOWN': 'Une erreur inconnue est survenue',
    'AUTH.VALIDATION.EMAIL.REQUIRED': 'Veuillez indiquer votre adresse courriel',
    'AUTH.VALIDATION.PASSWORD.REQUIRED': 'Veuillez reseigner votre mot de passe',
    'AUTH.VALIDATION.ORGANIZATION.REQUIRED':
        "Echec de connexion, il parait qu'il y a un problème lié à votre organisation, veuillez contacter votre admin",

    'AUTH.VALIDATION.INVALID.COUNTRY': 'Pays invalide',

    'HEADER.TOP_BAR.ACCOUNT.LOGOUT': 'Déconnexion',
    'HEADER.TOP_BAR.HELP.USE': 'Aide et utilisation',
    'HEADER.MENU.APP.NAME': 'Smart Office | ',
    'HEADER.MENU.PAGE.USERS_ROLES': 'Utilisateurs et Rôles',
    'HEADER.MENU.PAGE.LIBRARY': 'Configuration bibliothèque',
    'HEADER.MENU.PAGE.CONTENTS': 'Bibliothèque',
    'HEADER.MENU.PAGE.ACCOUNT': 'Compte',
    'HEADER.MENU.PAGE.RESOURCES': 'Ressources',
    'HEADER.MENU.PAGE.CONFIGURATION': 'Configuration',
    'HEADER.MENU.PAGE.ORGANIZATION': 'Organisation',
    'HEADER.MENU.PAGE.MASTER_ORGANIZATION': 'Organisation parente',
    'HEADER.MENU.PAGE.CREDENTIALS': 'Credentials',
    'HEADER.MENU.PAGE.MACRO': 'Macros',

    'ACCOUNT.SIDE_BAR_MENU.OVERVIEW_ENTRY': 'Vue générale',
    'ACCOUNT.SIDE_BAR_MENU.DEVICES_ENTRY': 'Appareils',
    'ACCOUNT.SIDE_BAR_MENU.LICENSE_ENTRY': 'Licence',
    'ACCOUNT.SIDE_BAR_MENU.SIGN_INS_ENTRY': 'Connexions',

    'ACCOUNT.OVERVIEW.USER.EMAIL': 'Courriel :',
    'ACCOUNT.OVERVIEW.USER.MASTER.ORGANIZATION': 'Organisation parente :',
    'ACCOUNT.OVERVIEW.USER.ORGANIZATIONS': 'Organisations :',
    'ACCOUNT.OVERVIEW.USER.ROLES': 'Rôles',
    'ACCOUNT.OVERVIEW.USER_AREA.TITLE': 'Utilisateur',
    'ACCOUNT.OVERVIEW.DEVICES_AREA.TITLE': 'Appareils',

    'USERS_ROLES.SPECIAL_USERS.TITLE': 'Utilisateurs spéciaux',
    'USERS_ROLES.USER.ROLES.TITLE': 'Rôles utilisateur',
    'USERS_ROLES.SPECIAL_USERS.MESSAGE':
        'Définir des utilisateurs spéciaux dans votre organisation et leur attribuer des rôles au sein de votre organisation',
    'USERS_ROLES.SPECIAL_USERS.DROP_DOWN.PLACEHOLDER': 'Rôle utilisateur',
    'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.EMAIL.REQUIRED': "l'Email est obligatoire",
    'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.INVALID.ROLE': 'Rôle invlaide',
    'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.ROLE.REQUIRED': 'Le rôle est obligatoire',
    'USERS_ROLES.SPECIAL_USERS.CREATE.DIALOG.TITLE': 'Nouvel utilisateur',
    'USERS_ROLES.SPECIAL_USERS.CREATE.SUCCESS': 'Utilisateur créé avec succès',
    'USERS_ROLES.SPECIAL_USERS.DELETE.USER.SUCCESS': 'Utilisateur supprimé avec succès',
    'USERS_ROLES.SPECIAL_USERS.CHANGE.ROLES.SUCCESS': 'Rôle modifié avec succès',
    'USERS_ROLES.SPECIAL_USERS.REMOVE.ROLES.SUCCESS': 'Rôle supprimé avec succès',

    'LIBRARY.HOME.TITLE': 'Bibliothèque',
    'LIBRARY.HOME.SUBTITLE': 'Gérer les contenus de la bibliothèque Smart Office de votre organisation',
    'LIBRARY.FIELD.FILE.DROPZONE.DRAG.MESSAGE': 'Déposer les fichiers ici ...',
    'LIBRARY.FIELD.FILE.DROPZONE.DEFAULT.MESSAGE':
        'Glisser déposer les fichiers ici, ou cliquer pour sélectionner les fichiers',

    'LIBRARY.VALIDATION.NAME.REQUIRED': 'Le nom est requis',
    'LIBRARY.VALIDATION.TEXT.REQUIRED': 'Le texte est requis',
    'LIBRARY.VALIDATION.INVALID.CONTENT_TYPE': 'Type de document invalide',
    'LIBRARY.VALIDATION.CONTENT_TYPE.REQUIRED': 'Le type du document est requis',
    'LIBRARY.VALIDATION.NODE.REQUIRED': 'Au moins un noeud est requis',
    'LIBRARY.CONTENT_TYPES.FIELD.PLACEHOLDER': 'Sélectionner un type',
    'LIBRARY.TOOLBAR.CONTENT_TYPES.TOOLTIP': 'Sélectionner un type de document',
    'LIBRARY.TOOLBAR.DELETE.TOOLTIP': 'Supprimer',
    'LIBRARY.TOOLBAR.SELECTED.ITEMS': '{numSelected} sélectionnés',

    'LIBRARY.CREATE.SUCCESS.MESSAGE': 'Noeuds de bibliothèques créés avec succès',
    'LIBRARY.CREATION.FAILURE.MESSAGE': 'La création des noeuds de librairie a échoué',
    'LIBRARY.TABLE.HEADER.FILE_NAME': 'Nom du fichier',
    'LIBRARY.TABLE.HEADER.CONTENT_TYPE': 'Type',
    'LIBRARY.TABLE.HEADER.FILE_PATH': 'Emplacement',

    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.PowerPointSlide': 'Diapositive PPT',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.None': 'None',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.PowerPointPresentation': 'Présentation PPT',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.ExcelWorksheet': 'Feuille Excel',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.ExcelWorkbook': 'Classeur Excel',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.WordDocument': 'Document Word',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Icon': 'Icone',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Text': 'Texte',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Shape': 'Sticker shape',

    'LIBRARY.MENU.ADD.FOLDER': 'Ajouter dossier',
    'LIBRARY.MENU.ADD.FILES': 'Ajouter fichiers',
    'LIBRARY.MENU.MORE.ACTIONS': 'Actions supplémentaires',
    'LIBRARY.CREATE.FOLDER.SUCCESS': 'Dossier créé avec succès',
    'LIBRARY.UPDATE.SUCCESS.MESSAGE': 'Noeud de bibliothèque mis à jour avec succès',
    'LIBRARY.DELETE.SUCCESS.MESSAGE': 'Noeud de bibliothèque supprimé avec succès',
    'LIBRARY.CREATE.FOLDER.NAME.FIELD.PLACEHOLDER': 'Nom',
    'LIBRARY.CREATE.FOLDER.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'LIBRARY.CREATE.FOLDER.DIALOG.TITLE': 'Nouveau dossier',
    'LIBRARY.TREE.ROOT.NODE.PowerPointSlide': 'Slides',
    'LIBRARY.TREE.ROOT.NODE.PowerPointPresentation': 'Presentations',
    'LIBRARY.TREE.ROOT.NODE.ExcelWorksheet': 'Worksheets',
    'LIBRARY.TREE.ROOT.NODE.ExcelWorkbook': 'Workbooks',
    'LIBRARY.TREE.ROOT.NODE.WordDocument': 'Documents',
    'LIBRARY.TREE.ROOT.NODE.Icon': 'Icons',

    'LIBRARY.EDIT.TITLE': 'Mise à jour > {name}',
    'LIBRARY.CREATE.TITLE': 'Ajouter fichiers à > {name}',

    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.TITLE': 'Champs remplaçables',
    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.NAME.FIELD.PLACEHOLDER': 'Nom',
    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.TEXT.FIELD.PLACEHOLDER': 'Valeur',

    'LIBRARY.UPDATE.MARKETING_VALIDATION': 'Contenu validé par le marketing et la communication',

    'LIBRARY.UPDATE.FILTERING_TAGS.TITLE': 'Tags de filtrage',
    'LIBRARY.UPDATE.FILTERING_TAGS.TAG.FIELD.PLACEHOLDER': 'Tag',
    'LIBRARY.UPDATE.VALIDATION.TAG.REQUIRED': 'Tag is required',
    'LIBRARY.WARNING': 'Warning',
    'LIBRARY.WARNING.MESSAGE':
        "Veuillez noter que les données cartographiques fournies peuvent ne pas refléter la position officielle de tous les pays ou régions. Les frontières, les noms de lieux et les revendications territoriales peuvent être des sources de conflit et de sensibilité. Il est conseillé aux utilisateurs de faire preuve de prudence et d'effectuer des recherches supplémentaires, en particulier dans les zones de tensions géopolitiques. Respectez les lois et réglementations locales lors de l'utilisation de ces informations",

    'PAGE.NOT.FOUND': "404, La page demandée n'existe pas.",

    'RESOURCES.SIDE_BAR_MENU.LINKS.ENTRY': 'Liens utiles',
    'RESOURCES.LINKS.AREA.TITLE': 'Liens',
    'RESOURCES.LINKS.CREATE.SUCCESS.MESSAGE': 'Lien créé avec succès',
    'RESOURCES.LINKS.UPDATE.SUCCESS.MESSAGE': 'Lien mis à jour avec succès',
    'RESOURCES.LINKS.DELETE.SUCCESS.MESSAGE': 'Lien supprimé avec succès',
    'RESOURCES.LINKS.TOOLBAR.EDIT.TOOLTIP': 'Modifier le lien',
    'RESOURCES.LINKS.TOOLBAR.DELETE.TOOLTIP': 'Supprimer le lien',
    'RESOURCES.LINKS.CREATE.NAME.FIELD.PLACEHOLDER': 'Nom',
    'RESOURCES.LINKS.CREATE.URL.FIELD.PLACEHOLDER': 'Url',
    'RESOURCES.LINKS.CREATE.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'RESOURCES.LINKS.FORM.VALIDATION.NAME.REQUIRED': 'Le nom est requis',
    'RESOURCES.LINKS.FORM.VALIDATION.DESCRIPTION.REQUIRED': 'La description est requise',
    'RESOURCES.LINKS.FORM.VALIDATION.URL.REQUIRED': "L'Url est requis",
    'RESOURCES.LINKS.FORM.VALIDATION.URL.INVALID': "Format de l'Url invalide",
    'RESOURCES.LINKS.DIALOG.TITLE': 'Ajouter/modifier Lien',

    'RESOURCES.SIDE_BAR_MENU.LANGUAGE_PACKS.ENTRY': 'Packs de langue',
    'RESOURCES.LANGUAGE_PACKS.AREA.TITLE': 'Packs de langue',
    'RESOURCES.LANGUAGE_PACKS.DIALOG.TITLE': 'Créer un pack de langue',

    'RESOURCES.LANGUAGE_PACKS.DOWNLOAD.FILES': 'Télécharger le fichier de pack de langue',

    'RESOURCES.LANGUAGE_PACKS.FORM.TYPE.FIELD.PLACEHOLDER': 'Sélectionner le type de logiciel',
    'RESOURCES.LANGUAGE_PACKS.FORM.FILE.FIELD.MSG': 'Choisir un fichier. Uniquement les fichiers .txt sont acceptés',
    'RESOURCES.LANGUAGE_PACKS.FORM.LANGUAGE_TAG.FIELD.PLACEHOLDER': 'Tag de langue (ex fr-FR)',
    'RESOURCES.LANGUAGE_PACKS.FORM.VERSION.FIELD.PLACEHOLDER': 'version (ex 1)',

    'RESOURCES.SIDE_BAR_MENU.USER_GUIDE.ENTRY': 'Guides utilisateur',
    'RESOURCES.SIDE_BAR_MENU.COLOR_SHORTCUTS.ENTRY': 'Raccourcis couleurs',
    'RESOURCES.COLOR_SHORT_CUTS.DIALOG.TITLE': 'Ajouter/modifier raccourci couleur',
    'RESOURCES.COLOR_SHORTCUTS.CREATE.COLOR.FIELD.PLACEHOLDER': 'Color HEX Code',
    'RESOURCES.COLOR_SHORTCUTS.CREATE.NAME.FIELD.PLACEHOLDER': 'Nom',
    'RESOURCES.COLOR_SHORTCUTS.EDIT': 'Modifier',
    'RESOURCES.COLOR_SHORTCUTS.DELETE': 'Supprimer',
    'RESOURCES.COLOR_SHORTCUTS.CREATE.SUCCESS.MESSAGE': 'Coleur créée avec succès',
    'RESOURCES.COLOR_SHORTCUTS.UPDATE.SUCCESS.MESSAGE': 'Coleur mise à jour avec succès',
    'RESOURCES.COLOR_SHORTCUTS.DELETE.SUCCESS.MESSAGE': 'Coleur supprimée avec succès',
    'RESOURCES.SIDE_BAR_MENU.BRANDING_RESOURCES.ENTRY': 'Resources de marque',
    'RESOURCES.BRANDING_RESOURCES.DIALOG.TITLE': 'Surcharger les resources de marque au niveau organisation', //TODO one title for master orga and other for orga
    'CONFIGURATION.SIDE_BAR_MENU.FEEDBACK_CONFIG.ENTRY': 'Configuration retours',
    'CONFIGURATION.SIDE_BAR_MENU.LEARNING_LINK.ENTRY': 'Lien apprentissage',
    'CONFIGURATION.SIDE_BAR_MENU.AGENDA_DESIGN.ENTRY': 'Agenda Design',

    'RESOURCES.BRANDING_RESOURCES.FORM.EXISTING.RESOURCE.INFO.MESSAGE':
        "Cette resource de marque à déja été surchargée pour cette organisation, vous pouvez le voir sur l'écran correspondant",
    'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.TYPE.REQUIRED': 'Le type est requis',
    'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.FILE.REQUIRED': 'Le file est requis',
    'RESOURCES.BRANDING_RESOURCES.FORM.TYPE.FIELD.PLACEHOLDER': 'Choisir un type de resource',
    'RESOURCES.BRANDING_RESOURCES.FORM.FILE.FIELD.MSG':
        'Choisir un fichier. Uniquement .ico, .png, .jpeg, .jpg sont acceptés',
    'RESOURCES.BRANDING_RESOURCES.CREATE.SUCCESS.MESSAGE': 'Resource de marque créée avec succès',
    'RESOURCES.BRANDING_RESOURCES.UPDATE.SUCCESS.MESSAGE': 'Resource de marque mise à jour avec succès',
    'RESOURCES.BRANDING_RESOURCES.DELETE.SUCCESS.MESSAGE': 'Resource de marque supprimée avec succès',

    'CONFIGURATION.FEEDBACK.DIALOG.TITLE': 'Modifier une configuration des retours',
    'CONFIGURATION.FEEDBACK.EMPTY.MESSAGE':
        "La configuration des retours n'est pas configurée dans votre organisation.",
    'CONFIGURATION.FEEDBACK.FORM.VALIDATION.EMAIL.REQUIRED': 'Une adresse courriel est requise',
    'CONFIGURATION.FEEDBACK.FORM.VALIDATION.BODY.REQUIRED': 'Un corps de courriel est requis',
    'CONFIGURATION.FEEDBACK.FORM.EMAIL.FIELD.PLACEHOLDER': 'Courriel',
    'CONFIGURATION.FEEDBACK.FORM.BODY.FIELD.PLACEHOLDER': 'Corps de courriel',
    'CONFIGURATION.FEEDBACK.CREATE.DIALOG.TITLE': 'Nouvelle configuration des retours',
    'CONFIGURATION.FEEDBACK.EDIT.DIALOG.TITLE': 'Modifier la configuration des retours',
    'CONFIGURATION.FEEDBACK_CONFIG.DELETE.SUCCESS.MESSAGE': 'Configuration des retours créée avec succès',
    'CONFIGURATION.FEEDBACK_CONFIG.CREATE.SUCCESS.MESSAGE': 'Configuration des retours mise à jour avec succès',
    'CONFIGURATION.FEEDBACK_CONFIG.UPDATE.SUCCESS.MESSAGE': 'Configuration des retours supprimée avec succès',
    'CONFIGURATION.FEEDBACK_CONFIG.FORM.EXISTING.CONFIG.INFO.MESSAGE':
        'La configuration des retours à déja été configurée dans votre organisation.',
    'CONFIGURATION.LEARNING_LINK.DELETE.SUCCESS.MESSAGE': "Lien d'apprentissage créé avec succès",
    'CONFIGURATION.LEARNING_LINK.CREATE.SUCCESS.MESSAGE': "Lien d'apprentissage mis à jour avec succès",
    'CONFIGURATION.LEARNING_LINK.UPDATE.SUCCESS.MESSAGE': "Lien d'apprentissage supprimé avec succès",
    'CONFIGURATION.LEARNING_LINK.FORM.VALIDATION.NAME.REQUIRED': 'Le nom est requis',
    'CONFIGURATION.LEARNING_LINK.FORM.VALIDATION.DESCRIPTION.REQUIRED': 'La description est requise',
    'CONFIGURATION.LEARNING_LINK.FORM.VALIDATION.URL.REQUIRED': "L'Url est requise",
    'CONFIGURATION.LEARNING_LINK.FORM.NAME.FIELD.PLACEHOLDER': 'Nom',
    'CONFIGURATION.LEARNING_LINK.FORM.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'CONFIGURATION.LEARNING_LINK.FORM.URL.FIELD.PLACEHOLDER': 'Url',

    'COMMON.OVERRIDING.INFO.MESSAGE':
        'Les resources ci-dessous existent au niveau Forvis Mazars et sont personnalisables pour votre organisation.',
    'COMMON.DEFAULT.INFO.MESSAGE':
        'Les resources ci-dessous sont disponibles au niveau Forvis Mazars et sont personnalisables pour chaque sous organisation.',

    'CONFIGURATION.AGENDA_DESIGN.DIALOG.TITLE': 'Ajouter/Modifier un fichier agenda design',
    'CONFIGURATION.AGENDA_DESIGN.PREVIEW.FILE': 'Prévisualiser un fichier agenda design',
    'CONFIGURATION.AGENDA_DESIGN.DOWNLOAD.FILE': 'Télécharger un fichier agenda design',
    'CONFIGURATION.AGENDA_DESIGN_FORM.EXISTING.VERSION.INFO.MESSAGE':
        'An agenda design has already been set for this version for your country, however you could updated it',
    'CONFIGURATION.AGENDA_DESIGN.FORM.FILE.FIELD.MSG': 'Choisir un fichier. Uniquement .potx accepté',
    'CONFIGURATION.AGENDA_DESIGN.EMPTY.MESSAGE': "Le agenda design n'a pas été configuré pour votre organisation.",
    'CONFIGURATION.LEARNING_LINK.EMPTY.MESSAGE':
        "Le lien d'apprentissage n'a pas été configuré pour votre organisation.",

    'CONFIGURATION.AGENDA_DESIGN.DELETE.SUCCESS.MESSAGE': 'Agenda design créé avec succès',
    'CONFIGURATION.AGENDA_DESIGN.CREATE.SUCCESS.MESSAGE': 'Agenda design mis à jour avec succès',
    'CONFIGURATION.AGENDA_DESIGN.UPDATE.SUCCESS.MESSAGE': 'Agenda design supprimé avec succès',
    'CONFIGURATION.AGENDA_DESIGN.FORM.VALIDATION.VERSION.REQUIRED': 'La version est requise',
    'CONFIGURATION.AGENDA_DESIGN.FORM.VALIDATION.FILE.REQUIRED': 'Le fichier est requis',
    'CONFIGURATION.AGENDA_DESIGN.FORM.VERSION.FIELD.PLACEHOLDER': 'Version',

    'ORGANIZATION.UPDATE.SUCCESS.MESSAGE': 'Organisation mise à jour avec succès',
    'ORGANIZATION.TITLE': 'Organisation',
    'ORGANIZATION.OVERVIEW.NAME': 'Nom',
    'ORGANIZATION.OVERVIEW.USER_DNS_DOMAINS': 'User DNS domains :',
    'ORGANIZATION.OVERVIEW.HOST_DOMAINS': 'Host domains :',
    'ORGANIZATION.OVERVIEW.WINDOWS_REGION': 'Region Windows :',
    'ORGANIZATION.OVERVIEW.AREA': 'Zone :',
    'MASTER.ORGANIZATION.TITLE': '{name} organisation parente',
    'MASTER.ORGANIZATION.SUBTITLE': 'Information de votre organisation parente',
    'MASTER.ORGANIZATION.CHILDS.TITLE': 'Sous organisations',
    'MASTER.ORGANIZATION.CHILDS.SUBTITLE': 'Liste des sous organisations de {name}',

    'RESOURCES.USER_GUIDE.DIALOG.TITLE': 'Guide utilisateur',
    'RESOURCES.USER_GUIDE.CREATE.SUCCESS.MESSAGE': 'Guide utilisateur créé avec succès',
    'RESOURCES.USER_GUIDE.UPDATE.SUCCESS.MESSAGE': 'Guide utilisateur mis à jour avec succès',
    'RESOURCES.USER_GUIDE.DELETE.SUCCESS.MESSAGE': 'Guide utilisateur supprimé avec succès',
    'RESOURCES.USER_GUIDE.FORM.FILE.FIELD.MSG': 'Choisir un fichier. Uniquement .pdf accepté',

    'RESOURCES.LANGUAGE_PACKS.CREATE.SUCCESS.MESSAGE': 'Pack de langue créé avec succès',
    'RESOURCES.LANGUAGE_PACKS.UPDATE.SUCCESS.MESSAGE': 'Pack de langue mis à jour avec succès',
    'RESOURCES.LANGUAGE_PACKS.DELETE.SUCCESS.MESSAGE': 'Pack de langue supprimé avec succès',

    'RESOURCES.EXCEL.TREE.TITLE': 'Excel',
    'RESOURCES.WORD.TREE.TITLE': 'Word',
    'RESOURCES.POWERPOINT.TREE.TITLE': 'PowerPoint',

    'CREDENTIALS.HOME.TITLE': 'Credentials',
    'CREDENTIALS.HOME.SUBTITLE': 'Gérer les contenus des credentials de Smart Office de votre organisation',
    'CREDENTIALS.FORM.NAME.FIELD.PLACEHOLDER': 'Name',
    'CREDENTIALS.FORM.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'CREDENTIALS.TABLE.HEADER.DESCRIPTION': 'Description',

    'CREDENTIALS.CREATE.TITLE': 'Créer des credentials',
    'CREDENTIALS.CREATE.SUCCESS.MESSAGE': 'Credentials créés avec succès',
    'CREDENTIALS.CREATION.FAILURE.MESSAGE': 'La création des credentials a échoué',
    'CREDENTIALS.UPDATE.SUCCESS.MESSAGE': 'Credential modifié avec succès',
    'CREDENTIALS.UPDATE.FAILURE.MESSAGE': 'La mise à jour des credentials a échoué',
    'CREDENTIALS.DELETE.SUCCESS.MESSAGE': 'Credential supprimé avec succès',
    'CREDENTIALS.DELETE.FAILURE.MESSAGE': 'La suppression des credentials a échoué',
    'LIBRARY.CREATE.BANNER.TITLE': 'Notice Importante',
    'LIBRARY.CREATE.BANNER.MESSAGE':
        "Afin de garantir des performances fluides et efficaces pour tous les utilisateurs, évitez de charger des fichiers trop volumineux. Les fichiers volumineux peuvent provoquer des ralentissements importants et perturber l'expérience d'Excel/PowerPoint/Word pour tout le monde.\n" +
        'Nous vous remercions de votre collaboration !',
    'MACRO.HOME.TITLE': 'Macros',
    'MACRO.HOME.SUBTITLE': 'Gérer les macos VBA pour votre organisation',
    'MACRO.HOME.MACROS': '{count} macros: {values} ',
    'MACRO.EDIT.MACROS_ITEMS.TITLE': 'Macros VBA du fichier',

    'MACROS.FORM.VALIDATION.NAME.REQUIRED': 'Le nom est requis',
    'MACROS.FORM.VALIDATION.DESCRIPTION.REQUIRED': 'La description est requise',
    'MACROS.FORM.VALIDATION.ITEMS.REQUIRED': 'Déclarer au moins une macro VBA',
    'MACROS.FORM.VALIDATION.ITEMS.UNIQUE': 'Le titre et le nom doivent être uniques dans la liste des macros VBA.',
    'MACROS.CREATE.TITLE': 'Créer',
    'MACROS.TABLE.HEADER.MACROS': 'Macros',
    'MACROS.FIELD.FILE.DROPZONE.DEFAULT.MESSAGE':
        'Faites glisser et déposez les fichiers .xlsm, contenant les macros VBA, ici, ou cliquez pour sélectionner les fichiers.',
    'MACROS.TABLE.HEADER.ACTIONS': 'Actions',
    'MACROS.FORM.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'MACROS.FORM.NAME.FIELD.PLACEHOLDER': 'Nom',
    'MACROS.FORM.TITLE.FIELD.PLACEHOLDER': 'Titre',

    'MACROS.CREATE.SUCCESS.MESSAGE': 'Macros créés avec succès',
    'MACROS.CREATION.FAILURE.MESSAGE': 'La création des macros a échoué',
    'MACROS.UPDATE.SUCCESS.MESSAGE': 'Macro modifié avec succès',
    'MACROS.UPDATE.FAILURE.MESSAGE': 'La mise à jour des macros a échoué',
    'MACROS.DELETE.SUCCESS.MESSAGE': 'Macro supprimé avec succès',
    'MACROS.DELETE.FAILURE.MESSAGE': 'La suppression des macros a échoué'
};
