import { EmptyGuid, SoftwareType } from '../../../types/types';

export interface BaseResource {
    id: string;
    emOfficeSoftware: SoftwareType;
    languageTag: string;
    fileExtension: string;
    version: number;
    masterOrganizationId: string;
    createDate: string;
    updateDate: string;
}

export interface BaseResourceInfoSpec {
    id?: string;
    emOfficeSoftware?: SoftwareType;
    languageTag: string;
    version?: number;
    masterOrganizationId: string;
}

export interface BaseResourceDataSpec extends BaseResourceInfoSpec {
    file?: Blob;
}

export const initBaseResource: BaseResourceDataSpec = {
    version: undefined,
    masterOrganizationId: EmptyGuid,
    emOfficeSoftware: undefined,
    languageTag: '',
    file: undefined,
    id: undefined
};

export const getBaseSourceData = (resource: BaseResource) => ({
    version: resource.version,
    masterOrganizationId: resource.masterOrganizationId,
    emOfficeSoftware: resource.emOfficeSoftware,
    languageTag: resource.languageTag,
    id: resource.id
});
