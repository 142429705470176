export const es = {
    'common.master.organization.name': 'Predeterminado',
    'COMMON.ADD.TITLE': 'Agregar',

    'COMMON.EMPTY.DATA.ERROR': 'Se ha producido un error. No hay datos',
    'COMMON.NETWORK.ERROR.0': '',
    'COMMON.NETWORK.ERROR.-1': 'Se ha producido un error técnico.',
    'COMMON.NETWORK.ERROR.TECHNICAL': 'Se ha producido un error técnico.',
    'COMMON.NETWORK.ERROR.400': 'Los datos enviados no son válidos. Por favor, inténtelo de nuevo.',
    'COMMON.NETWORK.ERROR.401': 'No tiene autorización para acceder a este recurso.',
    'COMMON.NETWORK.ERROR.403': 'Este recurso está prohibido',
    'COMMON.NETWORK.ERROR.404': 'Los datos solicitados no existen',
    'COMMON.NETWORK.ERROR.500': 'Se ha producido un error en el server.',
    'COMMON.VALIDATION.EMAIL.FORMAT': 'Formato de correo electrónico inválido',
    'COMMON.EMAIL.FIELD.PLACEHOLDER': 'Correo electrónico',
    'COMMON.TABLE.NO.RECORDS': 'Sin registros',
    'COMMON.ACTIONS.CANCEL': 'Cancelar',
    'COMMON.ACTIONS.SAVE': 'Guardar',
    'COMMON.ACTIONS.DELETE': 'Suprimir',
    'COMMON.ACTIONS.EDIT': 'Editar',
    'COMMON.ACTIONS.DOWNLOAD': 'Descargar',
    'COMMON.ACTIONS.VALIDATE': 'Validar',
    'COMMON.ACTIONS.LOGOUT': 'Cerrar sesión',
    'COMMON.LOADER.DEFAULT.MESSAGE': 'Cargando...',
    'COMMON.DIALOG.PREVIEW.TITLE': 'Ver el archivo',
    'COMMON.UNKNOWN': 'Desconocido',

    'FOOTER.MESSAGE': 'Impulsado por Forvis Mazars Global Technology & Innovation 2023 | Todos derechos reservados',

    'INVALID.SESSION.TITLE': 'Sesión inválida',
    'INVALID.SESSION.MESSAGE': 'Su sesión ha caducado, por favor inicie sesión nuevamente para acceder a la plataforma',

    'AUTH.SIGN_IN': 'Iniciar sesión con \nForvis Mazars Connect',

    'AUTH.LOGIN.MESSAGE': 'Conectar con mi espacio seguro',
    'AUTH.LOGIN.WELCOME.TITLE': 'Portal de Smart Office',
    'AUTH.LOGIN.WELCOME.SUBTITLE': 'Administre los contenidos y configuraciones de Smart Office para su organización',

    'AUTH.PASSWORD.FIELD.PLACEHOLDER': 'Contraseña',
    'AUTH.ORGANIZATION.FIELD.PLACEHOLDER': 'Selecciona un país',
    'AUTH.ORGANIZATION_ID.MISSING': 'Por favor, seleccione su país',
    'AUTH.ERROR.400': 'Sus credenciales no son válidas. Por favor, inténtelo de nuevo.',
    'AUTH.ERROR.401': 'Sus credenciales no son válidas. Por favor, inténtelo de nuevo.',
    'AUTH.ERROR.403': 'Sus credenciales no son válidas. Por favor, inténtelo de nuevo.',
    'AUTH.ERROR.UNKNOWN': 'Se ha producido un error de inicio de sesión desconocido',
    'AUTH.VALIDATION.EMAIL.REQUIRED': 'Por favor introduzca su correo electrónico',
    'AUTH.VALIDATION.PASSWORD.REQUIRED': 'Por favor introduzca su contraseña',
    'AUTH.VALIDATION.ORGANIZATION.REQUIRED':
        'Error de conexión, parece que hay un problema relacionado con su organización, póngase en contacto con su administrador.',

    'AUTH.VALIDATION.INVALID.COUNTRY': 'País inválido',

    'HEADER.TOP_BAR.ACCOUNT.LOGOUT': 'Desconectarse',
    'HEADER.TOP_BAR.HELP.USE': 'Ayuda y uso',
    'HEADER.MENU.APP.NAME': 'Smart Office | ',
    'HEADER.MENU.PAGE.USERS_ROLES': 'Usuarios y Roles',
    'HEADER.MENU.PAGE.LIBRARY': 'Configuración de biblioteca',
    'HEADER.MENU.PAGE.CONTENTS': 'Biblioteca',
    'HEADER.MENU.PAGE.ACCOUNT': 'Cuenta',
    'HEADER.MENU.PAGE.RESOURCES': 'Recursos',
    'HEADER.MENU.PAGE.CONFIGURATION': 'Configuración',
    'HEADER.MENU.PAGE.ORGANIZATION': 'Organización',
    'HEADER.MENU.PAGE.MASTER_ORGANIZATION': 'Organización Maestra',
    'HEADER.MENU.PAGE.CREDENTIALS': 'Credentials',
    'HEADER.MENU.PAGE.MACRO': 'Macros',

    'ACCOUNT.SIDE_BAR_MENU.OVERVIEW_ENTRY': 'Resumen',
    'ACCOUNT.SIDE_BAR_MENU.DEVICES_ENTRY': 'Dispositivos',
    'ACCOUNT.SIDE_BAR_MENU.LICENSE_ENTRY': 'Licencia',
    'ACCOUNT.SIDE_BAR_MENU.SIGN_INS_ENTRY': 'Inicios de sesión',

    'ACCOUNT.OVERVIEW.USER.EMAIL': 'Correo electrónico:',
    'ACCOUNT.OVERVIEW.USER.MASTER.ORGANIZATION': 'Organización maestra:',
    'ACCOUNT.OVERVIEW.USER.ORGANIZATIONS': 'Organizaciones:',
    'ACCOUNT.OVERVIEW.USER.ROLES': 'Roles',
    'ACCOUNT.OVERVIEW.USER_AREA.TITLE': 'Usuario',
    'ACCOUNT.OVERVIEW.DEVICES_AREA.TITLE': 'Dispositivos',

    'USERS_ROLES.SPECIAL_USERS.TITLE': 'Usuarios especiales',
    'USERS_ROLES.USER.ROLES.TITLE': 'Roles de usuario',
    'USERS_ROLES.SPECIAL_USERS.MESSAGE':
        'Define usuarios especiales en tu organización y asígnales roles dentro de tu organización',
    'USERS_ROLES.SPECIAL_USERS.DROP_DOWN.PLACEHOLDER': 'Roles de usuario',
    'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.EMAIL.REQUIRED': 'Se requiere correo electrónico',
    'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.INVALID.ROLE': 'Rol inválido',
    'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.ROLE.REQUIRED': 'Se requiere rol',
    'USERS_ROLES.SPECIAL_USERS.CREATE.DIALOG.TITLE': 'Agregar usuario',
    'USERS_ROLES.SPECIAL_USERS.CREATE.SUCCESS': 'Usuario creado con éxito',
    'USERS_ROLES.SPECIAL_USERS.CHANGE.ROLES.SUCCESS': 'Roles cambiados con éxito',
    'USERS_ROLES.SPECIAL_USERS.REMOVE.ROLES.SUCCESS': 'Roles eliminados  con éxito',
    'USERS_ROLES.SPECIAL_USERS.DELETE.USER.SUCCESS': 'Usuario eliminado con éxito',

    'LIBRARY.HOME.TITLE': 'Biblioteca',
    'LIBRARY.HOME.SUBTITLE': 'Administra los contenidos de la Biblioteca de Smart Office para tu organización',
    'LIBRARY.FIELD.FILE.DROPZONE.DRAG.MESSAGE': 'Suelta los archivos aquí ...',
    'LIBRARY.FIELD.FILE.DROPZONE.DEFAULT.MESSAGE':
        'Arrastra y suelta archivos aquí, o haz clic para seleccionar archivos',

    'LIBRARY.VALIDATION.NAME.REQUIRED': 'Se requiere el nombre',
    'LIBRARY.VALIDATION.TEXT.REQUIRED': 'Se requiere el text',
    'LIBRARY.VALIDATION.INVALID.CONTENT_TYPE': 'Tipo de documento inválido',
    'LIBRARY.VALIDATION.CONTENT_TYPE.REQUIRED': 'Se requiere el tipo de documento',
    'LIBRARY.VALIDATION.NODE.REQUIRED': 'Se requiere al menos un nodo',
    'LIBRARY.CONTENT_TYPES.FIELD.PLACEHOLDER': 'Selecciona un tipo',
    'LIBRARY.TOOLBAR.CONTENT_TYPES.TOOLTIP': 'Establecer un tipo de documento',
    'LIBRARY.TOOLBAR.DELETE.TOOLTIP': 'Eliminar',
    'LIBRARY.TOOLBAR.SELECTED.ITEMS': '{numSelected} seleccionados',

    'LIBRARY.CREATE.SUCCESS.MESSAGE': 'Nodos de la biblioteca creados con éxito',
    'LIBRARY.CREATION.FAILURE.MESSAGE': 'Se ha producido un error en la creación de nodos de la biblioteca',
    'LIBRARY.TABLE.HEADER.FILE_NAME': 'Nombre del archivo',
    'LIBRARY.TABLE.HEADER.CONTENT_TYPE': 'Tipo',
    'LIBRARY.TABLE.HEADER.FILE_PATH': 'Ruta del archivo',

    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.PowerPointSlide': 'PPT diapositivo',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.None': 'Ninguno',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.PowerPointPresentation': 'PPT presentación',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.ExcelWorksheet': 'Excel worksheet',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.ExcelWorkbook': 'Excel workbook',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.WordDocument': 'Word documento',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Icon': 'Ícono',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Text': 'Texto',
    'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Shape': 'Sticker forma',

    'LIBRARY.MENU.ADD.FOLDER': 'Agregar carpeta',
    'LIBRARY.MENU.ADD.FILES': 'Agregar archivos',
    'LIBRARY.MENU.MORE.ACTIONS': 'Más acciones',
    'LIBRARY.CREATE.FOLDER.SUCCESS': 'Carpeta creada con éxito',
    'LIBRARY.UPDATE.SUCCESS.MESSAGE': 'Nodo de la biblioteca actualizado con éxito',
    'LIBRARY.DELETE.SUCCESS.MESSAGE': 'Nodo de la biblioteca eliminado  con éxito',
    'LIBRARY.CREATE.FOLDER.NAME.FIELD.PLACEHOLDER': 'Nombre',
    'LIBRARY.CREATE.FOLDER.DESCRIPTION.FIELD.PLACEHOLDER': 'Descripción',
    'LIBRARY.CREATE.FOLDER.DIALOG.TITLE': 'Nueva carpeta',
    'LIBRARY.TREE.ROOT.NODE.PowerPointSlide': 'Diapositivas',
    'LIBRARY.TREE.ROOT.NODE.PowerPointPresentation': 'Presentaciones',
    'LIBRARY.TREE.ROOT.NODE.ExcelWorksheet': 'Worksheets',
    'LIBRARY.TREE.ROOT.NODE.ExcelWorkbook': 'Workbooks',
    'LIBRARY.TREE.ROOT.NODE.WordDocument': 'Documentos',
    'LIBRARY.TREE.ROOT.NODE.Icon': 'Íconos',

    'LIBRARY.EDIT.TITLE': 'Actualizar > {name}',
    'LIBRARY.CREATE.TITLE': 'Agregar archivos a > {name}',

    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.TITLE': 'Replaceable fields',
    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.NAME.FIELD.PLACEHOLDER': 'Name',
    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.DESCRIPTION.FIELD.PLACEHOLDER': 'Description',
    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.TEXT.FIELD.PLACEHOLDER': 'Value',

    'LIBRARY.UPDATE.MARKETING_VALIDATION': 'Content validated by marketing and communication',

    'LIBRARY.UPDATE.FILTERING_TAGS.TITLE': 'Filtering tags',
    'LIBRARY.UPDATE.FILTERING_TAGS.TAG.FIELD.PLACEHOLDER': 'Tag',
    'LIBRARY.UPDATE.VALIDATION.TAG.REQUIRED': 'Tag is required',

    'LIBRARY.WARNING': 'Warning',
    'LIBRARY.WARNING.MESSAGE':
        'Ten en cuenta que los datos cartográficos proporcionados pueden no reflejar la postura oficial de todos los países o regiones. Las fronteras, los topónimos y las reivindicaciones territoriales pueden ser fuente de disputas y sensibilidades. Se recomienda a los usuarios que actúen con cautela y realicen investigaciones adicionales, especialmente en zonas con tensiones geopolíticas. Respete las leyes y normativas locales al utilizar esta información',

    'PAGE.NOT.FOUND': '404, Lo siento, la página no existe',

    'RESOURCES.SIDE_BAR_MENU.LINKS.ENTRY': 'Enlaces útiles',
    'RESOURCES.LINKS.AREA.TITLE': 'Enlaces',
    'RESOURCES.LINKS.CREATE.SUCCESS.MESSAGE': 'Enlace creado con éxito',
    'RESOURCES.LINKS.UPDATE.SUCCESS.MESSAGE': 'Enlace actualizado con éxito',
    'RESOURCES.LINKS.DELETE.SUCCESS.MESSAGE': 'Enlace eliminado con éxito',
    'RESOURCES.LINKS.TOOLBAR.EDIT.TOOLTIP': 'Editar el enlace',
    'RESOURCES.LINKS.TOOLBAR.DELETE.TOOLTIP': 'Eliminar el enlace',
    'RESOURCES.LINKS.CREATE.NAME.FIELD.PLACEHOLDER': 'Nombre',
    'RESOURCES.LINKS.CREATE.URL.FIELD.PLACEHOLDER': 'Url',
    'RESOURCES.LINKS.CREATE.DESCRIPTION.FIELD.PLACEHOLDER': 'Descripción',
    'RESOURCES.LINKS.FORM.VALIDATION.NAME.REQUIRED': 'Se requiere el nombre',
    'RESOURCES.LINKS.FORM.VALIDATION.DESCRIPTION.REQUIRED': 'Se requiere el Descripción',
    'RESOURCES.LINKS.FORM.VALIDATION.URL.REQUIRED': 'Se requiere la Url',
    'RESOURCES.LINKS.FORM.VALIDATION.URL.INVALID': 'Formato de URL inválido',
    'RESOURCES.LINKS.DIALOG.TITLE': 'Agregar/Editar Enlace',

    'RESOURCES.SIDE_BAR_MENU.LANGUAGE_PACKS.ENTRY': 'Packs de idiomas',
    'RESOURCES.LANGUAGE_PACKS.AREA.TITLE': 'Packs de idiomas',
    'RESOURCES.LANGUAGE_PACKS.DIALOG.TITLE': 'Crear un pack de idioma',

    'RESOURCES.LANGUAGE_PACKS.DOWNLOAD.FILES': 'Descargar archivo de pack de idiomas',

    'RESOURCES.LANGUAGE_PACKS.FORM.TYPE.FIELD.PLACEHOLDER': 'Seleccionar un tipo de software',
    'RESOURCES.LANGUAGE_PACKS.FORM.FILE.FIELD.MSG': 'Por favor, elige un archivo, solo se acepta .txt',
    'RESOURCES.LANGUAGE_PACKS.FORM.LANGUAGE_TAG.FIELD.PLACEHOLDER': 'Etiqueta de idioma ej. es-ES',
    'RESOURCES.LANGUAGE_PACKS.FORM.VERSION.FIELD.PLACEHOLDER': 'Versión ej. 1',

    'RESOURCES.SIDE_BAR_MENU.USER_GUIDE.ENTRY': 'Guías de usuario',
    'RESOURCES.SIDE_BAR_MENU.COLOR_SHORTCUTS.ENTRY': 'Atajos de colores',
    'RESOURCES.COLOR_SHORT_CUTS.DIALOG.TITLE': 'Agregar/Editar atajo de color',
    'RESOURCES.COLOR_SHORTCUTS.CREATE.COLOR.FIELD.PLACEHOLDER': 'Código HEX de color',
    'RESOURCES.COLOR_SHORTCUTS.CREATE.NAME.FIELD.PLACEHOLDER': 'Nombre',
    'RESOURCES.COLOR_SHORTCUTS.EDIT': 'Editar',
    'RESOURCES.COLOR_SHORTCUTS.DELETE': 'Eliminar',
    'RESOURCES.COLOR_SHORTCUTS.CREATE.SUCCESS.MESSAGE': 'Color creado con éxito',
    'RESOURCES.COLOR_SHORTCUTS.UPDATE.SUCCESS.MESSAGE': 'Color editado con éxito',
    'RESOURCES.COLOR_SHORTCUTS.DELETE.SUCCESS.MESSAGE': 'Color eliminado con éxito',
    'RESOURCES.SIDE_BAR_MENU.BRANDING_RESOURCES.ENTRY': 'Recursos de marca',
    'RESOURCES.BRANDING_RESOURCES.DIALOG.TITLE': 'Anular el recurso de marca a nivel de país', //TODO one title for master orga and other for orga
    'CONFIGURATION.SIDE_BAR_MENU.FEEDBACK_CONFIG.ENTRY': 'Configuración de comentarios',
    'CONFIGURATION.SIDE_BAR_MENU.LEARNING_LINK.ENTRY': 'Enlace de capacitacion',
    'CONFIGURATION.SIDE_BAR_MENU.AGENDA_DESIGN.ENTRY': 'Agenda design',

    'RESOURCES.BRANDING_RESOURCES.FORM.EXISTING.RESOURCE.INFO.MESSAGE':
        'Este recurso de marca ya ha sido personalizado para su organización, puedes verificarlo en la pantalla de Recursos de Marca',
    'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.TYPE.REQUIRED': 'Se requiere el tipo',
    'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.FILE.REQUIRED': 'Se requiere el archivo',
    'RESOURCES.BRANDING_RESOURCES.FORM.TYPE.FIELD.PLACEHOLDER': 'Seleccionar un tipo de marca',
    'RESOURCES.BRANDING_RESOURCES.FORM.FILE.FIELD.MSG':
        'Por favor, elige un archivo, solo se aceptan .ico, .png, .jpeg, .jpg',
    'RESOURCES.BRANDING_RESOURCES.CREATE.SUCCESS.MESSAGE': 'Recurso de marca creado con éxito',
    'RESOURCES.BRANDING_RESOURCES.UPDATE.SUCCESS.MESSAGE': 'Recurso de marca actualizado con éxito',
    'RESOURCES.BRANDING_RESOURCES.DELETE.SUCCESS.MESSAGE': 'Recurso de marca eliminado con éxito',

    'CONFIGURATION.FEEDBACK.DIALOG.TITLE': 'Editar configuración de comentarios',
    'CONFIGURATION.FEEDBACK.EMPTY.MESSAGE':
        'La configuración de comentarios no se ha establecido para su organización, por favor, contacta a tu administrador.',
    'CONFIGURATION.FEEDBACK.FORM.VALIDATION.EMAIL.REQUIRED': 'Se requiere un correo electrónico',
    'CONFIGURATION.FEEDBACK.FORM.VALIDATION.BODY.REQUIRED': 'Se requiere el body del correo electrónico',
    'CONFIGURATION.FEEDBACK.FORM.EMAIL.FIELD.PLACEHOLDER': 'Correo electrónico',
    'CONFIGURATION.FEEDBACK.FORM.BODY.FIELD.PLACEHOLDER': 'Body del correo electrónico',
    'CONFIGURATION.FEEDBACK.CREATE.DIALOG.TITLE': 'Nueva configuración de comentarios',
    'CONFIGURATION.FEEDBACK.EDIT.DIALOG.TITLE': 'Editar configuración de comentarios',
    'CONFIGURATION.FEEDBACK_CONFIG.DELETE.SUCCESS.MESSAGE': 'Configuración de comentarios eliminada con éxito',
    'CONFIGURATION.FEEDBACK_CONFIG.CREATE.SUCCESS.MESSAGE': 'Configuración de comentarios creada con éxito',
    'CONFIGURATION.FEEDBACK_CONFIG.UPDATE.SUCCESS.MESSAGE': 'Configuración de comentarios actualizada con éxito',
    'CONFIGURATION.FEEDBACK_CONFIG.FORM.EXISTING.CONFIG.INFO.MESSAGE':
        'Esta configuración de comentarios ya ha sido personalizada para su organización, puedes verificarlo en la pantalla de Configuración de Comentarios en la configuración',
    'CONFIGURATION.LEARNING_LINK.DELETE.SUCCESS.MESSAGE': 'Enlace de aprendizaje eliminado con éxito',
    'CONFIGURATION.LEARNING_LINK.CREATE.SUCCESS.MESSAGE': 'Enlace de aprendizaje creado con éxito',
    'CONFIGURATION.LEARNING_LINK.UPDATE.SUCCESS.MESSAGE': 'Enlace de aprendizaje actualizado con éxito',
    'CONFIGURATION.LEARNING_LINK.FORM.VALIDATION.NAME.REQUIRED': 'Se requiere el nombre',
    'CONFIGURATION.LEARNING_LINK.FORM.VALIDATION.DESCRIPTION.REQUIRED': 'Se requiere la descripción',
    'CONFIGURATION.LEARNING_LINK.FORM.VALIDATION.URL.REQUIRED': 'Se requiere la URL',
    'CONFIGURATION.LEARNING_LINK.FORM.NAME.FIELD.PLACEHOLDER': 'Nombre',
    'CONFIGURATION.LEARNING_LINK.FORM.DESCRIPTION.FIELD.PLACEHOLDER': 'Descripción',
    'CONFIGURATION.LEARNING_LINK.FORM.URL.FIELD.PLACEHOLDER': 'Url',

    'COMMON.OVERRIDING.INFO.MESSAGE':
        'Los recursos listados aquí están disponibles en Forvis Mazars y pueden personalizarse para tu país',

    'COMMON.DEFAULT.INFO.MESSAGE':
        'TLos recursos listados aquí están disponibles globalmente en Forvis Mazars y pueden personalizarse por cada país',

    'CONFIGURATION.AGENDA_DESIGN.DIALOG.TITLE': 'Agregar/Editar un agenda design',
    'CONFIGURATION.AGENDA_DESIGN.PREVIEW.FILE': 'Ver el archivo de agenda design',
    'CONFIGURATION.AGENDA_DESIGN.DOWNLOAD.FILE': 'Descargar un archivo de agenda design',
    'CONFIGURATION.AGENDA_DESIGN_FORM.EXISTING.VERSION.INFO.MESSAGE':
        'Ya se ha establecido un agenda design para esta versión en tu país, sin embargo, puedes actualizarlo',
    'CONFIGURATION.AGENDA_DESIGN.FORM.FILE.FIELD.MSG': 'Por favor, elige un archivo, solo se aceptan archivos .potx',
    'CONFIGURATION.AGENDA_DESIGN.EMPTY.MESSAGE':
        'La configuración de diseño de agenda aún no se ha establecido para tu organización, por favor, contacta a tu administrador.',
    'CONFIGURATION.LEARNING_LINK.EMPTY.MESSAGE':
        'La configuración de enlaces de capacitacion aún no se ha establecido para tu organización, por favor, contacta a tu administrador.',

    'CONFIGURATION.AGENDA_DESIGN.DELETE.SUCCESS.MESSAGE': 'Agenda design eliminado con éxito',
    'CONFIGURATION.AGENDA_DESIGN.CREATE.SUCCESS.MESSAGE': 'Agenda design creado con éxito',
    'CONFIGURATION.AGENDA_DESIGN.UPDATE.SUCCESS.MESSAGE': 'Agenda design actualizado con éxito',
    'CONFIGURATION.AGENDA_DESIGN.FORM.VALIDATION.VERSION.REQUIRED': 'Se requiere la versión',
    'CONFIGURATION.AGENDA_DESIGN.FORM.VALIDATION.FILE.REQUIRED': 'Se requiere el archivo',
    'CONFIGURATION.AGENDA_DESIGN.FORM.VERSION.FIELD.PLACEHOLDER': 'Versión',

    'ORGANIZATION.UPDATE.SUCCESS.MESSAGE': 'Organization is updated with success',
    'ORGANIZATION.TITLE': 'Organización',
    'ORGANIZATION.OVERVIEW.NAME': 'Nombre',
    'ORGANIZATION.OVERVIEW.USER_DNS_DOMAINS': 'User DNS domains:',
    'ORGANIZATION.OVERVIEW.HOST_DOMAINS': 'Host domains:',
    'ORGANIZATION.OVERVIEW.WINDOWS_REGION': 'Región de Windows:',
    'ORGANIZATION.OVERVIEW.AREA': 'Área:',
    'MASTER.ORGANIZATION.TITLE': '{name} organización maestra',
    'MASTER.ORGANIZATION.SUBTITLE': 'Información de tu organización maestra',
    'MASTER.ORGANIZATION.CHILDS.TITLE': 'Organizaciones niñas',
    'MASTER.ORGANIZATION.CHILDS.SUBTITLE': 'La lista de organizaciones niñas de {name}',

    'RESOURCES.USER_GUIDE.DIALOG.TITLE': 'Guía del usuario',
    'RESOURCES.USER_GUIDE.CREATE.SUCCESS.MESSAGE': 'Guía del usuario creada con éxito',
    'RESOURCES.USER_GUIDE.UPDATE.SUCCESS.MESSAGE': 'Guía del usuario actualizada con éxito',
    'RESOURCES.USER_GUIDE.DELETE.SUCCESS.MESSAGE': 'Guía del usuario eliminada con éxito',
    'RESOURCES.USER_GUIDE.FORM.FILE.FIELD.MSG': 'Por favor, elige un archivo, solo se aceptan archivos .pdf',

    'RESOURCES.LANGUAGE_PACKS.CREATE.SUCCESS.MESSAGE': 'Paquete de idioma creado con éxito',
    'RESOURCES.LANGUAGE_PACKS.UPDATE.SUCCESS.MESSAGE': 'Paquete de idioma actualizado con éxito',
    'RESOURCES.LANGUAGE_PACKS.DELETE.SUCCESS.MESSAGE': 'Paquete de idioma eliminado con éxito',

    'RESOURCES.EXCEL.TREE.TITLE': 'Excel',
    'RESOURCES.WORD.TREE.TITLE': 'Word',
    'RESOURCES.POWERPOINT.TREE.TITLE': 'PowerPoint',

    'CREDENTIALS.HOME.TITLE': 'Credenciales',
    'CREDENTIALS.HOME.SUBTITLE': 'Gestiona de las credenciales de tu organización',
    'CREDENTIALS.FORM.NAME.FIELD.PLACEHOLDER': 'Nombre',
    'CREDENTIALS.FORM.DESCRIPTION.FIELD.PLACEHOLDER': 'Descripción',
    'CREDENTIALS.TABLE.HEADER.DESCRIPTION': 'Descripción',
    'CREDENTIALS.CREATE.TITLE': 'Crear credenciales',
    'CREDENTIALS.CREATE.SUCCESS.MESSAGE': 'Paquetes de credenciales creados con éxito',
    'CREDENTIALS.CREATION.FAILURE.MESSAGE': 'La creación de paquetes de credenciales falló',
    'CREDENTIALS.UPDATE.SUCCESS.MESSAGE': 'Paquete de credenciales actualizado con éxito',
    'CREDENTIALS.UPDATE.FAILURE.MESSAGE': 'La actualización de paquetes de credenciales falló',
    'CREDENTIALS.DELETE.SUCCESS.MESSAGE': 'Paquete de credenciales eliminado con éxito',
    'CREDENTIALS.DELETE.FAILURE.MESSAGE': 'La eliminación de paquetes de credenciales falló',

    'LIBRARY.CREATE.BANNER.TITLE': 'Aviso Importante',
    'LIBRARY.CREATE.BANNER.MESSAGE':
        'Para asegurar un rendimiento fluido y eficiente para todos los usuarios, por favor evite subir archivos que sean demasiado grandes. Los archivos grandes pueden causar desaceleraciones significativas y perturbar la experiencia en Excel/PowerPoint/Word para todos.\n¡Gracias por su cooperación!',

    'MACRO.HOME.TITLE': 'Macros',
    'MACRO.HOME.SUBTITLE': 'Manage VBA macos for your organization',
    'MACRO.HOME.MACROS': '{count} macros: {values} ',
    'MACRO.EDIT.MACROS_ITEMS.TITLE': 'Embedded VBA macros',

    'MACROS.FORM.VALIDATION.NAME.REQUIRED': 'Name is required',
    'MACROS.FORM.VALIDATION.DESCRIPTION.REQUIRED': 'Description is required',
    'MACROS.FORM.VALIDATION.ITEMS.REQUIRED': 'Declare at least one VBA Macro',
    'MACROS.FORM.VALIDATION.ITEMS.UNIQUE': 'Title and name must be unique in the list of VBA macro',
    'MACROS.CREATE.TITLE': 'Crear macros',
    'MACROS.TABLE.HEADER.MACROS': 'Macros',
    'MACROS.FIELD.FILE.DROPZONE.DEFAULT.MESSAGE':
        'Drag and drop .xlsm files including VBA macros here, or click to select files',
    'MACROS.TABLE.HEADER.ACTIONS': 'Actions',
    'MACROS.FORM.DESCRIPTION.FIELD.PLACEHOLDER': 'Descripción',
    'MACROS.FORM.NAME.FIELD.PLACEHOLDER': 'Nombre',
    'MACROS.FORM.TITLE.FIELD.PLACEHOLDER': 'Title',
    'MACROS.CREATE.SUCCESS.MESSAGE': 'Paquetes de macros creados con éxito',
    'MACROS.CREATION.FAILURE.MESSAGE': 'La creación de paquetes de macros falló',
    'MACROS.UPDATE.SUCCESS.MESSAGE': 'Paquete de macros actualizado con éxito',
    'MACROS.UPDATE.FAILURE.MESSAGE': 'La actualización de paquetes de macros falló',
    'MACROS.DELETE.SUCCESS.MESSAGE': 'Paquete de macros eliminado con éxito',
    'MACROS.DELETE.FAILURE.MESSAGE': 'La eliminación de paquetes de macros falló'
};
