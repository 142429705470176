import { ErrorMessage, Field, useFormikContext } from 'formik';
import { Grid, Switch, Tooltip } from '@mui/material';
import { UIErrorMessage } from '../UIErrorMessage';
import React from 'react';
import { FormLabel } from '../../../../hooks/useForm';

export const UISwitchField= ({
                                 id,
                                 color,
                                 labelIdcheckedA,
                                 labelTooltipIdcheckedA,
                                 labelIdcheckedB,
                                 labelTooltipIdcheckedB
                             }: any) => {
    const { values, setFieldValue } = useFormikContext();
    // @ts-ignore
    const isChecked = values[id];

    const handleChange = () => {
        // @ts-ignore
        setFieldValue(id, !values[id]);
    };

    return (
        <>
            <Grid container item alignItems='center' justifyContent='center' spacing={1}>
                <Grid item>
                    {labelIdcheckedA && labelTooltipIdcheckedA && (
                        <Tooltip
                            title={<FormLabel id={labelTooltipIdcheckedA} />}
                            children={<FormLabel id={labelIdcheckedA} />}
                        />
                    )}
                </Grid>
                <Grid item>
                    <Field
                        component={Switch}
                        id={id}
                        name={id}
                        checked={isChecked}
                        onChange={handleChange.bind(this)}
                        color={color}
                    />
                </Grid>
                <Grid item>
                    {labelIdcheckedB && labelTooltipIdcheckedB && (
                        <Tooltip
                            title={<FormLabel id={labelTooltipIdcheckedB} />}
                            children={<FormLabel id={labelIdcheckedB} />}
                        />
                    )}
                </Grid>
            </Grid>
            <ErrorMessage name={id}
                          render={errorMessage => <UIErrorMessage message={errorMessage} />} />
        </>
    );
};