import React from 'react';
import { FeedbackConfig } from '../../../../domain';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { FeedbackRepositorySpec } from '../../../../domain';
import { FeedbackConfigsArea } from './FeedbackConfigsArea';
import { UIContentScaffold } from '../../common/UIContentScaffold';

interface FeedbackConfigContentProps {
    isEditable: boolean;
    feedbackRepository: FeedbackRepositorySpec;
}

export const FeedbackConfigContent = ({
    feedbackRepository,
    isEditable,
    setSessionExpired,
    setToast,
    setLoading
}: FeedbackConfigContentProps & UIScaffoldChildContext) => {
    return (
        <UIContentScaffold
            successMessagePrefix="CONFIGURATION.FEEDBACK_CONFIG"
            repository={feedbackRepository}
            setToast={setToast}
            setLoading={setLoading}
            setSessionExpired={setSessionExpired}>
            {({ data, handleCreate, handleDelete, handleUpdate }) => (
                <FeedbackConfigsArea
                    feedbackConfig={data as FeedbackConfig}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                    handleCreate={handleCreate}
                    actionsHidden={!isEditable}
                />
            )}
        </UIContentScaffold>
    );
};
