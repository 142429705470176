import { Library } from './Library';
import { FilesMimeTypes, LibraryDocumentType, LibraryNodeType } from '../../../types/types';

export interface LibraryNodeSpec {
    files: Blob[];
    info: Library[];
}

export interface LibraryFileData extends Library {
    file?: Blob | null;
}

export const initLibraryInfo: Library = {
    id: undefined,
    parentId: null,
    name: '',
    description: '',
    eLibraryNodeType: LibraryNodeType.Directory,
    eDocumentType: LibraryDocumentType.None,
    childs: null,
    fileExtension: '',
    organizationId: '',
    masterOrganizationId: '',
    createDate: '',
    updateDate: '',
    replaceableFields: [],
    filteringTags: [],
    isValidated: false
};

export interface LibraryNodeFileTypeSpec {
    id: number;
    name: string;
    acceptedFiles: string[];
}

export const LibraryNodeDocumentTypeOptions: LibraryNodeFileTypeSpec[] = [
    {
        id: LibraryDocumentType.PowerPointSlide,
        name: 'LIBRARY.TABLE.SELECT.CONTENT_TYPE.PowerPointSlide',
        acceptedFiles: [...(FilesMimeTypes.Powerpoint as string[]), ...(FilesMimeTypes.PowerpointTemplate as string[])]
    },
    {
        id: LibraryDocumentType.PowerPointPresentation,
        name: 'LIBRARY.TABLE.SELECT.CONTENT_TYPE.PowerPointPresentation',
        acceptedFiles: [...(FilesMimeTypes.Powerpoint as string[]), ...(FilesMimeTypes.PowerpointTemplate as string[])]
    },
    {
        id: LibraryDocumentType.ExcelWorkbook,
        name: 'LIBRARY.TABLE.SELECT.CONTENT_TYPE.ExcelWorkbook',
        acceptedFiles: FilesMimeTypes.Excel as string[]
    },
    {
        id: LibraryDocumentType.ExcelWorksheet,
        name: 'LIBRARY.TABLE.SELECT.CONTENT_TYPE.ExcelWorksheet',
        acceptedFiles: FilesMimeTypes.Excel as string[]
    },
    {
        id: LibraryDocumentType.WordDocument,
        name: 'LIBRARY.TABLE.SELECT.CONTENT_TYPE.WordDocument',
        acceptedFiles: FilesMimeTypes.Word as string[]
    },
    {
        id: LibraryDocumentType.Icon,
        name: 'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Icon',
        acceptedFiles: FilesMimeTypes.Pictures as string[]
    },
    {
        id: LibraryDocumentType.Shape,
        name: 'LIBRARY.TABLE.SELECT.CONTENT_TYPE.Shape',
        acceptedFiles: [...(FilesMimeTypes.Powerpoint as string[]), ...(FilesMimeTypes.PowerpointTemplate as string[])]
    }
];
