import { Repository, RepositorySpec } from '../Repository';

import { AuthSessionInfoSpec, HttpRoutes, UserOrganizationRole } from '..';
import { HttpResponse } from '../mazars-network-http-client';
import { EOrganizationRole, UserMasterOrganizationRole } from './model/UserOrganizationRole';

export interface OrganizationRolesRepositorySpec extends RepositorySpec {
    fetchAll(organizationId: string, sessionInfo: AuthSessionInfoSpec): Promise<EOrganizationRole[]>;

    /// Fetch the roles of the given user within the organization
    fetchUserRoles(
        organizationId: string,
        email: string,
        sessionInfo: AuthSessionInfoSpec
    ): Promise<UserOrganizationRole[]>;

    /// fetch all the users that have roles with the organization
    fetchOrganizationUsers(
        organizationId: string,
        filteredERoles: string,
        sessionInfo: AuthSessionInfoSpec
    ): Promise<UserOrganizationRole[]>;

    /// Affect the role to the given user (email)
    attach(
        roles: UserOrganizationRole[] | UserMasterOrganizationRole[],
        sessionInfo: AuthSessionInfoSpec
    ): Promise<any>;

    /// Remove the roles from the given user (email)
    remove(rolesIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<any>;
}

export class OrganizationRolesRepository extends Repository implements OrganizationRolesRepositorySpec {
    async fetchAll(organizationId: string, sessionInfo: AuthSessionInfoSpec): Promise<EOrganizationRole[]> {
        const params = { organizationId };
        const response = (await this.datasource.remote.get(
            HttpRoutes.OrganizationRolesEnum,
            sessionInfo.accessToken,
            undefined,
            params,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<EOrganizationRole[], any>;

        return response.data || [];
    }

    async fetchUserRoles(
        organizationId: string,
        email: string,
        sessionInfo: AuthSessionInfoSpec
    ): Promise<UserOrganizationRole[]> {
        const params = { organizationId, email };
        const response = (await this.datasource.remote.get(
            HttpRoutes.OrganizationRoles,
            sessionInfo.accessToken,
            undefined,
            params,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<UserOrganizationRole[], any>;

        return response.data || [];
    }

    async fetchOrganizationUsers(
        organizationId: string,
        filteredERoles: string,
        sessionInfo: AuthSessionInfoSpec
    ): Promise<UserOrganizationRole[]> {
        const params = { organizationId, EOrganizationRole: filteredERoles };
        const response = (await this.datasource.remote.get(
            HttpRoutes.OrganizationRoles,
            sessionInfo.accessToken,
            undefined,
            params,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<UserOrganizationRole[], any>;
        return response.data || [];
    }

    async attach(roles: UserOrganizationRole[], sessionInfo: AuthSessionInfoSpec): Promise<any> {
        const response = (await this.datasource.remote.post(
            HttpRoutes.OrganizationRoles,
            roles,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<UserOrganizationRole[], any>;

        return response.data || [];
    }

    async remove(rolesIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<any> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.OrganizationRoles,
            rolesIds,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<UserOrganizationRole[], any>;

        return response.data;
    }
}
