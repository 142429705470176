import { BaseResourceDataSpec } from '../resources/common/BaseResource';
import { AgendaDesignSpec } from '../configuration/agendaDesign/model/AgendaDesign';

const prepareGuideRequestBody = (resource: BaseResourceDataSpec): FormData => {
    const body = new FormData();
    body.append('masterOrganizationId', resource.masterOrganizationId);
    body.append('emOfficeSoftware', resource.emOfficeSoftware?.toString() || '');
    body.append('languageTag', resource.languageTag);
    body.append('version', resource.version?.toString() || '');
    resource.id && body.append('id', resource.id);

    resource.file && body.append('File', resource.file);

    return body;
};

const prepareAgendaDesignRequestBody = (agendaDesign: AgendaDesignSpec): FormData => {
    const body = new FormData();

    body.append('masterOrganizationId', agendaDesign.masterOrganizationId);
    body.append('organizationId', agendaDesign.organizationId);
    body.append('version', agendaDesign.version);
    agendaDesign.id && body.append('id', agendaDesign.id);

    agendaDesign.file && body.append('File', agendaDesign.file);

    return body;
};
export const resourcesHelper = {
    prepareGuideRequestBody,
    prepareAgendaDesignRequestBody
};
