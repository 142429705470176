import { CredentialPackDetails, CredentialPackDetailsProps } from './CredentialPackDetails';
import { UIFilePreview } from '../common/UIFilePreview';

interface CredentialPackPreviewProps extends CredentialPackDetailsProps {
    file?: Blob;
}

export const CredentialPackPreview = ({ isEditable, pack, file, ...rest }: CredentialPackPreviewProps) => {
    return (
        <>
            {file && <UIFilePreview file={file} fileExtension={pack.fileExtension || ''} />}
            <CredentialPackDetails isEditable={isEditable} pack={pack} {...rest} />
        </>
    );
};
