import { AgendaDesign, BrandingResource, FeedbackConfig, LearningLink } from '../domain';
import { isOverridable } from '../utils/overridingHelper';
import { Palette } from '../res/colors';
import { IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import * as React from 'react';
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';
import { getLocalizedString } from '../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { isMasterOrganization } from '../utils/organizationHelper';

type DataType = FeedbackConfig | LearningLink | BrandingResource | AgendaDesign;

interface overridableViewProps {
    item: DataType | null;
    onEditAsked: (item: any) => void; // TODO it should be item:  BrandingResource | FeedbackConfig
    onDelete?: (items: string[]) => void;
}

export const useOverridableView = ({ item, onEditAsked, onDelete }: overridableViewProps) => {
    const overridable = item?.id ? isOverridable(item) : true;
    const { currentOrganization, isEditableAtOrganizationLevel, isCurrentOrganizationMaster } = useContext(AppContext);

    const isDeletable = (!overridable && isEditableAtOrganizationLevel) || (isCurrentOrganizationMaster && overridable);
    const isEditable = isEditableAtOrganizationLevel || (isCurrentOrganizationMaster && overridable);

    const intl = useIntl();

    const handleEdit = (item: DataType) => () => {
        onEditAsked(item);
    };
    const handleDelete = (itemId: string) => () => {
        onDelete && onDelete([itemId]);
    };

    const actions = item ? (
        <>
            {isEditable && (
                <IconButton onClick={handleEdit(item)} sx={{ color: Palette.white }} aria-label={item.id}>
                    <Edit />
                </IconButton>
            )}

            {isDeletable && (
                <IconButton onClick={handleDelete(item.id)} sx={{ color: Palette.white }} aria-label={item.id}>
                    <Delete />
                </IconButton>
            )}
        </>
    ) : undefined;

    return {
        backgroundColor: overridable ? Palette.queenBee : Palette.darkPrimaryColor,
        overridable,
        actions: actions,
        title: !overridable
            ? isMasterOrganization(currentOrganization)
                ? 'Custom'
                : currentOrganization.name
            : getLocalizedString(intl, 'common.master.organization.name') //TODO on devrait pas mettre en dur le nom de la master orga
    };
};
