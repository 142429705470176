import { EBrandingResourceType, EmptyGuid } from '../../../../types/types';
import { appConfiguration } from '../../../../appConfiguration';

export interface BrandingResource {
    id: string;
    masterOrganizationId: string;
    organizationId: string;
    eBrandingResourceType: EBrandingResourceType;
    createDate: string;
    updateDate: string;
}

export interface BrandingResourceDataSpec {
    id?: string;
    masterOrganizationId: string;
    organizationId: string;
    eBrandingResourceType?: EBrandingResourceType;
    file?: Blob;
}

export const initBrandingResource: BrandingResourceDataSpec = {
    id: undefined,
    masterOrganizationId: EmptyGuid,
    organizationId: EmptyGuid,
    eBrandingResourceType: undefined,
    file: undefined
};
