import { Navigate, useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { NavAdminRoleRoutes, NavRoutes, NavStandardRoleRoutes } from './NavRoutes';
import { AppContext } from '../context/AppContext';

interface ProtectedRouteProps {
    children: JSX.Element | null;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
    const {
        sessionInfo,
        accessData: { roleLevel, accessMode }
    } = useContext(AppContext);
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(!!sessionInfo.accessToken);

    useEffect(() => {
        setIsAuthenticated(!!sessionInfo.accessToken);
    }, [sessionInfo?.accessToken]);

    if (isAuthenticated) {
        if (
            (accessMode !== 'AppAdmin' && roleLevel === 'standard' && NavAdminRoleRoutes.includes(location.pathname)) ||
            ((roleLevel === 'organizationAdmin' || roleLevel === 'masterOrganizationAdmin') &&
                NavStandardRoleRoutes.includes(location.pathname))
        ) {
            return <Navigate to={NavRoutes.Home} replace />;
        } else {
            return children;
        }
    } else {
        return <Navigate to={NavRoutes.Login} state={{ from: location }} replace />;
    }
}
