import { MacroItem, MacroPack } from '../domain/macroPack/model/MacroPack';

const formatMacros = (macros: MacroItem[]) => {
    return macros.map(macro => macro.title).join(', ');
};

const prepareData = (
    packs: MacroPack[],
    pagination: {
        page: number;
        rowsPerPage: number;
    }
): MacroPack[] => {
    const { page, rowsPerPage } = pagination;
    if (packs.length === 0) {
        return [];
    }

    return packs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
};

export const macroHelper = {
    formatMacros,
    prepareData
};
