import { ErrorMessage, Field } from 'formik';
import { TextField } from '@mui/material';
import { UIErrorMessage } from '../UIErrorMessage';
import React from 'react';

export const UITextField = ({
    id,
    name,
    component,
    rows,
    placeholder,
    variant,
    disabled,
    type,
    className,
    ...rest
}: any) => {
    return (
        <>
            <Field
                {...rest}
                name={name}
                as={TextField}
                key={id}
                id={id}
                component={component}
                rows={rows}
                placeholder={placeholder}
                variant={variant ?? 'outlined'}
                disabled={disabled}
                type={type ?? ''}
                autoComplete={type ? 'current-password' : ''}
                style={{ padding: 0, width: '100%' }}
                className={className}
            />
            <ErrorMessage name={name} render={errorMessage => <UIErrorMessage message={errorMessage} />} />
        </>
    );
};
