const isSelectedEntry = (entries: string[], entry: string) => entries.indexOf(entry) !== -1;

const updateSelectedEntries = (entries: string[], entry: string) => {
    const selectedIndex = entries.indexOf(entry);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(entries, entry);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(entries.slice(1));
    } else if (selectedIndex === entries.length - 1) {
        newSelected = newSelected.concat(entries.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(entries.slice(0, selectedIndex), entries.slice(selectedIndex + 1));
    }

    return newSelected;
};

const removeEntriesFromTable = (entries: string[], remove: (index: number) => void, offset: number) => {
    entries.forEach((name, index) => {
        const selectedIndex = entries.length - 1 - index + offset;
        remove(selectedIndex);
    });
};

function createDisplayedData<T, S>(node: T, file?: Blob | null): S {
    return {
        ...node,
        file
    } as S;
}

export const tabledHelper = {
    isSelectedEntry,
    updateSelectedEntries,
    createDisplayedData,
    removeEntriesFromTable
};
