import React, { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { en } from './locales/en';
import { fr } from './locales/fr';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { es } from './locales/es';

interface I18nProviderProps {
    children?: ReactNode;
}

const strings: { [key: string]: any } = {
    en,
    fr,
    es
};

export function I18nProvider({ children }: I18nProviderProps) {
    const language = useSelector((state: RootState) => state.app.language);

    return (
        <IntlProvider locale={language} key={language} messages={strings[language]}>
            {children}
        </IntlProvider>
    );
}
