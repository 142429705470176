import React from 'react';
import { UIFilePreview } from '../common/UIFilePreview';
import { MacroPack } from '../../../domain/macroPack/model/MacroPack';
import { MacroPackDetails } from './MacroPackDetails';
import { MacroPackFileData } from '../../../domain/macroPack/model/initMacroPack';

type MacroPackPreviewProps = {
    file?: Blob;
    isEditable: boolean;
    formRef: any;
    pack: MacroPackFileData;
    handleUpdate: (pack: MacroPack) => Promise<void>;
    handleError: (error: string) => void;
};

export const MacroPackPreview: React.FC<MacroPackPreviewProps> = ({ file, pack, isEditable, ...rest }) => {
    return (
        <>
            {file && <UIFilePreview file={file} fileExtension={pack.fileExtension || ''} />}
            <MacroPackDetails
                isEditable={{
                    name: isEditable,
                    macros: isEditable,
                    description: isEditable
                }}
                pack={pack}
                {...rest}
            />
        </>
    );
};
