import { EmptyGuid, MasterOrganizationRoleEnum, OrganizationParams } from '../types/types';
import { Organization, UserMasterOrganizationRole } from '../domain';
import { EditableOrganization } from '../domain/organizations/model/Organization';

export const getMasterOrganizationAdminUserRole = (masterOrganizationRoles: UserMasterOrganizationRole[]) => {
    return (
        masterOrganizationRoles &&
        masterOrganizationRoles.filter(
            userRole => userRole.eMasterOrganizationRole === MasterOrganizationRoleEnum.Admin
        )
    );
};

export const getFirstMasterOrganizationAdminUserRole = (masterOrganizationRoles: UserMasterOrganizationRole[]) => {
    return masterOrganizationRoles.find(
        userRole => userRole.eMasterOrganizationRole === MasterOrganizationRoleEnum.Admin
    );
};

export const getMasterOrganizationsWithAdminRole = (
    masterOrganizations: Organization[],
    masterOrganizationRoles: UserMasterOrganizationRole[]
) => {
    const userRoleAdmins =
        masterOrganizationRoles &&
        getMasterOrganizationAdminUserRole(masterOrganizationRoles).map(role => role.masterOrganizationId);
    return userRoleAdmins
        ? masterOrganizations.filter(masterOrganization => userRoleAdmins.includes(masterOrganization.id))
        : [];
};

export const getFirstMasterOrganizationWithAdminRole = (
    masterOrganizations: Organization[],
    masterOrganizationRoles: UserMasterOrganizationRole[]
) => {
    const userRoleAdmin = getFirstMasterOrganizationAdminUserRole(masterOrganizationRoles);
    return (
        masterOrganizations &&
        masterOrganizations.find(masterOrganization => masterOrganization.id === userRoleAdmin?.masterOrganizationId)
    );
};

export const isMasterOrganization = (organization: Organization) => {
    return !organization.masterOrganizationId;
};

export const getDefaultOrganizationParams = (
    organization: Organization,
    isMasterOrganization: boolean
): OrganizationParams => {
    return {
        [isMasterOrganization ? 'masterOrganizationId' : 'organizationId']: organization.id
    } as OrganizationParams;
};

export const getOrganizationId = (organization: Organization, isMasterOrganization: boolean) => {
    return isMasterOrganization ? EmptyGuid : organization.id;
};

export const getOrganizationIdOrNull = (organization: Organization, isMasterOrganization: boolean) => {
    return isMasterOrganization ? null : organization.id;
};

export const getMasterOrganizationId = (organization: Organization) => {
    if (isMasterOrganization(organization)) return organization.id;
    else if (organization.masterOrganizationId) return organization.masterOrganizationId;
    else throw new Error('invalid organization');
};

export const getScreenSubtitleByOrganizationType = (isMasterOrganization: boolean) => {
    return isMasterOrganization ? 'COMMON.DEFAULT.INFO.MESSAGE' : 'COMMON.OVERRIDING.INFO.MESSAGE';
};

export const getActiveOrganizations = (organizations: Organization[]) => {
    return organizations.filter(organization => organization.isActivated);
};

export const getEditableOrganization = ({
    userDnsDomains,
    hostDomains,
    childs,
    ...organization
}: Organization): EditableOrganization => {
    return {
        ...organization,
        userDnsDomains: userDnsDomains.join(','),
        hostDomains: hostDomains.join(','),
        childs: childs?.map(child => getEditableOrganization(child))
    };
};
