import { NetworkHelpers } from './networkHelper';
import { appConfiguration } from '../appConfiguration';

const openIdConfig: Authentication = {
    clientId: appConfiguration.OPENID_CLIENT_ID,
    clientSecret: appConfiguration.OPENID_SECRET,
    federation: appConfiguration.OPENID_FEDERATION,
    redirectUri: appConfiguration.OPENID_REDIRECT_URI,
    openIdBaseUrl: appConfiguration.OPENID_BASE_URL
};

export type Authentication = {
    clientId: string;
    federation: string;
    clientSecret: string;
    redirectUri: string;
    openIdBaseUrl: string;
};

const makeOpenIdUrl = () => {
    const params = {
        client_id: openIdConfig.clientId,
        scope: 'openid',
        response_type: 'code',
        redirect_uri: `${getHostname()}${openIdConfig.redirectUri}`,
        state: 'mazars_office_dummy_state'
    };

    return `${openIdConfig.federation}/${NetworkHelpers.serializeUrlParams(params)}`;
};
const getHostname = () => {
    try {
        const url = new URL(location.href);

        return location.href.startsWith('file')
            ? 'http://localhost'
            : `${url.protocol}//${url.hostname}:${url.port ? url.port : ''}`;
    } catch (error) {
        throw new Error('invalid redirect url');
    }
};

const isOpenIdRedirectUrl = (url: string, redirectUrl: string) => {
    // Check if the URL starts with the provided redirectUrl or contains the 'code' query string.
    // Important Note: As a fallback, it's essential to verify if the 'code' query string is present in cases where mazars-connect may send only the code without the redirect URL.
    return url.startsWith(redirectUrl) || !!NetworkHelpers.extractUrlParamValue('code', url);
};

export const AuthHelpers = {
    makeOpenIdUrl,
    isOpenIdRedirectUrl,
    getHostname,
    openIdConfig
};
