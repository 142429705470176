import React, { useEffect, useRef } from 'react';
import { setRef } from '@mui/material';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { Link, LinkDataSpec, LinksRepositorySpec } from '../../../../domain';
import { DataType, UIContentScaffold, UIContentScaffoldContext } from '../../common/UIContentScaffold';
import { LinksArea } from './LinksArea';

interface LinksContentProps {
    linksRepository: LinksRepositorySpec;
    isEditable: boolean;
}

export const LinksContent = ({
    isEditable,
    setSessionExpired,
    setToast,
    setLoading,
    linksRepository
}: LinksContentProps & UIScaffoldChildContext) => {
    const handler = useRef<UIContentScaffoldContext<LinksRepositorySpec, DataType, LinkDataSpec> | undefined>(
        undefined
    );

    useEffect(() => {
        !!handler?.current?.handleFetch && (async () => handler?.current?.handleFetch())();
    }, [linksRepository, handler]);

    return (
        <>
            <UIContentScaffold
                repository={linksRepository}
                successMessagePrefix="RESOURCES.LINKS"
                setToast={setToast}
                setLoading={setLoading}
                setSessionExpired={setSessionExpired}
                fetchOnMount={false}>
                {contentScaffoldHandler => {
                    //need to call fetch method in this component (disable fetch on mount and get handler to call it in useEffect, see above)
                    //otherwise it is called twice in UIContentScaffold because link repository can change by selecting master organization or organization
                    setRef(handler, contentScaffoldHandler);

                    return (
                        <LinksArea
                            links={(contentScaffoldHandler.data as Link[]) || []}
                            actionsHidden={!isEditable}
                            handleEdit={contentScaffoldHandler.handleUpdate}
                            handleDelete={contentScaffoldHandler.handleDelete}
                            handleCreate={contentScaffoldHandler.handleCreate}
                        />
                    );
                }}
            </UIContentScaffold>
        </>
    );
};
