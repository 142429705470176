import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { UIDropDownMenu, UIDropDownMenuEntryProps } from '../common/UIDropDownMenu';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { Organization, RepositoryProvider, User } from '../../../domain';
import { AppContext } from '../../../context/AppContext';
import { useRepository } from '../../../hooks/useRepository';
import { setCurrentOrganization, setUserInfo, setUserRoles } from '../../../store/modules/user';
import { UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import { Palette } from '../../../res/colors';
import { getMasterOrganizationsWithAdminRole } from '../../../utils/organizationHelper';
import { UITopBarItem } from './UITopBarItem';
import { userHelper } from '../../../utils/userHelper';
import { OrganizationService } from '../../../services/OrganizationService';

const renderOrganizations = (
    organizations: Organization[],
    handleChange: (organization: Organization) => void,
    selectedOrganizationId: string | undefined
) => {
    if (!organizations || !Array.isArray(organizations)) {
        console.error('organizations array is not defined');

        return [];
    }

    return organizations.reduce((acc: UIDropDownMenuEntryProps[], organization: Organization) => {
        return [...acc, renderOrganization(organization, handleChange, selectedOrganizationId)];
    }, []);
};

const renderOrganization = (
    organization: Organization,
    handleChange: (organization: Organization) => void,
    selectedOrganizationId: string | undefined
): UIDropDownMenuEntryProps => {
    return {
        titleStyle: {
            color: organization.id === selectedOrganizationId ? styles.title.selected : styles.title.default
        },
        title: organization.name,
        id: organization.id,
        handler: () => handleChange(organization)
    };
};

export const OrganizationTopBarItem = ({
    setToast,
    setSessionExpired,
    setLoading
}: UITopBarItem & UIScaffoldChildContext) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [menuEntries, setMenuEntries] = useState<UIDropDownMenuEntryProps[]>([]);
    const { currentUser, sessionInfo, currentOrganization } = useContext(AppContext);

    const organizationService = new OrganizationService();
    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    useEffect(() => {
        const fetchUserOrganizations = async () => {
            await callRepository(
                () => RepositoryProvider.getProvider().userRepository.fetch(currentUser.email, sessionInfo),
                async data => {
                    const user = data as User;
                    if (!user) {
                        throw new Error('No user');
                    }

                    dispatch(setUserInfo(user));
                    dispatch(setUserRoles(user.organizationRoles));

                    const { appRoles } = user;
                    const isAppAdminAccess = userHelper.hasAppAdminAccess(appRoles);
                    if (isAppAdminAccess) {
                        // get master organization from API
                        const { masterOrganizations, organizations } =
                            await organizationService.fetchAppAdminOrganizations(sessionInfo);
                        prepareRender(masterOrganizations || [], organizations || []);
                    } else {
                        const { organizations, masterOrganizations, masterOrganizationRoles } = user;
                        // get the master organization where the current user has admin roles
                        const masterOrganizationsWithAdminRole: Organization[] =
                            getMasterOrganizationsWithAdminRole(masterOrganizations, masterOrganizationRoles) ?? [];

                        prepareRender(masterOrganizationsWithAdminRole, organizations);
                    }
                }
            );
        };

        (async () => await fetchUserOrganizations())();
    }, [currentUser?.id]);

    const prepareRender = (masterOrganizations: Organization[], organizations: Organization[]) => {
        const entries = [
            ...renderOrganizations(masterOrganizations, handleChangeOrganization, currentOrganization?.id),
            {
                titleComponent: () => (
                    <Divider sx={{ height: 1, backgroundColor: Palette.dividerColor, width: '100%' }} />
                ),
                id: 'divider'
            },
            ...renderOrganizations(organizations, handleChangeOrganization, currentOrganization?.id)
        ];

        setMenuEntries(entries);
    };

    useEffect(() => {
        updateEntrySelection();
    }, [currentOrganization?.id]);

    const updateEntrySelection = () => {
        currentOrganization?.id &&
            setMenuEntries(
                menuEntries.map(entry => ({
                    ...entry,
                    titleStyle: {
                        color: entry.id === currentOrganization.id ? styles.title.selected : styles.title.default
                    }
                }))
            );
    };

    const handleChangeOrganization = (organization: Organization) => {
        dispatch(setCurrentOrganization(organization));
    };

    return (
        <Box sx={{ marginX: 0.5 }}>
            <UIDropDownMenu id="topBar" entries={menuEntries} title={currentOrganization?.name} />
        </Box>
    );
};

const styles = {
    title: {
        default: {
            fontWeight: 'light',
            color: Palette.primaryTextColor
        },
        selected: {
            fontWeight: 'bold',
            color: Palette.defaultPrimaryColor
        }
    }
} as const;
