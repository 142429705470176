import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useIntl } from 'react-intl';
import { UIDropDownMenu, UIDropDownMenuEntryProps } from '../common/UIDropDownMenu';
import * as React from 'react';
import { setReduxLanguage } from '../../../store/modules/app';
import { languages } from '../../../res/languages';
import { makeStyles } from '@mui/styles';
import { Box, IconButton } from '@mui/material';
import { UITopBarItem } from './UITopBarItem';

export const LanguageTopBarItem = (_: UITopBarItem) => {
    const intl = useIntl();
    const storedLanguage = useSelector((state: RootState) => state.app.language);
    const currentLanguage: string = intl.locale ?? storedLanguage;
    const dispatch = useDispatch();

    const menuEntries = Object.keys(languages).reduce((acc: UIDropDownMenuEntryProps[], locale: string) => {
        const language = languages[locale];
        return [...acc, { title: language?.name, id: locale, handler: () => onLanguageSelect(locale) }];
    }, []);

    const onLanguageSelect = (language: string) => {
        dispatch(setReduxLanguage(language));
    };

    const LanguageTitleComponent = () => {
        const classes = useStyles();

        return (
            <>
                {languages && currentLanguage && (
                    <IconButton>
                        <img
                            alt="language-flag"
                            src={languages[currentLanguage.toLocaleLowerCase()]?.flag}
                            className={classes.flag}
                        />
                    </IconButton>
                )}
            </>
        );
    };

    return (
        <Box sx={{ marginX: 0.5 }}>
            <UIDropDownMenu id="topBar" entries={menuEntries} titleComponent={LanguageTitleComponent} />
        </Box>
    );
};

const useStyles = makeStyles({
    flag: {
        width: '30px',
        height: '30px',
        borderRadius: 5
    }
});
