import { AuthSessionInfoSpec, HttpRoutes, Organization, RepositorySpec } from '..';
import { HttpResponse } from '../mazars-network-http-client';
import { Repository } from '../Repository';

export interface MasterOrganizationRepositorySpec extends RepositorySpec {
    fetch(sessionInfo: AuthSessionInfoSpec): Promise<Organization[]>;

    fetchOrganizations(masterOrganizationId: string, sessionInfo: AuthSessionInfoSpec): Promise<Organization[]>;
}

export class MasterOrganizationRepository extends Repository implements MasterOrganizationRepositorySpec {
    async fetch(sessionInfo: AuthSessionInfoSpec): Promise<Organization[]> {
        const response = (await this.datasource.remote.get(
            HttpRoutes.GetMasterOrganizations,
            sessionInfo.accessToken
        )) as HttpResponse<Organization[], any>;

        return response.data || [];
    }

    async fetchOrganizations(masterOrganizationId: string, sessionInfo: AuthSessionInfoSpec): Promise<Organization[]> {
        const parameters = { masterOrganizationId };

        const path = HttpRoutes.MasterOrganizations.replace(':id', masterOrganizationId);
        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            parameters
        )) as HttpResponse<Organization[], any>;

        return response.data || [];
    }
}
