import { EmptyGuid } from '../../../../types/types';
import { LinkDataSpec } from '../../../resources/links/model/initLink';

export interface LearningLink {
    id: string;
    masterOrganizationId: string;
    organizationId: string;
    name: string;
    description: string;
    url: string;
    createDate: string;
    updateDate: string;
}

export const initLearningLink: LinkDataSpec = {
    name: '',
    description: '',
    organizationId: EmptyGuid,
    masterOrganizationId: EmptyGuid,
    url: ''
};
