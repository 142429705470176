import * as React from 'react';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { UIDropDownMenu, UIDropDownMenuEntryProps } from '../common/UIDropDownMenu';
import { useIntl } from 'react-intl';
import { removeSessionInfo, removeUserInfo } from '../../../store/modules/user';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { Box } from '@mui/material';
import { AppContext } from '../../../context/AppContext';
import AuthSessionService from '../../../services/AuthSessionService';
import { UITopBarItem } from './UITopBarItem';

export const AccountTopBarItem = (_: UITopBarItem) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { currentUser } = useContext(AppContext);
    const displayedName =
        currentUser.firstName || currentUser.lastName
            ? `${currentUser.firstName ?? ''} ${currentUser.lastName ?? ''}`
            : currentUser?.email ?? '';

    const menuEntries: UIDropDownMenuEntryProps[] = [
        {
            id: 'Logout',
            title: getLocalizedString(intl, 'HEADER.TOP_BAR.ACCOUNT.LOGOUT'),
            handler: () => {
                dispatch(removeSessionInfo());
                AuthSessionService.removeLocalSessionInfo();
                dispatch(removeUserInfo());
            }
        }
    ];

    return (
        <Box sx={{ marginX: 0.5 }}>
            <UIDropDownMenu id="accountTopBar" entries={menuEntries} title={displayedName} />
        </Box>
    );
};
