import { AgendaDesign } from '../../../../domain';
import { useOverridableView } from '../../../../hooks/useOverridableView';
import { UITreeItem } from '../../common/UITreeItem';
import { IconButton, Tooltip } from '@mui/material';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { Download, Visibility } from '@mui/icons-material';
import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { UISectionFooter } from '../UISectionFooter';

interface AgendaDesignTileProps {
    agendaDesign: AgendaDesign;
    onEditAsked: (agenda: AgendaDesign) => void;
    onDelete: (ids: string[]) => void;
    onDownload: (agenda: AgendaDesign) => void;
    onPreview: (agenda: AgendaDesign) => void;
    actionsHidden: boolean;
}

export const AgendaDesignTile = ({
    actionsHidden,
    agendaDesign,
    onDelete,
    onEditAsked,
    onDownload,
    onPreview
}: AgendaDesignTileProps) => {
    const intl = useIntl();
    const { backgroundColor, title, actions } = useOverridableView({
        item: agendaDesign,
        onDelete,
        onEditAsked
    });

    const handleVisualize = (agenda: AgendaDesign) => async (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        await onPreview(agenda);
    };

    const handleDownload = (agenda: AgendaDesign) => async (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        await onDownload(agenda);
    };

    return (
        <>
            <Fragment key={agendaDesign.id}>
                <UITreeItem
                    actionButton={
                        <>
                            <Tooltip title={getLocalizedString(intl, 'CONFIGURATION.AGENDA_DESIGN.PREVIEW.FILE')}>
                                <IconButton color="primary" onClick={handleVisualize(agendaDesign)}>
                                    <Visibility fontSize="small" />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={getLocalizedString(intl, 'CONFIGURATION.AGENDA_DESIGN.DOWNLOAD.FILE')}>
                                <IconButton color="primary" onClick={handleDownload(agendaDesign)}>
                                    <Download fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                    key={agendaDesign.id}
                    nodeId={agendaDesign.id}
                    labelText={'v-' + agendaDesign.version + ''}
                />

                <UISectionFooter
                    backgroundColor={backgroundColor}
                    title={title}
                    actions={actions}
                    actionsHidden={actionsHidden}
                />
            </Fragment>
        </>
    );
};
