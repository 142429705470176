import React, { useContext } from 'react';
import { APIScaffold } from '../../scaffolder/APIScaffold';
import { UIScaffold } from '../../scaffolder/UIScaffold';
import { RepositoryProvider } from '../../../domain';
import { AppContext } from '../../../context/AppContext';
import { MacroPacksContent } from '../../components/macro/MacroPacksContent';

export const MacroPacksScreen = () => {
    const { isEditableAtMasterOrganization, isEditableAtOrganizationLevel } = useContext(AppContext);

    return (
        <APIScaffold>
            {({ cancelRequest }) => (
                <UIScaffold>
                    {({ setLoading, setSessionExpired, setToast }) => (
                        <MacroPacksContent
                            isEditable={isEditableAtOrganizationLevel || isEditableAtMasterOrganization}
                            macroPacksRepository={RepositoryProvider.getProvider().macroPacksRepository}
                            cancelRequest={cancelRequest}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            setToast={setToast}
                        />
                    )}
                </UIScaffold>
            )}
        </APIScaffold>
    );
};
