import React from 'react';
import { List } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { UserRolesPanels } from '../../../../types/types';
import { RoleListItem } from './RoleListItem';
import { UISectionTitle } from '../../common/UISectionTitle';
import { EMasterOrganizationRole, EOrganizationRole } from '../../../../domain';

interface RolesContainerProps {
    handlePanelChange: (panel: UserRolesPanels) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    isExpanded: boolean;
    roles: EOrganizationRole[] | EMasterOrganizationRole[];
}

export const RolesContainer = ({ handlePanelChange, isExpanded, roles }: RolesContainerProps) => {
    const intl = useIntl();

    return (
        <>
            <Accordion sx={{ paddingX: 2 }} expanded={isExpanded} onChange={handlePanelChange(UserRolesPanels.Roles)}
                       elevation={3}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='roles-content'
                    id='roles-header'
                >
                    <UISectionTitle title={getLocalizedString(intl, 'USERS_ROLES.USER.ROLES.TITLE')} variant='h6'
                                    my={0} />

                </AccordionSummary>
                <AccordionDetails>
                    <List aria-label='roles'>
                        <>
                            {roles.map((role) =>
                                <RoleListItem key={role.value}
                                              displayName={role.text}
                                              description ={role.description}
                                />)
                            }
                        </>
                    </List>

                </AccordionDetails>
            </Accordion>

        </>
    );
};
