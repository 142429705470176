import { Organization } from './Organization';

export const initOrganization: Organization = {
    area: '',
    hostDomains: [],
    userDnsDomains: [],
    id: '',
    name: '',
    masterOrganizationId: '',
    isActivated: true,
    theGateCountryCode: '',
    masterOrganization: undefined
};
