import React, { memo } from 'react';
import { UIText } from './UIText';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { Box } from '@mui/material';
import { Palette } from '../../../res/colors';

export const UIFooter: React.FC = memo(() => {
    const intl = useIntl();

    return (
        <Box>
            <Box sx={styles.hiddenContent} />
            <Box sx={styles.content}>
                <UIText text={getLocalizedString(intl, 'FOOTER.MESSAGE')} sx={styles.text} />
            </Box>
        </Box>
    );
});

const styles = {
    content: {
        backgroundColor: Palette.backgroundPrimaryAccent,
        textAlign: 'center',
        padding: '20px',
        position: 'fixed',
        left: '0',
        bottom: '0',
        height: '60px',
        width: '100%'
    },
    hiddenContent: {
        display: 'block',
        padding: '20px',
        height: '60px',
        width: '100%'
    },
    text: {
        fontWeight: '600',
        fontSize: 13
    }
} as const;
