import { FormFieldProps } from '../../../../hooks/useForm';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { UIErrorMessage } from '../UIErrorMessage';
import React, { useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

export interface UIColorPickerFieldProps extends FormFieldProps {
    color: string;
    isSubmitOnChange?: boolean;
    id: string;
}

export const UIColorPickerField = ({
                                       isSubmitOnChange = false,
                                       id,
                                       color
                                   }: UIColorPickerFieldProps) => {


    const [colorValue, setColorValue] = useState(color);
    const { submitForm, setFieldValue, values } = useFormikContext();

    useEffect(() => {
        // @ts-ignore
        values && setColorValue(values[id]);
    }, [values]);


    const onColorChange = async (color: string) => {
        setFieldValue(id, color);
        isSubmitOnChange && await submitForm();
    };

    return <>
        <Field
            color={colorValue}
            component={HexColorPicker}
            onChange={onColorChange}
            id={id}
            key={id}
            fullWidth
        />

        <ErrorMessage name={id}
                      render={errorMessage => <UIErrorMessage message={errorMessage} />} />

    </>;
};
