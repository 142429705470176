import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper } from '@mui/material';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { makeStyles } from '@mui/styles';
import { UIText } from './UIText';
import { Close } from '@mui/icons-material';
import { Palette } from '../../../res/colors';

interface UIDialogProps {
    title: string;
    actions?: React.ReactNode;
    toolbarActions?: React.ReactNode;
    onClose?: () => void;
}

export const UIDialog = ({ title, actions, children, open, onClose, toolbarActions }: UIDialogProps & DialogProps) => {
    const classes = useStyles();

    return (
        <Dialog open={open}
                fullWidth
                maxWidth='md'
                disableEscapeKeyDown
                classes={{ paper: classes.dialogPaper }}
                aria-labelledby='dialog'
        >
            <DialogTitle id='simple-dialog-title' sx={sx.dialogTitle}>
                <Box sx={sx.titleContainer}>
                    <UIText text={title} sx={sx.title} />
                    <Box sx={sx.toolbarActions}>
                        {
                            toolbarActions && <Paper sx={sx.toolbarActionsPaper}>
                                {toolbarActions}
                            </Paper>
                        }
                        {onClose && (
                            <IconButton
                                aria-label='close'
                                onClick={onClose}
                                sx={sx.close}>
                                <Close />
                            </IconButton>
                        )}
                    </Box>

                </Box>
            </DialogTitle>

            <DialogContent>
                {children}
            </DialogContent>

            <DialogActions>{actions}</DialogActions>
        </Dialog>);

};

const useStyles = makeStyles({
    dialogPaper: {
        minHeight: '35vh'
    }
});

const sx = {
    title: { flexGrow: 1, fontWeight: '800' },
    dialogTitle: { m: 0, p: 2 },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    toolbarActionsPaper: { mx: 1 },
    toolbarActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    close: { color: Palette.primaryTextColor }

} as const;
