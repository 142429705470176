import { Repository, RepositorySpec } from '../../Repository';
import { AuthSessionInfoSpec } from '../../authentication/model/initAuthInfo';
import { HttpResponse } from '../../mazars-network-http-client';
import { HttpRoutes } from '../../HttpRoutes';
import { UserGuide } from './model/UserGuide';
import { BaseResourceDataSpec } from '../common/BaseResource';
import { resourcesHelper } from '../../utils/resourcesHelper';
import { HttpContentType, OrganizationParams } from '../../../types/types';

export interface UserGuideRepositorySpec extends RepositorySpec {
    /// Fetch all the user guides of the given Organization
    fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<UserGuide[]>;

    /// Fetch the file of a specific user guide
    fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined>;

    //only at master organization level
    create(pack: BaseResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    //only at master organization level
    update(pack: BaseResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    //only at master organization level
    delete(packsIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;
}

export class UserGuideRepository extends Repository implements UserGuideRepositorySpec {
    async fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<UserGuide[]> {
        const parameters = { ...params };
        const response = (await this.datasource.remote.get(
            HttpRoutes.UserGuides,
            sessionInfo.accessToken,
            undefined,
            parameters,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<UserGuide[], any>;

        return response.data || [];
    }

    async fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined> {
        const path = HttpRoutes.UserGuideFile.replace(':id', id);
        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<Blob, any>;

        return response.data;
    }

    async create(pack: BaseResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = resourcesHelper.prepareGuideRequestBody(pack);

        const response = (await this.datasource.remote.post(
            HttpRoutes.UserGuides,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async update(pack: BaseResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = resourcesHelper.prepareGuideRequestBody(pack);
        const response = (await this.datasource.remote.put(
            HttpRoutes.UserGuides,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async delete(packsIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.UserGuides,
            packsIds,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<string[], any>;
        return response.data || [];
    }
}
