import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import {
    BrandingResourcesRepositorySpec,
    ColorShortcutRepositorySpec,
    LanguagePackRepositorySpec,
    LinksRepositorySpec,
    RepositoryProvider,
    UserGuideRepositorySpec
} from '../../domain';
import { UIScaffold, UIScaffoldChildContext } from '../scaffolder/UIScaffold';
import { APIScaffold, APIScaffoldChildContext } from '../scaffolder/APIScaffold';

import { UISideMenuBarEntry } from '../../types/types';
import { UISideBarMenu, UISideMenuBarEntryProps } from '../components/common/UISideBarMenu';
import { getLocalizedString } from '../../i18n/I18nHelper';
import {
    BrandResourcesContent,
    ColorShortcutsContent,
    LanguagePacksContent,
    LinksContent,
    UserGuidesContent
} from '../components/resources';

import { AppContext } from '../../context/AppContext';

interface ResourcesContentProps {
    linksRepository: LinksRepositorySpec;
    languagePackRepository: LanguagePackRepositorySpec;
    userGuideRepository: UserGuideRepositorySpec;
    colorShortcutRepository: ColorShortcutRepositorySpec;
    brandingResourcesRepository: BrandingResourcesRepositorySpec;
}

export const ResourcesContent = ({
    languagePackRepository,
    linksRepository,
    userGuideRepository,
    colorShortcutRepository,
    brandingResourcesRepository,
    cancelRequest,
    setLoading,
    setToast,
    setSessionExpired
}: ResourcesContentProps & UIScaffoldChildContext & APIScaffoldChildContext) => {
    const intl = useIntl();
    const [selectedSectionType, setSelectedSectionType] = useState<UISideMenuBarEntry>('brandingResources');
    const [currentSectionComponent, setCurrentSectionComponent] = useState<React.ReactNode>(null);
    const { isCurrentOrganizationMaster, isEditableAtOrganizationLevel, currentOrganization } = useContext(AppContext);

    useEffect(() => {
        setCurrentSectionComponent(sectionReducer(selectedSectionType));
        return () => {
            cancelRequest(linksRepository);
        };
    }, [selectedSectionType, currentOrganization?.id]);

    const handleSectionChanged = (entryType: UISideMenuBarEntry) => () => {
        setSelectedSectionType(entryType);
    };

    const entries: UISideMenuBarEntryProps[] = [
        {
            title: getLocalizedString(intl, 'RESOURCES.SIDE_BAR_MENU.BRANDING_RESOURCES.ENTRY'),
            entryType: 'brandingResources',
            id: 'BRANDING_RESOURCES',
            onClick: handleSectionChanged
        },
        {
            title: getLocalizedString(intl, 'RESOURCES.SIDE_BAR_MENU.COLOR_SHORTCUTS.ENTRY'),
            entryType: 'colorShortcuts',
            id: 'COLOR_SHORTCUTS',
            onClick: handleSectionChanged
        },
        {
            title: getLocalizedString(intl, 'RESOURCES.SIDE_BAR_MENU.LANGUAGE_PACKS.ENTRY'),
            entryType: 'languagePacks',
            id: 'LANGUAGE_PACKS',
            onClick: handleSectionChanged
        },
        {
            title: getLocalizedString(intl, 'RESOURCES.SIDE_BAR_MENU.USER_GUIDE.ENTRY'),
            entryType: 'userGuide',
            id: 'USER_GUIDES',
            onClick: handleSectionChanged
        },
        {
            title: getLocalizedString(intl, 'RESOURCES.SIDE_BAR_MENU.LINKS.ENTRY'),
            entryType: 'links',
            id: 'LINKS',
            onClick: handleSectionChanged
        }
    ];

    const sectionReducer = (entryType: UISideMenuBarEntry) => {
        switch (entryType) {
            case 'links':
                return (
                    <LinksContent
                        linksRepository={linksRepository}
                        setToast={setToast}
                        setLoading={setLoading}
                        setSessionExpired={setSessionExpired}
                        isEditable={isEditableAtOrganizationLevel || isCurrentOrganizationMaster}
                    />
                );

            case 'languagePacks':
                return (
                    <LanguagePacksContent
                        languagePackRepository={languagePackRepository}
                        setToast={setToast}
                        setLoading={setLoading}
                        isEditable={isCurrentOrganizationMaster}
                        setSessionExpired={setSessionExpired}
                    />
                );
            case 'userGuide':
                return (
                    <UserGuidesContent
                        userGuideRepository={userGuideRepository}
                        setToast={setToast}
                        setLoading={setLoading}
                        isEditable={isCurrentOrganizationMaster}
                        setSessionExpired={setSessionExpired}
                    />
                );

            case 'colorShortcuts':
                return (
                    <ColorShortcutsContent
                        colorShortcutRepository={colorShortcutRepository}
                        setToast={setToast}
                        setLoading={setLoading}
                        setSessionExpired={setSessionExpired}
                        isEditable={isCurrentOrganizationMaster}
                    />
                );

            case 'brandingResources':
                return (
                    <BrandResourcesContent
                        brandingResourcesRepository={brandingResourcesRepository}
                        setToast={setToast}
                        setLoading={setLoading}
                        setSessionExpired={setSessionExpired}
                        isEditable={isEditableAtOrganizationLevel}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <Box sx={styles.container}>
            <UISideBarMenu entries={entries} selectedEntry={selectedSectionType} />
            <Box sx={styles.section} className="container">
                {currentSectionComponent}
            </Box>
        </Box>
    );
};

export const ResourcesScreen = () => {
    const { isCurrentOrganizationMaster, currentOrganization } = useContext(AppContext);

    const linksRepository = isCurrentOrganizationMaster
        ? RepositoryProvider.getProvider().masterLinksRepository
        : RepositoryProvider.getProvider().linksRepository;

    return (
        <APIScaffold>
            {({ cancelRequest }) => (
                <UIScaffold>
                    {({ setLoading, setSessionExpired, setToast }) => (
                        <ResourcesContent
                            linksRepository={linksRepository}
                            languagePackRepository={RepositoryProvider.getProvider().languagePackRepository}
                            userGuideRepository={RepositoryProvider.getProvider().userGuideRepository}
                            colorShortcutRepository={RepositoryProvider.getProvider().colorShortcutRepository}
                            brandingResourcesRepository={RepositoryProvider.getProvider().brandingResourcesRepository}
                            setToast={setToast}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            cancelRequest={cancelRequest}
                        />
                    )}
                </UIScaffold>
            )}
        </APIScaffold>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'stretch',
        alignSelf: 'stretch',
        height: '85vh'
    },
    section: {
        display: 'flex',
        flexGrow: 1
    }
} as const;
