import React, { useContext, useEffect, useState } from 'react';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { APIScaffoldChildContext } from '../../../scaffolder/APIScaffold';
import { UITableHeadCell } from '../../common/UITableHead';
import { Library, LibraryFileData, LibraryRepositorySpec } from '../../../../domain';
import { serializeLibraryNodes } from '../../../../utils/serializer';
import { NavRoutes } from '../../../../navigation/NavRoutes';
import { useIntl } from 'react-intl';

import { useNavigate, useParams } from 'react-router-dom';
import { useRepository } from '../../../../hooks/useRepository';
import { LibraryFilesTable } from '../LibraryFilesTable';
import { AppContext } from '../../../../context/AppContext';
import { LibraryNodeType } from '../../../../types/types';
import { getMasterOrganizationId } from '../../../../utils/organizationHelper';
import { tabledHelper } from '../../../../utils/tabledHelper';
import { StickyBanner } from '../../common/StickyBanner';

const updateHeadCells: UITableHeadCell<LibraryFileData>[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        labelId: 'LIBRARY.TABLE.HEADER.FILE_NAME'
    }
];

interface EditLibraryNodeContentProps {
    libraryRepository: LibraryRepositorySpec;
}

export const EditLibraryNodeContent = ({
    cancelRequest,
    libraryRepository,
    setLoading,
    setToast,
    setSessionExpired
}: EditLibraryNodeContentProps & UIScaffoldChildContext & APIScaffoldChildContext) => {
    const { parentNodeId, parentNodeName } = useParams();
    const [initialValues, setInitialValues] = useState<{ nodes: LibraryFileData[] }>({ nodes: [] });
    const intl = useIntl();
    const navigate = useNavigate();
    const { sessionInfo, currentOrganization } = useContext(AppContext);

    useEffect(() => {
        (async () => await fetchLibraryNodeItems())();
        return () => {
            cancelRequest(libraryRepository);
        };
    }, [currentOrganization]);

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const fetchLibraryNodeItems = async () => {
        const getNodesData = (libraryNodes: Library[], parentId: string | null): LibraryFileData[] => {
            return libraryNodes.reduce((acc: LibraryFileData[], node: Library) => {
                if (node.eLibraryNodeType === LibraryNodeType.Directory) {
                    if (node.childs && node.childs.length > 0) {
                        // @ts-ignore
                        return [...acc, ...getNodesData(node.childs, node.id)];
                    } else return [...acc];
                } else
                    return [
                        ...acc,
                        tabledHelper.createDisplayedData<Library, LibraryFileData>({
                            ...node,
                            parentId: parentId
                        })
                    ];
            }, []);
        };

        await callRepository(
            // @ts-ignore
            () => libraryRepository.fetch(parentNodeId, sessionInfo),
            data => {
                const parentNodeDetails: Library = data as Library;

                // @ts-ignore
                setInitialValues({ nodes: getNodesData([parentNodeDetails], parentNodeId) ?? [] });
            }
        );
    };

    const handleUpdate = async (values: any) => {
        const nodes: LibraryFileData[] = values.nodes;
        const nodesToAdd = await serializeLibraryNodes(nodes.filter(node => !node.id));
        const nodesToUpdate = await serializeLibraryNodes(nodes.filter(node => !!node.id));

        const shouldCreate = nodesToAdd.info.length > 0;
        const shouldUpdate = nodesToUpdate.info.length > 0;

        // @ts-ignore
        shouldCreate &&
            (await callRepository(
                () => libraryRepository.create(nodesToAdd, sessionInfo),
                () => {
                    if (!shouldUpdate) {
                        setToast(getLocalizedString(intl, 'LIBRARY.UPDATE.SUCCESS.MESSAGE'), 'success');
                        navigate(NavRoutes.Library, { replace: true });
                    }
                }
            ));

        // @ts-ignore
        shouldUpdate &&
            (await callRepository(
                () => libraryRepository.update(nodesToUpdate, sessionInfo),
                () => {
                    setToast(getLocalizedString(intl, 'LIBRARY.UPDATE.SUCCESS.MESSAGE'), 'success');
                    navigate(NavRoutes.Library, { replace: true });
                }
            ));
    };

    const handleDelete = async (nodes: LibraryFileData[]): Promise<boolean> => {
        const nodesIds = nodes.map(node => node.id).filter(nodeId => !!nodeId);
        let canDelete = false;

        nodesIds &&
            sessionInfo &&
            (await callRepository(
                // @ts-ignore
                () => libraryRepository.delete(nodesIds, sessionInfo),
                async data => {
                    canDelete = !!data;
                }
            ));

        return canDelete;
    };

    return (
        <>
            <StickyBanner
                title={getLocalizedString(intl, 'LIBRARY.CREATE.BANNER.TITLE')}
                content={getLocalizedString(intl, 'LIBRARY.CREATE.BANNER.MESSAGE')}
            />

            <LibraryFilesTable
                title={getLocalizedString(intl, 'LIBRARY.EDIT.TITLE', { name: parentNodeName })}
                initialValues={initialValues}
                parentNodeId={parentNodeId}
                organizationId={currentOrganization.id}
                masterOrganizationId={getMasterOrganizationId(currentOrganization)}
                onDelete={handleDelete}
                handleSubmit={handleUpdate}
                headCells={updateHeadCells}
            />
        </>
    );
};
