import { EmptyGuid } from '../../../../types/types';

export interface LinkDataSpec {
    id?: string | null;
    name: string;
    description: string;
    url: string;
    organizationId: string;
    masterOrganizationId: string;
}

export const initLinkInfo: LinkDataSpec = {
    organizationId: EmptyGuid,
    masterOrganizationId: EmptyGuid,
    description: '',
    name: '',
    url: ''
};
