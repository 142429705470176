export enum NavRoutes {
    Login = '/',
    Home = '/home',
    Library = '/library',
    CreateLibraryNode = '/library/node/create/:parentNodeId/:parentNodeName',
    EditLibraryNode = '/library/node/edit/:parentNodeId/:parentNodeName',
    UserRoles = '/usersroles',
    Account = '/account',
    Contents = '/contents',
    NotFound = '/notfound',
    Resources = '/resources',
    Configuration = '/configuration',
    Organization = '/organization',
    Credentials = '/credentials',
    CreateCredentialsPack = '/credentials/create',
    MacroPacks = '/macros',
    CreateMacroPack = '/macros/create'
}

export const NavAdminRoleRoutes: string[] = [
    NavRoutes.Library,
    NavRoutes.CreateLibraryNode,
    NavRoutes.UserRoles,
    NavRoutes.Organization,
    NavRoutes.CreateCredentialsPack
];

export const NavStandardRoleRoutes: string[] = [NavRoutes.Contents];
