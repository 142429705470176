import React from 'react';
import { UIText } from './UIText';

export interface UIErrorMessageProps {
    message: string;
}

export const UIErrorMessage = ({ message }: UIErrorMessageProps) => {
    const error = typeof message === 'string' ? message : JSON.stringify(message);
    return <UIText sx={{ color: 'red' }} text={error} />;
};
