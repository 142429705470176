import React, { useContext, useEffect } from 'react';

import { CreateCredentialsPacksTable } from './CreateCredentialsPacksTable';
import { UITableHeadCell } from '../../common/UITableHead';
import { CredentialPackFileData } from '../../../../domain/credentials/model/initCredentialPack';
import { CredentialsPacksRepositorySpec } from '../../../../domain';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { APIScaffoldChildContext } from '../../../scaffolder/APIScaffold';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { useRepository } from '../../../../hooks/useRepository';
import { serializeCreateCredentialsPacks } from '../../../../utils/serializer';
import { NavRoutes } from '../../../../navigation/NavRoutes';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { getMasterOrganizationId, getOrganizationIdOrNull } from '../../../../utils/organizationHelper';

const createHeadCells: UITableHeadCell<CredentialPackFileData>[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        labelId: 'LIBRARY.TABLE.HEADER.FILE_NAME'
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: true,
        labelId: 'CREDENTIALS.TABLE.HEADER.DESCRIPTION'
    }
];

interface CreateCredentialsPacksContentProps {
    credentialsPackRepository: CredentialsPacksRepositorySpec;
}

export const CreateCredentialsPacksContent = ({
    credentialsPackRepository,
    setLoading,
    setToast,
    setSessionExpired,
    cancelRequest
}: CreateCredentialsPacksContentProps & UIScaffoldChildContext & APIScaffoldChildContext) => {
    useEffect(() => {
        return () => {
            cancelRequest(credentialsPackRepository);
        };
    }, [credentialsPackRepository]);

    const intl = useIntl();
    const { sessionInfo, currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);
    const navigate = useNavigate();
    const initialValues: { packs: CredentialPackFileData[] } = { packs: [] };

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const handleCreate = async (values: any) => {
        const nodes = await serializeCreateCredentialsPacks(values.packs);

        await callRepository(
            () => credentialsPackRepository.create(nodes, sessionInfo),
            () => {
                navigate(NavRoutes.Credentials, { replace: true });
                setToast(getLocalizedString(intl, 'CREDENTIALS.CREATE.SUCCESS.MESSAGE'), 'success');
            }
        );
    };

    return (
        <CreateCredentialsPacksTable
            title={getLocalizedString(intl, 'CREDENTIALS.CREATE.TITLE')}
            initialValues={initialValues}
            masterOrganizationId={getMasterOrganizationId(currentOrganization)}
            organizationId={getOrganizationIdOrNull(currentOrganization, isCurrentOrganizationMaster)}
            handleSubmit={handleCreate}
            headCells={createHeadCells}
        />
    );
};
