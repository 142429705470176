import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { NavRoutes } from '../../../navigation/NavRoutes';
import { Button } from '@mui/material';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { MenuPageType, NavRoutesStack } from '../../../types/types';
import { UIText } from '../common/UIText';
import { Palette } from '../../../res/colors';
import { AppContext } from '../../../context/AppContext';
import { UserAccess } from '../../../domain/roles/model/initRole';

export interface MenuPageProps {
    type: MenuPageType;
    route: string;
}

const makeMenuPages = (accessInfo: UserAccess, isCurrentOrganizationMaster: boolean): MenuPageProps[] => {
    let level = accessInfo.roleLevel;

    if (accessInfo.accessMode === 'AppAdmin') {
        level = isCurrentOrganizationMaster ? 'masterOrganizationAdmin' : 'organizationAdmin';
    }

    switch (level) {
        case 'masterOrganizationAdmin':
            return [
                {
                    type: MenuPageType.Library,
                    route: NavRoutes.Library
                },
                {
                    type: MenuPageType.Credentials,
                    route: NavRoutes.Credentials
                },
                {
                    type: MenuPageType.Macro,
                    route: NavRoutes.MacroPacks
                },
                {
                    type: MenuPageType.Account,
                    route: NavRoutes.Account
                },
                {
                    type: MenuPageType.Resources,
                    route: NavRoutes.Resources
                },
                {
                    type: MenuPageType.Configuration,
                    route: NavRoutes.Configuration
                },
                {
                    type: MenuPageType.UsersRoles,
                    route: NavRoutes.UserRoles
                },
                {
                    type: MenuPageType.MasterOrganization,
                    route: NavRoutes.Organization
                }
            ];
        case 'organizationAdmin':
            return [
                {
                    type: MenuPageType.Library,
                    route: NavRoutes.Library
                },
                {
                    type: MenuPageType.Credentials,
                    route: NavRoutes.Credentials
                },
                {
                    type: MenuPageType.Macro,
                    route: NavRoutes.MacroPacks
                },
                {
                    type: MenuPageType.Account,
                    route: NavRoutes.Account
                },
                {
                    type: MenuPageType.Resources,
                    route: NavRoutes.Resources
                },
                {
                    type: MenuPageType.Configuration,
                    route: NavRoutes.Configuration
                },
                {
                    type: MenuPageType.UsersRoles,
                    route: NavRoutes.UserRoles
                },
                {
                    type: MenuPageType.Organization,
                    route: NavRoutes.Organization
                }
            ];
        case 'standard':
            return [
                {
                    type: MenuPageType.Contents,
                    route: NavRoutes.Contents
                },
                {
                    type: MenuPageType.Credentials,
                    route: NavRoutes.Credentials
                },
                {
                    type: MenuPageType.Macro,
                    route: NavRoutes.MacroPacks
                },
                {
                    type: MenuPageType.Account,
                    route: NavRoutes.Account
                },
                {
                    type: MenuPageType.Resources,
                    route: NavRoutes.Resources
                },
                {
                    type: MenuPageType.Configuration,
                    route: NavRoutes.Configuration
                }
            ];
    }
};

export const UIAppMenu = (): JSX.Element => {
    const { accessData, isCurrentOrganizationMaster } = useContext(AppContext);
    const [pages, setPages] = useState<MenuPageProps[]>(makeMenuPages(accessData, isCurrentOrganizationMaster));

    useEffect(() => {
        setPages(makeMenuPages(accessData, isCurrentOrganizationMaster));
    }, [accessData.roleLevel, accessData.accessMode, isCurrentOrganizationMaster]);

    return <UIMenuContent entries={pages} />;
};

interface UIMenuContentProps {
    entries: MenuPageProps[];
}

const UIMenuContent = ({ entries: pages }: UIMenuContentProps) => {
    const intl = useIntl();
    const navigation = useNavigate();
    const currentRoute = useLocation().pathname;

    useEffect(() => {
        if (currentRoute === NavRoutes.Home && pages?.length > 0 && !!pages[0]) {
            navigation(pages[0].route);
        }
    }, [currentRoute]);

    const handleSelectPage = (route: string) => () => {
        navigation(route, { replace: true });
    };

    const isStackOfCurrentRoute = (pageType: MenuPageType) => {
        const currentRoutesStack = NavRoutesStack[pageType];
        const getRoutePrefix = (route: string) => route.split('/:')[0];

        return currentRoutesStack.some(route => {
            const prefix = getRoutePrefix(route);
            return prefix && currentRoute.startsWith(prefix);
        });
    };
    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <UIText
                        variant="h5"
                        noWrap
                        sx={styles.appName}
                        text={getLocalizedString(intl, 'HEADER.MENU.APP.NAME')}
                    />

                    <Box sx={styles.pagesBox}>
                        {pages.map(({ route, type: pageType }) => (
                            <Button
                                key={pageType}
                                onClick={handleSelectPage(route)}
                                sx={styles.button(isStackOfCurrentRoute(pageType))}>
                                {getLocalizedString(intl, `HEADER.MENU.PAGE.${pageType}`)}
                            </Button>
                        ))}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const styles = {
    appName: {
        mr: 2,
        display: { xs: 'none', md: 'flex' },
        color: Palette.white
    },
    button: isSelected => ({
        '&:focus-within': {
            outline: 0
        },
        my: 2,
        color: 'white',
        display: 'block',
        border: 0,
        borderColor: 'white',
        borderRadius: 0,
        outline: 0,
        borderBottomWidth: isSelected ? 1 : 0,
        fontWeight: '600'
    }),
    pagesBox: {
        flexGrow: 1,
        display: { md: 'flex' }
    }
} as const;
