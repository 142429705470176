import React from 'react';
import { DefaultLayout } from '../ui/layout/DefaultLayout';
import { NavRoutes } from './NavRoutes';
import { HomeScreen } from '../ui/screens/HomeScreen';
import { ProtectedRoute } from './ProtectedRoute';
import { LibraryScreen } from '../ui/screens/library/LibraryScreen';
import { UsersRolesScreen } from '../ui/screens/UsersRolesScreen';
import { AccountScreen } from '../ui/screens/AccountScreen';
import { ContentsScreen } from '../ui/screens/library/ContentsScreen';
import { CreateLibraryNodeScreen } from '../ui/screens/library/CreateLibraryNodeScreen';
import { EditLibraryNodeScreen } from '../ui/screens/library/EditLibraryNodeScreen';
import { Navigate } from 'react-router-dom';
import { LoginLayout } from '../ui/layout/LoginLayout';
import { LoginScreen } from '../ui/screens/LoginScreen';
import { AuthSessionInfoSpec } from '../domain';
import { ResourcesScreen } from '../ui/screens/ResourcesScreen';
import { ConfigurationScreen } from '../ui/screens/ConfigurationScreen';
import { OrganizationScreen } from '../ui/screens/OrganizationScreen';

import { CreateCredentialPackScreen } from '../ui/screens/credentials/CreateCredentialPackScreen';
import { CredentialsScreen } from '../ui/screens/credentials/CredentialsScreen';
import { MacroPacksScreen } from '../ui/screens/macro/MacroPacksScreen';
import { CreateMacroPackScreen } from '../ui/screens/macro/CreateMacroPackScreen';

export const AppRoutes = [
    {
        path: NavRoutes.Home,
        component: HomeScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.Library,
        component: LibraryScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.CreateLibraryNode,
        component: CreateLibraryNodeScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.EditLibraryNode,
        component: EditLibraryNodeScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.UserRoles,
        component: UsersRolesScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.Organization,
        component: OrganizationScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.Account,
        component: AccountScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.Contents,
        component: ContentsScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.Resources,
        component: ResourcesScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.Configuration,
        component: ConfigurationScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.Credentials,
        component: CredentialsScreen,
        layout: DefaultLayout
    },

    {
        path: NavRoutes.CreateCredentialsPack,
        component: CreateCredentialPackScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.MacroPacks,
        component: MacroPacksScreen,
        layout: DefaultLayout
    },
    {
        path: NavRoutes.CreateMacroPack,
        component: CreateMacroPackScreen,
        layout: DefaultLayout
    }
];

export const RouteElement = ({ component: Component, layout: Layout, ...rest }: any) => (
    <ProtectedRoute>
        <Layout>
            <Component {...rest} />
        </Layout>
    </ProtectedRoute>
);

export const LoginElement = ({ sessionInfo }: { sessionInfo?: AuthSessionInfoSpec }) => {
    return (
        <>
            {!sessionInfo?.accessToken ? (
                <LoginLayout>
                    <LoginScreen />
                </LoginLayout>
            ) : (
                <Navigate to={NavRoutes.Home} replace />
            )}
        </>
    );
};
