import { EmptyGuid } from '../../../types/types';
import { CredentialPack } from './CredentialPack';

export type PostCredentialPack = Pick<
    CredentialPack,
    'organizationId' | 'masterOrganizationId' | 'name' | 'description' | 'fileHash'
>;

export interface CredentialPackDataSpec {
    files: Blob[];
    info: PostCredentialPack[] | CredentialPack[];
}

export interface CredentialPackFileData extends CredentialPack {
    file?: Blob | null;
}

export const initCredentialPackInfo: CredentialPack = {
    organizationId: null,
    masterOrganizationId: EmptyGuid,
    description: '',
    name: '',
    id: EmptyGuid,
    fileExtension: 'ppt',
    createDate: '',
    updateDate: ''
};
