import React, { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, List } from '@mui/material';

import { ExpandMore } from '@mui/icons-material';

import { MasterOrganizationRoleEnum, OrganizationRoleEnum, UserRolesPanels } from '../../../../types/types';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { SpecialUserListItem } from './SpecialUserListItem';
import { UIDialog } from '../../common/UIDialog';
import { UIActionButton } from '../../common/UIActionButton';
import { CreateSpecialUser } from './CreateSpecialUser';
import { UIText } from '../../common/UIText';
import { useRepository } from '../../../../hooks/useRepository';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { AppContext } from '../../../../context/AppContext';
import { FormikProps } from 'formik/dist/types';

import {
    EOrganizationRole,
    initMasterOrganizationUserDataSpec,
    initOrganizationUserDataSpec,
    OrganizationRolesRepositorySpec,
    UserOrganizationRole
} from '../../../../domain';

import { UISectionHeader } from '../../common/UISectionHeader';
import { getSelectableOrganizationRoles, serializeUserRole } from '../../../../utils/roleHelper';
import { getMasterOrganizationId } from '../../../../utils/organizationHelper';

interface SpecialUsersContainerProps<D> {
    handlePanelChange: (panel: UserRolesPanels) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    isExpanded: boolean;
    rolesRepository: OrganizationRolesRepositorySpec;
    isCreateUserAllowed?: boolean;
    isDeleteUserAllowed?: boolean;
    roles: EOrganizationRole[];
}

export const SpecialUsersContainer = <D,>({
    isDeleteUserAllowed = false,
    isCreateUserAllowed = false,
    handlePanelChange,
    isExpanded,
    roles,
    setSessionExpired,
    setToast,
    setLoading,
    rolesRepository
}: PropsWithChildren<SpecialUsersContainerProps<D>> & UIScaffoldChildContext) => {
    const { sessionInfo, currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);

    const selectableRoles = useMemo(() => getSelectableOrganizationRoles(roles), [roles]);

    const createFormRef = useRef();
    const intl = useIntl();
    const [specialUsers, setSpecialUsers] = useState<D[] | null>([]); // init to current user just for testing
    const [isCreateDialogVisible, setCreateUserMode] = useState<boolean>(false);

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    useEffect(() => {
        (async () => fetchSpecialUsers())();
    }, [currentOrganization]);

    const fetchSpecialUsers = async () => {
        const filteredERoles: string = isCurrentOrganizationMaster
            ? `${MasterOrganizationRoleEnum.Admin}`
            : `${OrganizationRoleEnum.Admin};${OrganizationRoleEnum.Guest}`;

        await callRepository<UserOrganizationRole[]>(
            () => rolesRepository.fetchOrganizationUsers(currentOrganization.id, filteredERoles, sessionInfo),
            users => {
                setSpecialUsers((users as D[]) ?? []);
            }
        );
    };

    const updateCreateDialogVisibility = () => {
        setCreateUserMode(!isCreateDialogVisible);
    };

    const openCreateDialog = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();
        updateCreateDialogVisibility();
    };

    const doTriggerSubmitCreate = () => {
        const formik = createFormRef.current ? (createFormRef.current as FormikProps<any>) : null;
        formik?.handleSubmit();
    };

    const handleCreateUser = async (data: D) => {
        await callRepository(
            () => rolesRepository.attach(serializeUserRole(data, isCurrentOrganizationMaster), sessionInfo),
            async () => {
                await fetchSpecialUsers();
                setToast(getLocalizedString(intl, 'USERS_ROLES.SPECIAL_USERS.CREATE.SUCCESS'), 'success');
                updateCreateDialogVisibility();
            }
        );
    };

    const handleDeleteUser = async (userRole: D) => {
        const { id: userRoleId } = userRole as { id: string };

        if (!userRoleId) {
            console.log('userRoleId is not defined');
            return;
        }

        await callRepository(
            () => rolesRepository.remove([userRoleId], sessionInfo),
            async () => {
                setToast(getLocalizedString(intl, 'USERS_ROLES.SPECIAL_USERS.DELETE.USER.SUCCESS'), 'success');
                await fetchSpecialUsers();
            }
        );
    };

    return (
        <>
            <Accordion
                elevation={3}
                sx={{ paddingX: 2 }}
                TransitionProps={{ unmountOnExit: true }}
                expanded={isExpanded}
                onChange={handlePanelChange(UserRolesPanels.SpecialUsers)}>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="users-content" id="users-header">
                    <Box sx={{ width: '100%' }}>
                        <UISectionHeader
                            titleProps={{ my: 0, variant: 'h6' }}
                            title={getLocalizedString(intl, 'USERS_ROLES.SPECIAL_USERS.TITLE')}
                            isCreateEnabled={isCreateUserAllowed}
                            onCreate={openCreateDialog}
                        />
                    </Box>
                </AccordionSummary>

                <AccordionDetails>
                    <>
                        <UIText text={getLocalizedString(intl, 'USERS_ROLES.SPECIAL_USERS.MESSAGE')} />

                        <List aria-label="special-users">
                            <>
                                {specialUsers?.map((userRole, index) => {
                                    const { id } = userRole as { id: string };
                                    return (
                                        <SpecialUserListItem<D>
                                            key={id || index}
                                            isDeleteAllowed={isDeleteUserAllowed}
                                            handleDelete={handleDeleteUser}
                                            isMasterOrganization={isCurrentOrganizationMaster} // enhance with the type of orga (master or child orga) to know which enum to use for orga roles type
                                            userRole={userRole}
                                        />
                                    );
                                })}
                            </>
                        </List>
                    </>
                </AccordionDetails>
            </Accordion>

            <UIDialog
                open={isCreateDialogVisible}
                onClose={updateCreateDialogVisibility}
                title={getLocalizedString(intl, 'USERS_ROLES.SPECIAL_USERS.CREATE.DIALOG.TITLE')}
                actions={
                    <UIActionButton
                        title={getLocalizedString(intl, 'COMMON.ACTIONS.VALIDATE')}
                        onClick={doTriggerSubmitCreate}
                    />
                }>
                <CreateSpecialUser<D>
                    isMasterOrganization={isCurrentOrganizationMaster}
                    initialUserValues={
                        {
                            ...initOrganizationUserDataSpec,
                            ...initMasterOrganizationUserDataSpec,
                            organizationId: currentOrganization.id,
                            masterOrganizationId: getMasterOrganizationId(currentOrganization)
                        } as D
                    }
                    formRef={createFormRef}
                    organizationRoles={selectableRoles}
                    onSubmit={handleCreateUser}
                />
            </UIDialog>
        </>
    );
};
