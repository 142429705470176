import React from 'react';
import * as Yup from 'yup';
import { UITableHead, UITableHeadCell } from '../../common/UITableHead';
import {
    CredentialPackFileData,
    initCredentialPackInfo
} from '../../../../domain/credentials/model/initCredentialPack';
import { useIntl } from 'react-intl';
import { BlobWithName, FilesMimeTypes, Order } from '../../../../types/types';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { flattenFileName } from '../../../../utils/fileHelper';
import { CredentialPack } from '../../../../domain/credentials/model/CredentialPack';
import { tabledHelper } from '../../../../utils/tabledHelper';
import { Box, Paper } from '@mui/material';
import { CONTAINER_TOP_MARGIN } from '../../../../res/dimensions';
import { UIText } from '../../common/UIText';
import { FieldArray, Form, Formik } from 'formik';
import { UIDropzone } from '../../common/UIDropzone';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { CredentialPackTableRow } from './CredentialPackTableRow';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import { UIActionButton } from '../../common/UIActionButton';
import { UITableToolbar } from '../../common/UITableToolbar';

export interface CreateCredentialsPacksTableProps {
    title: string;
    headCells: UITableHeadCell<CredentialPackFileData>[];
    organizationId: string | null;
    masterOrganizationId?: string;
    handleSubmit: (values: any) => Promise<any>;
    initialValues: { packs: CredentialPackFileData[] };
}

export const CreateCredentialsPacksTable = ({
    title,
    organizationId,
    masterOrganizationId,
    initialValues,
    handleSubmit,
    headCells
}: CreateCredentialsPacksTableProps) => {
    const intl = useIntl();
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof CredentialPackFileData>('name');
    const [selected, setSelected] = React.useState<string[]>([]);

    const validationSchema = Yup.object().shape({
        packs: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string().required(getLocalizedString(intl, 'LIBRARY.VALIDATION.NAME.REQUIRED')),
                    description: Yup.string().nullable()
                })
            )
            .required(getLocalizedString(intl, 'LIBRARY.VALIDATION.NODE.REQUIRED'))
    });

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = (packs: CredentialPackFileData[]) =>
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - packs.length) : 0;

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof CredentialPackFileData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (nodes: CredentialPackFileData[]) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            // @ts-ignore
            const newSelectedIs = nodes.map(n => n.name);
            setSelected(newSelectedIs);
            return;
        }

        setSelected([]);
    };

    const handleClick = (_, name: string) => {
        const newSelected = tabledHelper.updateSelectedEntries(selected, name);
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAdd = (add: (file: any) => void) => (files?: BlobWithName[]) => {
        files?.forEach((file: BlobWithName) => {
            const { name, extension } = flattenFileName(file.name);

            const newPack: CredentialPack = {
                ...initCredentialPackInfo,
                organizationId: organizationId,
                masterOrganizationId: masterOrganizationId,
                name,
                fileExtension: extension
            };

            add(tabledHelper.createDisplayedData<CredentialPack, CredentialPackFileData>(newPack, file));
        });
    };

    const handleRemove = async (remove: (index: number) => void, packs: CredentialPackFileData[]) => {
        tabledHelper.removeEntriesFromTable(selected, remove, page * rowsPerPage);
        setSelected([]);
    };

    return (
        <Box className="container" sx={{ mt: CONTAINER_TOP_MARGIN }}>
            <UIText sx={{ fontSize: 18, fontWeight: 'bold', mb: 1 }} text={title} />

            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}>
                {({ values, isValid, dirty, isSubmitting, errors }) => {
                    return (
                        <Form>
                            <FieldArray
                                name="packs"
                                render={({ push, remove }) => (
                                    <>
                                        <UIDropzone
                                            handleUploadFiles={handleAdd(push)}
                                            accept={
                                                [
                                                    ...(FilesMimeTypes.Powerpoint || []),
                                                    ...(FilesMimeTypes.PowerpointTemplate || [])
                                                ] as string[]
                                            }
                                            dropzoneDragText={getLocalizedString(
                                                intl,
                                                'LIBRARY.FIELD.FILE.DROPZONE.DRAG.MESSAGE'
                                            )}
                                            dropzoneDefaultText={getLocalizedString(
                                                intl,
                                                'LIBRARY.FIELD.FILE.DROPZONE.DEFAULT.MESSAGE'
                                            )}
                                        />

                                        <UITableToolbar
                                            numSelected={selected.length}
                                            handleDelete={async () => await handleRemove(remove, values.packs)}
                                        />

                                        <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                                            <TableContainer>
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    size="medium">
                                                    <UITableHead
                                                        selectionProps={{
                                                            numSelected: selected.length,
                                                            onSelectAllClick: handleSelectAllClick(values.packs)
                                                        }}
                                                        headCells={headCells}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={values.packs.length}
                                                    />

                                                    <TableBody>
                                                        <>
                                                            {values.packs &&
                                                                values.packs.length > 0 &&
                                                                values.packs
                                                                    .slice(
                                                                        page * rowsPerPage,
                                                                        page * rowsPerPage + rowsPerPage
                                                                    )
                                                                    .map(
                                                                        (
                                                                            pack: CredentialPackFileData,
                                                                            rawIndex: number
                                                                        ) => {
                                                                            const index = rawIndex + page * rowsPerPage;
                                                                            const isItemSelected =
                                                                                tabledHelper.isSelectedEntry(
                                                                                    selected,
                                                                                    pack.name
                                                                                );
                                                                            return (
                                                                                <CredentialPackTableRow
                                                                                    key={pack.id ?? pack.name}
                                                                                    fieldsProps={{
                                                                                        description: `packs.${index}.description`,
                                                                                        name: `packs.${index}.name`
                                                                                    }}
                                                                                    pack={pack}
                                                                                    index={index}
                                                                                    isSelected={isItemSelected}
                                                                                    handleRowClick={handleClick}
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                        </>
                                                    </TableBody>
                                                </Table>
                                                {emptyRows(values.packs) > 0 ? (
                                                    <TableRow
                                                        style={{
                                                            height: 53 * emptyRows(values.packs)
                                                        }}>
                                                        <TableCell colSpan={6}></TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <UIText
                                                        text={getLocalizedString(intl, 'COMMON.TABLE.NO.RECORDS')}
                                                        sx={{ textAlign: 'center' }}
                                                    />
                                                )}
                                            </TableContainer>

                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={values.packs.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    </>
                                )}
                            />

                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <UIActionButton
                                    variant="outlined"
                                    disabled={!isValid || isSubmitting || !dirty}
                                    type="submit"
                                    size="large"
                                    fullWidth
                                    sx={{ my: 5, mt: 5, width: 'auto' }}
                                    title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};
