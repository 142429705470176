import { MasterOrganizationRepositorySpec, Organization, OrganizationRepository } from '../../../domain';
import { UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { useIntl } from 'react-intl';
import { useRepository } from '../../../hooks/useRepository';
import { UISectionTitle } from '../common/UISectionTitle';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { UIText } from '../common/UIText';
import { Box, Paper } from '@mui/material';
import { UserField } from '../account/overview/UserField';
import { OrganizationContent } from './OrganizationContent';

interface MasterOrganizationContentProps {
    masterOrganization: Organization;
    masterOrganizationRepository: MasterOrganizationRepositorySpec;
    organizationRepository: OrganizationRepository;
}

export const MasterOrganizationContent = ({
    masterOrganization,
    masterOrganizationRepository,
    organizationRepository,
    setToast,
    setSessionExpired,
    setLoading
}: MasterOrganizationContentProps & UIScaffoldChildContext) => {
    const { sessionInfo, isEditableAtMasterOrganization } = useContext(AppContext);
    const [childOrganizations, setChildOrganizations] = useState<Organization[] | undefined>(undefined);

    const intl = useIntl();
    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    useEffect(() => {
        (async () =>
            await callRepository(
                () => masterOrganizationRepository.fetchOrganizations(masterOrganization.id, sessionInfo),
                data => {
                    setChildOrganizations(data);
                }
            ))();
    }, [masterOrganization?.id]);

    return (
        <>
            <UISectionTitle
                title={getLocalizedString(intl, 'MASTER.ORGANIZATION.TITLE', { name: masterOrganization.name })}
            />
            <UIText text={getLocalizedString(intl, 'MASTER.ORGANIZATION.SUBTITLE')} />
            <Paper elevation={3} sx={styles.paper}>
                <UserField
                    title={getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.NAME')}
                    value={masterOrganization.name}
                />
            </Paper>
            {childOrganizations ? (
                <Box sx={{ mt: 3 }}>
                    <UISectionTitle title={getLocalizedString(intl, 'MASTER.ORGANIZATION.CHILDS.TITLE')} />
                    <UIText
                        text={getLocalizedString(intl, 'MASTER.ORGANIZATION.CHILDS.SUBTITLE', {
                            name: masterOrganization.name
                        })}
                    />
                    {childOrganizations.map(childOrganization => (
                        <OrganizationContent
                            isEditable={isEditableAtMasterOrganization}
                            shouldFetchOnMount={false}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            setToast={setToast}
                            organizationRepository={organizationRepository}
                            organization={childOrganization}
                        />
                    ))}
                </Box>
            ) : null}
        </>
    );
};

const styles = {
    paper: {
        px: 3,
        py: 1,
        mt: 1
    }
} as const;
