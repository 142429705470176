import * as Yup from 'yup';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useForm } from '../../../../hooks/useForm';
import { EMasterOrganizationRole, EOrganizationRole } from '../../../../domain';
import { formatOrganizationRoles } from '../../../../utils/roleHelper';

interface CreateSpecialUserProps<D> {
    isMasterOrganization: boolean;
    formRef: any;
    initialUserValues: D;
    organizationRoles: (EOrganizationRole | EMasterOrganizationRole)[];
    onSubmit: (values: D) => void;
}

export const CreateSpecialUser = <D, >({
                                           isMasterOrganization,
                                           initialUserValues,
                                           organizationRoles,
                                           onSubmit,
                                           formRef
                                       }: PropsWithChildren<CreateSpecialUserProps<D>>) => {

    const [createdUserRole, setUserRole] = useState<D>(initialUserValues);

    const fieldsNames = useMemo(() => ({
        email: 'email',
        eRole: isMasterOrganization ? 'eMasterOrganizationRole' : 'eOrganizationRole'
    }), []);


    const intl = useIntl();
    const classes = useStyles();

    const validationSchema = Yup.object({
        [fieldsNames.email]: Yup.string()
            .email(getLocalizedString(intl, 'COMMON.VALIDATION.EMAIL.FORMAT'))
            .required(getLocalizedString(intl, 'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.EMAIL.REQUIRED')),

        [fieldsNames.eRole]: Yup.number()
            .required(getLocalizedString(intl, 'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.ROLE.REQUIRED'))

    });

    const fields = [
        {
            id: fieldsNames.email,
            name: fieldsNames.email,
            placeholder: getLocalizedString(intl, 'COMMON.EMAIL.FIELD.PLACEHOLDER'),
            type: 'email',
            variant: 'standard',
            className: classes.textField,
            fieldType: 'textFieldItem'
        },
        {
            id: fieldsNames.eRole,
            name: fieldsNames.eRole,
            placeholder: getLocalizedString(intl, 'USERS_ROLES.SPECIAL_USERS.DROP_DOWN.PLACEHOLDER'),
            fieldType: 'selectFieldItem',
            options: formatOrganizationRoles(organizationRoles),
            getOptionValue: (role: EOrganizationRole | EMasterOrganizationRole) => role.value,
            getOptionLabel: (role: EOrganizationRole | EMasterOrganizationRole) => role.text
        }
    ];

    const handleSubmit = (values: D) => {
        setUserRole(values);
        onSubmit(values);
    };

    const { RenderForm } = useForm<any>({
        initialValues: createdUserRole,
        onSubmit: handleSubmit,
        validationSchema: validationSchema
    });

    return <Box>
        <RenderForm formFields={fields} innerFormRef={formRef} sx={sx.container} />
    </Box>;
};

const sx = {
    container: {
        flexDirection: 'column',
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'repeat(2, 1fr)'
    }

} as const;

const useStyles = makeStyles({

    textField: {
        width: '90%'
    }
});
