import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useForm } from '../../../../hooks/useForm';
import { Alert, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FeedbackConfigSpec } from '../../../../domain/configuration/feedbackConfig/model/FeedbackConfig';
import { UIText } from '../../common/UIText';

interface FeedbackConfigFormProps {
    feedbackConfig: FeedbackConfigSpec;
    formRef: any;
    handleSubmit: (feedbackConfig: FeedbackConfigSpec) => void;
    disabled?: boolean;
    alertMessage?: string;
}

export const FeedbackConfigForm = ({
    feedbackConfig,
    formRef,
    handleSubmit,
    disabled = true,
    alertMessage
}: FeedbackConfigFormProps) => {
    const intl = useIntl();
    const classes = useStyles();

    const validationSchema = Yup.object({
        emailBody: Yup.string().required(
            getLocalizedString(intl, 'CONFIGURATION.FEEDBACK.FORM.VALIDATION.EMAIL.REQUIRED')
        ),
        emailAddress: Yup.string()
            .required(getLocalizedString(intl, 'CONFIGURATION.FEEDBACK.FORM.VALIDATION.BODY.REQUIRED'))
            .email()
    });

    const fields = [
        {
            id: 'emailAddress',
            name: 'emailAddress',
            placeholder: getLocalizedString(intl, 'CONFIGURATION.FEEDBACK.FORM.EMAIL.FIELD.PLACEHOLDER'),
            fieldType: 'textFieldItem',
            variant: 'standard',
            className: classes.textField,
            disabled: disabled
        },
        {
            id: 'emailBody',
            name: 'emailBody',
            fieldType: 'textFieldItem',
            component: 'textarea',
            rows: 8,
            variant: 'outlined',
            className: classes.textField,
            placeholder: getLocalizedString(intl, 'CONFIGURATION.FEEDBACK.FORM.BODY.FIELD.PLACEHOLDER'),
            disabled: disabled
        }
    ];

    const { RenderForm } = useMemo(
        () =>
            useForm({
                enableReinitialize: true,
                initialValues: feedbackConfig,
                onSubmit: handleSubmit,
                validationSchema: validationSchema
            }),
        [feedbackConfig]
    );

    return (
        <Box sx={{ marginX: 5 }}>
            {alertMessage && (
                <Alert severity="info" sx={{ width: '100%' }}>
                    <UIText text={alertMessage} />
                </Alert>
            )}

            <RenderForm
                sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'space-around', gap: 2 }}
                formFields={fields}
                innerFormRef={formRef}
            />
        </Box>
    );
};

const useStyles = makeStyles({
    textField: {
        width: '80%'
    }
});
