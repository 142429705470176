import React, { useContext } from 'react';
import { APIScaffold } from '../../scaffolder/APIScaffold';
import { UIScaffold } from '../../scaffolder/UIScaffold';
import { RepositoryProvider } from '../../../domain';
import { LibraryTreeContent } from '../../components/library/home/LibraryTreeContent';
import { AppContext } from '../../../context/AppContext';

export const LibraryScreen = () => {
    const { isCurrentOrganizationMaster } = useContext(AppContext);

    return (
        <APIScaffold>
            {({ cancelRequest }) => (
                <UIScaffold>
                    {({ setLoading, setSessionExpired, setToast }) => (
                        <LibraryTreeContent
                            isEditable={true}
                            libraryRepository={
                                isCurrentOrganizationMaster
                                    ? RepositoryProvider.getProvider().masterLibraryRepository
                                    : RepositoryProvider.getProvider().libraryRepository
                            }
                            cancelRequest={cancelRequest}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            setToast={setToast}
                        />
                    )}
                </UIScaffold>
            )}
        </APIScaffold>
    );
};
