import { AuthSessionInfoSpec, Organization, RepositoryProvider } from '../domain';

interface OrganizationServiceSpec {
    fetchAppAdminOrganizations: (sessionInfo: AuthSessionInfoSpec) => Promise<Record<string, Organization[]>>;
}

export class OrganizationService implements OrganizationServiceSpec {
    fetchAppAdminOrganizations = async (sessionInfo: AuthSessionInfoSpec): Promise<Record<string, Organization[]>> => {
        const masterOrganizations = await RepositoryProvider.getProvider().masterOrganizationRepository.fetch(
            sessionInfo
        );

        if (!masterOrganizations || !Array.isArray(masterOrganizations)) {
            throw new Error('masterOrganizations array is not defined');
        }

        //for every master orga get its organizations
        const organizations: Organization[] = await masterOrganizations.reduce(
            async (accPromise: Promise<Organization[]>, masterOrganization) => {
                const currentOrganizations =
                    await RepositoryProvider.getProvider().masterOrganizationRepository.fetchOrganizations(
                        masterOrganization.id,
                        sessionInfo
                    );

                const acc = await accPromise;

                return [...acc, ...currentOrganizations];
            },
            Promise.resolve([])
        );

        return {
            masterOrganizations: masterOrganizations,
            organizations: organizations
        };
    };
}
