import { appConfiguration } from '../../../../appConfiguration';
import { EmptyGuid } from '../../../../types/types';

export interface FeedbackConfig {
    id: string;
    masterOrganizationId: string;
    organizationId: string;
    emailBody: string;
    emailAddress: string;
    version: string;
}

export interface FeedbackConfigSpec {
    id?: string;
    masterOrganizationId: string;
    organizationId: string;
    emailBody: string;
    emailAddress: string;
}

export const initFeedbackConfig: FeedbackConfigSpec = {
    masterOrganizationId: EmptyGuid,
    organizationId: EmptyGuid,
    emailAddress: '',
    emailBody: '',
    id: undefined
};
