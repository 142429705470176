import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { Paper } from '@mui/material';
import React, { useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { useForm } from '../../../hooks/useForm';
import { MacroPackFileData } from '../../../domain/macroPack/model/initMacroPack';
import { MacroNodesForm } from './MacroNodesForm';
import { FormikProps } from 'formik/dist/types';
import { MacroItem } from '../../../domain/macroPack/model/MacroPack';

export interface MacroPackDetailsProps {
    isEditable: {
        macros: boolean;
        name: boolean;
        description: boolean;
    };
    formRef: any;
    pack: MacroPackFileData;
    handleUpdate: (pack: MacroPackFileData) => Promise<void>;
    handleError: (error: string) => void;
}

export const MacroPackDetails = ({ isEditable, handleUpdate, pack, formRef }: MacroPackDetailsProps) => {
    const intl = useIntl();

    const classes = useStyles();
    const macrosItemsFormRef = useRef<FormikProps<{ macros: MacroItem[] }>>(null);

    const [currentPackValues, setCurrentPackValues] = useState<MacroPackFileData>(pack);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('MACROS.FORM.VALIDATION.NAME.REQUIRED'),
        description: Yup.string().nullable()
    });

    const fields = [
        {
            id: 'name',
            name: 'name',
            placeholder: getLocalizedString(intl, 'MACROS.FORM.NAME.FIELD.PLACEHOLDER'),
            fieldType: 'textFieldItem',
            variant: 'standard',
            className: classes.textField,
            disabled: !isEditable.name
        },
        {
            id: 'description',
            name: 'description',
            placeholder: getLocalizedString(intl, 'MACROS.FORM.DESCRIPTION.FIELD.PLACEHOLDER'),
            className: classes.textField,
            fieldType: 'textFieldItem',
            component: 'textarea',
            rows: 5,
            variant: 'outlined',
            disabled: !isEditable.description
        }
    ];

    const triggerMacroListSubmit = (values: MacroPackFileData) => {
        setCurrentPackValues(values);
        macrosItemsFormRef.current?.handleSubmit();
    };

    const handleFinalSubmit = async (values: { macros: MacroItem[] }) => {
        await handleUpdate({
            ...currentPackValues,
            macros: values.macros
        });
    };

    const { RenderForm } = useForm({
        initialValues: pack,
        onSubmit: triggerMacroListSubmit,
        enableReinitialize: true,
        validationSchema: validationSchema
    });

    return (
        <Paper elevation={3} sx={sx.container}>
            <RenderForm innerFormRef={formRef} formFields={fields} sx={sx.content} />

            <MacroNodesForm
                macros={pack.macros}
                isEditable={isEditable.macros}
                innerFormRef={macrosItemsFormRef}
                handleSubmit={handleFinalSubmit}
            />
        </Paper>
    );
};

const useStyles = makeStyles({
    textField: {
        width: '80%'
    }
});

const sx = {
    container: {
        m: 2,
        p: 2
    },
    content: {
        flexDirection: 'column',
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'repeat(2, 1fr)',
        mt: 1,
        alignItems: 'center'
    }
} as const;
