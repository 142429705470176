import { FeedbackConfig } from '../../../../domain';
import {
    FeedbackConfigSpec,
    initFeedbackConfig
} from '../../../../domain/configuration/feedbackConfig/model/FeedbackConfig';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../../context/AppContext';
import { ResourceDialogMode } from '../../../../types/types';
import { useOverridableView } from '../../../../hooks/useOverridableView';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { FormikProps } from 'formik/dist/types';
import { Box, Paper } from '@mui/material';
import { UIActionButton } from '../../common/UIActionButton';
import { FeedbackConfigForm } from './FeedbackConfigForm';
import { UIText } from '../../common/UIText';
import { UIDialog } from '../../common/UIDialog';
import { UISectionHeader } from '../../common/UISectionHeader';
import { UISectionFooter } from '../UISectionFooter';
import {
    getMasterOrganizationId,
    getOrganizationId,
    getScreenSubtitleByOrganizationType
} from '../../../../utils/organizationHelper';

interface FeedbackConfigsAreaProps {
    actionsHidden: boolean;
    feedbackConfig: FeedbackConfig | null;
    handleUpdate: (config: FeedbackConfigSpec) => Promise<boolean>;
    handleDelete: (ids: string[]) => void;
    handleCreate: (config: FeedbackConfigSpec) => Promise<boolean>;
}

export const FeedbackConfigsArea = ({
    feedbackConfig: existingFeedbackConfig,
    handleCreate,
    handleUpdate,
    handleDelete,
    actionsHidden
}: FeedbackConfigsAreaProps) => {
    const formRef = useRef();
    const intl = useIntl();
    const { currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogMode, setDialogMode] = useState<ResourceDialogMode | null>(null);
    const [currentFeedbackConfig, setFeedbackConfig] = useState<FeedbackConfigSpec>({
        ...initFeedbackConfig,
        masterOrganizationId: getMasterOrganizationId(currentOrganization),
        organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
    });

    const isCreateEnabled = useMemo(
        () => !actionsHidden && !existingFeedbackConfig?.id,
        [actionsHidden, existingFeedbackConfig]
    );

    const onEditRequested = (config: FeedbackConfig) => {
        setFeedbackConfig({
            ...existingFeedbackConfig,
            ...config,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });
        setOpenDialog(!openDialog);
        setDialogMode('edit');
    };

    const { backgroundColor, title, actions, overridable } = useOverridableView({
        item: existingFeedbackConfig,
        onDelete: handleDelete,
        onEditAsked: onEditRequested
    });

    const alertMessage =
        !overridable && dialogMode === 'create'
            ? getLocalizedString(intl, 'CONFIGURATION.FEEDBACK_CONFIG.FORM.EXISTING.CONFIG.INFO.MESSAGE')
            : '';

    const onCreateRequested = () => {
        setDialogMode('create');
        setFeedbackConfig({
            ...initFeedbackConfig,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });
        setOpenDialog(!openDialog);
    };

    const onCloseDialog = () => {
        setOpenDialog(!openDialog);
    };

    const doTriggerSubmit = () => {
        const formik = formRef.current ? (formRef.current as FormikProps<FeedbackConfigSpec>) : null;
        formik?.handleSubmit();
    };

    const onCreate = async (config: FeedbackConfigSpec) => {
        const isSuccess = await handleCreate(config);
        setOpenDialog(!isSuccess);
    };

    const onUpdate = async (config: FeedbackConfigSpec) => {
        const isSuccess = await handleUpdate(config);
        setOpenDialog(!isSuccess);
    };

    const handleSubmit = async (config: FeedbackConfigSpec) => {
        if (isCurrentOrganizationMaster) {
            !config.id ? await onCreate(config) : await onUpdate(config);
        } else {
            !overridable
                ? await onUpdate({
                      ...config,
                      id: existingFeedbackConfig?.id // enforce id here for the case we pressed add button (dialogMode= create) but finally it's an update (override at orga level)
                  })
                : await onCreate(config);
        }
    };

    return (
        <>
            <UISectionHeader
                onCreate={onCreateRequested}
                isCreateEnabled={isCreateEnabled}
                title={getLocalizedString(intl, 'CONFIGURATION.SIDE_BAR_MENU.FEEDBACK_CONFIG.ENTRY')}
                subtitle={getLocalizedString(intl, getScreenSubtitleByOrganizationType(isCurrentOrganizationMaster))}
            />
            <Paper elevation={3} sx={{ paddingY: 2 }}>
                <>
                    {!existingFeedbackConfig?.id ? (
                        <UIText
                            text={getLocalizedString(intl, 'CONFIGURATION.FEEDBACK.EMPTY.MESSAGE')}
                            sx={{ margin: 2 }}
                        />
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <FeedbackConfigForm
                                feedbackConfig={existingFeedbackConfig}
                                key={existingFeedbackConfig.id}
                                formRef={formRef}
                                handleSubmit={handleSubmit}
                            />

                            <UISectionFooter
                                backgroundColor={backgroundColor}
                                title={title}
                                actions={actions}
                                actionsHidden={actionsHidden}
                            />
                        </Box>
                    )}
                </>
            </Paper>
            {openDialog && (
                <UIDialog
                    open={openDialog}
                    onClose={onCloseDialog}
                    title={getLocalizedString(
                        intl,
                        `CONFIGURATION.FEEDBACK.${dialogMode === 'edit' ? 'EDIT' : 'CREATE'}.DIALOG.TITLE`
                    )}
                    actions={
                        <UIActionButton
                            title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                            onClick={doTriggerSubmit}
                        />
                    }>
                    <>
                        <FeedbackConfigForm
                            feedbackConfig={currentFeedbackConfig}
                            formRef={formRef}
                            alertMessage={alertMessage}
                            handleSubmit={handleSubmit}
                            disabled={false}
                        />
                    </>
                </UIDialog>
            )}
        </>
    );
};
