import { useEffect, useState } from 'react';
import { UIScaffoldChildContext, UIScaffoldLoadingAndSession } from '../ui/scaffolder/UIScaffold';
import { IntlShape } from 'react-intl/src/types';
import { MazarsErrorSpec } from '../domain';
import { ErrorCode } from '@mazars/mazars-network';
import { formatError, handleAPIErrorsIfNeeded } from '../utils/errorHelper';

export const useRepository = <T>(
    { setLoading, setToast, setSessionExpired }: UIScaffoldLoadingAndSession & UIScaffoldChildContext,
    intl: IntlShape,
    getData?: () => Promise<T>,
    useEffectDependencies?: Array<unknown>
) => {
    const [data, setData] = useState<T | null>(null);

    const callRepository = async <T>(
        apiMethod: () => Promise<T>,
        onSuccess?: (data: T) => void,
        onError?: (error: MazarsErrorSpec) => void
    ) => {
        try {
            setLoading(true);
            const result = (await apiMethod()) as T;
            //setData(result);

            onSuccess && onSuccess(result);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            const formattedError = handleAPIErrorsIfNeeded(error);

            if (!formattedError) {
                //TODO manage this case
                return;
            }
            /// 401 is managed in refreshAccessToken in Repository.ts
            if (formattedError.status !== ErrorCode.Unauthorized) {
                const message = formatError(formattedError, intl);
                setToast(message, 'error');

                onError && onError(formattedError);
            } else {
                setSessionExpired(true);
            }
        }
    };

    useEffect(() => {
        getData &&
            (async () =>
                await callRepository(getData, async data => {
                    setData(data);
                }))();
    }, useEffectDependencies ?? []);

    return { data: data, callRepository };
};
