import { AuthSessionInfoSpec, HttpRoutes, LearningLink, LinkDataSpec, RepositorySpec } from '../..';

import { HttpResponse } from '../../mazars-network-http-client';
import { Repository } from '../../Repository';
import { OrganizationParams } from '../../../types/types';

export interface LearningLinkRepositorySpec extends RepositorySpec {
    /// Fetch the learning link  of the given organization, there is one learning link per organization
    fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<LearningLink | null>;

    create(learningLink: LinkDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    update(learningLink: LinkDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    delete(ids: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;
}

export class LearningLinkRepository extends Repository implements LearningLinkRepositorySpec {
    async fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<LearningLink | null> {
        const parameters = { ...params };
        const response = (await this.datasource.remote.get(
            HttpRoutes.LearningLinks,
            sessionInfo.accessToken,
            undefined,
            parameters,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<LearningLink, any>;

        return response.data || null;
    }

    async create(learningLink: LinkDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.post(
            HttpRoutes.LearningLinks,
            learningLink,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async update(learningLink: LinkDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.put(
            HttpRoutes.LearningLinks,
            learningLink,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async delete(ids: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.LearningLinks,
            ids,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }
}
