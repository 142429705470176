import React, { useMemo } from 'react';
import { User, UserOrganizationRole } from '../../../../domain';
import { Box, Card, CardContent } from '@mui/material';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { UserField } from './UserField';
import { UISectionTitle } from '../../common/UISectionTitle';
import { accountHelper } from '../../../../utils/accountHelper';

export interface UserAreaProps {
    user: User | undefined;
    userRoles: UserOrganizationRole[] | undefined;
    style?: any;
}

export const UserArea = ({ user, userRoles }: UserAreaProps) => {
    const intl = useIntl();

    const organizationsLabel = useMemo(
        () => accountHelper.getAllOrganizationsLabel(user?.organizations || []),
        [user?.organizations]
    );

    const rolesLabel = useMemo(
        () => accountHelper.getUserRolesLabel(userRoles || [], user?.organizations || []),
        [userRoles, user?.organizations]
    );

    return (
        <>
            <UISectionTitle title={getLocalizedString(intl, 'ACCOUNT.OVERVIEW.USER_AREA.TITLE')} />
            <Card>
                <CardContent>
                    <Box sx={styles.content}>
                        {user && (
                            <>
                                <UserField
                                    title={getLocalizedString(intl, 'ACCOUNT.OVERVIEW.USER.EMAIL')}
                                    value={user.email.toLowerCase()}
                                />

                                <UserField
                                    title={getLocalizedString(intl, 'ACCOUNT.OVERVIEW.USER.MASTER.ORGANIZATION')}
                                    value={user.masterOrganization?.name || ''}
                                />

                                <UserField
                                    title={getLocalizedString(intl, 'ACCOUNT.OVERVIEW.USER.ORGANIZATIONS')}
                                    value={organizationsLabel}
                                />

                                <UserField
                                    title={getLocalizedString(intl, 'ACCOUNT.OVERVIEW.USER.ROLES')}
                                    value={rolesLabel}
                                />
                            </>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

const styles = {
    content: { display: 'flex', paddingX: 2, flexDirection: 'column', gap: 2 }
} as const;
