import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { appReducer } from './modules/app';
import { userReducer } from './modules/user';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist'; // defaults to localStorage for web
import thunk from 'redux-thunk';

export const rootReducer = combineReducers({
    user: userReducer,
    app: appReducer
});

const persistConfig = {
    key: 'mOfficeRoot',
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export type RootState = ReturnType<typeof rootReducer>;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
);
export const persistor = persistStore(store);



