import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { LibraryNodeDocumentTypeOptions, LibraryNodeFileTypeSpec } from '../../../domain';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { Article } from '@mui/icons-material';
import { UISelect } from './UISelect';
import { Palette } from '../../../res/colors';
import { UIText } from './UIText';

interface LibraryNodeToolbarActionsProps {
    handleDelete: (event: React.MouseEvent<unknown>) => void;
    handleSetDocumentType?: (newContentType: LibraryNodeFileTypeSpec) => void;
    style?: any;
}

interface UITableToolbarProps extends LibraryNodeToolbarActionsProps {
    numSelected: number;
}

export const LibraryNodeToolbarActions = ({
    handleDelete,
    handleSetDocumentType,
    style
}: LibraryNodeToolbarActionsProps) => {
    const [openContentTypeOptions, setOpenContentTypeOptions] = useState(false);
    const intl = useIntl();
    const showContentTypeOptions = () => {
        setOpenContentTypeOptions(true);
    };

    return (
        <Box sx={{ ...styles.actionsContainer, ...style }}>
            <Box>
                <Tooltip title={getLocalizedString(intl, 'LIBRARY.TOOLBAR.DELETE.TOOLTIP')}>
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon style={{ ...styles.image(style?.fontSize ?? 'small'), color: Palette.red }} />
                    </IconButton>
                </Tooltip>

                {handleSetDocumentType && (
                    <Tooltip title={getLocalizedString(intl, 'LIBRARY.TOOLBAR.CONTENT_TYPES.TOOLTIP')}>
                        <IconButton onClick={showContentTypeOptions}>
                            <Article style={styles.image(style?.fontSize ?? 'small')} />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>

            {openContentTypeOptions && handleSetDocumentType && (
                <UISelect
                    getOptionLabel={item => getLocalizedString(intl, item.name)}
                    id="eDocumentType"
                    name="eDocumentType"
                    placeholder={getLocalizedString(intl, 'LIBRARY.CONTENT_TYPES.FIELD.PLACEHOLDER')}
                    options={LibraryNodeDocumentTypeOptions}
                    onSelectionChange={handleSetDocumentType}
                />
            )}
        </Box>
    );
};

export const UITableToolbar = ({ numSelected, ...rest }: UITableToolbarProps) => {
    const intl = useIntl();

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: theme => alpha(Palette.defaultPrimaryColor, theme.palette.action.activatedOpacity)
                })
            }}>
            {numSelected > 0 && (
                <UIText
                    sx={{ flex: '1 1 50%' }}
                    color="inherit"
                    text={getLocalizedString(intl, 'LIBRARY.TOOLBAR.SELECTED.ITEMS', { numSelected: numSelected })}
                />
            )}
            {numSelected > 0 && <LibraryNodeToolbarActions {...rest} />}
        </Toolbar>
    );
};

const styles = {
    image: (fontSize: string) => {
        const size = fontSize === 'small' ? '28px' : '44px';
        return {
            width: size,
            height: size,
            color: Palette.defaultPrimaryColor
        };
    },
    actionsContainer: { display: 'flex', flexDirection: 'column', alignItems: 'flex-end', py: 1 }
} as const;
