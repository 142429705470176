import { useForm } from '../../../../hooks/useForm';
import * as Yup from 'yup';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import React, { useEffect, useMemo, useState } from 'react';
import { BrandingResourceTypes } from '../../../../types/types';
import { BrandingResource, BrandingResourceDataSpec } from '../../../../domain';
import { Alert } from '@mui/material';
import { UIText } from '../../common/UIText';
import { isOverriddenResource } from '../../../../utils/overridingHelper';

const getBrandingResourceOptionLabel = (resource: { name: string; value: number }) => `${resource.name ?? ''}`;
const getBrandingResourceOptionValue = (resource: { name: string; id: number }) => resource.id;

interface BrandingResourceFormProps {
    brandingResource: BrandingResourceDataSpec;
    allBrandingResources: BrandingResource[];
    filesInputProps: any;
    handleSubmit: (values: BrandingResourceDataSpec) => Promise<void>;
    formRef: any;
}

export const BrandingResourceForm = ({
    allBrandingResources,
    brandingResource,
    filesInputProps,
    handleSubmit,
    formRef
}: BrandingResourceFormProps) => {
    const intl = useIntl();
    const [alreadyExist, setAlreadyExist] = useState<boolean>(false);
    useEffect(() => {
        setAlreadyExist(isOverriddenResource(brandingResource.eBrandingResourceType, allBrandingResources));
    }, [brandingResource]);

    const validationSchema = Yup.object({
        eBrandingResourceType: Yup.mixed().required(
            getLocalizedString(intl, 'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.TYPE.REQUIRED')
        ),
        file: Yup.mixed().required(
            getLocalizedString(intl, 'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.FILE.REQUIRED')
        ) //TODO check accepted extension
    });

    const fields = [
        {
            id: 'eBrandingResourceType',
            name: 'eBrandingResourceType',
            placeholder: getLocalizedString(intl, 'RESOURCES.BRANDING_RESOURCES.FORM.TYPE.FIELD.PLACEHOLDER'),
            fieldType: 'selectFieldItem',
            getOptionValue: getBrandingResourceOptionValue,
            getOptionLabel: getBrandingResourceOptionLabel,
            options: BrandingResourceTypes,
            value: brandingResource.eBrandingResourceType
        },
        {
            fieldType: 'fileInputFieldItem',
            id: 'file',
            name: 'file',
            acceptedFiles: filesInputProps.acceptedFiles,
            filesLimit: filesInputProps.filesLimit,
            dropzoneDefaultText: getLocalizedString(intl, 'RESOURCES.BRANDING_RESOURCES.FORM.FILE.FIELD.MSG'),
            showPreviews: filesInputProps.showPreviews,
            isSubmitOnChange: false
        }
    ];

    const onSubmit = async (values: BrandingResourceDataSpec) => {
        // @ts-ignore
        const file = values?.file?.length > 0 && values.file[0];
        await handleSubmit({ ...values, file: file });
    };

    const { RenderForm } = useMemo(
        () =>
            useForm({
                enableReinitialize: true,
                initialValues: brandingResource,
                onSubmit: onSubmit,
                validationSchema: validationSchema
            }),
        []
    );

    return (
        <>
            {alreadyExist && (
                <Alert severity="info" sx={{ width: '100%' }}>
                    <UIText
                        text={getLocalizedString(
                            intl,
                            'RESOURCES.BRANDING_RESOURCES.FORM.EXISTING.RESOURCE.INFO.MESSAGE'
                        )}
                    />
                </Alert>
            )}

            <RenderForm
                formFields={fields}
                innerFormRef={formRef}
                getProps={(props: any) => {
                    setAlreadyExist(isOverriddenResource(props.values.eBrandingResourceType, allBrandingResources));
                }}
            />
        </>
    );
};
