import React from 'react';
import { MouseEventHandler, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UIText } from './UIText';
import { Box, Divider } from '@mui/material';
import { Palette } from '../../../res/colors';


export interface UIDropDownMenuEntryProps {
    id: string;
    title?: string;
    handler?: MouseEventHandler<any>;
    titleStyle?: any;
    titleComponent?: any;
    nestedMenu?: any;
    disabled?: boolean;
    tooltip?: string;
    divider?: boolean;
    nested?: boolean;
}

export interface UIDropDownMenuProps extends UIDropDownMenuEntryProps {
    entries: UIDropDownMenuEntryProps[];
}

export const UIDropDownMenu = ({ entries, title, titleStyle, titleComponent: TitleComponent }: UIDropDownMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (handler?: MouseEventHandler<any>) => (event: React.MouseEvent<HTMLElement>) => {
        handleCloseMenu();
        handler && handler(event);
    };

    return (
        <>
            {TitleComponent ? (
                <Box onClick={handleOpenMenu} title="header">
                    <TitleComponent />
                </Box>
            ) : (
                <UIText
                    title="header"
                    text={title ?? ''}
                    style={titleStyle ?? {}}
                    sx={{
                        color: Palette.primaryTextColor,
                        textAlign: 'center',
                        padding: 1
                    }}
                    onClick={handleOpenMenu}
                />
            )}
            <Menu
                sx={{ mt: '30px' }}
                id="menu-appbar"
                open={isMenuOpen}
                keepMounted
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={handleCloseMenu}>
                {entries.map(
                    (
                        {
                            id,
                            title,
                            handler,
                            divider,
                            titleComponent: TitleComponent,
                            nestedMenu: NestedMenu,
                            disabled = false,
                            titleStyle = {},
                            nested
                        },
                        index
                    ) => {
                        return (
                            <MenuItem
                                key={id}
                                onClick={nested ? undefined : onMenuItemClick(handler)}
                                disabled={disabled}>
                                <>
                                    {NestedMenu ? (
                                        <NestedMenu />
                                    ) : TitleComponent ? (
                                        <TitleComponent />
                                    ) : (
                                        <UIText textAlign="center" text={title ?? ''} sx={titleStyle} />
                                    )}

                                    {divider && index < entries.length - 1 && <Divider />}
                                </>
                            </MenuItem>
                        );
                    }
                )}
            </Menu>
        </>
    );
};
