import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { UIText } from './UIText';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Palette } from '../../../res/colors';
import { FormFieldProps } from '../../../hooks/useForm';

const UIToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 1,
        background: Palette.whiteSmoke,
        borderWidth: 1,
        borderColor: Palette.white,
        color: Palette.primaryTextColor,
        '&.Mui-selected': {
            background: Palette.defaultPrimaryColor,
            border: 0,
            color: Palette.white
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius
        }
    }
}));

export interface UIToggleButtonsProps<T = unknown> extends FormFieldProps {
    name: string;
    id: string;
    value?: T | null;
    options: T[];
}

interface UIExtendedToggleButtonsProps<T = unknown> extends UIToggleButtonsProps<T> {
    onChange: (event: React.MouseEvent<HTMLElement>,
               newItem: T) => void;
}

export const UIToggleButtons = <T extends unknown>({
                                                       disabled,
                                                       value,
                                                       id,
                                                       options,
                                                       onChange
                                                   }: UIExtendedToggleButtonsProps<T>) => {

    return <UIToggleButtonGroup id={id}
                                disabled={disabled}
                                color='primary'
                                value={value}
                                exclusive
                                onChange={onChange}>
        {
            options.map((option) => <ToggleButton key={option as string} value={option as any}>
                <UIText text={option as string} sx={{ color: 'inherit' }} />
            </ToggleButton>)
        }
    </UIToggleButtonGroup>;
};
