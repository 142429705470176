import { AuthSessionInfoSpec, HttpRoutes, RepositorySpec } from '..';
import { MacroPack } from './model/MacroPack';
import { Repository } from '../Repository';
import { HttpResponse } from '@mazars/mazars-network';
import { HttpContentType } from '../../types/types';
import { MacroPackDataSpec } from './model/initMacroPack';

export interface MacroPacksRepositorySpec extends RepositorySpec {
    /// Fetch all macros of the given masterOrganization/organization
    fetchAll(
        sessionInfo: AuthSessionInfoSpec,
        masterOrganizationId: string,
        organizationId: string | null
    ): Promise<MacroPack[] | null>;

    /// Fetch a specific macro
    fetch(id: string, sessionInfo: AuthSessionInfoSpec): Promise<MacroPack | undefined>;

    /// Fetch the file of a specific macro
    fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined>;

    create(nodes: MacroPackDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    update(nodes: MacroPackDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    delete(nodeIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;
}

export class MacroPacksRepository extends Repository implements MacroPacksRepositorySpec {
    async fetchAll(
        sessionInfo: AuthSessionInfoSpec,
        masterOrganizationId: string,
        organizationId: string | null
    ): Promise<MacroPack[]> {
        const parameters = organizationId ? { masterOrganizationId, organizationId } : { masterOrganizationId };
        const response = (await this.datasource.remote.get(
            HttpRoutes.MacroPacks,
            sessionInfo.accessToken,
            undefined,
            parameters,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<MacroPack[], any>;

        return response.data || [];
    }

    async fetch(id: string, sessionInfo: AuthSessionInfoSpec): Promise<MacroPack | undefined> {
        const path = HttpRoutes.MacroPack.replace(':id', id);

        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<MacroPack, any>;

        return response.data;
    }

    async fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined> {
        const path = HttpRoutes.MacroPackFile.replace(':id', id);
        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<Blob, any>;

        return response.data;
    }

    async create(nodes: MacroPackDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = new FormData();
        body.append('JSON', JSON.stringify(nodes.info));
        nodes.files.forEach(file => {
            body.append('FILES', file);
        });

        const response = (await this.datasource.remote.post(
            HttpRoutes.MacroPacks,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async update(nodes: MacroPackDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = new FormData();
        body.append('JSON', JSON.stringify(nodes.info));

        nodes.files.forEach(file => {
            body.append('FILES', file);
        });

        const response = (await this.datasource.remote.put(
            HttpRoutes.MacroPacks,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async delete(nodeIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.MacroPacks,
            nodeIds,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }
}
