import React from 'react';
import { Box, IconButton, Paper } from '@mui/material';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { makeStyles } from '@mui/styles';
import { FieldArray, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { UISectionHeader } from '../../common/UISectionHeader';
import { UITextField } from '../../common/fields/UITextField';
import { Add, Delete } from '@mui/icons-material';
import { resourcesStyles } from '../../resources';
import { Palette } from '../../../../res/colors';

export interface FilteringTagsContentProps {
    isEditable: boolean;
    innerFormRef: any;
    filteringTags: string[];
}

export const FilteringTagsContent = ({ isEditable, filteringTags, innerFormRef }: FilteringTagsContentProps) => {
    const classes = useStyles();
    const intl = useIntl();

    const validationSchema = Yup.object().shape({
        filteringTags: Yup.array().of(
            Yup.string().required(getLocalizedString(intl, 'LIBRARY.UPDATE.VALIDATION.TAG.REQUIRED'))
        )
    });

    const getFields = (name: string) => {
        return {
            id: name,
            name: name,
            placeholder: getLocalizedString(intl, 'LIBRARY.UPDATE.FILTERING_TAGS.TAG.FIELD.PLACEHOLDER'),
            fieldType: 'textFieldItem',
            variant: 'standard',
            className: classes.textField,
            disabled: !isEditable
        };
    };

    const handleRemove = (remove: (index: number) => void, index: number) => () => {
        remove(index);
    };

    const handleAdd = (add: (item: any) => void) => () => {
        add('');
    };

    const onSubmit = async (values: { filteringTags: string[] }) => {
        //submit is managed is LibraryFileDetails
        console.log('onSubmit', values);
    };
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ filteringTags: filteringTags }}
            innerRef={innerFormRef}
            enableReinitialize={true}
            onSubmit={onSubmit}>
            {({ values }) => {
                return (
                    <Form>
                        <FieldArray
                            name="filteringTags"
                            render={({ push, remove }) => (
                                <>
                                    {values.filteringTags && values.filteringTags.length > 0 ? (
                                        <>
                                            <UISectionHeader
                                                titleProps={{ variant: 'h6' }}
                                                title={getLocalizedString(intl, 'LIBRARY.UPDATE.FILTERING_TAGS.TITLE')}
                                                isCreateEnabled={false}
                                            />
                                            <Paper elevation={1} sx={sx.content(isEditable)}>
                                                <>
                                                    {values.filteringTags.map((_, index) => (
                                                        <>
                                                            <UITextField
                                                                key={index}
                                                                {...getFields(`filteringTags.${index}`)}
                                                            />
                                                            {isEditable && (
                                                                <Box sx={sx.iconsContainer}>
                                                                    <IconButton onClick={handleRemove(remove, index)}>
                                                                        <Delete
                                                                            style={{
                                                                                ...resourcesStyles.image('small'),
                                                                                color: Palette.red
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Box>
                                                            )}
                                                        </>
                                                    ))}
                                                </>
                                                {isEditable && (
                                                    <Box>
                                                        <IconButton onClick={handleAdd(push)}>
                                                            <Add
                                                                style={{
                                                                    ...resourcesStyles.image('small'),
                                                                    color: Palette.darkPrimaryColor
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </Box>
                                                )}
                                            </Paper>
                                        </>
                                    ) : (
                                        isEditable && (
                                            <UISectionHeader
                                                titleProps={{ variant: 'h6' }}
                                                title={getLocalizedString(intl, 'LIBRARY.UPDATE.FILTERING_TAGS.TITLE')}
                                                onCreate={handleAdd(push)}
                                                isCreateEnabled={isEditable}
                                            />
                                        )
                                    )}
                                </>
                            )}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

const useStyles = makeStyles({
    textField: {
        width: '80%'
    }
});

const sx = {
    iconsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    content: (isEditable: boolean) => ({
        padding: 2,
        flexDirection: 'column',
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'repeat(8, 1fr)',
        mt: 1,
        alignItems: 'center'
    })
} as const;
