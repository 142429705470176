import { Repository, RepositorySpec } from '../../Repository';
import { HttpResponse } from '../../mazars-network-http-client';

import { AuthSessionInfoSpec } from '../../authentication/model/initAuthInfo';
import { HttpRoutes } from '../../HttpRoutes';
import { LanguagePack } from './model/LanguagePack';
import { BaseResourceDataSpec } from '../common/BaseResource';
import { resourcesHelper } from '../../utils/resourcesHelper';
import { HttpContentType, OrganizationParams } from '../../../types/types';

export interface LanguagePackRepositorySpec extends RepositorySpec {
    /// Fetch all the language packs of the given Organization
    fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<LanguagePack[]>;

    /// Fetch the  file of a specific language pack
    fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined>;

    create(pack: BaseResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    update(pack: BaseResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    delete(packsIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;
}

export class LanguagePackRepository extends Repository implements LanguagePackRepositorySpec {
    async fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<LanguagePack[]> {
        const parameters = { ...params };
        const response = (await this.datasource.remote.get(
            HttpRoutes.LanguagePacks,
            sessionInfo.accessToken,
            undefined,
            parameters,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<LanguagePack[], any>;

        return response.data || [];
    }

    async fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined> {
        const path = HttpRoutes.LanguagePackFile.replace(':id', id);
        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<Blob, any>;

        return response.data;
    }

    async create(pack: BaseResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = resourcesHelper.prepareGuideRequestBody(pack);
        const response = (await this.datasource.remote.post(
            HttpRoutes.LanguagePacks,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async update(pack: BaseResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = resourcesHelper.prepareGuideRequestBody(pack);

        const response = (await this.datasource.remote.put(
            HttpRoutes.LanguagePacks,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async delete(packsIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.LanguagePacks,
            packsIds,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }
}
