import { initLinkInfo, Link, LinkDataSpec } from '../../../../domain';
import { useIntl } from 'react-intl';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { AppContext } from '../../../../context/AppContext';
import { getMasterOrganizationId, getOrganizationId } from '../../../../utils/organizationHelper';
import { FormikProps } from 'formik/dist/types';
import { UISectionHeader } from '../../common/UISectionHeader';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { Divider, List, Paper } from '@mui/material';
import { UIText } from '../../common/UIText';
import { UIDialog } from '../../common/UIDialog';
import { UIActionButton } from '../../common/UIActionButton';
import { LinkForm } from './LinkForm';
import { LinksListItem } from './LinksListItem';

interface LinksAreaProps {
    links: Link[];
    actionsHidden: boolean;
    handleEdit: (link: LinkDataSpec[]) => Promise<boolean>;
    handleDelete: (ids: string[]) => void;
    handleCreate: (link: LinkDataSpec[]) => Promise<boolean>;
}

export const LinksArea = ({ links, actionsHidden, handleEdit, handleDelete, handleCreate }: LinksAreaProps) => {
    const intl = useIntl();
    const { currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [linkData, setLinkData] = useState<LinkDataSpec>({
        ...initLinkInfo,
        masterOrganizationId: getMasterOrganizationId(currentOrganization),
        organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
    });

    const createFormRef = useRef();

    const onCreateRequested = () => {
        setOpenDialog(!openDialog);
    };

    const onEditRequested = (link: Link) => {
        setLinkData({
            ...initLinkInfo,
            ...link,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });
        setOpenDialog(!openDialog);
    };

    const onCloseDialog = () => {
        setOpenDialog(!openDialog);
    };

    const doTriggerSubmit = () => {
        const formik = createFormRef.current ? (createFormRef.current as FormikProps<Link>) : null;
        formik?.handleSubmit();
    };

    const onSubmit = async (linkData: LinkDataSpec) => {
        const isSuccess = linkData.id ? await handleEdit([linkData]) : await handleCreate([linkData]);
        setOpenDialog(!isSuccess);
    };
    const onDelete = async (link: Link) => {
        await handleDelete([link.id]);
    };

    return (
        <>
            <UISectionHeader
                title={getLocalizedString(intl, 'RESOURCES.LINKS.AREA.TITLE')}
                onCreate={onCreateRequested}
                isCreateEnabled={!actionsHidden}
            />

            <Paper elevation={3} sx={{ paddingY: 2, paddingLeft: 2 }}>
                <List style={{ maxHeight: '100%' }}>
                    {links?.length > 0 ? (
                        links?.map(link => (
                            <Fragment key={link.id}>
                                <LinksListItem
                                    item={link}
                                    onDelete={onDelete}
                                    onEditRequested={onEditRequested}
                                    isEditable={!actionsHidden}
                                />
                                <Divider />
                            </Fragment>
                        ))
                    ) : (
                        <UIText text={getLocalizedString(intl, 'COMMON.TABLE.NO.RECORDS')} />
                    )}
                </List>

                {openDialog && (
                    <UIDialog
                        open={openDialog}
                        onClose={onCloseDialog}
                        title={getLocalizedString(intl, 'RESOURCES.LINKS.DIALOG.TITLE')}
                        actions={
                            <UIActionButton
                                title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                                onClick={doTriggerSubmit}
                            />
                        }>
                        <LinkForm formRef={createFormRef} onSubmit={onSubmit} linkData={linkData} key={'create_link'} />
                    </UIDialog>
                )}
            </Paper>
        </>
    );
};
