import { useRepository } from '../../../hooks/useRepository';
import { UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import { useIntl } from 'react-intl';
import {
    AgendaDesign,
    AgendaDesignRepositorySpec,
    AgendaDesignSpec,
    BrandingResource,
    BrandingResourceDataSpec,
    BrandingResourcesRepositorySpec,
    ColorShortcut,
    ColorShortcutDataSpec,
    ColorShortcutRepositorySpec,
    CredentialsPacksRepositorySpec,
    FeedbackConfig,
    FeedbackConfigSpec,
    FeedbackRepositorySpec,
    LanguagePack,
    LanguagePackRepositorySpec,
    LearningLink,
    LearningLinkRepositorySpec,
    Link,
    LinkDataSpec,
    LinksRepositorySpec,
    UserGuide,
    UserGuideRepositorySpec
} from '../../../domain';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../context/AppContext';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { Box } from '@mui/material';
import { resourcesStyles } from '../resources';
import { BaseResourceDataSpec } from '../../../domain/resources/common/BaseResource';
import { OrganizationParams } from '../../../types/types';
import { getDefaultOrganizationParams } from '../../../utils/organizationHelper';
import { CredentialPackFileData } from '../../../domain/credentials/model/initCredentialPack';

export type DataType =
    | FeedbackConfig
    | LearningLink
    | BrandingResource[]
    | AgendaDesign
    | AgendaDesign[]
    | Link[]
    | ColorShortcut[]
    | LanguagePack[]
    | UserGuide[]
    | CredentialPackFileData[];

export type DataSpecType =
    | FeedbackConfigSpec
    | BrandingResourceDataSpec
    | AgendaDesignSpec
    | LinkDataSpec
    | ColorShortcutDataSpec
    | BaseResourceDataSpec;

export type RepositoryType =
    | FeedbackRepositorySpec
    | LearningLinkRepositorySpec
    | BrandingResourcesRepositorySpec
    | AgendaDesignRepositorySpec
    | LinksRepositorySpec
    | ColorShortcutRepositorySpec
    | LanguagePackRepositorySpec
    | UserGuideRepositorySpec
    | CredentialsPacksRepositorySpec;

export interface UIContentScaffoldContext<T extends RepositoryType, D extends DataType, S extends DataSpecType> {
    handleUpdate: (item: S | S[]) => Promise<boolean>;
    handleCreate: (item: S | S[]) => Promise<boolean>;
    handleDelete: (resourcesIds: string[]) => Promise<boolean>;
    handleFetch: () => Promise<void>;
    data: D | null;
}

export interface UIContentScaffoldProps<T extends RepositoryType, D extends DataType, S extends DataSpecType> {
    repository: T;
    children: (context: UIContentScaffoldContext<T, D, S>) => React.ReactNode;
    successMessagePrefix: string;
    fetchMethodName?: string;
    fetchParams?: OrganizationParams;
    fetchOnMount?: boolean;
}

export const UIContentScaffold = <T extends RepositoryType, D extends DataType, S extends DataSpecType>({
    fetchParams,
    fetchMethodName = 'fetch',
    successMessagePrefix,
    children,
    repository,
    setLoading,
    setToast,
    setSessionExpired,
    fetchOnMount = true
}: UIContentScaffoldProps<T, D, S> & UIScaffoldChildContext) => {
    const intl = useIntl();
    const { sessionInfo, currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);
    const [data, setData] = useState<D | null>(null);

    useEffect(() => {
        fetchOnMount && (async () => handleFetch())();
    }, [currentOrganization?.id]);

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const handleFetch = async () => {
        const params = fetchParams
            ? fetchParams
            : getDefaultOrganizationParams(currentOrganization, isCurrentOrganizationMaster);

        await callRepository(
            // @ts-ignore
            () => repository[fetchMethodName](params, sessionInfo),
            (data: D | null) => {
                setData(data as D);
            }
        );
    };

    const handleDelete = async (resourcesIds: string[]) => {
        let isSuccess = false;
        await callRepository(
            () => repository.delete(resourcesIds, sessionInfo),
            async _ => {
                setToast(getLocalizedString(intl, `${successMessagePrefix}.DELETE.SUCCESS.MESSAGE`), 'success');
                isSuccess = true;
                await handleFetch();
            }
        );

        return isSuccess;
    };

    const handleUpdate = async (resource: S | S[]): Promise<boolean> => {
        let isSuccess = false;

        await callRepository(
            // @ts-ignore
            () => repository.update(resource, sessionInfo),
            async _ => {
                setToast(getLocalizedString(intl, `${successMessagePrefix}.UPDATE.SUCCESS.MESSAGE`), 'success');
                isSuccess = true;
                await handleFetch();
            }
        );

        return isSuccess;
    };

    const handleCreate = async (resource: S | S[]): Promise<boolean> => {
        let isSuccess = false;

        await callRepository(
            // @ts-ignore
            () => repository.create(resource, sessionInfo),
            async _ => {
                setToast(getLocalizedString(intl, `${successMessagePrefix}.CREATE.SUCCESS.MESSAGE`), 'success');
                isSuccess = true;
                await handleFetch();
            }
        );

        return isSuccess;
    };

    return (
        <Box sx={resourcesStyles.container}>
            {children({
                handleUpdate,
                handleCreate,
                handleDelete,
                data,
                handleFetch
            })}
        </Box>
    );
};
