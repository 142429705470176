import { appConfiguration } from '../../appConfiguration';
import { apiDefaultOptions, HttpRoutes, RepositorySpec, TheGateAuthResponse } from '..';
import { ApiDatasource, ApiDatasourceSpec, HttpResponse, MZRHttpClient } from '../mazars-network-http-client';
import { Repository } from '../Repository';
import { HttpContentType } from '../../types/types';
import { AuthSessionInfoDTO } from './model/TheGateAuthResponse';
import { NetworkHelpers } from '../../utils/networkHelper';
import { AuthHelpers } from '../../utils/authHelper';

export interface AuthRepositorySpec extends RepositorySpec {
    generateToken: (code: string, clientId: string) => Promise<AuthSessionInfoDTO | undefined>;

    mazarsLogin(token: string): Promise<TheGateAuthResponse | null>;

    refresh(accessToken: string, refreshToken: string): Promise<TheGateAuthResponse | null>;
}

export class AuthRepository extends Repository implements AuthRepositorySpec {
    constructor(
        public datasource: ApiDatasourceSpec = new ApiDatasource(
            new MZRHttpClient(appConfiguration.API_BASE_URL, apiDefaultOptions)
        )
    ) {
        super(datasource);
    }

    async mazarsLogin(token: string): Promise<TheGateAuthResponse | null> {
        const body = { token };

        const authResponse = (await this.datasource.remote.post(HttpRoutes.MazarsWebLogin, body)) as HttpResponse<
            TheGateAuthResponse,
            any
        >;

        return authResponse.data || null;
    }

    async refresh(accessToken: string, refreshToken: string): Promise<TheGateAuthResponse | null> {
        const body = { token: accessToken, refreshToken };

        const authResponse = (await this.datasource.remote.post(HttpRoutes.AuthRefresh, body)) as HttpResponse<
            TheGateAuthResponse,
            any
        >;

        return authResponse.data || null;
    }

    generateToken = async (code: string, clientId: string): Promise<AuthSessionInfoDTO | undefined> => {
        const mConnectDatasource: ApiDatasourceSpec = new ApiDatasource(
            new MZRHttpClient(AuthHelpers.openIdConfig.openIdBaseUrl, apiDefaultOptions)
        );

        const path = `generateToken${NetworkHelpers.serializeUrlParams({ clientId })}`;

        const body = new FormData();
        body.append('code', code);

        const rawResponse = (await mConnectDatasource.remote.post(path, body, undefined, {
            'content-type': HttpContentType.MultipartFormData
        })) as HttpResponse<AuthSessionInfoDTO, any>;

        return rawResponse?.data;
    };
}
