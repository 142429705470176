import { ColorShortcut } from '../../../../domain';
import {
    ColorShortcutDataSpec,
    initColorShortcutData
} from '../../../../domain/resources/colorShortcuts/model/ColorShortcut';
import React, { useContext, useRef, useState } from 'react';
import { AppContext } from '../../../../context/AppContext';
import { ResourceDialogMode } from '../../../../types/types';
import { useIntl } from 'react-intl';
import { FormikProps } from 'formik/dist/types';
import { UISectionHeader } from '../../common/UISectionHeader';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { Box, Grid, Paper, Stack } from '@mui/material';
import { UIText } from '../../common/UIText';
import { UIDialog } from '../../common/UIDialog';
import { UIActionButton } from '../../common/UIActionButton';
import { ColorShortcutForm } from './ColorShortcutForm';
import { ColorShortcutActions } from './ColorShortcutsContent';
import { colorShortcutsHelper } from '../../../../utils/colorShortcutsHelper';
import { getMasterOrganizationId, getOrganizationId } from '../../../../utils/organizationHelper';

export interface ColorShortcutsAreaProps {
    colorShortcuts: ColorShortcut[];
    actionsHidden: boolean;
    handleEdit: (data: ColorShortcutDataSpec[]) => Promise<boolean>;
    handleDelete: (ids: string[]) => void;
    handleCreate: (data: ColorShortcutDataSpec[]) => Promise<boolean>;
}

export const ColorShortcutsArea = ({
    colorShortcuts,
    actionsHidden,
    handleCreate,
    handleDelete,
    handleEdit
}: ColorShortcutsAreaProps) => {
    const createFormRef = useRef();
    const { currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [colorData, setColorData] = useState<ColorShortcutDataSpec>(initColorShortcutData);
    const [dialogMode, setDialogMode] = useState<ResourceDialogMode | null>(null);
    const intl = useIntl();

    const onCloseDialog = () => {
        setOpenDialog(!openDialog);
    };

    const onCreateColorRequest = () => {
        setColorData({
            ...initColorShortcutData,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });
        setDialogMode('create');
        setOpenDialog(!openDialog);
    };

    const onEditColorRequested = (colorShortcut: ColorShortcutDataSpec) => {
        setDialogMode('edit');
        setOpenDialog(!openDialog);
        setColorData({
            ...colorShortcut,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });
    };

    const doTriggerSubmit = () => {
        const formik = createFormRef.current ? (createFormRef.current as FormikProps<ColorShortcutDataSpec>) : null;
        formik?.handleSubmit();
    };

    const onSubmit = async (colorShortcutData: ColorShortcutDataSpec) => {
        const isSuccess =
            dialogMode === 'edit'
                ? await handleEdit(colorShortcutsHelper.prepareColorShortcutData(colorShortcutData))
                : await handleCreate(colorShortcutsHelper.prepareColorShortcutData(colorShortcutData));
        setOpenDialog(!isSuccess);
    };

    return (
        <>
            <UISectionHeader
                title={getLocalizedString(intl, 'RESOURCES.SIDE_BAR_MENU.COLOR_SHORTCUTS.ENTRY')}
                isCreateEnabled={!actionsHidden}
                onCreate={onCreateColorRequest}
            />

            <Paper elevation={3} sx={{ paddingY: 2, paddingLeft: 2 }}>
                <Grid container xs={12} spacing={2} style={{ maxHeight: '100%', paddingBottom: 6 }}>
                    {colorShortcuts?.map(colorShortcut => {
                        return (
                            <Grid container item display={'flex'} direction="column" xs={4}>
                                <Stack>
                                    <Box sx={[styles.content, { background: '#' + colorShortcut.hexColor }]} />

                                    <Paper elevation={1} sx={{ backgroundColor: 'white', padding: 1 }}>
                                        <Box sx={styles.footer}>
                                            <UIText text={`${colorShortcut.name}`} />

                                            {!actionsHidden && (
                                                <ColorShortcutActions
                                                    colorShortcut={colorShortcutsHelper.getColorShortcutData(
                                                        colorShortcut,
                                                        currentOrganization
                                                    )}
                                                    handleEdit={onEditColorRequested}
                                                    handleDelete={handleDelete}
                                                />
                                            )}
                                        </Box>

                                        <UIText
                                            text={`Hex: #${
                                                colorShortcut.hexColor
                                            } / ${colorShortcutsHelper.formatRGBColor(colorShortcut.rgb)}`}
                                        />
                                    </Paper>
                                </Stack>
                            </Grid>
                        );
                    })}
                </Grid>
            </Paper>
         
            {openDialog && (
                <UIDialog
                    open={openDialog}
                    onClose={onCloseDialog}
                    title={getLocalizedString(intl, 'RESOURCES.COLOR_SHORT_CUTS.DIALOG.TITLE')}
                    actions={
                        <UIActionButton
                            title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                            onClick={doTriggerSubmit}
                        />
                    }>
                    <>
                        <ColorShortcutForm colorShortcutData={colorData} formRef={createFormRef} onSubmit={onSubmit} />
                    </>
                </UIDialog>
            )}
        </>
    );
};

const styles = {
    content: {
        width: '100%',
        height: '100px'
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '38px'
    }
} as const;
