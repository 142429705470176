import { AuthSessionInfoSpec, RepositorySpec, UserResource } from '..';
import { HttpResponse } from '../mazars-network-http-client';
import { Repository } from '../Repository';

export interface UserResourceRepositorySpec extends RepositorySpec {
    fetchAll(sessionInfo: AuthSessionInfoSpec): Promise<UserResource[] | null>;

    fetchFile(fileId: string, sessionInfo: AuthSessionInfoSpec): Promise<any | null>;
}

export class UserResourceRepository extends Repository implements UserResourceRepositorySpec {
    async fetchAll(sessionInfo: AuthSessionInfoSpec): Promise<UserResource[] | null> {
        /*  const response = (await this.datasource.remote.get('userResource', sessionInfo.accessToken)) as HttpResponse<UserResource[],
              any>;
           buildAPIErrors(response);

          return response.data || null;*/
        return null;
    }

    async fetchFile(fileId: string, sessionInfo: AuthSessionInfoSpec): Promise<any> {
        const response = (await this.datasource.remote.get(
            `userResource/${fileId}/file`,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<UserResource[], any>;

        return response.data || null;
    }
}
