import { AppRoleEnum } from '../types/types';
import { RoleCategory, UserOrganizationRole } from '../domain';
import { UserAppRole, UserMasterOrganizationRole } from '../domain/roles/model/UserOrganizationRole';
import { UserAccess } from '../domain/roles/model/initRole';

const hasAppAdminAccess = (appRoles: UserAppRole[]) => {
    return appRoles && appRoles.length > 0 && appRoles.some(role => role.eAppRole === AppRoleEnum.Admin);
};
const getUserRoleLevel = (
    appRoles: UserAppRole[],
    masterOrganizationRoles: UserMasterOrganizationRole[],
    organizationRoles: UserOrganizationRole[],
    organizationId: string
): UserAccess => {
    const isMasterOrganizationAdmin =
        masterOrganizationRoles &&
        masterOrganizationRoles.some(
            role =>
                RoleCategory.masterOrganizationAdmin.includes(role.eMasterOrganizationRole) &&
                role.masterOrganizationId === organizationId
        );

    const isOrganizationAdmin =
        organizationRoles &&
        organizationRoles.some(
            role =>
                RoleCategory.organizationAdmin.includes(role.eOrganizationRole) &&
                role.organizationId === organizationId
        );

    const isAppAdminAccess = hasAppAdminAccess(appRoles);
    return {
        roleLevel: isMasterOrganizationAdmin
            ? 'masterOrganizationAdmin'
            : isOrganizationAdmin
            ? 'organizationAdmin'
            : 'standard',
        accessMode: isAppAdminAccess ? 'AppAdmin' : 'Other'
    };
};
export const userHelper = {
    getUserRoleLevel,
    hasAppAdminAccess
};
