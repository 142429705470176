import React from 'react';
import { Order } from '../../../types/types';
import { useIntl } from 'react-intl';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import { UIText } from './UIText';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';

type TableSelectionSpec = {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export interface UITableHeadCell<T> {
    disablePadding: boolean;
    id: keyof T;
    labelId: string;
    numeric: boolean;
}

interface UITableProps<T> {
    selectionProps?: TableSelectionSpec;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: UITableHeadCell<T>[];
}

export function UITableHead<T>({
    selectionProps,
    order,
    orderBy,
    rowCount,
    onRequestSort,
    headCells
}: UITableProps<T>) {
    const intl = useIntl();

    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <>
                    {!!selectionProps && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                indeterminate={selectionProps.numSelected > 0 && selectionProps.numSelected < rowCount}
                                checked={rowCount > 0 && selectionProps.numSelected === rowCount}
                                onChange={selectionProps.onSelectAllClick}
                                inputProps={{
                                    'aria-label': 'select all'
                                }}
                            />
                        </TableCell>
                    )}
                    {headCells.map(headCell => (
                        <TableCell
                            sx={{
                                paddingY: 1,
                                paddingX: 2
                            }}
                            key={headCell.id as string}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}>
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}>
                                <UIText text={getLocalizedString(intl, headCell.labelId)} variant="h6" />
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </>
            </TableRow>
        </TableHead>
    );
}
