import React from 'react';
import { ErrorMessage, Field, Form, Formik, FormikValues, useFormikContext } from 'formik';
import { UIErrorMessage } from '../ui/components/common/UIErrorMessage';
import { Box, Tooltip } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { Help } from '@mui/icons-material';
import { UIActionButton } from '../ui/components/common/UIActionButton';
import { UIDropzoneField } from '../ui/components/common/fields/UIDropzoneField';
import { UITextField } from '../ui/components/common/fields/UITextField';
import { UISelectField } from '../ui/components/common/fields/UISelectField';
import { UICheckboxField } from '../ui/components/common/fields/UICheckboxField';
import { UISwitchField } from '../ui/components/common/fields/UISwitchField';
import { UIToggleButtonsField } from '../ui/components/common/fields/UIToggleButtonsField';
import { UIColorPickerField } from '../ui/components/common/fields/UIColorPickerField';

export const FormLabel = ({ id, isRequired, helperTitleId, helperCustomMessage, isHidden = false }: any) =>
    !isHidden ? (
        <label>
            <FormattedMessage id={id} />
            {isRequired ? <label style={{ color: 'red', marginBottom: 0 }}> *</label> : null}
            {helperTitleId ? (
                <Tooltip
                    title={<FormattedMessage id={helperTitleId} values={{ customMessage: helperCustomMessage }} />}
                    children={<Help style={{ fontSize: '1em', marginInline: 2 }} color="primary" />}
                />
            ) : null}
        </label>
    ) : null;

export interface FormFieldProps {
    id: string;
    name: string;
    fieldType?: FormFieldType;
    labelItem?: { id: string; isRequired: boolean };
    component?: any;
    variant?: any | 'inherit';
    disabled?: boolean;
    hidden?: boolean;
    className?: any;
    placeholder?: string;
    isSubmitOnChange?: boolean;
}

export type FormFieldType =
    | 'textFieldItem'
    | 'selectFieldItem'
    | 'fileInputFieldItem'
    | 'dateFieldItem'
    | 'switchFieldItem'
    | 'checkBoxFieldItem'
    | 'providedFieldItem'
    | 'componentFieldItem'
    | 'customFieldItem'
    | 'toggleButtonFieldItem'
    | 'colorPickerFieldItem';

/*
const RenderDateFieldItem = ({ id, variant, disabled, disablePast, className }: any) => {
    return (
        <>
            <div className={`form-control ${className}`}>
                <Field
                    component={DateInput}
                    key={id}
                    id={id}
                    name={id}
                    label={id}
                    as={variant ?? OutlinedInput}
                    variant={variant ?? 'outlined'}
                    fullWidth
                    style={{ margin: '0 0.6em', width: '100%' }}
                    className={className}
                    disabled={disabled}
                    disablePast={disablePast}
                />
            </div>

            <ErrorMessage name={id}
                          render={errorMessage => <UIErrorMessage message={errorMessage} />} />
        </>
    );
};*/

export const UIComponentField = ({ id, component, options, isPreview, ...rest }: any) => {
    return (
        <>
            <Field component={component} name={id} options={options} disabled={isPreview} {...rest} />
            <ErrorMessage name={id} render={errorMessage => <UIErrorMessage message={errorMessage} />} />
        </>
    );
};

export const UIProvidedField = ({ component, customProps }: any) => {
    return React.cloneElement(component, { ...customProps });
};

interface useFormProps<Values extends FormikValues> {
    validationSchema?: any;
    onSubmit: (values: Values) => void;
    readonly initialValues: Values;
    enableReinitialize?: boolean;
}

export function useForm<Values extends FormikValues>({
    enableReinitialize = true,
    initialValues,
    validationSchema,
    onSubmit
}: useFormProps<Values>) {
    const getFieldElement = (field: any) => {
        const fieldType: FormFieldType = field.fieldType as FormFieldType;

        switch (fieldType) {
            case 'textFieldItem': {
                const { value, ...rest } = field;
                return value ? <UITextField value={value} {...rest} /> : <UITextField {...field} />;
            }

            case 'selectFieldItem':
                return <UISelectField {...field} />;

            case 'fileInputFieldItem':
                return <UIDropzoneField {...field} />;

            /*  case FORM_FIELD.DATE_FIELD:
                  return <RenderDateFieldItem {...field} />;*/

            case 'switchFieldItem':
                return <UISwitchField {...field} />;

            case 'checkBoxFieldItem':
                return <UICheckboxField {...field} />;

            case 'toggleButtonFieldItem':
                return <UIToggleButtonsField {...field} />;

            case 'providedFieldItem':
                return <UIProvidedField {...field} />;

            case 'componentFieldItem':
                return <UIComponentField {...field} />;

            case 'colorPickerFieldItem':
                return <UIColorPickerField {...field} />;

            default:
                return null;
        }
    };

    const PrettyField = ({ field }: { field: any }) => {
        const renderFieldElement = getFieldElement(field);

        if (!renderFieldElement?.props?.hidden)
            return (
                <Box
                    sx={
                        field?.labelItem
                            ? {
                                  display: 'grid',
                                  gap: 1,
                                  gridTemplateColumns: 'repeat(2, 1fr)'
                              }
                            : null
                    }>
                    {field?.labelItem && (
                        <Box style={{ width: 'inherit' }}>
                            <label>
                                <FormLabel
                                    id={field?.labelItem?.id ?? 'id'}
                                    isRequired={field?.labelItem?.isRequired ?? false}
                                />
                            </label>
                        </Box>
                    )}
                    <Box>{renderFieldElement}</Box>
                </Box>
            );
        else return null;
    };

    const BuildForm = ({ sx, formFields, conditionalFormKey, conditionalFields, actionTitle }: any) => {
        const style = sx || styles.content;
        return (
            <Form>
                <Box sx={style}>
                    {React.Children.toArray(formFields?.map((field: any) => <PrettyField field={field} />))}
                    {conditionalFields && conditionalFormKey && (
                        <BuildConditionalForm
                            conditionalFormKey={conditionalFormKey}
                            conditionalFields={conditionalFields}
                        />
                    )}

                    {actionTitle && (
                        <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                            <UIActionButton
                                variant="outlined"
                                type="submit"
                                size="large"
                                fullWidth
                                sx={{ mx: 5 }}
                                title={actionTitle}
                            />
                        </Box>
                    )}
                </Box>
            </Form>
        );
    };

    const BuildConditionalForm = ({ conditionalFormKey, conditionalFields }: any): React.ReactElement | null => {
        const { values } = useFormikContext();

        // @ts-ignore
        const form = conditionalFields.find((form: any) => form?.id === values[conditionalFormKey])?.form;

        if (form) return <> {React.Children.toArray(form?.map((field: any) => <PrettyField field={field} />))};</>;
        else return null;
    };

    const RenderForm = ({
        formFields,
        conditionalFormKey,
        conditionalFields,
        innerFormRef,
        actionTitle,
        sx,
        getProps = (values: any, errors: any) => {
            //console.log(values, errors);
        }
    }: any) => {
        return (
            <Formik
                innerRef={innerFormRef}
                enableReinitialize={enableReinitialize}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                {({ errors, values }) => {
                    getProps({ values, errors });
                    return (
                        <BuildForm
                            sx={sx}
                            formFields={formFields}
                            conditionalFormKey={conditionalFormKey}
                            conditionalFields={conditionalFields}
                            actionTitle={actionTitle}
                        />
                    );
                }}
            </Formik>
        );
    };

    return {
        RenderForm,
        getFieldElement,
        PrettyField
    };
}

const styles = {
    content: {
        flexDirection: 'row',
        display: 'grid',
        gap: 1,
        gridTemplateRows: 'repeat(2, 1fr)'
    }
} as const;
