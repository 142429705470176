import React from 'react';
import { APIScaffold } from '../../scaffolder/APIScaffold';
import { UIScaffold } from '../../scaffolder/UIScaffold';
import { LibraryTreeContent } from '../../components/library/home/LibraryTreeContent';
import { RepositoryProvider } from '../../../domain';

export const ContentsScreen = () => {

    return (
        <>
            <APIScaffold>
                {({ cancelRequest }) => (
                    <UIScaffold>
                        {({ setLoading, setSessionExpired, setToast }) => (
                            <LibraryTreeContent
                                isEditable={false}
                                libraryRepository={RepositoryProvider.getProvider().libraryRepository}
                                cancelRequest={cancelRequest}
                                setLoading={setLoading}
                                setSessionExpired={setSessionExpired}
                                setToast={setToast}
                            />
                        )}

                    </UIScaffold>
                )}
            </APIScaffold>
        </>
    );
};
