export const ImageResolver = {
    MazarsLogo: require('../res/images/logo.png').default,
    FavIcon: require('../res/images/mazars_logo_favico.png').default,
    mOffice: require('../res/images/office.png').default,
    flags: {
        fr: require('../res/images/flags/fr.svg').default,
        es: require('../res/images/flags/es.svg').default,
        en: require('../res/images/flags/uk.svg').default
    }
};

export const FontsResolver = {
    defaultFontBold: 'Halyard Bold',
    defaultFontLight: 'Halyard Text'
};
