import { AuthProvider } from '../../../types/types';

export interface AuthSessionInfoSpec {
    accessToken: string;
    refreshToken: string;
    provider?: AuthProvider;
    email?: string;
    organizationId?: string;
}

export const initAuthInfo: AuthSessionInfoSpec = {
    accessToken: '',
    refreshToken: '',
    email: undefined,
    organizationId: '',
    provider: 'theGate'
};

export interface LoginInfoSpec {
    email: string;
    password: string;
    organizationId: string;
}

export const initLoginInfo: LoginInfoSpec = {
    email: '',
    password: '',
    organizationId: ''
};
