import React from 'react';
import { UITopBar } from '../components/header/UITopBar';
import { Box } from '@mui/material';
import { UIAppMenu } from '../components/header/UIAppMenu';
import { LanguageTopBarItem } from '../components/header/LanguageTopBarItem';
import { AccountTopBarItem } from '../components/header/AccountTopBarItem';
import { OrganizationTopBarItem } from '../components/header/OrganizationTopBarItem';

export interface DefaultLayoutSpec {
    children: React.ReactNode;
}

export const DefaultLayout = ({ children }: DefaultLayoutSpec) => (
    <Box sx={styles.container}>
        <UITopBar items={[OrganizationTopBarItem, AccountTopBarItem, LanguageTopBarItem]} />
        <UIAppMenu />
        {children}
    </Box>
);

const styles = {
    container: {
        paddingBottom: 10,
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flexDirection: 'column'
    }
} as const;
