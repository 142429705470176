import React, { useContext, useEffect } from 'react';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { APIScaffoldChildContext } from '../../../scaffolder/APIScaffold';
import { LibraryFileData, LibraryRepositorySpec } from '../../../../domain';
import { serializeLibraryNodes } from '../../../../utils/serializer';
import { useNavigate, useParams } from 'react-router-dom';
import { NavRoutes } from '../../../../navigation/NavRoutes';
import { UITableHeadCell } from '../../common/UITableHead';
import { useRepository } from '../../../../hooks/useRepository';
import { LibraryFilesTable } from '../LibraryFilesTable';
import { AppContext } from '../../../../context/AppContext';
import { getMasterOrganizationId } from '../../../../utils/organizationHelper';
import { StickyBanner } from '../../common/StickyBanner';

const createHeadCells: UITableHeadCell<LibraryFileData>[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        labelId: 'LIBRARY.TABLE.HEADER.FILE_NAME'
    },
    {
        id: 'eDocumentType',
        numeric: true,
        disablePadding: false,
        labelId: 'LIBRARY.TABLE.HEADER.CONTENT_TYPE'
    }
];

interface CreateLibraryNodeContentProps {
    libraryRepository: LibraryRepositorySpec;
}

export const CreateLibraryNodeContent = ({
    libraryRepository,
    setLoading,
    setToast,
    setSessionExpired,
    cancelRequest
}: CreateLibraryNodeContentProps & UIScaffoldChildContext & APIScaffoldChildContext) => {
    const { parentNodeId, parentNodeName } = useParams();

    useEffect(() => {
        return () => {
            cancelRequest(libraryRepository);
        };
    }, [libraryRepository]);

    const intl = useIntl();
    const { sessionInfo, currentOrganization } = useContext(AppContext);
    const navigate = useNavigate();
    const initialValues: { nodes: LibraryFileData[] } = { nodes: [] };

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const handleCreate = async (values: any) => {
        const nodes = await serializeLibraryNodes(values.nodes);

        // @ts-ignore
        await callRepository(
            () => libraryRepository.create(nodes, sessionInfo),
            () => {
                navigate(NavRoutes.Library, { replace: true });
                setToast(getLocalizedString(intl, 'LIBRARY.CREATE.SUCCESS.MESSAGE'), 'success');
            }
        );
    };

    return (
        <>
            <StickyBanner
                title={getLocalizedString(intl, 'LIBRARY.CREATE.BANNER.TITLE')}
                content={getLocalizedString(intl, 'LIBRARY.CREATE.BANNER.MESSAGE')}
            />
            <LibraryFilesTable
                title={getLocalizedString(intl, 'LIBRARY.CREATE.TITLE', { name: parentNodeName })}
                initialValues={initialValues}
                masterOrganizationId={getMasterOrganizationId(currentOrganization)}
                parentNodeId={parentNodeId}
                organizationId={currentOrganization.id}
                handleSubmit={handleCreate}
                headCells={createHeadCells}
            />
        </>
    );
};
