import React, { useState } from 'react';
import { UILoader } from '../components/common/UILoader';
import { Alert, Dialog, Paper, Snackbar, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AlertColor } from '@mui/material/Alert/Alert';
import { ExpiredSessionComponent } from '../components/login/ExpiredSessionComponent';
import { UIDialog } from '../components/common/UIDialog';
import { getLocalizedString } from '../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { setSessionExpired } from '../../store/modules/user';
import { UIFooter } from '../components/common/UIFooter';

export interface UIScaffoldLoadingAndSession {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setSessionExpired: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface UIScaffoldChildContext extends UIScaffoldLoadingAndSession {
    setToast: (text: string, severity: AlertColor, ms?: number) => void;
}

interface UIScaffoldProps {
    loadingText?: string;
    children: (context: UIScaffoldChildContext) => React.ReactNode;
}

const emptyToast: { text: string; severity: AlertColor; ms: number } = { text: '', severity: 'success', ms: 6000 };
export const UIScaffold = ({ children, loadingText }: UIScaffoldProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);
    // const [isSessionExpired, setSessionExpired] = useState(false);
    const [toast, setToastInfo] = useState(emptyToast);

    const classes = useStyles();
    const isLoaderVisible = isLoading;
    const isToastVisible = !!toast.text;
    const isSessionExpired: boolean = useSelector((state: RootState) => state.user.isSessionExpired) ?? false;

    const enableSessionExpired = () => {
        !isSessionExpired && dispatch(setSessionExpired(true));
    };

    const setToast = (text: string, severity: AlertColor, ms: number = 6000) => {
        setToastInfo({ text: text, severity: severity, ms: ms });
    };

    const onDismissLoader = (_: any, reason: string) => {
        if (reason && reason === 'backdropClick') return;

        setLoading(false);
    };

    const onDismissToast = () => setToastInfo(emptyToast);

    return (
        <>
            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: LOADER_BG_COLOR,
                        boxShadow: 'none'
                    }
                }}
                disableEscapeKeyDown
                fullWidth
                classes={{ paper: classes.dialogPaper }}
                maxWidth={'md'}
                open={isLoaderVisible}
                onClose={onDismissLoader}
                aria-labelledby="indicator">
                <Paper
                    component={Stack}
                    className={classes.container}
                    style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <UILoader text={loadingText || getLocalizedString(intl, 'COMMON.LOADER.DEFAULT.MESSAGE')} />
                </Paper>
            </Dialog>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isToastVisible}
                autoHideDuration={toast.ms ?? 6000}
                onClose={onDismissToast}>
                <Alert onClose={onDismissToast} severity={toast.severity} sx={{ width: '100%' }}>
                    {toast.text}
                </Alert>
            </Snackbar>
            {isSessionExpired && (
                <UIDialog open={isSessionExpired} title={getLocalizedString(intl, 'INVALID.SESSION.TITLE')}>
                    <ExpiredSessionComponent />
                </UIDialog>
            )}

            {children({ setLoading, setSessionExpired: enableSessionExpired, setToast })}
            <UIFooter />
        </>
    );
};

const LOADER_BG_COLOR = '#FFFFFF30';
const useStyles = makeStyles({
    dialogPaper: {
        minHeight: '50vh',
        maxHeight: '50vh'
    },
    container: {
        flex: '1',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center'
    }
});
