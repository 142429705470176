import { AuthSessionInfoSpec, HttpRoutes, RepositorySpec } from '../../index';
import { HttpResponse } from '../../mazars-network-http-client';
import { BrandingResource, BrandingResourceDataSpec } from './model/BrandingResource';
import { Repository } from '../../Repository';
import { EmptyGuid, HttpContentType, OrganizationParams } from '../../../types/types';

export interface BrandingResourcesRepositorySpec extends RepositorySpec {
    /// Fetch all the brand resources of the given Organization
    fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<BrandingResource[]>;

    create(brandResources: BrandingResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    update(brandResources: BrandingResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    delete(brandResourcesIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined>;
}

export class BrandingResourcesRepository extends Repository implements BrandingResourcesRepositorySpec {
    async fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<BrandingResource[]> {
        const parameters = { ...params };
        const response = (await this.datasource.remote.get(
            HttpRoutes.BrandingResources,
            sessionInfo.accessToken,
            undefined,
            parameters,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<BrandingResource[], any>;

        return response.data || [];
    }

    async create(brandResource: BrandingResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = new FormData();
        body.append('masterOrganizationId', brandResource.masterOrganizationId);
        brandResource.organizationId !== EmptyGuid && body.append('organizationId', brandResource.organizationId);
        body.append('eBrandingResourceType', brandResource.eBrandingResourceType + '');

        brandResource.file && body.append('File', brandResource.file);

        const response = (await this.datasource.remote.post(
            HttpRoutes.BrandingResources,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async update(brandResource: BrandingResourceDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = new FormData();

        body.append('eBrandingResourceType', brandResource.eBrandingResourceType + '');
        body.append('id', brandResource.id + '');
        brandResource.file && body.append('File', brandResource.file);

        const response = (await this.datasource.remote.put(
            HttpRoutes.BrandingResources,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async delete(brandResourcesIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.BrandingResources,
            brandResourcesIds,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined> {
        const path = HttpRoutes.BrandingResourceFile.replace(':id', id);
        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<Blob, any>;

        return response.data;
    }
}
