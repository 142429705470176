import { FilesInputProps, SoftwareType } from '../../../../types/types';
import { BaseResource, BrandingResource } from '../../../../domain';
import { useIntl } from 'react-intl';
import { Divider, Paper } from '@mui/material';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { TreeView } from '@mui/lab';
import { Language, LanguageSharp } from '@mui/icons-material';
import { SoftwareGuidesTree } from './SoftwareGuidesTree';
import React, { useContext, useRef, useState } from 'react';
import { UISectionHeader } from '../../common/UISectionHeader';
import { FormikProps } from 'formik/dist/types';
import { AppContext } from '../../../../context/AppContext';
import { BaseResourceDataSpec, initBaseResource } from '../../../../domain/resources/common/BaseResource';
import { UIDialog } from '../../common/UIDialog';
import { UIActionButton } from '../../common/UIActionButton';
import { GuideForm } from './GuideForm';
import { getMasterOrganizationId } from '../../../../utils/organizationHelper';

interface GuideAreaProps {
    filesInputProps: FilesInputProps;
    groupedSoftwareGuides: Record<SoftwareType, Record<string, BaseResource[]>>;
    handleDownload: (pack: BaseResource) => Promise<any>;
    handlePreview?: (pack: BaseResource) => Promise<any>;
    title: string;
    subtitle?: string;
    dialogTitle: string;
    isVisualizable?: boolean;
    isEditable: boolean;
    handleDelete: (ids: string[]) => Promise<boolean>;
    handleCreate: (data: BaseResourceDataSpec) => Promise<boolean>;
    handleUpdate: (data: BaseResourceDataSpec) => Promise<boolean>;
}

export const GuidesArea = ({
    dialogTitle,
    filesInputProps,
    handleCreate,
    handleDelete,
    handleUpdate,
    isEditable,
    groupedSoftwareGuides,
    handleDownload,
    handlePreview,
    title,
    subtitle,
    isVisualizable = false
}: GuideAreaProps) => {
    const intl = useIntl();
    const createFormRef = useRef();
    const { currentOrganization } = useContext(AppContext);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [currentGuide, setCurrentGuide] = useState<BaseResourceDataSpec>({
        ...initBaseResource,
        masterOrganizationId: getMasterOrganizationId(currentOrganization) || ''
    });

    const onCreateRequest = () => {
        setOpenDialog(!openDialog);
    };

    const doTriggerSubmit = () => {
        const formik = createFormRef.current ? (createFormRef.current as FormikProps<BrandingResource>) : null;
        formik?.handleSubmit();
    };

    const onCreate = async (guide: BaseResourceDataSpec) => {
        const isSuccess = await handleCreate(guide);
        setOpenDialog(!isSuccess);
    };

    const onUpdate = async (resource: BaseResourceDataSpec) => {
        const isSuccess = await handleUpdate(resource);
        setOpenDialog(!isSuccess);
    };

    const onCloseDialog = () => {
        setOpenDialog(!openDialog);
    };

    const onSubmit = (resource: BaseResourceDataSpec) => {
        const isUpdate = !!resource.id;
        isUpdate ? onUpdate(resource) : onCreate(resource);
    };

    return (
        <>
            <UISectionHeader
                title={getLocalizedString(intl, title)}
                subtitle={subtitle ? getLocalizedString(intl, subtitle) : ''}
                onCreate={onCreateRequest}
                isCreateEnabled={isEditable}
            />

            <Paper elevation={3} sx={{ paddingY: 2, paddingLeft: 2 }}>
                <TreeView
                    aria-label="guide-area-content"
                    defaultCollapseIcon={<Language />}
                    defaultExpandIcon={<LanguageSharp />}
                    sx={{ flexGrow: 1, overflowY: 'auto' }}>
                    <SoftwareGuidesTree
                        isEditable={isEditable}
                        handleDelete={handleDelete}
                        handlePreview={handlePreview}
                        isVisualizable={isVisualizable}
                        title={getLocalizedString(intl, 'RESOURCES.EXCEL.TREE.TITLE')}
                        softwareGuides={groupedSoftwareGuides[SoftwareType.Excel]}
                        treeId="Excel"
                        handleDownload={handleDownload}
                    />

                    <Divider />

                    <SoftwareGuidesTree
                        isEditable={isEditable}
                        handleDelete={handleDelete}
                        handlePreview={handlePreview}
                        isVisualizable={isVisualizable}
                        title={getLocalizedString(intl, 'RESOURCES.POWERPOINT.TREE.TITLE')}
                        softwareGuides={groupedSoftwareGuides[SoftwareType.PowerPoint]}
                        treeId="PP"
                        handleDownload={handleDownload}
                    />
                    <Divider />

                    <SoftwareGuidesTree
                        isEditable={isEditable}
                        handleDelete={handleDelete}
                        handlePreview={handlePreview}
                        isVisualizable={isVisualizable}
                        title={getLocalizedString(intl, 'RESOURCES.WORD.TREE.TITLE')}
                        softwareGuides={groupedSoftwareGuides[SoftwareType.Word]}
                        treeId="Word"
                        handleDownload={handleDownload}
                    />
                </TreeView>

                {isEditable && openDialog && (
                    <UIDialog
                        open={openDialog}
                        onClose={onCloseDialog}
                        title={dialogTitle}
                        actions={
                            <UIActionButton
                                title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                                onClick={doTriggerSubmit}
                            />
                        }>
                        <>
                            <GuideForm
                                baseGuide={currentGuide}
                                formRef={createFormRef}
                                handleSubmit={onSubmit}
                                filesInputProps={filesInputProps}
                            />
                        </>
                    </UIDialog>
                )}
            </Paper>
        </>
    );
};
