import { IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { Delete, Edit } from '@mui/icons-material';
import { resourcesStyles } from '../common/ResourcesStyles';
import { Palette } from '../../../../res/colors';
import React from 'react';
import { Link } from '../../../../domain';
import { useIntl } from 'react-intl';

interface LinksListItemProps {
    item: Link;
    isEditable: boolean;
    onEditRequested: (link: Link) => void;
    onDelete: (link: Link) => Promise<void>;
}

export const LinksListItem = ({ item, isEditable, onEditRequested, onDelete }: LinksListItemProps) => {
    const intl = useIntl();

    const handleEdit = () => {
        onEditRequested(item);
    };

    const handleDelete = async () => {
        await onDelete(item);
    };

    return (
        <ListItem component="a" href={item.url} target="_blank" rel="noopener noreferrer">
            <ListItemText primary={item.name} secondary={item.description} />

            {isEditable && (
                <ListItemSecondaryAction>
                    <Tooltip title={getLocalizedString(intl, 'RESOURCES.LINKS.TOOLBAR.EDIT.TOOLTIP')}>
                        <IconButton onClick={handleEdit}>
                            <Edit style={resourcesStyles.image('small')} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={getLocalizedString(intl, 'RESOURCES.LINKS.TOOLBAR.DELETE.TOOLTIP')}>
                        <IconButton onClick={handleDelete}>
                            <Delete
                                style={{
                                    ...resourcesStyles.image('small'),
                                    color: Palette.red
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
};
