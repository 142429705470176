import { MazarsErrorSpec } from '../domain';
import { getLocalizedString } from '../i18n/I18nHelper';
import { IntlShape } from 'react-intl/src/types';
import { ErrorCode, HttpResponse } from '../domain/mazars-network-http-client';

export const formatError = (
    error: unknown,
    intl: IntlShape,
    defaultMessageId: string = 'COMMON.NETWORK.ERROR.TECHNICAL'
) => {
    const mzrError = error as MazarsErrorSpec;

    let message: string = '';
    if (mzrError && mzrError.message) {
        message = `${getLocalizedString(intl, mzrError.message, undefined, mzrError.message ?? defaultMessageId)} ${
            mzrError.apiError || ''
        }`;
    } else {
        message = `${getLocalizedString(intl, defaultMessageId)} \n${mzrError?.apiError || ''}`;
    }

    return message;
};

const isSuccess = (response: HttpResponse<any, any>) => {
    if (!response || !response.status || response.error) {
        return false;
    }

    return !!response.status && response.status >= 200 && response.status < 300;
};

const EMPTY_DATA_ERROR_MESSAGE = 'COMMON.EMPTY.DATA.ERROR';

export const handleAPIErrorsIfNeeded = (
    unknownResponse: any,
    emptyDataPossible?: boolean
): MazarsErrorSpec | undefined => {
    if (!('status' in unknownResponse && 'errorCode' in unknownResponse && 'data' in unknownResponse)) {
        return {
            status: ErrorCode.Technical,
            apiError: undefined,
            errorCode: ErrorCode.Technical,
            message: 'COMMON.NETWORK.ERROR.TECHNICAL'
        };
    }

    const response = unknownResponse as HttpResponse<any, any>;
    const isCancelledRequest = response.status === ErrorCode.Cancel || response.errorCode === ErrorCode.Cancel;

    if (isSuccess(response) || isCancelledRequest) {
        return undefined;
    }

    const status = response.status;
    const apiError = response.error ?? response.data.error ?? response.data;
    const errorCode = response.errorCode ?? status ?? ErrorCode.Technical;

    const message =
        !emptyDataPossible && !response.data
            ? EMPTY_DATA_ERROR_MESSAGE
            : `COMMON.NETWORK.ERROR.${status || 'TECHNICAL'}`;

    return {
        status,
        apiError,
        errorCode,
        message
    };
};
