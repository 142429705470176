import React from 'react';
import { Box, IconButton, Paper } from '@mui/material';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { makeStyles } from '@mui/styles';
import { FieldArray, Form, Formik } from 'formik';
import { UITextField } from '../../common/fields/UITextField';
import * as Yup from 'yup';
import { Add, Delete } from '@mui/icons-material';
import { resourcesStyles } from '../../resources';
import { Palette } from '../../../../res/colors';
import { UISectionHeader } from '../../common/UISectionHeader';
import { LibraryReplaceableField } from '../../../../domain/library/model/Library';

export interface ReplaceableFieldsContentProps {
    isEditable: boolean;
    innerFormRef: any;
    replaceableFields: LibraryReplaceableField[];
}

export const ReplaceableFieldsContent = ({
    isEditable,
    replaceableFields,
    innerFormRef
}: ReplaceableFieldsContentProps) => {
    const classes = useStyles();

    const intl = useIntl();

    const validationSchema = Yup.object().shape({
        replaceableFields: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required(getLocalizedString(intl, 'LIBRARY.VALIDATION.NAME.REQUIRED')),
                text: Yup.string().required(getLocalizedString(intl, 'LIBRARY.VALIDATION.TEXT.REQUIRED')),
                description: Yup.string().nullable()
            })
        )
    });

    const getFields = (name: string, text: string, description: string) => {
        return [
            {
                id: name,
                name: name,
                placeholder: getLocalizedString(intl, 'LIBRARY.UPDATE.REPLACEABLE_FIELDS.NAME.FIELD.PLACEHOLDER'),
                fieldType: 'textFieldItem',
                variant: 'standard',
                className: classes.textField,
                disabled: !isEditable
            },
            {
                id: text,
                name: text,
                placeholder: getLocalizedString(intl, 'LIBRARY.UPDATE.REPLACEABLE_FIELDS.TEXT.FIELD.PLACEHOLDER'),
                fieldType: 'textFieldItem',
                variant: 'standard',
                className: classes.textField,
                disabled: !isEditable
            },
            {
                id: description,
                name: description,
                placeholder: getLocalizedString(
                    intl,
                    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.DESCRIPTION.FIELD.PLACEHOLDER'
                ),
                fieldType: 'textFieldItem',
                variant: 'standard',
                className: classes.textField,
                disabled: !isEditable
            }
        ];
    };

    const handleRemove = (remove: (index: number) => void, index: number) => () => {
        remove(index);
    };

    const handleAdd = (add: (item: any) => void) => () => {
        const item = { name: null, text: null, description: null };
        add(item);
    };

    const onSubmit = async (_: { replaceableFields: LibraryReplaceableField[] }) => {
        //submit is managed is LibraryFileDetails cf. LibraryFileDetails.tsx
    };
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={{ replaceableFields: replaceableFields }}
            innerRef={innerFormRef}
            enableReinitialize={true}
            onSubmit={onSubmit}>
            {({ values }) => {
                return (
                    <Form>
                        <FieldArray
                            name="replaceableFields"
                            render={({ push, remove }) => (
                                <>
                                    {values.replaceableFields && values.replaceableFields.length > 0 ? (
                                        <>
                                            <UISectionHeader
                                                titleProps={{ variant: 'h6' }}
                                                title={getLocalizedString(
                                                    intl,
                                                    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.TITLE'
                                                )}
                                                isCreateEnabled={false}
                                            />
                                            {values.replaceableFields.map((_, index) => (
                                                <Paper key={index} elevation={1} sx={sx.content(isEditable)}>
                                                    {getFields(
                                                        `replaceableFields.${index}.name`,
                                                        `replaceableFields.${index}.text`,
                                                        `replaceableFields.${index}.description`
                                                    ).map(field => {
                                                        return <UITextField {...field} />;
                                                    })}

                                                    {isEditable && (
                                                        <Box sx={sx.iconsContainer}>
                                                            <IconButton onClick={handleRemove(remove, index)}>
                                                                <Delete
                                                                    style={{
                                                                        ...resourcesStyles.image('small'),
                                                                        color: Palette.red
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            {index === values.replaceableFields.length - 1 && (
                                                                <IconButton onClick={handleAdd(push)}>
                                                                    <Add
                                                                        style={{
                                                                            ...resourcesStyles.image('small'),
                                                                            color: Palette.darkPrimaryColor
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            )}
                                                        </Box>
                                                    )}
                                                </Paper>
                                            ))}
                                        </>
                                    ) : (
                                        isEditable && (
                                            <UISectionHeader
                                                titleProps={{ variant: 'h6' }}
                                                title={getLocalizedString(
                                                    intl,
                                                    'LIBRARY.UPDATE.REPLACEABLE_FIELDS.TITLE'
                                                )}
                                                onCreate={handleAdd(push)}
                                                isCreateEnabled={isEditable}
                                            />
                                        )
                                    )}
                                </>
                            )}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

const useStyles = makeStyles({
    textField: {
        width: '80%'
    }
});

const sx = {
    iconsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    content: (isEditable: boolean) => ({
        padding: 2,
        flexDirection: 'column',
        display: 'grid',
        gap: 1,
        gridTemplateColumns: isEditable ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)',
        mt: 1,
        alignItems: 'center'
    })
} as const;
