import { ListItemText, Paper } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import React from 'react';
import { UIText } from '../../common/UIText';

interface RoleListItemProps {
    displayName: string;
    description: string;
}

export const RoleListItem = ({ displayName, description }: RoleListItemProps) => {
    return (
        <Paper elevation={3} sx={{ my: 2 }}>
            <ListItem>
                <ListItemText
                    primary={<UIText text={displayName} sx={{ fontWeight: '600' }} />}
                    secondary={<UIText text={description} sx={{ fontSize: 14 }} />}
                />
            </ListItem>
        </Paper>
    );
};
