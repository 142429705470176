import React, { useContext, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../../context/AppContext';
import { ResourceDialogMode } from '../../../../types/types';
import { useOverridableView } from '../../../../hooks/useOverridableView';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { FormikProps } from 'formik/dist/types';
import { Box, Link, Paper } from '@mui/material';
import { UIActionButton } from '../../common/UIActionButton';
import { UIText } from '../../common/UIText';
import { UIDialog } from '../../common/UIDialog';
import { UISectionHeader } from '../../common/UISectionHeader';
import { UISectionFooter } from '../UISectionFooter';
import { LinkForm } from '../../resources';
import { LearningLink, LinkDataSpec } from '../../../../domain';
import { initLearningLink } from '../../../../domain/configuration/learningLink/model/LearningLink';
import {
    getMasterOrganizationId,
    getOrganizationId,
    getScreenSubtitleByOrganizationType
} from '../../../../utils/organizationHelper';

interface LearningLinkAreaProps {
    actionsHidden: boolean;
    learningLink: LearningLink | null;
    handleUpdate: (link: LinkDataSpec) => Promise<boolean>;
    onDelete: (ids: string[]) => void;
    handleCreate: (link: LinkDataSpec) => Promise<boolean>;
}

export const LearningLinkArea = ({
    learningLink: existingLearningLink,
    handleCreate,
    handleUpdate,
    onDelete,
    actionsHidden
}: LearningLinkAreaProps) => {
    const formRef = useRef();
    const intl = useIntl();
    const { currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogMode, setDialogMode] = useState<ResourceDialogMode | null>(null);
    const [currentLearningLink, setCurrentLearningLink] = useState<LinkDataSpec>({
        ...initLearningLink,
        masterOrganizationId: getMasterOrganizationId(currentOrganization),
        organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
    });

    const isCreateEnabled = useMemo(
        () => !actionsHidden && !existingLearningLink?.id,
        [existingLearningLink, actionsHidden]
    );

    const onEditRequested = (link: LearningLink) => {
        setCurrentLearningLink({
            ...existingLearningLink,
            ...link,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });
        setOpenDialog(!openDialog);
        setDialogMode('edit');
    };

    const { backgroundColor, title, actions, overridable } = useOverridableView({
        item: existingLearningLink,
        onDelete,
        onEditAsked: onEditRequested
    });

    const alertMessage =
        !overridable && dialogMode === 'create'
            ? getLocalizedString(intl, 'CONFIGURATION.FEEDBACK_CONFIG.FORM.EXISTING.CONFIG.INFO.MESSAGE')
            : '';

    const onCreateRequested = () => {
        setDialogMode('create');
        setCurrentLearningLink({
            ...initLearningLink,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });
        setOpenDialog(!openDialog);
    };

    const onCloseDialog = () => {
        setOpenDialog(!openDialog);
    };

    const doTriggerSubmit = () => {
        const formik = formRef.current ? (formRef.current as FormikProps<LinkDataSpec>) : null;
        formik?.handleSubmit();
    };

    const onCreate = async (config: LinkDataSpec) => {
        const isSuccess = await handleCreate(config);
        setOpenDialog(!isSuccess);
    };

    const onUpdate = async (config: LinkDataSpec) => {
        const isSuccess = await handleUpdate(config);
        setOpenDialog(!isSuccess);
    };

    const handleSubmit = async (config: LinkDataSpec) => {
        if (isCurrentOrganizationMaster) {
            !config.id ? await onCreate(config) : await onUpdate(config);
        } else {
            !overridable
                ? await onUpdate({
                      ...config,
                      id: existingLearningLink?.id // enforce id here for the case we pressed add button (dialogMode= create) but finally it's an update (override at orga level)
                  })
                : await onCreate(config);
        }
    };

    return (
        <>
            <UISectionHeader
                title={getLocalizedString(intl, 'CONFIGURATION.SIDE_BAR_MENU.LEARNING_LINK.ENTRY')}
                subtitle={getLocalizedString(intl, getScreenSubtitleByOrganizationType(isCurrentOrganizationMaster))}
                isCreateEnabled={isCreateEnabled}
                onCreate={onCreateRequested}
            />

            <Paper elevation={3} sx={{ paddingY: 2 }}>
                <>
                    {!existingLearningLink?.id ? (
                        <UIText
                            text={getLocalizedString(intl, 'CONFIGURATION.LEARNING_LINK.EMPTY.MESSAGE')}
                            sx={{ margin: 2 }}
                        />
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 2 }}>
                            <UIText text={existingLearningLink.name} sx={{ fontWeight: 800 }} />
                            <Link href={existingLearningLink.url} target="_blank">
                                {' '}
                                {existingLearningLink.url}
                            </Link>
                            <UIText
                                text={existingLearningLink.description}
                                sx={{
                                    marginTop: 2,
                                    fontSize: 14
                                }}
                            />

                            <UISectionFooter
                                backgroundColor={backgroundColor}
                                title={title}
                                actions={actions}
                                actionsHidden={actionsHidden}
                            />
                        </Box>
                    )}
                </>
            </Paper>
            {openDialog && (
                <UIDialog
                    open={openDialog}
                    onClose={onCloseDialog}
                    title={getLocalizedString(intl, 'CONFIGURATION.LEARNING_LINK.DIALOG.TITLE')}
                    actions={
                        <UIActionButton
                            title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                            onClick={doTriggerSubmit}
                        />
                    }>
                    <>
                        <LinkForm
                            linkData={currentLearningLink}
                            onSubmit={handleSubmit}
                            formRef={formRef}
                            alertMessage={alertMessage}
                        />
                    </>
                </UIDialog>
            )}
        </>
    );
};
