import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { TableCellInputField } from '../../library';
import Checkbox from '@mui/material/Checkbox';
import { MacroPackFileData } from '../../../../domain/macroPack/model/initMacroPack';
import { UIText } from '../../common/UIText';
import { IconButton } from '@mui/material';
import { Palette } from '../../../../res/colors';
import { Edit } from '@mui/icons-material';

interface MacroPackTableRowProps {
    disabled?: boolean;
    pack: MacroPackFileData;
    index: number;
    fieldsProps: { description: string; name: string; macros: string };
    isSelected: boolean;
    handleRowClick: (event: React.MouseEvent<unknown>, name: string) => void;
    handleEditMacros: (pack: MacroPackFileData) => Promise<void>;
}

export const MacroPackTableRow = ({
    pack,
    index,
    fieldsProps,
    isSelected,
    handleRowClick,
    handleEditMacros,
    disabled = false
}: MacroPackTableRowProps) => {
    const labelId = `enhanced-table-checkbox-${index}`;

    const handleOnMacroClick = async (event: React.MouseEvent<unknown>) => {
        event.preventDefault();
        event.stopPropagation();
        await handleEditMacros(pack);
    };

    return (
        <TableRow
            hover
            onClick={event => handleRowClick(event, pack.name)}
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={-1}
            key={pack.name}
            selected={isSelected}>
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isSelected}
                    inputProps={{
                        'aria-labelledby': labelId
                    }}
                />
            </TableCell>

            <TableCell
                component="th"
                id={labelId}
                scope="row"
                sx={{
                    paddingY: 1,
                    paddingX: 2
                }}>
                <TableCellInputField
                    disabled={disabled}
                    id={fieldsProps.name}
                    name={fieldsProps.name}
                    value={pack.name}
                    extension={pack.fileExtension}
                />
            </TableCell>
            <TableCell
                align="right"
                sx={{
                    paddingY: 1,
                    paddingX: 2
                }}>
                <TableCellInputField
                    editable={false}
                    disabled={disabled}
                    id={fieldsProps.description}
                    name={fieldsProps.description}
                    value={pack.description}
                    extension={''}
                />
            </TableCell>

            <TableCell
                onClick={handleOnMacroClick}
                align="right"
                sx={{
                    paddingY: 1,
                    paddingX: 2
                }}>
                <UIText text={pack.macros.map(a => a.name).join(', ')} />
            </TableCell>

            <TableCell
                onClick={handleOnMacroClick}
                align="right"
                sx={{
                    paddingY: 1,
                    paddingX: 5
                }}>
                <IconButton sx={{ color: Palette.defaultPrimaryColor, marginHorizontal: 1 }}>
                    <Edit />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};
