import { User } from './User';
import { EmptyGuid, MasterOrganizationRoleEnum, OrganizationRoleEnum } from '../../../types/types';
import { UserMasterOrganizationRole, UserOrganizationRole } from '../../roles/model/UserOrganizationRole';

export const initOrganizationUserDataSpec: UserOrganizationRole = {
    email: '',
    organizationId: '',
    eOrganizationRole: OrganizationRoleEnum.Guest
};

export const initMasterOrganizationUserDataSpec: UserMasterOrganizationRole = {
    email: '',
    masterOrganizationId: '',
    eMasterOrganizationRole: MasterOrganizationRoleEnum.Member
};

export const initUser: User = {
    masterOrganization: undefined,
    masterOrganizationRoles: [],
    masterOrganizations: [],
    id: '',
    organizationId: EmptyGuid,
    email: '',
    organizations: [],
    organizationRoles: [],
    appRoles: []
};
