import React, { useContext, useEffect, useState } from 'react';
import { UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import { APIScaffoldChildContext } from '../../scaffolder/APIScaffold';
import { CredentialsPacksRepositorySpec } from '../../../domain';
import { useRepository } from '../../../hooks/useRepository';
import { useIntl } from 'react-intl';

import { AppContext } from '../../../context/AppContext';
import { getMasterOrganizationId, getOrganizationIdOrNull } from '../../../utils/organizationHelper';
import { CredentialPack } from '../../../domain/credentials/model/CredentialPack';
import { CredentialsArea } from './CredentialsArea';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { CredentialPackFileData } from '../../../domain/credentials/model/initCredentialPack';
import { serializeUpdateCredentialsPacks } from '../../../utils/serializer';
import { resourcesStyles } from '../resources';
import { Box } from '@mui/material';

type CredentialsContentProps = {
    credentialsPackRepository: CredentialsPacksRepositorySpec;
    isEditable: boolean;
};

export const CredentialsContent = ({
    setToast,
    setSessionExpired,
    credentialsPackRepository,
    isEditable,
    setLoading,
    cancelRequest
}: CredentialsContentProps & UIScaffoldChildContext & APIScaffoldChildContext) => {
    const intl = useIntl();
    const [packs, setPacks] = useState<CredentialPack[] | null>(null);

    const { sessionInfo, currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const fetchContent = async () => {
        await callRepository(
            () =>
                credentialsPackRepository.fetchAll(
                    sessionInfo,
                    getMasterOrganizationId(currentOrganization),
                    getOrganizationIdOrNull(currentOrganization, isCurrentOrganizationMaster)
                ),
            data => {
                const files: CredentialPack[] = data as CredentialPack[];
                setPacks(files);
            }
        );
    };

    useEffect(() => {
        (async () => await fetchContent())();

        return () => {
            cancelRequest(credentialsPackRepository);
        };
    }, [currentOrganization?.id]);

    const handlePreviewFile = async (pack: CredentialPack) => {
        const nodeId = pack.id;
        if (!nodeId) {
            //TODO throw
            return;
        }

        let credentialPackFile: Blob | undefined = undefined;

        await callRepository(
            () => credentialsPackRepository.fetchFile(nodeId, sessionInfo),
            data => {
                credentialPackFile = data;
            }
        );

        return credentialPackFile;
    };

    const handleUpdate = async (packs: CredentialPackFileData[]): Promise<boolean> => {
        const packData = await serializeUpdateCredentialsPacks(packs);

        let isSuccess = false;
        await callRepository(
            () => credentialsPackRepository.update(packData, sessionInfo),
            async () => {
                isSuccess = true;
                setToast(getLocalizedString(intl, 'CREDENTIALS.UPDATE.SUCCESS.MESSAGE'), 'success');
                await fetchContent();
            }
        );

        return isSuccess;
    };

    const handleDelete = async (resourcesIds: string[]) => {
        let isSuccess = false;
        await callRepository(
            () => credentialsPackRepository.delete(resourcesIds, sessionInfo),
            async _ => {
                setToast(getLocalizedString(intl, 'CREDENTIALS.DELETE.SUCCESS.MESSAGE'), 'success');
                isSuccess = true;
                await fetchContent();
            }
        );

        return isSuccess;
    };

    return (
        <Box sx={resourcesStyles.container}>
            <CredentialsArea
                packs={packs || []}
                isEditable={isEditable}
                handlePreview={handlePreviewFile}
                handleEdit={handleUpdate}
                handleDelete={handleDelete}
            />
        </Box>
    );
};
