import { AuthSessionInfoSpec, HttpRoutes, LinkDataSpec, RepositorySpec } from '../../index';
import { HttpResponse } from '../../mazars-network-http-client';
import { Link } from './model/Link';
import { Repository } from '../../Repository';
import { OrganizationParams } from '../../../types/types';

export interface LinksRepositorySpec extends RepositorySpec {
    /// Fetch all the links of the given Organization
    fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<Link[]>;

    create(linkData: LinkDataSpec[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    delete(linksIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    update(linkData: LinkDataSpec[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;
}

export class LinksRepository extends Repository implements LinksRepositorySpec {
    async fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<Link[]> {
        const response = (await this.datasource.remote.get(
            HttpRoutes.Links,
            sessionInfo.accessToken,
            undefined,
            params,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<Link[], any>;

        return response.data || [];
    }

    async create(linkData: LinkDataSpec[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.post(
            HttpRoutes.Links,
            linkData,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async update(linkData: LinkDataSpec[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.put(
            HttpRoutes.Links,
            linkData,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async delete(linksIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.Links,
            linksIds,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;
        return response.data || [];
    }
}
