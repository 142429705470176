export const Palette = {
    darkPrimaryColor: '#081F8F',
    defaultPrimaryColor: '#0071CE',
    primaryTextColor: '#464B4B',
    secondaryTextColor: '#757575',

    lightPrimaryColor: '#C5CAE9',
    secondaryColor: '#dcdcdc',
    white: '#FFFFFF',
    accentColor: '#B95C2B',

    dividerColor: '#BDBDBD',
    lightGray: '#f1f1f1',
    sonicSilver: '#787878',
    transparent: 'rgba(0, 0, 0, 0)',

    whiteSmoke: '#F4F4F4',
    byzantium: '#704B62',
    queenBee: '#457E8F',
    glitter: '#E5F0FA',
    extraLightBlue: '#E5F0FA80',
    backgroundPrimaryAccent: '#F3F6FD',
    warningColor: '#FFC107',

    red: '#cc0000'
};
