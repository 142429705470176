import { Library } from '../../../../domain';
import { UITreeItem } from '../../common/UITreeItem';
import React, { Fragment } from 'react';
import { Palette } from '../../../../res/colors';
import { LibraryTreeItemActions } from './LibraryTreeItemActions';
import { LibraryNodeType } from '../../../../types/types';

export interface LibraryTreeItemProps {
    node: Library;
    onAdd: (folder: Library, nodeType: LibraryNodeType) => void;
    onDelete: (nodeId: string) => void;
    onEdit: (folder: Library) => void;
}

export const LibrarySingleTreeItem = ({
                                          isEditable,
                                          node,
                                          onDisplayFile,
                                          ...rest
                                      }: LibraryTreeItemProps & { onDisplayFile: (node: Library) => void, isEditable: boolean; }) => {
    const hasChildren = node.childs && node.childs?.length > 0;

    const handleSelectItem = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        onDisplayFile(node);
    };

    return (

        <UITreeItem onSelectItem={handleSelectItem}
                    actionButton={isEditable ? <LibraryTreeItemActions node={node} {...rest} /> : undefined}
                    labelInfo={`${hasChildren ? node.childs?.length : ''}`}
                    key={`${node.id}`}
                    nodeId={`${node.id}`}
                    labelText={`${node.name}${node.fileExtension || ''}`} />
    );
};

export const LibraryNodeTree = ({
                                    isEditable,
                                    node: parentNode,
                                    ...rest
                                }: LibraryTreeItemProps & { onDisplayFile: (node: Library) => void; isEditable: boolean; }): JSX.Element => {

    return (
        <UITreeItem key={`${parentNode.id}`}
                    nodeId={`${parentNode.id}`}
                    color={Palette.defaultPrimaryColor}
                    bgColor={Palette.extraLightBlue}
                    actionButton={isEditable ? <LibraryTreeItemActions node={parentNode} {...rest} /> : undefined}
                    labelText={parentNode.name}>
            <>
                {
                    parentNode.childs?.map((node) => {
                        const isDirectory = node.eLibraryNodeType === LibraryNodeType.Directory;
                        return <Fragment key={node.id}>
                            {
                                isDirectory ?
                                    <LibraryNodeTree node={node} {...rest} isEditable={isEditable} /> :
                                    <LibrarySingleTreeItem node={node} {...rest} isEditable={isEditable} />
                            }
                        </Fragment>;
                    })
                }
            </>

        </UITreeItem>
    );
};
