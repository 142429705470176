import React from 'react';
import * as Yup from 'yup';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useForm } from '../../../../hooks/useForm';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { Library } from '../../../../domain';

interface CreateLibraryFolderProps {
    formRef: any;
    folder: Library;
    onSubmit: (values: Library) => void;
}

export const CreateLibraryFolder = ({ folder, formRef, onSubmit }: CreateLibraryFolderProps) => {
    const intl = useIntl();
    const classes = useStyles();

    const validationSchema = Yup.object({
        name: Yup.string().required(getLocalizedString(intl, 'USERS_ROLES.SPECIAL_USERS.CREATE.VALIDATION.EMAIL.REQUIRED')),
        description: Yup.string()
    });

    const fields = [
        {
            id: 'name',
            name: 'name',
            placeholder: getLocalizedString(intl, 'LIBRARY.CREATE.FOLDER.NAME.FIELD.PLACEHOLDER'),
            variant: 'standard',
            className: classes.textField,
            fieldType: 'textFieldItem',
            disabled: false
        },
        {
            id: 'description',
            name: 'description',
            placeholder: getLocalizedString(intl, 'LIBRARY.CREATE.FOLDER.DESCRIPTION.FIELD.PLACEHOLDER'),
            className: classes.textField,
            fieldType: 'textFieldItem',
            disabled: false,
            component: 'textarea',
            rows: 5,
            variant: 'outlined'
        }
    ];

    const { RenderForm } = useForm({
        initialValues: folder,
        onSubmit: onSubmit,
        validationSchema: validationSchema
    });

    return <Box>
        <RenderForm formFields={fields} innerFormRef={formRef} />
    </Box>;
};

const useStyles = makeStyles({

    textField: {
        width: '90%'
    }
});