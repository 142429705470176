import React from 'react';
import { LearningLink, LearningLinkRepositorySpec } from '../../../../domain';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { LearningLinkArea } from './LearningLinkArea';
import { UIContentScaffold } from '../../common/UIContentScaffold';

interface LearningLinkContentProps {
    isEditable: boolean;
    learningLinkRepository: LearningLinkRepositorySpec;
}

export const LearningLinkContent = ({
                                        learningLinkRepository,
                                        isEditable,
                                        setSessionExpired,
                                        setToast,
                                        setLoading
                                    }: LearningLinkContentProps & UIScaffoldChildContext) => {

    return <UIContentScaffold  successMessagePrefix='CONFIGURATION.LEARNING_LINK' repository={learningLinkRepository} setToast={setToast} setLoading={setLoading}
                              setSessionExpired={setSessionExpired}>
        {({ data, handleCreate, handleDelete, handleUpdate }) => (
            <LearningLinkArea learningLink={data as LearningLink}
                              handleUpdate={handleUpdate}
                              onDelete={handleDelete}
                              handleCreate={handleCreate}
                              actionsHidden={!isEditable} />
        )
        }
    </UIContentScaffold>;


};
