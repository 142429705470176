import { CONTAINER_TOP_MARGIN } from '../../../../res/dimensions';
import { Palette } from '../../../../res/colors';

export const resourcesStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        margin: CONTAINER_TOP_MARGIN,
        gap: 1
    },
    image: (fontSize: string) => {
        const size = fontSize === 'small' ? '18px' : '22px';
        return {
            width: size,
            height: size,
            color: Palette.defaultPrimaryColor
        };
    },
    actionsContainer:
        { display: 'flex', flexDirection: 'column', alignItems: 'flex-end', py: 1 }
} as const;
