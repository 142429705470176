import { Repository, RepositorySpec } from '../../Repository';
import { HttpResponse } from '../../mazars-network-http-client';

import { AuthSessionInfoSpec } from '../../authentication/model/initAuthInfo';
import { HttpRoutes } from '../../HttpRoutes';
import { ColorShortcut, ColorShortcutDataSpec } from './model/ColorShortcut';
import { OrganizationParams } from '../../../types/types';

export interface ColorShortcutRepositorySpec extends RepositorySpec {
    /// Fetch all the color shortcuts  of the given Organization
    fetchAll(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<ColorShortcut[]>;

    // at master organization Only
    create(colorShortcutData: ColorShortcutDataSpec[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    // at master organization Only
    delete(colorShortcutIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    // at master organization Only
    update(colorShortcutData: ColorShortcutDataSpec[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;
}

export class ColorShortcutRepository extends Repository implements ColorShortcutRepositorySpec {
    async fetchAll(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<ColorShortcut[]> {
        const response = (await this.datasource.remote.get(
            HttpRoutes.ColorShortcuts,
            sessionInfo.accessToken,
            undefined,
            params,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<ColorShortcut[], any>;

        return response.data || [];
    }

    async create(colorShortcutData: ColorShortcutDataSpec[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.post(
            HttpRoutes.ColorShortcuts,
            colorShortcutData,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async update(colorShortcutData: ColorShortcutDataSpec[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.put(
            HttpRoutes.ColorShortcuts,
            colorShortcutData,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async delete(colorShortcutIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.ColorShortcuts,
            colorShortcutIds,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }
}
