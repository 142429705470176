import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import {
    AuthSessionInfoSpec,
    initAuthInfo,
    Organization,
    OrganizationRepositorySpec,
    signIn,
    User,
    UserRepositorySpec
} from '../../../domain';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { APIScaffoldChildContext } from '../../scaffolder/APIScaffold';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { UIText } from '../common/UIText';
import { ImageResolver } from '../../../utils/resolver';

import { UIActionButton } from '../common/UIActionButton';
import { appConfiguration } from '../../../appConfiguration';
import { useRepository } from '../../../hooks/useRepository';
import { AuthHelpers } from '../../../utils/authHelper';
import { NetworkHelpers } from '../../../utils/networkHelper';
import { getFirstMasterOrganizationWithAdminRole } from '../../../utils/organizationHelper';

export interface LoginContentProps {
    userRepository: UserRepositorySpec;
    organizationsRepository: OrganizationRepositorySpec;
    onLoginSuccess: (sessionInfo: AuthSessionInfoSpec, userInfo: User, userOrganization: Organization) => void;
}

export const LoginScreenContent = ({
    userRepository,
    organizationsRepository,
    onLoginSuccess,
    setSessionExpired,
    setLoading,
    setToast
}: LoginContentProps & UIScaffoldChildContext & APIScaffoldChildContext): JSX.Element => {
    const classes = useStyles();
    const intl = useIntl();

    const currentLocation = useLocation();

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    useEffect(() => {
        (async () => await handleAuthRedirect(currentLocation.hash))();
    }, [currentLocation.hash]);

    const onLoginFailure = (errorMessage?: string) => {
        setLoading(false);
        setToast(getLocalizedString(intl, errorMessage ?? 'AUTH.ERROR.UNKNOWN'), 'error');
    };

    const triggerSignIn = () => {
        window.location.replace(AuthHelpers.makeOpenIdUrl());
    };

    const handleAuthRedirect = async (url: string) => {
        if (AuthHelpers.isOpenIdRedirectUrl(url, AuthHelpers.openIdConfig.redirectUri)) {
            const code = NetworkHelpers.extractUrlParamValue('code', url);
            if (!code) {
                onLoginFailure();
                return;
            }

            await continueSignIn(code, AuthHelpers.openIdConfig.clientId);
        } else {
            console.log('handleAuthRedirect', url);
        }
    };

    const getUserOrganization = async (organizationId: string) => {
        let userOrganization: Organization | undefined;
        await callRepository(
            () => organizationsRepository.fetchAll(appConfiguration.MAZARS_MASTER_ORGANIZATION_ID),
            organizations => {
                if (organizations && Array.isArray(organizations)) {
                    userOrganization = organizations.find(org => org.id === organizationId);
                }
            }
        );

        return userOrganization;
    };
    const continueSignIn = async (code: string, clientId: string) => {
        setLoading(true);

        //get access Token
        const authResponse = await signIn(code, clientId);

        if (authResponse?.token) {
            //get user info
            const userResponse = await userRepository.fetch('', {
                accessToken: authResponse.token,
                refreshToken: authResponse.refreshToken
            });

            if (!userResponse) {
                setLoading(false);
                onLoginFailure(getLocalizedString(intl, 'AUTH.ERROR.UNKNOWN'));

                return;
            }

            const sessionInfo: AuthSessionInfoSpec = {
                ...initAuthInfo,
                email: userResponse.email,
                accessToken: authResponse?.token ?? '',
                refreshToken: authResponse?.refreshToken ?? '',
                organizationId: userResponse.organizationId,
                provider: 'theGate'
            };

            setLoading(false);

            let currentOrganization: Organization | undefined = undefined;

            const currentAdminMasterOrganization = getFirstMasterOrganizationWithAdminRole(
                userResponse.masterOrganizations,
                userResponse.masterOrganizationRoles
            );

            // set the current organization to the master admin one if user has admin role in one of the master organization
            if (currentAdminMasterOrganization) {
                currentOrganization = currentAdminMasterOrganization;
            } else {
                const userOrganization = await getUserOrganization(userResponse.organizationId);

                if (!userOrganization) {
                    onLoginFailure(getLocalizedString(intl, 'AUTH.VALIDATION.ORGANIZATION.REQUIRED'));
                    return;
                }

                // otherwise set it to the user organization
                currentOrganization = userOrganization;
            }

            onLoginSuccess(sessionInfo, userResponse, currentOrganization);
        } else {
            onLoginFailure();
        }
    };

    return (
        <Grid sx={sx.container} className="container" container>
            <Grid item>
                <UIText variant="h5" sx={{ mb: 1 }} text={getLocalizedString(intl, 'AUTH.LOGIN.WELCOME.TITLE')} />
                <UIText sx={{ mb: 1 }} text={getLocalizedString(intl, 'AUTH.LOGIN.WELCOME.SUBTITLE')} />

                <img alt="Logo" className={classes.logo} src={ImageResolver.mOffice} />
            </Grid>

            <Grid item sx={{ width: 395 }}>
                <Paper elevation={3} sx={sx.paper}>
                    <UIText
                        sx={{ marginBottom: 5, fontSize: 32 }}
                        textAlign="center"
                        text={getLocalizedString(intl, 'AUTH.LOGIN.MESSAGE')}
                    />

                    <UIActionButton
                        startIcon={<img alt="app logo" className={classes.favIcon} src={ImageResolver.FavIcon} />}
                        display="secondary"
                        title={getLocalizedString(intl, 'AUTH.SIGN_IN')}
                        onClick={triggerSignIn}
                        sx={{ marginX: 8, textTransform: 'none' }}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    logo: {
        maxWidth: '480px'
    },
    favIcon: {
        maxWidth: '28px',
        marginLeft: '10px'
    },
    textField: {
        width: '100%'
    }
});

const sx = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingY: 15
    },
    paper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: 3,
        paddingBottom: 5,
        paddingX: 5
    }
} as const;
