import { RepositoryProvider } from '..';

export const signIn = async (code: string, clientId: string) => {
    const authRepository = RepositoryProvider.getProvider().authRepository;

    //get access Token
    const authResponse = await authRepository.generateToken(code, clientId);

    if (authResponse?.access_token) {
        return authRepository.mazarsLogin(authResponse?.access_token);
    } else {
        throw new Error('no access token');
    }
};
