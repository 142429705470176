import { Box, SvgIconProps } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { TreeItem, treeItemClasses, TreeItemProps } from '@mui/lab';
import { Palette } from '../../../res/colors';
import { UIText } from './UIText';

declare module 'react' {
    interface CSSProperties {
        '--tree-view-color'?: string;
        '--tree-view-bg-color'?: string;
    }
}

const UITreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: Palette.primaryTextColor,
    [`& .${treeItemClasses.content}`]: {
        borderBottomLeftRadius: theme.spacing(2),
        fontWeight: theme.typography.fontWeightMedium,
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightRegular
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)'
        },

        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit'
        }
    }
}));

type UITreeItemProps = TreeItemProps & {
    bgColor?: string;
    color?: string;
    startIcon?: React.ElementType<SvgIconProps>;
    actionButton?: JSX.Element;
    labelInfo?: string;
    labelText: string;
    onSelectItem?: (event: React.MouseEvent) => void;
};

export const UITreeItem = ({
    onSelectItem,
    bgColor,
    color,
    startIcon: StartIcon,
    actionButton: ActionButton,
    labelInfo,
    labelText,
    ref,
    ...rest
}: UITreeItemProps) => {
    return (
        <UITreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: '38px' }}>
                    <Box onClick={onSelectItem} style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                        {StartIcon && <Box component={StartIcon} color="inherit" sx={{ mr: 1 }} />}

                        <UIText text={labelText} variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }} />
                        <UIText text={labelInfo} variant="caption" color="inherit" />
                    </Box>

                    <Box>{ActionButton}</Box>
                </Box>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor
            }}
            {...rest}></UITreeItemRoot>
    );
};
