import { typedAction } from './helper';
import { User, AuthSessionInfoSpec, Organization, UserOrganizationRole } from '../../domain';
import { AuthProvider } from '../../types/types';

// Constants
export const LOGOUT = 'user/LOGOUT';
export const LOGIN = 'user/LOGIN';
export const SET_CURRENT_ORGANIZATION = 'user/SET_CURRENT_ORGANIZATION';
export const SET_CURRENT_MASTER_ORGANIZATION = 'user/SET_CURRENT_MASTER_ORGANIZATION';
export const REMOVE_CURRENT_ORGANIZATION = 'user/REMOVE_CURRENT_ORGANIZATION';
export const SET_USER_INFO = 'user/SET_USER_INFO';
export const REMOVE_USER_INFO = 'user/REMOVE_USER_INFO';
export const SET_USER_ROLES = 'user/SET_USER_ROLES';
export const REMOVE_USER_ROLES = 'user/REMOVE_USER_ROLES';
export const SET_SESSION_EXPIRED = 'user/SET_SESSION_EXPIRED';

interface UserState {
    provider: AuthProvider | undefined;
    isSessionExpired: boolean | undefined;
    userProfile: User | undefined;
    userRoles: UserOrganizationRole[] | undefined;
    sessionInfo: AuthSessionInfoSpec | undefined;
    currentOrganization: Organization | undefined;
}

const initialState: UserState = {
    sessionInfo: undefined,
    provider: undefined,
    userProfile: undefined,
    userRoles: undefined,
    currentOrganization: undefined,
    isSessionExpired: undefined
};

// ACTIONS
export const setSessionInfo = (sessionInfo: AuthSessionInfoSpec, provider?: AuthProvider) => {
    return typedAction(LOGIN, { sessionInfo: sessionInfo, provider: provider });
};

// ACTIONS
export const setSessionExpired = (state: boolean) => {
    return typedAction(SET_SESSION_EXPIRED, { expired: state });
};

export const removeSessionInfo = () => {
    return typedAction(LOGOUT);
};

export const setUserInfo = (userProfile: User) => {
    return typedAction(SET_USER_INFO, { userProfile: userProfile });
};

export const removeUserInfo = () => {
    return typedAction(REMOVE_USER_INFO);
};

export const setUserRoles = (roles: UserOrganizationRole[]) => {
    return typedAction(SET_USER_ROLES, { userRoles: roles });
};

export const removeUserRoles = () => {
    return typedAction(REMOVE_USER_ROLES);
};

export const setCurrentOrganization = (organization: Organization | undefined) => {
    return typedAction(SET_CURRENT_ORGANIZATION, { organization });
};

export const removeCurrentOrganization = () => {
    return typedAction(REMOVE_CURRENT_ORGANIZATION);
};

type UserAction = ReturnType<typeof setSessionInfo
    | typeof removeSessionInfo
    | typeof setSessionExpired
    | typeof setUserInfo
    | typeof removeUserInfo
    | typeof setUserRoles
    | typeof removeUserRoles
    | typeof setCurrentOrganization
    | typeof removeCurrentOrganization>

// REDUCERS
export function userReducer(state = initialState, action: UserAction): UserState {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                sessionInfo: action.payload.sessionInfo,
                provider: action.payload.provider,
                isSessionExpired: false
            };

        case LOGOUT:
            return { ...state, ...initialState };

        case SET_USER_INFO:
            return { ...state, userProfile: action.payload.userProfile };

        case REMOVE_USER_INFO:
            return { ...state, userProfile: undefined };

        case SET_CURRENT_ORGANIZATION:
            return {
                ...state,
                currentOrganization: action.payload.organization,
                //isMasterOrganizationSelected: !!action.payload.organization?.masterOrganizationId
            };

        case REMOVE_CURRENT_ORGANIZATION:
            return { ...state, currentOrganization: undefined };

        case SET_USER_ROLES:
            return { ...state, userRoles: action.payload.userRoles };

        case REMOVE_USER_ROLES:
            return { ...state, userRoles: undefined };

        case SET_SESSION_EXPIRED:
            return { ...state, isSessionExpired: action.payload.expired };

        default:
            return state;
    }
}
