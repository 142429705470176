import { AuthSessionInfoSpec, HttpRoutes, Organization, RepositorySpec } from '..';
import { HttpResponse } from '../mazars-network-http-client';
import { Repository } from '../Repository';

export interface OrganizationRepositorySpec extends RepositorySpec {
    fetch(organizationId: string): Promise<Organization | undefined>;

    /// Fetch all the organizations of the given master Organization
    fetchAll(masterOrganizationId: string): Promise<Organization[]>;

    update(organization: Organization, session: AuthSessionInfoSpec): Promise<string>;
}

export class OrganizationRepository extends Repository implements OrganizationRepositorySpec {
    async fetch(organizationId: string): Promise<Organization | undefined> {
        const response = (await this.datasource.remote.get(
            HttpRoutes.Organization.replace(':id', organizationId)
        )) as HttpResponse<Organization, any>;

        return response?.data;
    }

    async fetchAll(masterOrganizationId: string): Promise<Organization[]> {
        const parameters = { masterOrganizationId, name: null };
        const response = (await this.datasource.remote.get(
            HttpRoutes.Organizations,
            undefined,
            undefined,
            parameters
        )) as HttpResponse<Organization[], any>;

        return response?.data || [];
    }

    async update(organization: Organization, sessionInfo: AuthSessionInfoSpec): Promise<string> {
        const response = (await this.datasource.remote.put(
            HttpRoutes.Organizations,
            organization,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string, any>;

        return response.data || '';
    }
}
