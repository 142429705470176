import React from 'react';
import { Typography } from '@mui/material';
import { TypographyProps } from '@mui/material/Typography/Typography';
import { Palette } from '../../../res/colors';
import { FontsResolver } from '../../../utils/resolver';
import { Variant } from '@mui/material/styles/createTypography';

interface UITextProps {
    text?: string;
}

export const UIText = ({ text, sx, variant, ...rest }: UITextProps & TypographyProps) => {
    const style = { ...styles.text, ...styles.defaultFont(variant), ...sx };
    return (
        <Typography
            {...rest}
            variant={variant}
            sx={style}
            style={{
                whiteSpace: 'normal',
                wordWrap: 'break-word'
            }}>
            {text}
        </Typography>
    );
};

const styles = {
    text: {
        color: Palette.primaryTextColor
    },
    defaultFont: (variant?: Variant | 'inherit') =>
        variant && ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant)
            ? {
                  fontFamily: FontsResolver.defaultFontBold
              }
            : {
                  fontFamily: FontsResolver.defaultFontLight
              }
};
