import { typedAction } from './helper';

// Constants
const INIT_LANGUAGE = 'app/INIT_LANGUAGE';
export const SET_LANGUAGE = 'app/SET_LANGUAGE';
export const HYDRATE_APP_STATE = 'app/HYDRATE_APP_STATE';
export const DEFAULT_LANGUAGE = 'en';
export const SET_IS_APP_INITIALIZED = 'app/SET_IS_APP_INITIALIZED';

type AppState = {
    language: string;
    isAppInitialized: boolean;
};

const initialState: AppState = {
    language: DEFAULT_LANGUAGE,
    isAppInitialized: false
};

// ACTIONS
export const setReduxLanguage = (currentLanguage: string) => {
    return typedAction(SET_LANGUAGE, currentLanguage);
};

export const initReduxLanguage = (language: string) => {
    return typedAction(INIT_LANGUAGE, language);
};

export const hydrateApp = () => {
    return typedAction(HYDRATE_APP_STATE);
};

export const setIsAppInitialized = () => {
    return typedAction(SET_IS_APP_INITIALIZED);
};

type AppAction = ReturnType<| typeof setReduxLanguage
    | typeof initReduxLanguage
    | typeof hydrateApp //TODO use this action to manage token encryption
    | typeof setIsAppInitialized>;

// REDUCERS
export function appReducer(state = initialState, action: AppAction): AppState {
    switch (action.type) {
        case SET_LANGUAGE:
            return { ...state, language: action.payload };

        case INIT_LANGUAGE:
            return { ...state, language: action.payload };

        case SET_IS_APP_INITIALIZED:
            return { ...state, isAppInitialized: true };

        default:
            return state;
    }
}
