import React from 'react';
import MuiList from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import { ListItemIcon, ListItemText } from '@mui/material';
import { Palette } from '../../../res/colors';
import { UISideMenuBarEntry } from '../../../types/types';
import { UIText } from './UIText';

export interface UISideBarMenuProps {
    entries: UISideMenuBarEntryProps[];
    selectedEntry: UISideMenuBarEntry;
}

export const UISideBarMenu = ({ entries, selectedEntry }: UISideBarMenuProps) => {
    return (
        <Box
            sx={{
                width: 180
            }}>
            <Paper elevation={3} sx={{ minHeight: '98%' }}>
                <MuiList aria-label="sidebar">
                    {entries.map((entry: UISideMenuBarEntryProps) => (
                        <SideMenuBarEntry key={entry.id} {...entry} isSelected={selectedEntry === entry.entryType} />
                    ))}
                </MuiList>
            </Paper>
        </Box>
    );
};

export interface UISideMenuBarEntryProps {
    isSelected?: boolean;
    id: string;
    entryType: UISideMenuBarEntry;
    icon?: React.ReactElement;
    title: string;
    onClick: (entryType: UISideMenuBarEntry) => (event: React.SyntheticEvent) => void;
}

function SideMenuBarEntry({ icon, title, onClick, entryType, isSelected = false }: UISideMenuBarEntryProps) {
    return (
        <ListItem onClick={onClick(entryType)}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={<UIText text={title} sx={sx.title(isSelected)} />} />
        </ListItem>
    );
}

const sx = {
    title: (isSelected: boolean) => ({
        fontSize: 18,
        fontWeight: isSelected ? '600' : '400',
        color: isSelected ? Palette.darkPrimaryColor : Palette.defaultPrimaryColor
    })
};
