import React, { useContext, useEffect, useState } from 'react';
import { UIScaffold, UIScaffoldChildContext } from '../scaffolder/UIScaffold';
import { APIScaffold, APIScaffoldChildContext } from '../scaffolder/APIScaffold';
import { useIntl } from 'react-intl';
import { UISideMenuBarEntry } from '../../types/types';
import { UISideBarMenu, UISideMenuBarEntryProps } from '../components/common/UISideBarMenu';
import { getLocalizedString } from '../../i18n/I18nHelper';
import { Box } from '@mui/material';
import { AppContext } from '../../context/AppContext';
import { FeedbackConfigContent } from '../components/configuration/feedbackConfig/FeedbackConfigContent';
import { LearningLinkContent } from '../components/configuration/learningLink/LearningLinkContent';
import {
    AgendaDesignRepositorySpec,
    FeedbackRepositorySpec,
    LearningLinkRepositorySpec,
    RepositoryProvider
} from '../../domain';
import { AgendaDesignContent } from '../components/configuration/agendaDesign/AgendaDesignContent';

interface ConfigurationContentProps {
    feedbackRepository: FeedbackRepositorySpec;
    learningLinkRepository: LearningLinkRepositorySpec;
    agendaDesignRepository: AgendaDesignRepositorySpec;
}

export const ConfigurationContent = ({
    agendaDesignRepository,
    learningLinkRepository,
    feedbackRepository,
    setLoading,
    setToast,
    setSessionExpired
}: ConfigurationContentProps & UIScaffoldChildContext & APIScaffoldChildContext) => {
    const intl = useIntl();

    const [selectedSectionType, setSelectedSectionType] = useState<UISideMenuBarEntry>('agendaDesign');
    const [currentSectionComponent, setCurrentSectionComponent] = useState<React.ReactNode>(null);
    const { isEditableAtOrganizationLevel, isEditableAtMasterOrganization } = useContext(AppContext);

    useEffect(() => {
        setCurrentSectionComponent(sectionReducer(selectedSectionType));
    }, [selectedSectionType, isEditableAtOrganizationLevel, isEditableAtMasterOrganization]);

    const handleSectionChanged = (entryType: UISideMenuBarEntry) => () => {
        setSelectedSectionType(entryType);
    };

    const entries: UISideMenuBarEntryProps[] = [
        {
            title: getLocalizedString(intl, 'CONFIGURATION.SIDE_BAR_MENU.AGENDA_DESIGN.ENTRY'),
            entryType: 'agendaDesign',
            id: 'AGENDA_DESIGN',
            onClick: handleSectionChanged
        },
        {
            title: getLocalizedString(intl, 'CONFIGURATION.SIDE_BAR_MENU.LEARNING_LINK.ENTRY'),
            entryType: 'learningLink',
            id: 'LEARNING_LINK',
            onClick: handleSectionChanged
        },
        {
            title: getLocalizedString(intl, 'CONFIGURATION.SIDE_BAR_MENU.FEEDBACK_CONFIG.ENTRY'),
            entryType: 'feedbackConfig',
            id: 'FEEDBACK_CONFIG',
            onClick: handleSectionChanged
        }
    ];

    const sectionReducer = (entryType: UISideMenuBarEntry) => {
        switch (entryType) {
            case 'feedbackConfig':
                return (
                    <FeedbackConfigContent
                        setSessionExpired={setSessionExpired}
                        setLoading={setLoading}
                        setToast={setToast}
                        feedbackRepository={feedbackRepository}
                        isEditable={isEditableAtOrganizationLevel || isEditableAtMasterOrganization}
                    />
                );

            case 'learningLink':
                return (
                    <LearningLinkContent
                        learningLinkRepository={learningLinkRepository}
                        setSessionExpired={setSessionExpired}
                        setLoading={setLoading}
                        setToast={setToast}
                        isEditable={isEditableAtOrganizationLevel || isEditableAtMasterOrganization}
                    />
                );

            case 'agendaDesign':
                return (
                    <AgendaDesignContent
                        agendaDesignRepository={agendaDesignRepository}
                        setSessionExpired={setSessionExpired}
                        setLoading={setLoading}
                        setToast={setToast}
                        isEditable={isEditableAtOrganizationLevel || isEditableAtMasterOrganization}
                    />
                );

            default:
                return null;
        }
    };

    return (
        <Box sx={styles.container}>
            <UISideBarMenu entries={entries} selectedEntry={selectedSectionType} />
            <Box sx={styles.section} className="container">
                {currentSectionComponent}
            </Box>
        </Box>
    );
};

export const ConfigurationScreen = () => {
    return (
        <APIScaffold>
            {({ cancelRequest }) => (
                <UIScaffold>
                    {({ setLoading, setSessionExpired, setToast }) => (
                        <ConfigurationContent
                            agendaDesignRepository={RepositoryProvider.getProvider().agendaDesignRepository}
                            learningLinkRepository={RepositoryProvider.getProvider().learningLinkRepository}
                            feedbackRepository={RepositoryProvider.getProvider().feedbackRepository}
                            setToast={setToast}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            cancelRequest={cancelRequest}
                        />
                    )}
                </UIScaffold>
            )}
        </APIScaffold>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'stretch',
        alignSelf: 'stretch',
        height: '85vh'
    },
    section: {
        display: 'flex',
        flexGrow: 1
    }
} as const;
