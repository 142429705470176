import { ErrorMessage, Field, FieldProps, useFormikContext } from 'formik';
import { UIErrorMessage } from '../UIErrorMessage';
import { UIDropzone, UIDropzoneProps } from '../UIDropzone';
import React from 'react';
import { FormFieldProps } from '../../../../hooks/useForm';

export interface UIDropzoneFieldProps extends FormFieldProps {
    previewText?: string;
    acceptedFiles?: any;
    showPreviews?: boolean;
}

export const UIDropzoneField = ({
                                    showPreviews,
                                    id,
                                    hidden,
                                    acceptedFiles,
                                    dropzoneDragText,
                                    dropzoneDefaultText,
                                    isSubmitOnChange = false
                                }: UIDropzoneFieldProps & UIDropzoneProps & FieldProps) => {

    const { submitForm, setFieldValue } = useFormikContext();

    const handleUploadFiles = async (files: Blob[]) => {
        if (files.length > 0) {
            setFieldValue(id, files);
            isSubmitOnChange && await submitForm();
        }
    };

    return (
        <>
            <Field
                component={UIDropzone}
                id={id}
                name={id}
                showPreviews={showPreviews}
                dropzoneDragText={dropzoneDragText}
                dropzoneDefaultText={dropzoneDefaultText}
                hidden={hidden}
                handleUploadFiles={handleUploadFiles}
                accept={acceptedFiles}
            />

            <ErrorMessage name={id}
                          render={errorMessage => <UIErrorMessage message={errorMessage} />} />
        </>
    );
};
