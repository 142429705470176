import { AuthSessionInfoSpec } from '../domain/authentication/model/initAuthInfo';

const SESSION_INFO_KEY = 'SESSION_INFO_KEY';

class AuthSessionService {
    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem(SESSION_INFO_KEY) ?? '');
        return user?.accessToken;
    }

    getLocalSessionInfo(): AuthSessionInfoSpec | null {
        try {
            return JSON.parse(localStorage.getItem(SESSION_INFO_KEY) ?? '') as AuthSessionInfoSpec;
        } catch (e) {
            return null;
        }
    }

    setLocalSessionInfo(sessionInfo: AuthSessionInfoSpec) {
        localStorage.setItem(SESSION_INFO_KEY, JSON.stringify(sessionInfo));
    }

    removeLocalSessionInfo() {
        localStorage.removeItem(SESSION_INFO_KEY);
    }
}

export default new AuthSessionService();
