import { Organization, OrganizationRepositorySpec } from '../../../domain';
import { UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import { useIntl } from 'react-intl';
import { useRepository } from '../../../hooks/useRepository';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UISectionTitle } from '../common/UISectionTitle';
import { Box, Paper } from '@mui/material';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { UIActionButton } from '../common/UIActionButton';
import { FormikProps } from 'formik/dist/types';
import { useForm } from '../../../hooks/useForm';
import * as Yup from 'yup';
import { EditableOrganization } from '../../../domain/organizations/model/Organization';
import { getEditableOrganization } from '../../../utils/organizationHelper';
import { serializeUpdateOrganization } from '../../../utils/serializer';
import { AppContext } from '../../../context/AppContext';

interface OrganizationContentProps {
    organization: Organization;
    organizationRepository: OrganizationRepositorySpec;
    title?: string;
    shouldFetchOnMount?: boolean;
    isEditable?: boolean;
}

export const OrganizationContent = ({
    organization,
    title,
    organizationRepository,
    setSessionExpired,
    setLoading,
    setToast,
    shouldFetchOnMount = true,
    isEditable = false
}: OrganizationContentProps & UIScaffoldChildContext) => {
    const formRef = useRef();

    const [organizationInfo, setOrganizationInfo] = useState<EditableOrganization>(
        getEditableOrganization(organization)
    );

    const intl = useIntl();

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const { sessionInfo } = useContext(AppContext);

    useEffect(() => {
        shouldFetchOnMount && (async () => await onRefresh())();
    }, [organization.id]);

    const onRefresh = async () => {
        await callRepository(
            () => organizationRepository.fetch(organization.id),
            data => {
                data && setOrganizationInfo(getEditableOrganization(data));
            }
        );
    };

    const doTriggerSubmit = () => {
        const formik = formRef.current ? (formRef.current as FormikProps<Organization>) : null;
        formik?.handleSubmit();
    };

    const nonEmptyValidator = value => value.trim() !== '';

    const validateDomain = Yup.string().test(
        'is-comma-separated-array',
        'The string must be a comma-separated array of non-empty elements',
        value => {
            if (!value) return true;
            if (typeof value !== 'string') return false;

            const arr = value.split(',');
            return arr.every(nonEmptyValidator);
        }
    );
    const validationSchema = Yup.object({
        userDnsDomains: validateDomain,
        hostDomains: validateDomain,
        area: Yup.string().required('Area is required')
    });

    const fields = [
        {
            labelItem: { id: getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.NAME'), isRequired: true },
            id: 'name',
            name: 'name',
            placeholder: getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.NAME'),
            fieldType: 'textFieldItem',
            variant: 'standard',
            disabled: true
        },
        {
            labelItem: { id: getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.USER_DNS_DOMAINS') },
            id: 'userDnsDomains',
            name: 'userDnsDomains',
            placeholder: getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.USER_DNS_DOMAINS'),
            fieldType: 'textFieldItem',
            variant: 'standard'
        },
        {
            labelItem: { id: getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.HOST_DOMAINS') },
            id: 'hostDomains',
            name: 'hostDomains',
            placeholder: getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.HOST_DOMAINS'),
            fieldType: 'textFieldItem',
            variant: 'standard'
        },
        {
            labelItem: { id: getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.WINDOWS_REGION') },
            id: 'windowsRegion',
            name: 'windowsRegion',
            placeholder: getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.WINDOWS_REGION'),
            fieldType: 'textFieldItem',
            variant: 'standard'
        },
        {
            labelItem: { id: getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.AREA'), isRequired: true },
            id: 'area',
            name: 'area',
            placeholder: getLocalizedString(intl, 'ORGANIZATION.OVERVIEW.AREA'),
            fieldType: 'textFieldItem',
            variant: 'standard'
        }
    ];

    const onSubmit = async (values: EditableOrganization) => {
        const updatedValues: Organization = serializeUpdateOrganization(values);
        await callRepository(
            () => organizationRepository.update(updatedValues, sessionInfo),
            async () => {
                setToast(getLocalizedString(intl, 'ORGANIZATION.UPDATE.SUCCESS.MESSAGE'), 'success');
                await onRefresh();
            }
        );
    };

    const { RenderForm } = useMemo(
        () =>
            useForm({
                enableReinitialize: true,
                initialValues: organizationInfo,
                onSubmit: onSubmit,
                validationSchema: validationSchema
            }),
        [isEditable, onSubmit]
    );

    return (
        <>
            <UISectionTitle title={title || ''} />

            <Paper elevation={3} sx={styles.paper}>
                <Box sx={styles.content}>
                    <RenderForm formFields={fields} innerFormRef={formRef} />

                    {isEditable && (
                        <UIActionButton
                            style={{ display: 'flex', alignSelf: 'flex-end' }}
                            title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                            onClick={doTriggerSubmit}
                        />
                    )}
                </Box>
            </Paper>
        </>
    );
};

const styles = {
    content: { display: 'flex', paddingX: 2, flexDirection: 'column', gap: 2 },
    paper: {
        px: 3,
        py: 1,
        mt: 1
    }
} as const;
