import { appConfiguration } from '../../../../appConfiguration';
import { EmptyGuid } from '../../../../types/types';

export interface AgendaDesign {
    id: string;
    masterOrganizationId: string;
    organizationId: string;
    fileExtension: string;
    version: string;
    createDate: string,
    updateDate: string
}

//TODO add file
export interface AgendaDesignSpec {
    id?: string,
    masterOrganizationId: string,
    organizationId: string,
    version: string,
    file?: Blob
}

export const initAgendaDesign: AgendaDesignSpec = {
    masterOrganizationId: EmptyGuid,
    organizationId: EmptyGuid,
    id: undefined,
    version: ''
};



