import { useForm } from '../../../../hooks/useForm';
import * as Yup from 'yup';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { ColorShortcutDataSpec } from '../../../../domain/resources/colorShortcuts/model/ColorShortcut';
import { UIColorPickerField } from '../../common/fields/UIColorPickerField';

interface ColorShortcutFormProps {
    colorShortcutData: ColorShortcutDataSpec;
    onSubmit: (values: ColorShortcutDataSpec) => void;
    formRef: any;
}

export const ColorShortcutForm = ({ colorShortcutData, onSubmit, formRef }: ColorShortcutFormProps) => {
    const classes = useStyles();
    const intl = useIntl();

    const validationSchema = Yup.object({
        name: Yup.string().required(getLocalizedString(intl, 'RESOURCES.COLOR_SHORTCUTS.FORM.VALIDATION.NAME.REQUIRED')),
        hexColor: Yup.string().required(getLocalizedString(intl, 'RESOURCES.COLOR_SHORTCUTS.FORM.VALIDATION.COLOR.REQUIRED'))
    });

    const fields = [
        {
            id: 'name',
            name: 'name',
            placeholder: getLocalizedString(intl, 'RESOURCES.COLOR_SHORTCUTS.CREATE.NAME.FIELD.PLACEHOLDER'),
            variant: 'standard',
            className: classes.textField,
            fieldType: 'textFieldItem',
            disabled: false
        },
        {
            id: 'hexColor',
            name: 'hexColor',
            placeholder: getLocalizedString(intl, 'RESOURCES.COLOR_SHORTCUTS.CREATE.COLOR.FIELD.PLACEHOLDER'),
            variant: 'standard',
            className: classes.textField,
            fieldType: 'textFieldItem',
            disabled: false
        },
        {
            id: 'hexColor',
            name: 'hexColor',
            fieldType: 'colorPickerFieldItem',
            component: UIColorPickerField,
            color: colorShortcutData.hexColor
        }
    ];

    const { RenderForm } = useForm({
        enableReinitialize: true,
        initialValues: colorShortcutData,
        onSubmit: onSubmit,
        validationSchema: validationSchema
    });


    return <>
        <RenderForm formFields={fields} innerFormRef={formRef} />
    </>;
};

const useStyles = makeStyles({

    textField: {
        width: '90%'
    }
});
