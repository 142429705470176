export const appConfiguration = {
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL ?? '',
    MAZARS_AUTH_BASE_URL: process.env.REACT_APP_MAZARS_AUTH_BASE_URL ?? '',
    API_KEY: process.env.REACT_APP_API_KEY ?? '',
    MAZARS_MASTER_ORGANIZATION_ID: process.env.REACT_APP_MAZARS_MASTER_ORGANIZATION_ID ?? '',
    OPENID_BASE_URL: process.env.REACT_APP_OPENID_BASE_URL ?? '',
    OPENID_SECRET: process.env.REACT_APP_OPENID_SECRET ?? '',
    OPENID_CLIENT_ID: process.env.REACT_APP_OPENID_CLIENT_ID ?? '',
    OPENID_FEDERATION: process.env.REACT_APP_OPENID_FEDERATION ?? '',
    OPENID_REDIRECT_URI: process.env.REACT_APP_OPENID_REDIRECT_URI ?? ''
};
