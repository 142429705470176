import { BaseResourceDataSpec } from '../../../../domain/resources/common/BaseResource';
import { useIntl } from 'react-intl';
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { FilesInputProps, SoftwareTypes } from '../../../../types/types';
import { useForm } from '../../../../hooks/useForm';

interface GuideFormProps {
    baseGuide: BaseResourceDataSpec;
    filesInputProps: FilesInputProps;
    handleSubmit: (values: BaseResourceDataSpec) => void;
    formRef: any;
}

const getOptionLabel = (resource: { name: string; value: number }) => `${resource.name ?? ''}`;
const getOptionValue = (resource: { name: string; id: number }) => resource.id;

export const GuideForm = ({ baseGuide, formRef, handleSubmit, filesInputProps }: GuideFormProps) => {
    const intl = useIntl();

    const validationSchema = Yup.object({
        emOfficeSoftware: Yup.mixed().required(
            getLocalizedString(intl, 'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.TYPE.REQUIRED')
        ),
        languageTag: Yup.string().required(
            getLocalizedString(intl, 'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.TYPE.REQUIRED')
        ),
        version: Yup.string().required(
            getLocalizedString(intl, 'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.TYPE.REQUIRED')
        ),
        file: Yup.mixed().required(
            getLocalizedString(intl, 'RESOURCES.BRANDING_RESOURCES.FORM.VALIDATION.FILE.REQUIRED')
        ) //TODO check accepted extension
    });

    const fields = [
        {
            id: 'emOfficeSoftware',
            name: 'emOfficeSoftware',
            placeholder: getLocalizedString(intl, 'RESOURCES.LANGUAGE_PACKS.FORM.TYPE.FIELD.PLACEHOLDER'),
            fieldType: 'selectFieldItem',
            getOptionValue: getOptionValue,
            getOptionLabel: getOptionLabel,
            options: SoftwareTypes
        },
        {
            id: 'languageTag',
            name: 'languageTag',
            placeholder: getLocalizedString(intl, 'RESOURCES.LANGUAGE_PACKS.FORM.LANGUAGE_TAG.FIELD.PLACEHOLDER'),
            fieldType: 'textFieldItem'
        },
        {
            id: 'version',
            name: 'version',
            placeholder: getLocalizedString(intl, 'RESOURCES.LANGUAGE_PACKS.FORM.VERSION.FIELD.PLACEHOLDER'),
            fieldType: 'textFieldItem'
        },

        {
            fieldType: 'fileInputFieldItem',
            id: 'file',
            name: 'file',
            acceptedFiles: filesInputProps.acceptedFiles,
            filesLimit: filesInputProps.filesLimit,
            dropzoneDefaultText: filesInputProps.dropzoneDefaultText,
            showPreviews: filesInputProps.showPreviews,
            isSubmitOnChange: false
        }
    ];

    const onSubmit = (values: BaseResourceDataSpec) => {
        // @ts-ignore
        const file = values?.file?.length > 0 && values.file[0];

        handleSubmit({ ...values, id: baseGuide.id, file: file });
    };

    const { RenderForm } = useMemo(
        () =>
            useForm({
                enableReinitialize: true,
                initialValues: baseGuide,
                onSubmit: onSubmit,
                validationSchema: validationSchema
            }),
        []
    );

    return (
        <>
            <RenderForm formFields={fields} innerFormRef={formRef} />
        </>
    );
};
