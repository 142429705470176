import React, { useContext } from 'react';
import { APIScaffold } from '../../scaffolder/APIScaffold';
import { UIScaffold } from '../../scaffolder/UIScaffold';
import { RepositoryProvider } from '../../../domain';
import { CreateLibraryNodeContent } from '../../components/library/create/CreateLibraryNodeContent';
import { AppContext } from '../../../context/AppContext';

export const CreateLibraryNodeScreen = () => {
    const { isEditableAtMasterOrganization, isCurrentOrganizationMaster } = useContext(AppContext);
    const libraryRepository =
        isCurrentOrganizationMaster && isEditableAtMasterOrganization
            ? RepositoryProvider.getProvider().masterLibraryRepository
            : RepositoryProvider.getProvider().libraryRepository;

    return (
        <APIScaffold>
            {({ cancelRequest }) => (
                <UIScaffold>
                    {({ setLoading, setSessionExpired, setToast }) => (
                        <CreateLibraryNodeContent
                            cancelRequest={cancelRequest}
                            libraryRepository={libraryRepository}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            setToast={setToast}
                        />
                    )}
                </UIScaffold>
            )}
        </APIScaffold>
    );
};
