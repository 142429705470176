import React, { PropsWithChildren } from 'react';
import { IconButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import { Delete, Person } from '@mui/icons-material';
import { UIText } from '../../common/UIText';
import { Palette } from '../../../../res/colors';
import { MasterOrganizationRoleEnum, OrganizationRoleEnum } from '../../../../types/types';

interface SpecialUserListItemProps<D> {
    isMasterOrganization: boolean;
    userRole: D;
    handleDelete: (userRole: D) => void;
    isDeleteAllowed: boolean;
}

export const SpecialUserListItem = <D, >({
                                             isMasterOrganization,
                                             isDeleteAllowed,
                                             userRole,
                                             handleDelete
                                         }: PropsWithChildren<SpecialUserListItemProps<D>>) => {

    const {
        email,
        eOrganizationRole,
        eMasterOrganizationRole
    } = userRole as { id?: string, email?: string, eOrganizationRole?: OrganizationRoleEnum, eMasterOrganizationRole?: MasterOrganizationRoleEnum };

    const onClear = () => {
        handleDelete(userRole);
    };

    const getRoleLabel = () => {

        // @ts-ignore
        return isMasterOrganization ? MasterOrganizationRoleEnum[eMasterOrganizationRole] : OrganizationRoleEnum[eOrganizationRole] ;
    };

    return (
        <Paper elevation={3} sx={{ my: 2 }}>
            <ListItem>
                <ListItemIcon><Person /></ListItemIcon>
                <ListItemText sx={{ width: '50%' }} primary={<UIText text={email?.toLowerCase()} />} />
                <ListItemText
                    primary={getRoleLabel()}
                />

                {isDeleteAllowed &&
                <IconButton onClick={onClear} sx={{ mx: 1, color: Palette.red }}>
                    <Delete />
                </IconButton>}
            </ListItem>
        </Paper>
    );
};

