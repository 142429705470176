import { BrandingResource } from '../../../../domain';
import {
    BrandingResourceDataSpec,
    initBrandingResource
} from '../../../../domain/resources/brandingResources/model/BrandingResource';
import React, { useContext, useRef, useState } from 'react';
import { AppContext } from '../../../../context/AppContext';
import { useIntl } from 'react-intl';
import { FormikProps } from 'formik/dist/types';
import { UISectionHeader } from '../../common/UISectionHeader';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { Paper } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import { BrandingResourceTile } from './BrandingResourceTile';
import { UIDialog } from '../../common/UIDialog';
import { UIActionButton } from '../../common/UIActionButton';
import { BrandingResourceForm } from './BrandingResourceForm';
import { FilesMimeTypes } from '../../../../types/types';
import {
    getMasterOrganizationId,
    getOrganizationId,
    getScreenSubtitleByOrganizationType
} from '../../../../utils/organizationHelper';
import { getOverriddenResource } from '../../../../utils/overridingHelper';

export interface BrandingResourcesAreaProps {
    brandResources: BrandingResource[];
    fetchResourceImage: (resource: BrandingResource) => Promise<string>;
    actionsHidden: boolean;
    handleDelete: (ids: string[]) => void;
    handleCreate: (data: BrandingResourceDataSpec) => Promise<boolean>;
    handleUpdate: (data: BrandingResourceDataSpec) => Promise<boolean>;
}

export const BrandingResourcesArea = ({
    fetchResourceImage,
    brandResources: allBrandingResources,
    handleCreate,
    handleDelete,
    handleUpdate
}: BrandingResourcesAreaProps) => {
    const createFormRef = useRef();
    const { currentOrganization, isEditableAtMasterOrganization, isCurrentOrganizationMaster } = useContext(AppContext);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [currentBrandingResource, setCurrentBrandingResource] = useState<BrandingResourceDataSpec>({
        ...initBrandingResource,
        masterOrganizationId: getMasterOrganizationId(currentOrganization),
        organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
    });

    const intl = useIntl();

    const onCloseDialog = () => {
        setOpenDialog(!openDialog);
    };

    const onCreateBrandingResourceRequest = () => {
        setOpenDialog(!openDialog);
    };

    const doTriggerSubmit = () => {
        const formik = createFormRef.current ? (createFormRef.current as FormikProps<BrandingResource>) : null;
        formik?.handleSubmit();
    };

    const onCreate = async (brandResources: BrandingResourceDataSpec) => {
        const isSuccess = await handleCreate(brandResources);
        setOpenDialog(!isSuccess);
    };

    const onEditAsked = (resource: BrandingResource) => {
        setCurrentBrandingResource({
            ...initBrandingResource,
            ...resource,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });
        setOpenDialog(!openDialog);
    };

    const onUpdate = async (brandResources: BrandingResourceDataSpec) => {
        const isSuccess = await handleUpdate(brandResources);
        setOpenDialog(!isSuccess);
    };

    const onSubmitForm = async (values: BrandingResourceDataSpec) => {
        if (!values.eBrandingResourceType) {
            throw new Error('eBrandingResourceType should be defined');
        }

        if (isCurrentOrganizationMaster) {
            !values.id ? await onCreate(values) : await onUpdate(values);
        } else {
            const overriddenResource = getOverriddenResource(values.eBrandingResourceType, allBrandingResources);
            overriddenResource
                ? await onUpdate({
                      ...values,
                      id: overriddenResource?.id
                  })
                : await onCreate(values);
        }
    };

    return (
        <>
            <UISectionHeader
                title={getLocalizedString(intl, 'RESOURCES.SIDE_BAR_MENU.BRANDING_RESOURCES.ENTRY')}
                subtitle={getLocalizedString(intl, getScreenSubtitleByOrganizationType(isCurrentOrganizationMaster))}
                onCreate={onCreateBrandingResourceRequest}
                isCreateEnabled={isEditableAtMasterOrganization}
            />

            <Paper elevation={3} sx={{ paddingY: 2, paddingLeft: 2 }}>
                <ImageList sx={{ width: '100%', height: 200 }} cols={3}>
                    {Array.isArray(allBrandingResources) &&
                        allBrandingResources?.map((item, index) => (
                            <BrandingResourceTile
                                key={item.id + index}
                                resource={item}
                                fetchResourceImage={fetchResourceImage}
                                onEditAsked={onEditAsked}
                                onDelete={handleDelete}
                            />
                        ))}
                </ImageList>
            </Paper>
            {openDialog && (
                <UIDialog
                    open={openDialog}
                    onClose={onCloseDialog}
                    title={getLocalizedString(intl, 'RESOURCES.BRANDING_RESOURCES.DIALOG.TITLE')}
                    actions={
                        <UIActionButton
                            title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                            onClick={doTriggerSubmit}
                        />
                    }>
                    <>
                        <BrandingResourceForm
                            allBrandingResources={allBrandingResources}
                            brandingResource={currentBrandingResource}
                            formRef={createFormRef}
                            handleSubmit={onSubmitForm}
                            filesInputProps={{
                                filesLimit: 1,
                                acceptedFiles: FilesMimeTypes.Pictures as string[],
                                showPreviews: true
                            }}
                        />
                    </>
                </UIDialog>
            )}
        </>
    );
};
