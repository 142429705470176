import { FormFieldProps } from '../../../../hooks/useForm';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { UISelect } from '../UISelect';
import { UIErrorMessage } from '../UIErrorMessage';
import React from 'react';

export interface FormSelectFieldProps extends FormFieldProps {
    getOptionValue?: (item: any) => string;
    getOptionLabel?: (item: any) => string;
    getRemovedValue?: (item: any) => void;
    value: any;
    options: any;
    multiple?: boolean;
    validation?: boolean;
    isClearable?: boolean;
}

export const UISelectField = ({
    value,
    name,
    id,
    multiple,
    placeholder,
    variant,
    disabled,
    className,
    validation = true,
    options,
    getOptionValue,
    getOptionLabel,
    isSubmitOnChange = false,
    getRemovedValue,
    isClearable = true
}: FormSelectFieldProps) => {
    const { submitForm, setFieldValue } = useFormikContext();

    // the option must include id field
    const onSelectionChange = async (option: any) => {
        if (multiple) {
            const options = option as Array<any>;
            await setFieldValue(id, options.map(opt => opt.id).join('|'));
        } else {
            await setFieldValue(id, option.id);
        }

        isSubmitOnChange && (await submitForm());
    };

    const onMultiSelectionChange = async (options: any[]) => {
        await setFieldValue(
            id,
            options.map(opt => opt.id)
        );
        isSubmitOnChange && (await submitForm());
    };

    const onRemoveValue = async (removedValue: any, remainingValues: any[]) => {
        await setFieldValue(
            id,
            remainingValues.map(opt => opt.id)
        );
        getRemovedValue && getRemovedValue(removedValue);
        isSubmitOnChange && (await submitForm());
    };

    return (
        <>
            <Field
                isClearable={isClearable}
                value={value}
                component={UISelect}
                onSelectionChange={onSelectionChange}
                onMultiSelectionChange={onMultiSelectionChange}
                onRemoveValue={onRemoveValue}
                name={name}
                id={id}
                key={id}
                multiple={multiple}
                disabled={disabled}
                placeholder={placeholder}
                options={options}
                fullWidth
                variant={variant ?? 'outlined'}
                className={className}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
            />

            {validation ? (
                <ErrorMessage name={id} render={errorMessage => <UIErrorMessage message={errorMessage} />} />
            ) : null}
        </>
    );
};
