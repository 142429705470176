import { Box } from '@mui/material';
import { Palette } from '../../../../res/colors';
import React from 'react';
import { UIText } from '../../common/UIText';

export const UserField = ({ title, value }: any) => {
    return (
        <Box sx={sx.container}>
            <UIText sx={sx.title} text={title}/>
            <UIText sx={sx.value} text={value}/>
        </Box>);
};


const sx = {
    container: {
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'repeat(2, 1fr)'
    },
    title: {
        flexGrow: 0,
        color: Palette.primaryTextColor
    },
    value: {
        flexGrow: 1,
        color: Palette.primaryTextColor
    }
} as const;