import { BaseResource, LanguagePack } from '../../../../domain';
import { useIntl } from 'react-intl';
import React, { Fragment } from 'react';
import { UITreeItem } from '../../common/UITreeItem';
import { Palette } from '../../../../res/colors';
import { IconButton, Tooltip } from '@mui/material';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { Delete, Download, Edit, Visibility } from '@mui/icons-material';
import { UIText } from '../../common/UIText';
import { BaseResourceDataSpec, getBaseSourceData } from '../../../../domain/resources/common/BaseResource';

interface SoftwareGuidesTreeProps {
    title: string;
    treeId: string;
    softwareGuides: { [_: string]: BaseResource[] };
    handleDownload: (pack: BaseResource) => Promise<any>;
    handlePreview?: (pack: BaseResource) => Promise<any>;
    handleDelete?: (ids: string[]) => Promise<any>;
    handleEdit?: (pack: BaseResourceDataSpec) => void;
    isVisualizable: boolean;
    isEditable?: boolean;
}

export const SoftwareGuidesTree = ({
    isEditable = false,
    handleDelete,
    handleEdit,
    handlePreview,
    isVisualizable,
    title,
    treeId,
    handleDownload,
    softwareGuides
}: SoftwareGuidesTreeProps): JSX.Element => {
    const intl = useIntl();

    const handleSelectItem = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const onDownload = (pack: BaseResource) => async (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        await handleDownload(pack);
    };

    const onEdit = (pack: BaseResource) => (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        handleEdit && handleEdit(getBaseSourceData(pack));
    };

    const onDelete = (pack: BaseResource) => async (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        handleDelete && (await handleDelete([pack.id]));
    };

    const onPreview = (pack: BaseResource) => async (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        handlePreview && (await handlePreview(pack));
    };

    return (
        <>
            {Object.keys(softwareGuides)?.length > 0 && <UIText text={title} sx={{ fontSize: '14' }} variant="h6" />}
            {Object.keys(softwareGuides).map(key => {
                const packs = softwareGuides[key];
                return (
                    <UITreeItem
                        key={key}
                        nodeId={`${treeId}-${key}`}
                        color={Palette.defaultPrimaryColor}
                        bgColor={Palette.extraLightBlue}
                        labelText={key}>
                        <>
                            {packs?.map((pack: LanguagePack) => (
                                <Fragment key={pack.id}>
                                    <UITreeItem
                                        onSelectItem={handleSelectItem}
                                        actionButton={
                                            <>
                                                {isVisualizable && (
                                                    <Tooltip
                                                        title={getLocalizedString(
                                                            intl,
                                                            'CONFIGURATION.AGENDA_DESIGN.PREVIEW.FILE'
                                                        )}>
                                                        <IconButton color="primary" onClick={onPreview(pack)}>
                                                            <Visibility fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}

                                                <Tooltip
                                                    title={getLocalizedString(
                                                        intl,
                                                        'RESOURCES.LANGUAGE_PACKS.DOWNLOAD.FILES'
                                                    )}>
                                                    <IconButton color="primary" onClick={onDownload(pack)}>
                                                        <Download fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>

                                                {isEditable && (
                                                    <>
                                                        {!!handleEdit && (
                                                            <Tooltip
                                                                title={getLocalizedString(
                                                                    intl,
                                                                    'RESOURCES.LANGUAGE_PACKS.EDIT.FILES'
                                                                )}>
                                                                <IconButton color="primary" onClick={onEdit(pack)}>
                                                                    <Edit fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}

                                                        <Tooltip
                                                            title={getLocalizedString(
                                                                intl,
                                                                'RESOURCES.LANGUAGE_PACKS.DELETE.FILES'
                                                            )}>
                                                            <IconButton color="primary" onClick={onDelete(pack)}>
                                                                <Delete fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                )}
                                            </>
                                        }
                                        key={pack.id}
                                        nodeId={pack.id}
                                        labelText={'v-' + pack.version + ''}
                                    />
                                </Fragment>
                            ))}
                        </>
                    </UITreeItem>
                );
            })}
        </>
    );
};
