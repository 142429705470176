import { NavRoutes } from '../navigation/NavRoutes';

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';
export type Order = 'asc' | 'desc';
export type RoleLevel = 'masterOrganizationAdmin' | 'organizationAdmin' | 'standard';
export type AuthProvider = 'theGate' | 'organization' | 'other';

export enum OrganizationRoleEnum {
    Admin = 0,
    Member = 1,
    Guest = 2
}

export enum MasterOrganizationRoleEnum {
    Admin = 0,
    Member = 1
}

export enum AppRoleEnum {
    Admin = 0
}

export enum UserResourceType {
    File = 1,
    Link = 2,
    VideoLink = 3,
    Text = 4
}

export enum SoftwareType {
    Excel = 1,
    PowerPoint = 2,
    Word = 3
}

export enum LibraryDocumentType {
    None = 0,
    PowerPointSlide = 1,
    PowerPointPresentation,
    ExcelWorksheet,
    ExcelWorkbook,
    WordDocument,
    Icon,
    Text,
    Shape
}

export enum LibraryNodeType {
    Directory = 0,
    File
}

export type UISideMenuBarEntry =
    | 'overview'
    | 'license'
    | 'sign_ins'
    | 'links'
    | 'languagePacks'
    | 'userGuide'
    | 'colorShortcuts'
    | 'brandingResources'
    | 'feedbackConfig'
    | 'learningLink'
    | 'agendaDesign';

export enum MenuPageType {
    Library = 'LIBRARY',
    Account = 'ACCOUNT',
    UsersRoles = 'USERS_ROLES',
    Organization = 'ORGANIZATION',
    MasterOrganization = 'MASTER_ORGANIZATION',
    Contents = 'CONTENTS',
    Resources = 'RESOURCES',
    Configuration = 'CONFIGURATION',
    Credentials = 'CREDENTIALS',
    Macro = 'MACRO'
}

export const NavRoutesStack: Record<MenuPageType, NavRoutes[]> = {
    [MenuPageType.Library]: [NavRoutes.Home, NavRoutes.Library, NavRoutes.EditLibraryNode, NavRoutes.CreateLibraryNode],
    [MenuPageType.Contents]: [NavRoutes.Contents],
    [MenuPageType.Credentials]: [NavRoutes.Credentials, NavRoutes.CreateCredentialsPack],
    [MenuPageType.Macro]: [NavRoutes.MacroPacks, NavRoutes.CreateMacroPack],
    [MenuPageType.Account]: [NavRoutes.Account],
    [MenuPageType.UsersRoles]: [NavRoutes.UserRoles],
    [MenuPageType.Resources]: [NavRoutes.Resources],
    [MenuPageType.Configuration]: [NavRoutes.Configuration],
    [MenuPageType.Organization]: [NavRoutes.Organization],
    [MenuPageType.MasterOrganization]: [NavRoutes.Organization]
};

export enum UserRolesPanels {
    SpecialUsers,
    Roles
}

export const FilesMimeTypes: { [key: string]: readonly string[] } = {
    Excel: [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '.xlsm',
        '.xltx',
        '.xltm'
    ],
    PowerpointTemplate: [
        'application/vnd.openxmlformats-officedocument.presentationml.template',
        '.pptm',
        '.potm',
        '.pot',
        '.potx'
    ],
    Powerpoint: [
        'application/ppt',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ],
    Word: [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        '.dotx',
        '.dotm',
        '.docm'
    ],
    Pictures: ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/vnd.microsoft.icon', 'image/x-icon'],
    Text: ['text/plain'],
    Pdf: ['application/pdf'],
    Macro: ['.xlsm']
} as const;

export type ResourceDialogMode = 'create' | 'edit';

export enum EBrandingResourceType {
    Logo = 1,
    RibbonIcon = 2,
    FileIcon = 3
}

export const BrandingResourceTypes: { name: string; id: number }[] = [
    {
        id: 1,
        name: 'Logo'
    },
    {
        id: 2,
        name: 'RibbonIcon'
    },
    {
        id: 3,
        name: 'FileIcon'
    }
];

export const SoftwareTypes: { name: string; id: number }[] = [
    {
        id: 1,
        name: 'Excel'
    },
    {
        id: 2,
        name: 'PowerPoint'
    },
    {
        id: 3,
        name: 'Word'
    }
];

export interface FilesInputProps {
    acceptedFiles: readonly string[];
    filesLimit: number;
    dropzoneDefaultText: string;
    showPreviews: boolean;
}

export type OrganizationParams =
    | {
          masterOrganizationId: string;
      }
    | { organizationId: string }
    | { masterOrganizationId: string; organizationId: string };

export enum HttpContentType {
    MultipartFormData = 'multipart/form-data'
}

export type RequestParam = string | boolean | number;

export type BlobWithName = Blob & { name: string };
