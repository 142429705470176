import { CreateMacroPackContent } from '../../components/macro/create/CreateMacroPackContent';
import React from 'react';
import { RepositoryProvider } from '../../../domain';
import { APIScaffold } from '../../scaffolder/APIScaffold';
import { UIScaffold } from '../../scaffolder/UIScaffold';

export const CreateMacroPackScreen: React.FC = () => {
    return (
        <APIScaffold>
            {({ cancelRequest }) => (
                <UIScaffold>
                    {({ setLoading, setSessionExpired, setToast }) => (
                        <CreateMacroPackContent
                            macroPacksRepository={RepositoryProvider.getProvider().macroPacksRepository}
                            setToast={setToast}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            cancelRequest={cancelRequest}
                        />
                    )}
                </UIScaffold>
            )}
        </APIScaffold>
    );
};
