import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';

import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { APIScaffoldChildContext } from '../../../scaffolder/APIScaffold';

import TreeView from '@mui/lab/TreeView';
import { Delete, Folder, FolderOpen, GetApp } from '@mui/icons-material';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { UIDialog } from '../../common/UIDialog';
import { UIActionButton } from '../../common/UIActionButton';
import { CreateLibraryFolder } from '../create/CreateLibraryFolder';
import { initLibraryInfo, Library, LibraryFileData, LibraryNodeSpec, LibraryRepositorySpec } from '../../../../domain';
import { LibraryNodeTree } from '../index';
import { Box, IconButton, Paper, Tooltip } from '@mui/material';
import { CONTAINER_TOP_MARGIN } from '../../../../res/dimensions';
import { LibraryFilePreview } from '../create/LibraryFilePreview';
import { NavRoutes } from '../../../../navigation/NavRoutes';
import { useNavigate } from 'react-router-dom';
import { useRepository } from '../../../../hooks/useRepository';
import { serializeLibraryNodes } from '../../../../utils/serializer';
import { FormikProps } from 'formik/dist/types';
import { replaceAll } from '../../../../utils/helper';
import { saveFileFromUrl } from '../../../../utils/fileHelper';
import { UIText } from '../../common/UIText';
import { Palette } from '../../../../res/colors';
import { AppContext } from '../../../../context/AppContext';
import { LibraryDocumentType, LibraryNodeType } from '../../../../types/types';
import { getMasterOrganizationId } from '../../../../utils/organizationHelper';
import { tabledHelper } from '../../../../utils/tabledHelper';
import { UISectionHeader } from '../../common/UISectionHeader';
import { StickyBanner } from '../../common/StickyBanner';

export interface LibraryTreeContentProps {
    libraryRepository: LibraryRepositorySpec;
    isEditable: boolean;
}

export const LibraryTreeContent = ({
    isEditable,
    libraryRepository,
    cancelRequest,
    setLoading,
    setToast,
    setSessionExpired
}: LibraryTreeContentProps & UIScaffoldChildContext & APIScaffoldChildContext) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { sessionInfo, currentOrganization } = useContext(AppContext);
    const createFolderFormRef = useRef<FormikProps<LibraryFileData>>();
    const updateFileFormRef = useRef<FormikProps<LibraryFileData>>();

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const [selectedNode, setSelectedNode] = useState<Library | null>(null);
    const [downloadedFile, setDownloadedFile] = useState<Blob | null>(null);
    const [openCreateFolder, setOpenCreateFolder] = useState<boolean>(false);
    const [openPreviewFile, setOpenPreviewFile] = useState<boolean>(false);
    const [rootNodes, setRootNodes] = useState<Library[] | null>(null);

    useEffect(() => {
        (async () => await fetchLibraryContent())();

        return () => {
            cancelRequest(libraryRepository);
        };
    }, [currentOrganization?.id]);

    const fetchLibraryContent = async () => {
        await callRepository(
            () => libraryRepository.fetchAll(currentOrganization.id, sessionInfo),
            data => {
                const libraryNodes: Library[] = data as Library[];
                setRootNodes(libraryNodes.filter(node => !node.parent));
            }
        );
    };

    const updateCreateDialogVisibility = () => {
        setOpenCreateFolder(!openCreateFolder);
        setOpenPreviewFile(false);
    };

    const updatePreviewFileVisibility = () => {
        setOpenPreviewFile(!openPreviewFile);
        setOpenCreateFolder(false);
    };

    const handleOpenFolderDialog = () => {
        updateCreateDialogVisibility();
    };

    const handleEditAsked = async (node: Library) => {
        setSelectedNode(node);

        switch (node.eLibraryNodeType) {
            case LibraryNodeType.Directory:
                navigate(
                    replaceAll(NavRoutes.EditLibraryNode, {
                        ':parentNodeId': node.id ?? '',
                        ':parentNodeName': node.name
                    })
                );
                break;

            case LibraryNodeType.File: {
                await handlePreviewFile(node);

                break;
            }
        }
    };

    const doTriggerSubmitUpdateFile = () => {
        const formikDetails = updateFileFormRef.current
            ? (updateFileFormRef.current as FormikProps<LibraryFileData>)
            : null;
        formikDetails?.handleSubmit();
    };

    const handleSubmitUpdate = async (nodeData: LibraryFileData) => {
        const node = await serializeLibraryNodes([nodeData]);

        // @ts-ignore
        await callRepository(
            () => libraryRepository.update(node, sessionInfo),
            async () => {
                setToast(getLocalizedString(intl, 'LIBRARY.UPDATE.SUCCESS.MESSAGE'), 'success');
                setOpenPreviewFile(false);
                await fetchLibraryContent();
            }
        );
    };

    const handleDelete = async (nodeId: string) => {
        // @ts-ignore
        sessionInfo &&
            (await callRepository(
                () => libraryRepository.delete([nodeId], sessionInfo),
                async () => {
                    setOpenPreviewFile(false);
                    setToast(getLocalizedString(intl, 'LIBRARY.DELETE.SUCCESS.MESSAGE'), 'success');
                    await fetchLibraryContent();
                }
            ));
    };

    const handleDownload = (file: Blob | null, fileName?: string) => {
        if (file && fileName) {
            const url = URL.createObjectURL(file);
            saveFileFromUrl(url, fileName);
        }
    };

    const handleAdd = (node: Library, nodeType: LibraryNodeType) => {
        setSelectedNode(node);

        switch (nodeType) {
            case LibraryNodeType.Directory:
                handleOpenFolderDialog();
                break;

            case LibraryNodeType.File:
                navigate(
                    replaceAll(NavRoutes.CreateLibraryNode, {
                        ':parentNodeId': node.id ?? '',
                        ':parentNodeName': node.name
                    })
                );
                break;
        }
    };

    const doTriggerSubmitCreateFolder = () => {
        const formik = createFolderFormRef.current
            ? (createFolderFormRef.current as FormikProps<LibraryFileData>)
            : null;
        formik?.handleSubmit();
    };

    const handleSubmitCreateFolder = async (nodeValues: Library) => {
        const node: LibraryNodeSpec = { files: [], info: [nodeValues] };

        // @ts-ignore
        await callRepository(
            () => libraryRepository.create(node, sessionInfo),
            async () => {
                setToast(getLocalizedString(intl, 'LIBRARY.CREATE.FOLDER.SUCCESS'), 'success');
                updateCreateDialogVisibility();
                await fetchLibraryContent();
            }
        );
    };

    const handlePreviewFile = async (fileNode: Library) => {
        const nodeId = fileNode.id;
        if (!nodeId) {
            //TODO throw
            return;
        }

        await callRepository(
            () => libraryRepository.fetchFile(nodeId, sessionInfo),
            data => {
                setDownloadedFile(data as Blob);
                setSelectedNode(fileNode);
                updatePreviewFileVisibility();
            }
        );
    };

    const handleError = (error: string) => {
        setToast(error, 'error');
    };

    const onCreateRootFolderRequested = () => {
        handleOpenFolderDialog();
    };

    return (
        <>
            <StickyBanner
                title={getLocalizedString(intl, 'LIBRARY.WARNING')}
                content={getLocalizedString(intl, 'LIBRARY.WARNING.MESSAGE')}
            />
            <Box className="container" sx={styles.container}>
                <UISectionHeader
                    title={getLocalizedString(intl, 'LIBRARY.HOME.TITLE')}
                    onCreate={onCreateRootFolderRequested}
                    isCreateEnabled={isEditable}
                />
                {isEditable && <UIText text={getLocalizedString(intl, 'LIBRARY.HOME.SUBTITLE')} />}
                <Paper elevation={3} sx={{ px: 3, py: 1, mt: 1 }}>
                    <TreeView
                        aria-label="library-content"
                        defaultCollapseIcon={<FolderOpen />}
                        defaultExpandIcon={<Folder />}
                        sx={{ flexGrow: 1, overflowY: 'auto' }}>
                        {rootNodes?.map(rootNode => (
                            <Fragment key={rootNode.id}>
                                {
                                    <LibraryNodeTree
                                        isEditable={isEditable}
                                        node={rootNode}
                                        onAdd={handleAdd}
                                        onDisplayFile={handlePreviewFile}
                                        onDelete={handleDelete}
                                        onEdit={handleEditAsked}
                                    />
                                }
                            </Fragment>
                        ))}
                    </TreeView>

                    {openCreateFolder && (
                        <UIDialog
                            open={openCreateFolder}
                            onClose={updateCreateDialogVisibility}
                            title={getLocalizedString(intl, 'LIBRARY.CREATE.FOLDER.DIALOG.TITLE')}
                            actions={
                                <UIActionButton
                                    title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                                    onClick={doTriggerSubmitCreateFolder}
                                />
                            }>
                            <>
                                <CreateLibraryFolder
                                    formRef={createFolderFormRef}
                                    folder={{
                                        ...initLibraryInfo,
                                        masterOrganizationId: getMasterOrganizationId(currentOrganization),
                                        organizationId: currentOrganization.id,
                                        eLibraryNodeType: LibraryNodeType.Directory,
                                        eDocumentType: selectedNode?.eDocumentType as LibraryDocumentType,
                                        parentId: selectedNode?.id || null
                                    }}
                                    onSubmit={handleSubmitCreateFolder}
                                />
                            </>
                        </UIDialog>
                    )}

                    <UIDialog
                        open={openPreviewFile}
                        toolbarActions={
                            <>
                                <IconButton
                                    aria-label="download"
                                    onClick={() => handleDownload(downloadedFile, selectedNode?.name)}>
                                    <Tooltip title={getLocalizedString(intl, 'COMMON.ACTIONS.DOWNLOAD')}>
                                        <GetApp color="primary" />
                                    </Tooltip>
                                </IconButton>

                                {isEditable && (
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => handleDelete(selectedNode?.id || '')}>
                                        <Tooltip title={getLocalizedString(intl, 'COMMON.ACTIONS.DELETE')}>
                                            <Delete sx={{ color: Palette.red }} />
                                        </Tooltip>
                                    </IconButton>
                                )}
                            </>
                        }
                        onClose={updatePreviewFileVisibility}
                        title={selectedNode?.name ?? ''}
                        actions={
                            isEditable ? (
                                <UIActionButton
                                    title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                                    onClick={doTriggerSubmitUpdateFile}
                                />
                            ) : undefined
                        }>
                        <>
                            {selectedNode && downloadedFile && (
                                <LibraryFilePreview
                                    handleError={handleError}
                                    formRef={updateFileFormRef}
                                    isEditable={isEditable}
                                    node={tabledHelper.createDisplayedData<Library, LibraryFileData>(
                                        selectedNode,
                                        null
                                    )}
                                    file={downloadedFile}
                                    handleUpdate={handleSubmitUpdate}
                                />
                            )}
                        </>
                    </UIDialog>
                </Paper>
            </Box>
        </>
    );
};

const styles = {
    container: {
        mt: CONTAINER_TOP_MARGIN,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    }
} as const;
