import { EmptyGuid } from '../../../../types/types';

export interface ColorShortcut {
    id: string;
    name: string;
    index: number;
    hexColor: string;
    rgbInt: number;
    rgb: RgbColor;
    masterOrganizationId: string;
    createDate: string;
    updateDate: string;
}

export interface RgbColor {
    r: number;
    g: number;
    b: number;
}

export interface ColorShortcutDataSpec {
    id?: string;
    masterOrganizationId: string;
    name: string;
    hexColor: string;
    organizationId: string;
    index: number;
}

export const initColorShortcutData: ColorShortcutDataSpec = {
    masterOrganizationId: EmptyGuid,
    organizationId: EmptyGuid,
    name: '',
    hexColor: '#000000',
    index: 0
};
