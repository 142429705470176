import { AuthSessionInfoSpec, HttpRoutes, RepositorySpec } from '..';

import { HttpResponse } from '../mazars-network-http-client';
import { Repository } from '../Repository';
import { HttpContentType } from '../../types/types';
import { CredentialPack } from './model/CredentialPack';
import { CredentialPackDataSpec } from './model/initCredentialPack';

export interface CredentialsPacksRepositorySpec extends RepositorySpec {
    /// Fetch all credentials of the given masterOrganization
    fetchAll(
        sessionInfo: AuthSessionInfoSpec,
        masterOrganizationId: string,
        organizationId: string | null
    ): Promise<CredentialPack[] | null>;

    /// Fetch a specific credential
    fetch(id: string, sessionInfo: AuthSessionInfoSpec): Promise<CredentialPack | undefined>;

    /// Fetch the file of a specific credential
    fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined>;

    create(nodes: CredentialPackDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    update(nodes: CredentialPackDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    delete(nodeIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;
}

export class CredentialsPacksRepository extends Repository implements CredentialsPacksRepositorySpec {
    async fetchAll(
        sessionInfo: AuthSessionInfoSpec,
        masterOrganizationId: string,
        organizationId: string | null
    ): Promise<CredentialPack[]> {
        const parameters = organizationId ? { masterOrganizationId, organizationId } : { masterOrganizationId };
        const response = (await this.datasource.remote.get(
            HttpRoutes.CredentialsPacks,
            sessionInfo.accessToken,
            undefined,
            parameters,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<CredentialPack[], any>;

        return response.data || [];
    }

    async fetch(id: string, sessionInfo: AuthSessionInfoSpec): Promise<CredentialPack | undefined> {
        const path = HttpRoutes.CredentialsPack.replace(':id', id);

        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<CredentialPack, any>;

        return response.data;
    }

    async fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined> {
        const path = HttpRoutes.CredentialsPackFile.replace(':id', id);
        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<Blob, any>;

        return response.data;
    }

    async create(nodes: CredentialPackDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = new FormData();
        body.append('JSON', JSON.stringify(nodes.info));
        nodes.files.forEach(file => {
            body.append('FILES', file);
        });

        const response = (await this.datasource.remote.post(
            HttpRoutes.CredentialsPacks,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async update(nodes: CredentialPackDataSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = new FormData();
        body.append('JSON', JSON.stringify(nodes.info));

        nodes.files.forEach(file => {
            body.append('FILES', file);
        });

        const response = (await this.datasource.remote.put(
            HttpRoutes.CredentialsPacks,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async delete(nodeIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.CredentialsPacks,
            nodeIds,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }
}
