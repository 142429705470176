import { initLibraryInfo, Library, LibraryFileData } from '../../../domain';
import React from 'react';
import { FilesMimeTypes, LibraryDocumentType, LibraryNodeType, Order } from '../../../types/types';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import Box from '@mui/material/Box';
import { CONTAINER_TOP_MARGIN } from '../../../res/dimensions';
import { FieldArray, Form, Formik } from 'formik';
import { UIDropzone } from '../common/UIDropzone';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import { UITableHead, UITableHeadCell } from '../common/UITableHead';
import TableBody from '@mui/material/TableBody';
import { LibraryTableRow } from './create/LibraryTableRow';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { UIText } from '../common/UIText';
import TablePagination from '@mui/material/TablePagination';
import { UIActionButton } from '../common/UIActionButton';
import { flattenFileName } from '../../../utils/fileHelper';
import { tabledHelper } from '../../../utils/tabledHelper';
import { UITableToolbar } from '../common/UITableToolbar';

export interface LibraryFilesTableProps {
    title: string;
    headCells: UITableHeadCell<LibraryFileData>[];
    parentNodeId?: string;
    organizationId: string;
    masterOrganizationId?: string;
    handleSubmit: (values: any) => Promise<any>;
    initialValues: { nodes: LibraryFileData[] };
    onDelete?: (nodes: LibraryFileData[]) => Promise<boolean>;
}

export const LibraryFilesTable = ({
    title,
    parentNodeId,
    organizationId,
    masterOrganizationId,
    initialValues,
    handleSubmit,
    onDelete,
    headCells
}: LibraryFilesTableProps) => {
    const intl = useIntl();
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof LibraryFileData>('name');
    const [selected, setSelected] = React.useState<string[]>([]);

    const validationSchema = Yup.object().shape({
        nodes: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string().required(getLocalizedString(intl, 'LIBRARY.VALIDATION.NAME.REQUIRED')),
                    eDocumentType: Yup.mixed()
                        /*.oneOf(
                            // @ts-ignore
                            LibraryNodeDocumentTypeOptions.map(item => LibraryDocumentType[item.id]),
                            getLocalizedString(intl, 'LIBRARY.VALIDATION.INVALID.CONTENT_TYPE')
                        )*/
                        .required(getLocalizedString(intl, 'LIBRARY.VALIDATION.CONTENT_TYPE.REQUIRED'))
                })
            )
            .required(getLocalizedString(intl, 'LIBRARY.VALIDATION.NODE.REQUIRED'))
    });

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = (nodes: LibraryFileData[]) =>
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - nodes.length) : 0;

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof LibraryFileData) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (nodes: LibraryFileData[]) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            // @ts-ignore
            const newSelectedIs = nodes.map(n => n.name);
            setSelected(newSelectedIs);
            return;
        }

        setSelected([]);
    };

    const handleClick = (_, name: string) => {
        setSelected(tabledHelper.updateSelectedEntries(selected, name));
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRemove = async (remove: (index: number) => void, nodes: LibraryFileData[]) => {
        if (onDelete) {
            const canDelete = await onDelete(nodes);
            canDelete && tabledHelper.removeEntriesFromTable(selected, remove, page * rowsPerPage);
        } else {
            tabledHelper.removeEntriesFromTable(selected, remove, page * rowsPerPage);
        }

        setSelected([]);
    };

    const updateRow = (
        replace: (index: number, value: any) => void,
        nodes: LibraryFileData[],
        key: string,
        value: any
    ) => {
        selected.forEach((_, index) => {
            const selectedIndex = index + page * rowsPerPage;
            const newValue = nodes[selectedIndex];
            replace(selectedIndex, { ...newValue, [key]: value });
        });

        setSelected([]);
    };

    const handleSetDocumentType = (
        replace: (index: number, value: any) => void,
        nodes: LibraryFileData[],
        documentType: LibraryDocumentType | string
    ) => {
        updateRow(replace, nodes, 'eDocumentType', documentType);
    };

    const handleAdd = (add: (file: any) => void) => (files?: Blob[]) => {
        files?.forEach((file: Blob) => {
            // @ts-ignore
            const { name, extension } = flattenFileName(file.name);

            const newNode: Library = {
                ...initLibraryInfo,
                organizationId: organizationId,
                masterOrganizationId: masterOrganizationId,
                name,
                fileExtension: extension,
                parentId: parentNodeId,
                eLibraryNodeType: LibraryNodeType.File
            };
            add(tabledHelper.createDisplayedData<Library, LibraryFileData>(newNode, file));
        });
    };

    // @ts-ignore
    return (
        <Box className="container" sx={{ mt: CONTAINER_TOP_MARGIN }}>
            <UIText sx={{ fontSize: 18, fontWeight: 'bold', mb: 1 }} text={title} />

            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}>
                {({ values, isValid, dirty, isSubmitting }) => {
                    return (
                        <Form>
                            <FieldArray
                                name="nodes"
                                render={({ push, remove, replace }) => (
                                    <>
                                        <UIDropzone
                                            handleUploadFiles={handleAdd(push)}
                                            accept={
                                                [
                                                    ...(FilesMimeTypes.Word || []),
                                                    ...(FilesMimeTypes.Powerpoint || []),
                                                    ...(FilesMimeTypes.PowerpointTemplate || []),
                                                    ...(FilesMimeTypes.Excel || []),
                                                    ...(FilesMimeTypes.Pictures || [])
                                                ] as string[]
                                            }
                                            dropzoneDragText={getLocalizedString(
                                                intl,
                                                'LIBRARY.FIELD.FILE.DROPZONE.DRAG.MESSAGE'
                                            )}
                                            dropzoneDefaultText={getLocalizedString(
                                                intl,
                                                'LIBRARY.FIELD.FILE.DROPZONE.DEFAULT.MESSAGE'
                                            )}
                                        />

                                        <UITableToolbar
                                            numSelected={selected.length}
                                            handleSetDocumentType={newContentType =>
                                                handleSetDocumentType(replace, values.nodes, newContentType.id)
                                            }
                                            handleDelete={async () => await handleRemove(remove, values.nodes)}
                                        />

                                        <Paper sx={{ width: '100%', mb: 2 }} elevation={3}>
                                            <TableContainer>
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    size="medium">
                                                    <UITableHead
                                                        selectionProps={{
                                                            numSelected: selected.length,
                                                            onSelectAllClick: handleSelectAllClick(values.nodes)
                                                        }}
                                                        headCells={headCells}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={values.nodes.length}
                                                    />

                                                    <TableBody>
                                                        <>
                                                            {values.nodes &&
                                                                values.nodes.length > 0 &&
                                                                values.nodes
                                                                    .slice(
                                                                        page * rowsPerPage,
                                                                        page * rowsPerPage + rowsPerPage
                                                                    )
                                                                    .map((node: LibraryFileData, rawIndex: number) => {
                                                                        const index = rawIndex + page * rowsPerPage;
                                                                        const isItemSelected =
                                                                            tabledHelper.isSelectedEntry(
                                                                                selected,
                                                                                node.name
                                                                            );

                                                                        return (
                                                                            <LibraryTableRow
                                                                                key={node.id ?? node.name}
                                                                                fieldsProps={{
                                                                                    documentType: `nodes.${index}.eDocumentType`,
                                                                                    name: `nodes.${index}.name`
                                                                                }}
                                                                                node={node}
                                                                                index={index}
                                                                                isSelected={isItemSelected}
                                                                                handleRowClick={handleClick}
                                                                            />
                                                                        );
                                                                    })}
                                                        </>
                                                    </TableBody>
                                                </Table>
                                                {emptyRows(values.nodes) > 0 ? (
                                                    <TableRow
                                                        style={{
                                                            height: 53 * emptyRows(values.nodes)
                                                        }}>
                                                        <TableCell colSpan={6}></TableCell>
                                                    </TableRow>
                                                ) : (
                                                    <UIText
                                                        text={getLocalizedString(intl, 'COMMON.TABLE.NO.RECORDS')}
                                                        sx={{ textAlign: 'center' }}
                                                    />
                                                )}
                                            </TableContainer>

                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={values.nodes.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    </>
                                )}
                            />

                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                <UIActionButton
                                    variant="outlined"
                                    disabled={!isValid || isSubmitting || !dirty}
                                    type="submit"
                                    size="large"
                                    fullWidth
                                    sx={{ my: 5, mt: 5, width: 'auto' }}
                                    title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                                />
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};
