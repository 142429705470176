import React, { useContext } from 'react';
import { AppContext } from '../../../../context/AppContext';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { BrandingResource, BrandingResourcesRepositorySpec } from '../../../../domain';
import { UIContentScaffold } from '../../common/UIContentScaffold';
import { BrandingResourcesArea } from './BrandingResourcesArea';

export interface BrandResourcesContentContentProps {
    brandingResourcesRepository: BrandingResourcesRepositorySpec;
    isEditable: boolean;
}

export const BrandResourcesContent = ({
                                          isEditable,
                                          brandingResourcesRepository,
                                          setLoading,
                                          setSessionExpired,
                                          setToast
                                      }: BrandResourcesContentContentProps & UIScaffoldChildContext) => {

    const { sessionInfo } = useContext(AppContext);

    const getBrandingResourceImage = async (brandingResource: BrandingResource): Promise<string> => {
        setLoading(true);
        const blobResult = await brandingResourcesRepository.fetchFile(brandingResource.id, sessionInfo);
        const imageObjectURL = blobResult ? URL.createObjectURL(blobResult) : ''; // todo manage undefined
        setLoading(false);
        return imageObjectURL;
    };

    return <UIContentScaffold  successMessagePrefix='RESOURCES.BRANDING_RESOURCES'
                              repository={brandingResourcesRepository} setToast={setToast} setLoading={setLoading}
                              setSessionExpired={setSessionExpired}>
        {({ data, handleCreate, handleDelete, handleUpdate }) => (
            <BrandingResourcesArea brandResources={data as BrandingResource[]}
                                   actionsHidden={!isEditable}
                                   handleDelete={handleDelete}
                                   handleUpdate={handleUpdate}
                                   handleCreate={handleCreate} fetchResourceImage={getBrandingResourceImage} />
        )
        }
    </UIContentScaffold>;
};


