import React, { useContext, useEffect } from 'react';
import { UserArea } from './UserArea';
import { User, UserRepositorySpec } from '../../../../domain';
import { Box } from '@mui/material';
import { Palette } from '../../../../res/colors';
import { useRepository } from '../../../../hooks/useRepository';
import { useIntl } from 'react-intl';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { CONTAINER_TOP_MARGIN } from '../../../../res/dimensions';
import { AppContext } from '../../../../context/AppContext';
import { useDispatch } from 'react-redux';
import { setUserInfo } from '../../../../store/modules/user';

export interface OverviewContainerProps {
    devices: string[]; // from where to get devices
    userRepository: UserRepositorySpec;
}

export const OverviewContainer = ({
                                      userRepository,
                                      setLoading,
                                      setSessionExpired,
                                      setToast
                                  }: OverviewContainerProps & UIScaffoldChildContext) => {

    const { sessionInfo, currentUser, currentUserRoles } = useContext(AppContext);

    const intl = useIntl();
    const dispatch = useDispatch();

    const repositoryManager = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl,

        () => userRepository.fetch(currentUser.email, sessionInfo)
    );

    const userInfo: User = repositoryManager.data as User;

    useEffect(() => {
        dispatch(setUserInfo(userInfo));
    }, [userInfo]);

    return (
        <Box sx={styles.container}>
            <UserArea user={userInfo} style={styles.title} userRoles={currentUserRoles} />
            {/*<DevicesArea devices={[]} style={styles.title} />*/}
        </Box>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
        margin: CONTAINER_TOP_MARGIN,
        gap: 1
    },
    title: {
        color: Palette.defaultPrimaryColor
    }
} as const;

