export { RepositoryProvider } from './RepositoryProvider';
export { HttpRoutes } from './HttpRoutes';
export type { RepositorySpec, MazarsErrorSpec } from './Repository';

export type { TheGateAuthResponse } from './authentication/model/TheGateAuthResponse';
export { initAuthInfo } from './authentication/model/initAuthInfo';
export type { AuthSessionInfoSpec } from './authentication/model/initAuthInfo';
export type { AuthRepositorySpec } from './authentication/AuthRepository';
export { AuthRepository } from './authentication/AuthRepository';

export { signIn } from './authentication/AuthService';

export type { LibraryRepositorySpec } from './library/LibraryRepository';
export { LibraryRepository } from './library/LibraryRepository';
export type { Library } from './library/model/Library';
export { initLibraryInfo, LibraryNodeDocumentTypeOptions } from './library/model/initLibrary';

export type { LibraryNodeFileTypeSpec, LibraryFileData, LibraryNodeSpec } from './library/model/initLibrary';

export { OrganizationRepository } from './organizations/OrganizationRepository';
export type { OrganizationRepositorySpec } from './organizations/OrganizationRepository';
export type { Organization } from './organizations/model/Organization';
export { initOrganization } from './organizations/model/initOrganization';

export { UserResourceRepository } from './help/UserResourceRepository';
export type { UserResourceRepositorySpec } from './help/UserResourceRepository';
export type { UserResource } from './help/model/UserResource';

export { OrganizationRolesRepository } from './roles/OrganizationRolesRepository';
export type { OrganizationRolesRepositorySpec } from './roles/OrganizationRolesRepository';
export { RoleCategory } from './roles/model/initRole';

export type { UserRepositorySpec } from './user/UserRepository';
export { UserRepository } from './user/UserRepository';
export type { User } from './user/model/User';

export { LinksRepository } from './resources/links/LinksRepository';
export type { LinksRepositorySpec } from './resources/links/LinksRepository';

export type { Link } from './resources/links/model/Link';
export { initLinkInfo } from './resources/links/model/initLink';
export type { LinkDataSpec } from './resources/links/model/initLink';
export type { LanguagePack } from './resources/languagePack/model/LanguagePack';
export { LanguagePackRepository } from './resources/languagePack/LanguagePackRepository';
export type { LanguagePackRepositorySpec } from './resources/languagePack/LanguagePackRepository';
export type { BaseResource } from './resources/common/BaseResource';
export type { UserGuide } from './resources/userGuide/model/UserGuide';
export { UserGuideRepository } from './resources/userGuide/UserGuideRepository';
export type { UserGuideRepositorySpec } from './resources/userGuide/UserGuideRepository';

export { ColorShortcutRepository } from './resources/colorShortcuts/ColorShortcutRepository';
export type { ColorShortcutRepositorySpec } from './resources/colorShortcuts/ColorShortcutRepository';
export type { ColorShortcut, ColorShortcutDataSpec } from './resources/colorShortcuts/model/ColorShortcut';

export { BrandingResourcesRepository } from './resources/brandingResources/BrandingResourcesRepository';
export type { BrandingResourcesRepositorySpec } from './resources/brandingResources/BrandingResourcesRepository';
export type { BrandingResource, BrandingResourceDataSpec } from './resources/brandingResources/model/BrandingResource';

export type { FeedbackConfig, FeedbackConfigSpec } from './configuration/feedbackConfig/model/FeedbackConfig';
export type { FeedbackRepositorySpec } from './configuration/feedbackConfig/FeedbackConfigRepository';
export { FeedbackRepository } from './configuration/feedbackConfig/FeedbackConfigRepository';

export type { LearningLink } from './configuration/learningLink/model/LearningLink';
export type { LearningLinkRepositorySpec } from './configuration/learningLink/LearningLinkRepository';
export { LearningLinkRepository } from './configuration/learningLink/LearningLinkRepository';

export type { AgendaDesignSpec, AgendaDesign } from './configuration/agendaDesign/model/AgendaDesign';
export type { AgendaDesignRepositorySpec } from './configuration/agendaDesign/AgendaDesignRepository';
export { AgendaDesignRepository } from './configuration/agendaDesign/AgendaDesignRepository';

export { apiDefaultOptions } from './Repository';

export { MasterOrganizationRepository } from './organizations/MasterOrganizationRepository';
export type { MasterOrganizationRepositorySpec } from './organizations/MasterOrganizationRepository';

export { initUser, initMasterOrganizationUserDataSpec, initOrganizationUserDataSpec } from './user/model/initUser';
export type {
    UserMasterOrganizationRole,
    UserOrganizationRole,
    EMasterOrganizationRole,
    EOrganizationRole
} from './roles/model/UserOrganizationRole';

export type { CredentialsPacksRepositorySpec } from './credentials/CredentialsPacksRepository';
export { CredentialsPacksRepository } from './credentials/CredentialsPacksRepository';

export type { MacroPacksRepositorySpec } from './macroPack/MacroPacksRepository';
export { MacroPacksRepository } from './macroPack/MacroPacksRepository';
