import React, { useEffect } from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { UIErrorMessage } from '../UIErrorMessage';
import { UIToggleButtons, UIToggleButtonsProps } from '../UIToggleButtons';

export const UIToggleButtonsField = <T extends unknown>({
                                                            disabled,
                                                            value,
                                                            id,
                                                            options,
                                                            name
                                                        }: UIToggleButtonsProps<T>) => {


    const [selected, setValue] = React.useState<T | undefined | null>(value);

    useEffect(() => {
        setValue(value);
    }, [value]);

    const { setFieldValue } = useFormikContext();
    const onChange = (
        event: React.MouseEvent<HTMLElement>,
        newItem: T
    ) => {
        event.preventDefault();
        event.stopPropagation();
        setValue(newItem);
        setFieldValue(id, newItem);
    };

    return <>
        <Field
            disabled={disabled}
            id={id}
            name={name}
            component={UIToggleButtons}
            color='primary'
            value={selected}
            exclusive
            onChange={onChange}
            options={options}
        />

        <ErrorMessage name={name}
                      render={errorMessage => <UIErrorMessage message={errorMessage} />} />
    </>;
};
