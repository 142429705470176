import { UITextField } from '../common/fields/UITextField';
import { Box, IconButton, Paper } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { resourcesStyles } from '../resources';
import { Palette } from '../../../res/colors';
import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { useIntl } from 'react-intl';

type MacroNodeFieldsProps = {
    isEditable: boolean;
    nodeIndex: number;
    isLast: boolean;
    isDeletable: boolean;
    handleRemove: (_: number) => void;
    handleAdd: () => void;
};
const MacroNodeFieldsLocal: React.FC<MacroNodeFieldsProps> = ({
    nodeIndex: index,
    isEditable,
    isLast,
    isDeletable,
    handleRemove,
    handleAdd
}) => {
    const intl = useIntl();
    const classes = useStyles();

    const fields = useMemo(
        () => [
            {
                id: `macros.${index}.name`,
                name: `macros.${index}.name`,
                placeholder: getLocalizedString(intl, 'MACROS.FORM.NAME.FIELD.PLACEHOLDER'),
                fieldType: 'textFieldItem',
                variant: 'standard',
                className: classes.textField,
                disabled: !isEditable
            },
            {
                id: `macros.${index}.title`,
                name: `macros.${index}.title`,
                placeholder: getLocalizedString(intl, 'MACROS.FORM.TITLE.FIELD.PLACEHOLDER'),
                fieldType: 'textFieldItem',
                variant: 'standard',
                className: classes.textField,
                disabled: !isEditable
            },
            {
                id: `macros.${index}.description`,
                name: `macros.${index}.description`,
                placeholder: getLocalizedString(intl, 'MACROS.FORM.DESCRIPTION.FIELD.PLACEHOLDER'),
                fieldType: 'textFieldItem',
                variant: 'standard',
                className: classes.textField,
                disabled: !isEditable
            }
        ],
        [index, intl, classes.textField, isEditable]
    );

    const onRemove = useCallback(() => {
        handleRemove(index);
    }, [handleRemove, index]);

    return (
        <Paper elevation={1} sx={sx.content(isEditable)}>
            {fields.map(field => (
                <UITextField key={field.id} {...field} />
            ))}

            {isEditable && (
                <Box sx={sx.iconsContainer}>
                    {isDeletable && (
                        <IconButton onClick={onRemove}>
                            <Delete
                                style={{
                                    ...resourcesStyles.image('small'),
                                    color: Palette.red
                                }}
                            />
                        </IconButton>
                    )}

                    {isLast && (
                        <IconButton onClick={handleAdd}>
                            <Add
                                style={{
                                    ...resourcesStyles.image('small'),
                                    color: Palette.darkPrimaryColor
                                }}
                            />
                        </IconButton>
                    )}
                </Box>
            )}
        </Paper>
    );
};

const useStyles = makeStyles({
    textField: {
        width: '80%'
    }
});

const sx = {
    iconsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    content: (isEditable: boolean) => ({
        padding: 2,
        flexDirection: 'column',
        display: 'grid',
        gap: 1,
        gridTemplateColumns: isEditable ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)',
        mt: 1,
        alignItems: 'center'
    })
} as const;

export const MacroNodeFields = React.memo(MacroNodeFieldsLocal);
