import React, { useContext, useEffect, useState } from 'react';
import { MacroPacksRepositorySpec } from '../../../domain';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../context/AppContext';
import { useRepository } from '../../../hooks/useRepository';
import { getMasterOrganizationId, getOrganizationIdOrNull } from '../../../utils/organizationHelper';
import { Box } from '@mui/material';
import { resourcesStyles } from '../resources';
import { MacroPack } from '../../../domain/macroPack/model/MacroPack';
import { UIScaffoldChildContext } from '../../scaffolder/UIScaffold';
import { APIScaffoldChildContext } from '../../scaffolder/APIScaffold';
import { MacroPacksArea } from './MacroPacksArea';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { MacroPackFileData } from '../../../domain/macroPack/model/initMacroPack';
import { serializeUpdateMacroPacks } from '../../../utils/serializer';

type MacroPacksContentProps = {
    macroPacksRepository: MacroPacksRepositorySpec;
    isEditable: boolean;
};
export const MacroPacksContent: React.FC<MacroPacksContentProps & UIScaffoldChildContext & APIScaffoldChildContext> = ({
    macroPacksRepository,
    isEditable,
    cancelRequest,
    setLoading,
    setSessionExpired,
    setToast
}) => {
    const intl = useIntl();
    const [packs, setPacks] = useState<MacroPack[] | null>(null);

    const { sessionInfo, currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const fetchContent = async () => {
        await callRepository(
            () =>
                macroPacksRepository.fetchAll(
                    sessionInfo,
                    getMasterOrganizationId(currentOrganization),
                    getOrganizationIdOrNull(currentOrganization, isCurrentOrganizationMaster)
                ),
            data => {
                const files: MacroPack[] = data as MacroPack[];
                setPacks(files);
            }
        );
    };

    useEffect(() => {
        (async () => await fetchContent())();

        return () => {
            cancelRequest(macroPacksRepository);
        };
    }, [currentOrganization?.id]);

    const handlePreviewFile = async (pack: MacroPack) => {
        const nodeId = pack.id;
        if (!nodeId) {
            //TODO throw
            return;
        }

        let credentialPackFile: Blob | undefined = undefined;

        await callRepository(
            () => macroPacksRepository.fetchFile(nodeId, sessionInfo),
            data => {
                credentialPackFile = data;
            }
        );

        return credentialPackFile;
    };

    const handleUpdate = async (packs: MacroPackFileData[]): Promise<boolean> => {
        const packData = await serializeUpdateMacroPacks(packs);

        let isSuccess = false;
        await callRepository(
            () => macroPacksRepository.update(packData, sessionInfo),
            async () => {
                isSuccess = true;
                setToast(getLocalizedString(intl, 'MACROS.UPDATE.SUCCESS.MESSAGE'), 'success');
                await fetchContent();
            }
        );

        return isSuccess;
    };

    const handleDelete = async (resourcesIds: string[]) => {
        let isSuccess = false;
        await callRepository(
            () => macroPacksRepository.delete(resourcesIds, sessionInfo),
            async _ => {
                setToast(getLocalizedString(intl, 'MACROS.DELETE.SUCCESS.MESSAGE'), 'success');
                isSuccess = true;
                await fetchContent();
            }
        );

        return isSuccess;
    };

    const handleError = (error: string) => {
        setToast(error, 'error');
    };

    return (
        <Box sx={resourcesStyles.container}>
            <MacroPacksArea
                handleError={handleError}
                packs={packs || []}
                isEditable={isEditable}
                handlePreview={handlePreviewFile}
                handleEdit={handleUpdate}
                handleDelete={handleDelete}
            />
        </Box>
    );
};
