import React from 'react';
import { UIScaffold } from '../scaffolder/UIScaffold';
import { LoginScreenContent } from '../components/login/LoginContent';
import { APIScaffold } from '../scaffolder/APIScaffold';
import { AuthSessionInfoSpec, Organization, RepositoryProvider, User } from '../../domain';
import { useNavigate } from 'react-router-dom';
import { NavRoutes } from '../../navigation/NavRoutes';

import { useDispatch } from 'react-redux';
import { setCurrentOrganization, setSessionInfo, setUserInfo, setUserRoles } from '../../store/modules/user';
import AuthSessionService from '../../services/AuthSessionService';

export const LoginScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onLoginSuccess = (sessionInfo: AuthSessionInfoSpec, userInfo: User, currentOrganization: Organization) => {
        dispatch(setUserInfo(userInfo));
        dispatch(setUserRoles(userInfo.organizationRoles));
        dispatch(setSessionInfo(sessionInfo, 'theGate')); //TODO don"t hard code
        AuthSessionService.setLocalSessionInfo(sessionInfo);
        dispatch(setCurrentOrganization(currentOrganization));

        navigate(NavRoutes.Home);
    };

    return (
        <APIScaffold>
            {({ cancelRequest }) => (
                <UIScaffold>
                    {({ setLoading, setSessionExpired, setToast }) => (
                        <LoginScreenContent
                            onLoginSuccess={onLoginSuccess}
                            userRepository={RepositoryProvider.getProvider().userRepository}
                            organizationsRepository={RepositoryProvider.getProvider().organizationRepository}
                            cancelRequest={cancelRequest}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            setToast={setToast}
                        />
                    )}
                </UIScaffold>
            )}
        </APIScaffold>
    );
};
