import React from 'react';
import { RepositorySpec } from '../../domain';
export interface APIScaffoldChildContext {
    cancelRequest: (repository: RepositorySpec) => void;
}

interface APIScaffoldProps {
    children: (context: APIScaffoldChildContext) => React.ReactNode;
}

export const APIScaffold = ({ children }: APIScaffoldProps) => {

    const cancelRequest = (repository: RepositorySpec) => {
       // repository && repository.datasource.remote.cancel();
    };

    return (<>
        {children({ cancelRequest })}
    </>);

};

