import { createContext } from 'react';
import {
    AuthSessionInfoSpec,
    initAuthInfo,
    initOrganization,
    initUser,
    Organization,
    User,
    UserOrganizationRole
} from '../domain';
import { RoleLevel } from '../types/types';
import { AccessMode } from '../domain/roles/model/initRole';

interface AppContextSpec {
    sessionInfo: AuthSessionInfoSpec;
    currentUser: User;
    currentOrganization: Organization;
    isCurrentOrganizationMaster: boolean;
    currentUserRoles: UserOrganizationRole[];
    isEditableAtOrganizationLevel: boolean;
    isEditableAtMasterOrganization: boolean;
    accessData: { roleLevel: RoleLevel; accessMode: AccessMode };
}

const appContextDefaultValue: AppContextSpec = {
    isCurrentOrganizationMaster: false,
    sessionInfo: initAuthInfo,
    currentUser: initUser,
    currentOrganization: initOrganization,
    currentUserRoles: [],
    isEditableAtOrganizationLevel: false,
    isEditableAtMasterOrganization: false,
    accessData: { roleLevel: 'standard', accessMode: 'Other' }
};

export const AppContext = createContext(appContextDefaultValue);
