import * as React from 'react';
import { useEffect, useState } from 'react';
import { BrandingResource } from '../../../../domain';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ImageListItem from '@mui/material/ImageListItem';
import { BrandingResourceTypes } from '../../../../types/types';
import { Palette } from '../../../../res/colors';
import { makeStyles } from '@mui/styles';
import { useOverridableView } from '../../../../hooks/useOverridableView';

interface BrandingResourceImageProps {
    resource: BrandingResource;
    fetchResourceImage: (resource: BrandingResource) => Promise<string>;
}

const BrandingResourceImage = ({ resource, fetchResourceImage }: BrandingResourceImageProps) => {
    const [imageData, setImageData] = useState('');
    const classes = useStyles();
    useEffect(() => {
        fetchResourceImage(resource).then((localUrl: string) => {
            setImageData(localUrl);
        });
    }, [resource]);

    return <img alt={resource.eBrandingResourceType + ''} className={classes.logo} src={imageData} />;
};

export interface BrandingResourceTileProps {
    resource: BrandingResource;
    fetchResourceImage: (resource: BrandingResource) => Promise<string>;
    onEditAsked: (resource: BrandingResource) => void;
    onDelete: (ids: string[]) => void;
}

export const BrandingResourceTile = ({
    resource,
    fetchResourceImage,
    onDelete,
    onEditAsked
}: BrandingResourceTileProps) => {
    const {
        backgroundColor,
        title: subtitle,
        actions
    } = useOverridableView({
        item: resource,
        onDelete,
        onEditAsked
    });

    const title = BrandingResourceTypes.find(r => r.id === resource.eBrandingResourceType)?.name;

    return (
        <>
            <ImageListItem key={resource.id}>
                <BrandingResourceImage resource={resource} fetchResourceImage={fetchResourceImage} />

                <ImageListItemBar
                    sx={{ backgroundColor: backgroundColor, color: Palette.white }}
                    title={title}
                    subtitle={subtitle}
                    actionIcon={actions}
                />
            </ImageListItem>
        </>
    );
};

const useStyles = makeStyles({
    logo: {
        alignSelf: 'center',
        maxWidth: '80%',
        maxHeight: '50%'
    }
});
