import React, { useContext } from 'react';
import { APIScaffold } from '../scaffolder/APIScaffold';
import { UIScaffold } from '../scaffolder/UIScaffold';
import { RepositoryProvider } from '../../domain';
import { UsersRolesContent } from '../components/usersRoles/UsersRolesContent';
import { AppContext } from '../../context/AppContext';

export const UsersRolesScreen = () => {
    const { isCurrentOrganizationMaster } = useContext(AppContext);

    const repository = isCurrentOrganizationMaster
        ? RepositoryProvider.getProvider().masterOrganizationRolesRepository
        : RepositoryProvider.getProvider().organizationRolesRepository;

    return (
        <APIScaffold>
            {({ cancelRequest }) => (
                <UIScaffold>
                    {props => (
                        <UsersRolesContent {...props} cancelRequest={cancelRequest} rolesRepository={repository} />
                    )}
                </UIScaffold>
            )}
        </APIScaffold>
    );
};
