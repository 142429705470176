import React, { useContext } from 'react';
import { AgendaDesign, AgendaDesignRepositorySpec } from '../../../../domain';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { UIContentScaffold } from '../../common/UIContentScaffold';
import { AgendaDesignArea } from './AgendaDesignArea';
import { saveDownloadedFile } from '../../../../utils/fileHelper';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useRepository } from '../../../../hooks/useRepository';
import { useIntl } from 'react-intl';
import { AppContext } from '../../../../context/AppContext';

interface AgendaDesignContentProps {
    isEditable: boolean;
    agendaDesignRepository: AgendaDesignRepositorySpec;
}

export const AgendaDesignContent = ({
    agendaDesignRepository,
    isEditable,
    setSessionExpired,
    setToast,
    setLoading
}: AgendaDesignContentProps & UIScaffoldChildContext) => {
    const { currentOrganization, sessionInfo, isCurrentOrganizationMaster } = useContext(AppContext);
    const intl = useIntl();

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const handleDownload = async (agenda: AgendaDesign): Promise<boolean> => {
        let isSuccess = false;
        await callRepository(
            () => agendaDesignRepository.fetchFile(agenda.id, sessionInfo),
            data => {
                saveDownloadedFile(
                    data,
                    getLocalizedString(intl, `${currentOrganization.name}-AgendaDesign${agenda.fileExtension}`)
                );
                isSuccess = true;
            }
        );

        return isSuccess;
    };

    const fetchAgendaFile = async (agenda: AgendaDesign): Promise<Blob | null> => {
        let fileData: Blob | null = null;
        await callRepository(
            () => agendaDesignRepository.fetchFile(agenda.id, sessionInfo),
            data => {
                fileData = data || null;
            }
        );

        return fileData;
    };

    return (
        <UIContentScaffold
            successMessagePrefix="CONFIGURATION.AGENDA_DESIGN"
            repository={agendaDesignRepository}
            setToast={setToast}
            setLoading={setLoading}
            setSessionExpired={setSessionExpired}>
            {({ data, handleCreate, handleDelete, handleUpdate }) => (
                <AgendaDesignArea
                    actionsHidden={!isEditable}
                    agendaDesigns={data as AgendaDesign[]}
                    fetchAgendaFile={fetchAgendaFile}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                    handleCreate={handleCreate}
                    handleDownload={handleDownload}
                />
            )}
        </UIContentScaffold>
    );
};
