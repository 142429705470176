import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { Paper } from '@mui/material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { CredentialPackFileData } from '../../../domain/credentials/model/initCredentialPack';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { useForm } from '../../../hooks/useForm';

export interface CredentialPackDetailsProps {
    isEditable: boolean;
    formRef: any;
    pack: CredentialPackFileData;
    handleUpdate: (pack: CredentialPackFileData) => void;
}

export const CredentialPackDetails = ({ isEditable, handleUpdate, pack, formRef }: CredentialPackDetailsProps) => {
    const classes = useStyles();

    const intl = useIntl();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('CREDENTIALS.FORM.VALIDATION.NAME.REQUIRED'),
        description: Yup.string().nullable()
    });

    const fields = [
        {
            id: 'name',
            name: 'name',
            placeholder: getLocalizedString(intl, 'CREDENTIALS.FORM.NAME.FIELD.PLACEHOLDER'),
            fieldType: 'textFieldItem',
            variant: 'standard',
            className: classes.textField,
            disabled: !isEditable
        },

        {
            id: 'description',
            name: 'description',
            placeholder: getLocalizedString(intl, 'CREDENTIALS.FORM.DESCRIPTION.FIELD.PLACEHOLDER'),
            className: classes.textField,
            fieldType: 'textFieldItem',
            component: 'textarea',
            rows: 5,
            variant: 'outlined',
            disabled: !isEditable
        }
    ];

    const { RenderForm } = useForm({
        initialValues: pack,
        onSubmit: handleUpdate,
        validationSchema: validationSchema
    });

    return (
        <Paper elevation={3} sx={sx.container}>
            <RenderForm innerFormRef={formRef} formFields={fields} sx={sx.content} />
        </Paper>
    );
};

const useStyles = makeStyles({
    textField: {
        width: '80%'
    }
});

const sx = {
    container: {
        m: 2,
        p: 2
    },
    content: {
        flexDirection: 'column',
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'repeat(2, 1fr)',
        mt: 1,
        alignItems: 'center'
    }
} as const;
