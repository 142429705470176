import { UISectionTitle } from './UISectionTitle';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { UIActionButton } from './UIActionButton';
import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { UIText } from './UIText';

export interface UISectionHeaderProps {
    onCreate?: (event: React.SyntheticEvent) => void | (() => void);
    isCreateEnabled?: boolean;
    title: string;
    subtitle?: string;
    titleProps?: any;
}

export const UISectionHeader = ({
    isCreateEnabled = false,
    onCreate,
    title,
    subtitle,
    titleProps = {}
}: UISectionHeaderProps) => {
    const intl = useIntl();

    return (
        <>
            <Box sx={{ direction: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <UISectionTitle title={title} {...titleProps} />
                {isCreateEnabled && onCreate && (
                    <UIActionButton
                        title={getLocalizedString(intl, 'COMMON.ADD.TITLE')}
                        endIcon={<Add />}
                        onClick={onCreate}
                    />
                )}
            </Box>
            {subtitle && <UIText text={subtitle} />}
        </>
    );
};
