import React from 'react';
import { CircularProgress, Paper, Stack } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { UIText } from './UIText';

interface UILoaderProps {
    text?: string;
    color?: any;
}

export const UILoader = ({ text, color = 'primary' }: UILoaderProps) => {
    const classes = useStyles();
    return (
        <Paper component={Stack} className={classes.container}
               style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <>
                <CircularProgress color={color} />
                <UIText text={text} />
            </>
        </Paper>

    );
};

const useStyles: any = makeStyles(() =>
    createStyles({
        container: {
            flex: '1',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center'
        }
    })
);