import { removeCurrentOrganization, removeSessionInfo, removeUserInfo } from '../../../store/modules/user';
import { useDispatch } from 'react-redux';
import { UIText } from '../common/UIText';
import { useIntl } from 'react-intl';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { UIActionButton } from '../common/UIActionButton';
import { Box } from '@mui/material';

export const ExpiredSessionComponent = () => {
    const intl = useIntl();

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(removeSessionInfo());
        dispatch(removeUserInfo());
        dispatch(removeCurrentOrganization());
    };

    return <>
        <UIText text={getLocalizedString(intl, 'INVALID.SESSION.MESSAGE')} />
        <Box sx={{ mt: 10, justifyContent: 'center', display: 'flex' }}>
            <UIActionButton title={getLocalizedString(intl, 'COMMON.ACTIONS.LOGOUT')} onClick={handleLogout} />
        </Box>
    </>;
};