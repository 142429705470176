import { AgendaDesign, BrandingResource, FeedbackConfig, LearningLink } from '../domain';
import { EBrandingResourceType, EmptyGuid } from '../types/types';

type DataType = FeedbackConfig | LearningLink | BrandingResource | AgendaDesign;

export const isOverridable = (item: DataType) => item.organizationId === EmptyGuid;

export const getOverriddenResource = (
    eBrandingResourceType: EBrandingResourceType,
    allResources: BrandingResource[]
): BrandingResource | undefined => {
    const existingResource = allResources.find(r => r.eBrandingResourceType === eBrandingResourceType);
    return existingResource && !isOverridable(existingResource) ? existingResource : undefined;
};

export const isOverriddenResource = (
    eBrandingResourceType: EBrandingResourceType | undefined,
    allResources: BrandingResource[]
): boolean => {
    return eBrandingResourceType ? getOverriddenResource(eBrandingResourceType, allResources) !== undefined : false;
};

export const getOverriddenAgenda = (
    version: string | undefined,
    allAgendas: AgendaDesign[]
): AgendaDesign | undefined => {
    const existingAgenda = allAgendas.find(r => String(r.version) === String(version));
    return existingAgenda && !isOverridable(existingAgenda) ? existingAgenda : undefined;
};

export const isOverriddenAgenda = (version: string | undefined, allAgendas: AgendaDesign[]): boolean => {
    return version ? getOverriddenAgenda(version, allAgendas) !== undefined : false;
};
