import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import { TableCellInputField } from '../../common/TableCellInputField';
import { UISelectField } from '../../common/fields/UISelectField';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LibraryFileData, LibraryNodeDocumentTypeOptions, LibraryNodeFileTypeSpec } from '../../../../domain';

interface LibraryNodeRowProps {
    disabled?: boolean;
    node: LibraryFileData;
    index: number;
    isSelected: boolean;
    handleRowClick: (event: React.MouseEvent<unknown>, name: string) => void;
    fieldsProps: { documentType: string; name: string };
}

export const LibraryTableRow = ({
    node,
    isSelected,
    index,
    handleRowClick,
    fieldsProps,
    disabled = false
}: LibraryNodeRowProps) => {
    const intl = useIntl();
    const labelId = `enhanced-table-checkbox-${index}`;

    const [documentOptions, setDocumentOptions] = useState<LibraryNodeFileTypeSpec[]>(LibraryNodeDocumentTypeOptions);

    useEffect(() => {
        console.log(node.file?.type);
        node.fileExtension || node.file?.type
            ? setDocumentOptions(
                  LibraryNodeDocumentTypeOptions.filter(
                      a =>
                          a.acceptedFiles?.includes(node.fileExtension || '') ||
                          a.acceptedFiles?.includes(node.file?.type || '')
                  )
              )
            : setDocumentOptions(LibraryNodeDocumentTypeOptions);
    }, [node.fileExtension]);

    return (
        <TableRow
            hover
            onClick={event => handleRowClick(event, node.name)}
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={-1}
            key={node.name}
            selected={isSelected}>
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isSelected}
                    inputProps={{
                        'aria-labelledby': labelId
                    }}
                />
            </TableCell>

            <TableCell component="th" id={labelId} scope="row" padding="none">
                <TableCellInputField
                    disabled={disabled}
                    id={fieldsProps.name}
                    name={fieldsProps.name}
                    value={node.name}
                    extension={node.fileExtension}
                />
            </TableCell>
            <TableCell
                align="right"
                sx={{
                    paddingY: 1,
                    paddingX: 5
                }}>
                <UISelectField
                    disabled={disabled}
                    getOptionLabel={item => getLocalizedString(intl, item.name)}
                    value={node.eDocumentType}
                    name={fieldsProps.documentType}
                    id={fieldsProps.documentType}
                    placeholder={getLocalizedString(intl, 'LIBRARY.CONTENT_TYPES.FIELD.PLACEHOLDER')}
                    options={documentOptions}
                />
            </TableCell>
        </TableRow>
    );
};
