import { AgendaDesign, AgendaDesignSpec, AuthSessionInfoSpec, HttpRoutes, RepositorySpec } from '../..';

import { HttpResponse } from '../../mazars-network-http-client';
import { Repository } from '../../Repository';
import { HttpContentType, OrganizationParams } from '../../../types/types';
import { resourcesHelper } from '../../utils/resourcesHelper';

export interface AgendaDesignRepositorySpec extends RepositorySpec {
    /// Fetch the agenda design of the given organization
    fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<AgendaDesign[]>;

    create(agendaDesign: AgendaDesignSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    update(agendaDesign: AgendaDesignSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    delete(ids: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]>;

    /// Fetch the file of the given agenda design
    fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined>;
}

export class AgendaDesignRepository extends Repository implements AgendaDesignRepositorySpec {
    async fetch(params: OrganizationParams, sessionInfo: AuthSessionInfoSpec): Promise<AgendaDesign[]> {
        const response = (await this.datasource.remote.get(
            HttpRoutes.AgendaDesignList,
            sessionInfo.accessToken,
            undefined,
            params,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<AgendaDesign[], any>;

        return response.data || [];
    }

    async create(agendaDesign: AgendaDesignSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = resourcesHelper.prepareAgendaDesignRequestBody(agendaDesign);

        const response = (await this.datasource.remote.post(
            HttpRoutes.AgendaDesigns,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async update(agendaDesign: AgendaDesignSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = resourcesHelper.prepareAgendaDesignRequestBody(agendaDesign);
        const response = (await this.datasource.remote.put(
            HttpRoutes.AgendaDesigns,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async delete(ids: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.AgendaDesigns,
            ids,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined> {
        const path = HttpRoutes.AgendaDesignFile.replace(':id', id);
        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<Blob, any>;

        return response.data;
    }
}
