export enum HttpRoutes {
    TheGateLogin = 'user/login',
    MazarsWebLogin = 'auth/login/mazars-auth-api/web',
    AuthRefresh = 'auth/refresh/web',
    UserInfo = 'users',
    Organizations = 'organizations',
    Organization = 'organizations/:id',
    MasterOrganizations = 'MasterOrganizations/:id/organizations',
    GetMasterOrganizations = 'MasterOrganizations',
    Library = 'library',
    LibraryNode = 'library/:id',
    LibraryFile = 'library/:id/file',
    MasterLibrary = 'masterLibrary',
    MasterLibraryNode = 'masterLibrary/:id',
    MasterLibraryFile = 'masterLibrary/:id/file',
    OrganizationRoles = 'OrganizationRoles',
    OrganizationRolesEnum = 'enumerations/EOrganizationRoles',
    MasterOrganizationRoles = 'MasterOrganizationRoles',
    MasterOrganizationRolesEnum = 'enumerations/EMasterOrganizationRoles',
    SpecialUsers = 'organizationRoles/organization/:organizationId/SpecialUsers',
    Links = 'links',
    MasterLinks = 'masterLinks',
    LanguagePacks = 'languagePacks',
    LanguagePackFile = 'languagePacks/:id/file',
    UserGuides = 'userGuides',
    UserGuideFile = 'userGuides/:id/file',
    ColorShortcuts = 'colorShortcuts',
    BrandingResources = 'brandingResources',
    BrandingResourceFile = 'brandingResources/:id/file',
    FeedbackConfigs = 'feedbackConfigs',
    LearningLinks = 'learningLinks',
    AgendaDesigns = 'agendaDesigns',
    AgendaDesignList = 'agendaDesigns/list',
    AgendaDesignFile = 'agendaDesigns/:id/file',

    CredentialsPacks = 'credentialsPacks',
    CredentialsPack = 'credentialsPacks/:id',
    CredentialsPackFile = 'credentialsPacks/:id/file',
    MacroPacks = 'macroPacks',
    MacroPack = 'MacroPacks/:id',
    MacroPackFile = 'MacroPacks/:id/file'
}
