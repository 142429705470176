import { IntlShape } from 'react-intl/lib';
import { getLocalizedString } from '../i18n/I18nHelper';

export function groupBy<T, K extends string>(array: T[], keyName: string): { [key in K]: T[] } {
    // @ts-ignore
    const empty: { [key in K]: T[] } = {};

    return array.reduce((hash, obj) => {
        // @ts-ignore
        if (obj[keyName] === undefined) return hash;
        // @ts-ignore
        return Object.assign(hash, { [obj[keyName]]: (hash[obj[keyName]] || []).concat(obj) });
    }, empty);
}

export function replaceAll(inputString: string, mappingObject: { [key: string]: string }) {
    const regExp = new RegExp(Object.keys(mappingObject).join('|'), 'gi');
    return inputString.replace(regExp, matched => {
        return mappingObject[matched] || '';
    });
}

export const format = (label: string | undefined | null, intl: IntlShape) => {
    return label ? label : getLocalizedString(intl, 'COMMON.UNKNOWN');
};
