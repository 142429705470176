import React from 'react';
import { APIScaffold } from '../../scaffolder/APIScaffold';
import { UIScaffold } from '../../scaffolder/UIScaffold';
import { CreateCredentialsPacksContent } from '../../components/credentials/create/CreateCredentialsPacksContent';
import { RepositoryProvider } from '../../../domain';

export const CreateCredentialPackScreen = () => {
    return (
        <APIScaffold>
            {({ cancelRequest }) => (
                <UIScaffold>
                    {({ setLoading, setSessionExpired, setToast }) => (
                        <CreateCredentialsPacksContent
                            credentialsPackRepository={RepositoryProvider.getProvider().credentialsPacksRepository}
                            setToast={setToast}
                            setLoading={setLoading}
                            setSessionExpired={setSessionExpired}
                            cancelRequest={cancelRequest}
                        />
                    )}
                </UIScaffold>
            )}
        </APIScaffold>
    );
};
