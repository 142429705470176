import { MacroPacksRepositorySpec } from '../../../../domain';
import { useRepository } from '../../../../hooks/useRepository';
import { serializeCreateMacroPacks } from '../../../../utils/serializer';
import { NavRoutes } from '../../../../navigation/NavRoutes';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { APIScaffoldChildContext } from '../../../scaffolder/APIScaffold';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { MacroPack } from '../../../../domain/macroPack/model/MacroPack';
import { AppContext } from '../../../../context/AppContext';
import { MacroPackFileData } from '../../../../domain/macroPack/model/initMacroPack';
import { useNavigate } from 'react-router-dom';
import { UITableHeadCell } from '../../common/UITableHead';
import { getMasterOrganizationId, getOrganizationIdOrNull } from '../../../../utils/organizationHelper';
import { CreateMacrosPackTable } from './CreateMacrosPackTable';

const createHeadCells: UITableHeadCell<MacroPackFileData>[] = [
    {
        id: 'name',
        numeric: false,
        disablePadding: true,
        labelId: 'LIBRARY.TABLE.HEADER.FILE_NAME'
    },
    {
        id: 'description',
        numeric: false,
        disablePadding: true,
        labelId: 'CREDENTIALS.TABLE.HEADER.DESCRIPTION'
    },
    {
        id: 'macros',
        numeric: false,
        disablePadding: true,
        labelId: 'MACROS.TABLE.HEADER.MACROS'
    },
    {
        id: 'macros',
        numeric: false,
        disablePadding: true,
        labelId: 'MACROS.TABLE.HEADER.ACTIONS'
    }
];

type CreateMacroPackContentProps = {
    macroPacksRepository: MacroPacksRepositorySpec;
};

export const CreateMacroPackContent: React.FC<
    CreateMacroPackContentProps & UIScaffoldChildContext & APIScaffoldChildContext
> = ({ macroPacksRepository, setSessionExpired, setToast, setLoading, cancelRequest }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [packs, setPacks] = useState<MacroPack[] | null>(null);

    const { sessionInfo, currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);

    const initialValues: { packs: MacroPackFileData[] } = { packs: [] };

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const handleCreate = async (values: any) => {
        const nodes = await serializeCreateMacroPacks(values.packs);

        await callRepository(
            () => macroPacksRepository.create(nodes, sessionInfo),
            () => {
                navigate(NavRoutes.MacroPacks, { replace: true });
                setToast(getLocalizedString(intl, 'MACROS.CREATE.SUCCESS.MESSAGE'), 'success');
            }
        );
    };

    return (
        <CreateMacrosPackTable
            title={getLocalizedString(intl, 'MACROS.CREATE.TITLE')}
            initialValues={initialValues}
            masterOrganizationId={getMasterOrganizationId(currentOrganization)}
            organizationId={getOrganizationIdOrNull(currentOrganization, isCurrentOrganizationMaster)}
            handleSubmit={handleCreate}
            headCells={createHeadCells}
        />
    );
};
