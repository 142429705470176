import { AuthSessionInfoSpec, HttpRoutes, Library, LibraryNodeSpec, LibraryRepositorySpec } from '..';

import { HttpResponse } from '../mazars-network-http-client';
import { Repository } from '../Repository';
import { HttpContentType } from '../../types/types';

export class MasterLibraryRepository extends Repository implements LibraryRepositorySpec {
    async fetchAll(masterOrganizationId: string, sessionInfo: AuthSessionInfoSpec): Promise<Library[]> {
        const parameters = { masterOrganizationId };
        const response = (await this.datasource.remote.get(
            HttpRoutes.MasterLibrary,
            sessionInfo.accessToken,
            undefined,
            parameters,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<Library[], any>;

        return response.data || [];
    }

    async fetch(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Library | undefined> {
        const path = HttpRoutes.MasterLibraryNode.replace(':id', id);

        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<Library, any>;

        return response.data;
    }

    async fetchFile(id: string, sessionInfo: AuthSessionInfoSpec): Promise<Blob | undefined> {
        const path = HttpRoutes.MasterLibraryFile.replace(':id', id);
        const response = (await this.datasource.remote.get(
            path,
            sessionInfo.accessToken,
            undefined,
            undefined,
            this.refreshAccessToken(sessionInfo),
            'blob'
        )) as HttpResponse<Blob, any>;

        return response.data;
    }

    async create(nodes: LibraryNodeSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = new FormData();
        body.append('JSON', JSON.stringify(nodes.info));
        nodes.files.forEach(file => {
            body.append('FILES', file);
        });

        const response = (await this.datasource.remote.post(
            HttpRoutes.MasterLibrary,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async update(nodes: LibraryNodeSpec, sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const body = new FormData();
        body.append('JSON', JSON.stringify(nodes.info));

        nodes.files.forEach(file => {
            body.append('FILES', file);
        });

        const response = (await this.datasource.remote.put(
            HttpRoutes.MasterLibrary,
            body,
            sessionInfo.accessToken,
            {
                'content-type': HttpContentType.MultipartFormData
            },
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }

    async delete(nodeIds: string[], sessionInfo: AuthSessionInfoSpec): Promise<string[]> {
        const response = (await this.datasource.remote.delete(
            HttpRoutes.MasterLibrary,
            nodeIds,
            sessionInfo.accessToken,
            undefined,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<string[], any>;

        return response.data || [];
    }
}
