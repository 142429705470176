import {
    EMasterOrganizationRole,
    EOrganizationRole,
    UserMasterOrganizationRole, UserOrganizationRole
} from '../domain';
import { MasterOrganizationRoleEnum, OrganizationRoleEnum } from '../types/types';

export const formatOrganizationRoles = (roles: (EOrganizationRole | EMasterOrganizationRole)[]) => roles.map(r => ({
    ...r,
    id: r.value
}));

export const getSelectableOrganizationRoles = (roles: (EOrganizationRole | EMasterOrganizationRole)[]) => {
    return roles.filter(r => r.value !== OrganizationRoleEnum.Member && r.value !== MasterOrganizationRoleEnum.Member);
};

export const serializeUserRole = <D>(data: D, isMasterOrganization: boolean): UserMasterOrganizationRole[] | UserOrganizationRole[] => {

    if (isMasterOrganization) {
        const fullData = data as UserMasterOrganizationRole;
        return [{
            id: fullData.id,
            email: fullData.email,
            eMasterOrganizationRole: fullData.eMasterOrganizationRole,
            masterOrganizationId: fullData.masterOrganizationId
        }];

    } else {
        const fullData = data as UserOrganizationRole;
        return [{
            id: fullData.id,
            email: fullData.email,
            eOrganizationRole: fullData.eOrganizationRole,
            organizationId: fullData.organizationId
        }];
    }
};
