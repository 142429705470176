import { ColorShortcut, ColorShortcutDataSpec, Organization } from '../domain';
import { RgbColor } from '../domain/resources/colorShortcuts/model/ColorShortcut';

const getColorShortcutData = (colorShortcut: ColorShortcut, organization: Organization): ColorShortcutDataSpec => {
    return {
        id: colorShortcut.id,
        organizationId: organization.id,
        masterOrganizationId: colorShortcut.masterOrganizationId,
        name: colorShortcut.name,
        hexColor: colorShortcut.hexColor,
        index: colorShortcut.index
    };
};

const formatRGBColor = (color: RgbColor) => {
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
};

const prepareColorShortcutData = (colorShortcut: ColorShortcutDataSpec) => {
    return [
        {
            ...colorShortcut,
            hexColor: colorShortcut.hexColor.replace('#', '')
        }
    ];
};

export const colorShortcutsHelper = {
    getColorShortcutData,
    formatRGBColor,
    prepareColorShortcutData
};
