import { initLibraryInfo, Library, LibraryFileData, LibraryNodeSpec } from '../domain';
import { getFileHash } from './fileHelper';
import {
    CredentialPackDataSpec,
    CredentialPackFileData,
    initCredentialPackInfo,
    PostCredentialPack
} from '../domain/credentials/model/initCredentialPack';
import { CredentialPack } from '../domain/credentials/model/CredentialPack';
import { EditableOrganization } from '../domain/organizations/model/Organization';
import {
    initMacroPackInfo,
    MacroPackDataSpec,
    MacroPackFileData,
    PostMacroPack
} from '../domain/macroPack/model/initMacroPack';
import { MacroPack } from '../domain/macroPack/model/MacroPack';

export const serializeLibraryNodes = async (nodes: LibraryFileData[]) => {
    const files: Blob[] = [];
    const promises = nodes.map(async (nodeData: LibraryFileData): Promise<Library> => {
        nodeData.file && files.push(nodeData.file);

        return {
            ...initLibraryInfo,
            ...nodeData,
            // @ts-ignore
            childs: nodeData.childs?.length > 0 ? nodeData.childs : null,
            eDocumentType: nodeData.eDocumentType,
            fileHash: nodeData.file ? await getFileHash(nodeData.file) : nodeData.fileHash
        };
    });

    const nodesInfo: Library[] = await Promise.all(promises);

    const node: LibraryNodeSpec = {
        files: files,
        info: nodesInfo
    };

    return node;
};

export const serializeCreateCredentialsPacks = async (packs: CredentialPackFileData[]) => {
    const files: Blob[] = [];
    const promises = packs.map(async (nodeData: CredentialPackFileData): Promise<PostCredentialPack> => {
        nodeData.file && files.push(nodeData.file);

        return {
            organizationId: nodeData.organizationId,
            masterOrganizationId: nodeData.masterOrganizationId,
            name: nodeData.name,
            description: nodeData.description,
            fileHash: nodeData.file ? await getFileHash(nodeData.file) : nodeData.fileHash
        };
    });

    const packsInfo: PostCredentialPack[] = await Promise.all(promises);

    const pack: CredentialPackDataSpec = {
        files: files,
        info: packsInfo
    };

    return pack;
};

export const serializeUpdateCredentialsPacks = async (packs: CredentialPackFileData[]) => {
    const files: Blob[] = [];
    const promises = packs.map(async (nodeData: CredentialPackFileData): Promise<CredentialPack> => {
        nodeData.file && files.push(nodeData.file);

        return {
            ...initCredentialPackInfo,
            ...nodeData,
            fileHash: nodeData.file ? await getFileHash(nodeData.file) : nodeData.fileHash
        };
    });

    const packsInfo: CredentialPack[] = await Promise.all(promises);

    const pack: CredentialPackDataSpec = {
        files: files,
        info: packsInfo
    };

    return pack;
};

export const serializeUpdateOrganization = (organization: EditableOrganization) => {
    return {
        ...organization,
        userDnsDomains: organization.userDnsDomains.split(','),
        hostDomains: organization.hostDomains.split(','),
        childs: organization.childs?.map(child => serializeUpdateOrganization(child))
    };
};

export const serializeUpdateMacroPacks = async (packs: MacroPackFileData[]) => {
    const files: Blob[] = [];
    const promises = packs.map(async (nodeData: MacroPackFileData): Promise<MacroPack> => {
        nodeData.file && files.push(nodeData.file);

        return {
            ...initMacroPackInfo,
            ...nodeData,
            fileHash: nodeData.file ? await getFileHash(nodeData.file) : nodeData.fileHash
        };
    });

    const packsInfo: MacroPack[] = await Promise.all(promises);

    const pack: MacroPackDataSpec = {
        files: files,
        info: packsInfo
    };

    return pack;
};

export const serializeCreateMacroPacks = async (packs: MacroPackFileData[]) => {
    const files: Blob[] = [];
    const promises = packs.map(async (nodeData: MacroPackFileData): Promise<PostMacroPack> => {
        nodeData.file && files.push(nodeData.file);

        return {
            fileExtension: nodeData.fileExtension,
            organizationId: nodeData.organizationId,
            masterOrganizationId: nodeData.masterOrganizationId,
            name: nodeData.name,
            description: nodeData.description,
            fileHash: nodeData.file ? await getFileHash(nodeData.file) : nodeData.fileHash,
            macros: nodeData.macros
        };
    });

    const packsInfo: PostMacroPack[] = await Promise.all(promises);

    const pack: MacroPackDataSpec = {
        files: files,
        info: packsInfo
    };

    return pack;
};
