import { UIScaffoldChildContext } from '../../../scaffolder/UIScaffold';
import { LanguagePackRepositorySpec } from '../../../../domain/resources/languagePack/LanguagePackRepository';
import React, { useContext } from 'react';
import { AppContext } from '../../../../context/AppContext';
import { useRepository } from '../../../../hooks/useRepository';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';
import { format } from '../../../../utils/languagePacksHelper';
import { saveFileFromBlob } from '../../../../utils/fileHelper';
import { BaseResource, LanguagePack } from '../../../../domain';
import { GuidesArea } from '../common/GuideArea';
import { resourcesStyles } from '../common/ResourcesStyles';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { getMasterOrganizationId } from '../../../../utils/organizationHelper';
import { UIContentScaffold } from '../../common/UIContentScaffold';
import { FilesMimeTypes } from '../../../../types/types';

export interface LanguagePacksContentProps {
    languagePackRepository: LanguagePackRepositorySpec;
    isEditable: boolean;
}

export const LanguagePacksContent = ({
    isEditable,
    setSessionExpired,
    setToast,
    setLoading,
    languagePackRepository
}: LanguagePacksContentProps & UIScaffoldChildContext) => {
    const intl = useIntl();
    const { sessionInfo, currentOrganization } = useContext(AppContext);

    const { callRepository } = useRepository(
        {
            setLoading,
            setSessionExpired,
            setToast
        },
        intl
    );

    const onDownload = async (selectedPack: BaseResource) => {
        // @ts-ignore
        await callRepository(
            () => languagePackRepository.fetchFile(selectedPack.id, sessionInfo),
            downloadedFile => {
                downloadedFile &&
                    saveFileFromBlob(
                        downloadedFile as Blob,
                        `${selectedPack?.languageTag}-${selectedPack?.version}${selectedPack?.fileExtension}`
                    );
            }
        );
    };

    return (
        <>
            <Box sx={resourcesStyles.container}>
                <UIContentScaffold
                    fetchParams={{ masterOrganizationId: getMasterOrganizationId(currentOrganization) }}
                    successMessagePrefix="RESOURCES.LANGUAGE_PACKS"
                    repository={languagePackRepository}
                    setToast={setToast}
                    setLoading={setLoading}
                    setSessionExpired={setSessionExpired}>
                    {({ data, handleCreate, handleDelete, handleUpdate }) => (
                        <GuidesArea
                            groupedSoftwareGuides={format((data as LanguagePack[]) || [])}
                            handleDownload={onDownload}
                            handleCreate={handleCreate}
                            handleUpdate={handleUpdate}
                            handleDelete={handleDelete}
                            isEditable={isEditable}
                            filesInputProps={{
                                filesLimit: 1,
                                dropzoneDefaultText: getLocalizedString(
                                    intl,
                                    'RESOURCES.LANGUAGE_PACKS.FORM.FILE.FIELD.MSG'
                                ),
                                acceptedFiles: FilesMimeTypes.Text as readonly string[],
                                showPreviews: true
                            }}
                            dialogTitle={getLocalizedString(intl, 'RESOURCES.LANGUAGE_PACKS.DIALOG.TITLE')}
                            title={getLocalizedString(intl, 'RESOURCES.LANGUAGE_PACKS.AREA.TITLE')}
                        />
                    )}
                </UIContentScaffold>
            </Box>
        </>
    );
};
