import { LinkDataSpec } from '../../../../domain';
import { useForm } from '../../../../hooks/useForm';
import * as Yup from 'yup';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Alert } from '@mui/material';
import { UIText } from '../../common/UIText';

interface LinkFormProps {
    linkData: LinkDataSpec;
    onSubmit: (values: LinkDataSpec) => void;
    formRef: any;
    alertMessage?: string;
}

//(
//             /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
//             getLocalizedString(intl, 'RESOURCES.LINKS.FORM.VALIDATION.URL.INVALID')
//         )
export const LinkForm = ({ linkData, onSubmit, formRef, alertMessage }: LinkFormProps) => {
    const classes = useStyles();
    const intl = useIntl();

    const validationSchema = Yup.object({
        name: Yup.string().required(getLocalizedString(intl, 'RESOURCES.LINKS.FORM.VALIDATION.NAME.REQUIRED')),
        description: Yup.string().required(
            getLocalizedString(intl, 'RESOURCES.LINKS.FORM.VALIDATION.DESCRIPTION.REQUIRED')
        ),
        url: Yup.string().required(getLocalizedString(intl, 'RESOURCES.LINKS.FORM.VALIDATION.URL.REQUIRED')).url()
    });

    const fields = [
        {
            id: 'name',
            name: 'name',
            placeholder: getLocalizedString(intl, 'RESOURCES.LINKS.CREATE.NAME.FIELD.PLACEHOLDER'),
            variant: 'standard',
            className: classes.textField,
            fieldType: 'textFieldItem',
            disabled: false
        },
        {
            id: 'url',
            name: 'url',
            placeholder: getLocalizedString(intl, 'RESOURCES.LINKS.CREATE.URL.FIELD.PLACEHOLDER'),
            variant: 'standard',
            className: classes.textField,
            fieldType: 'textFieldItem',
            disabled: false
        },
        {
            id: 'description',
            name: 'description',
            placeholder: getLocalizedString(intl, 'RESOURCES.LINKS.CREATE.DESCRIPTION.FIELD.PLACEHOLDER'),
            className: classes.textField,
            fieldType: 'textFieldItem',
            disabled: false,
            component: 'textarea',
            rows: 5,
            variant: 'outlined'
        }
    ];

    const { RenderForm } = useForm({
        initialValues: linkData,
        onSubmit: onSubmit,
        validationSchema: validationSchema
    });

    return (
        <>
            {alertMessage && (
                <Alert severity="info" sx={{ width: '100%' }}>
                    <UIText text={alertMessage} />
                </Alert>
            )}

            <RenderForm formFields={fields} innerFormRef={formRef} />
        </>
    );
};

const useStyles = makeStyles({
    textField: {
        width: '90%'
    }
});
