import { Box } from '@mui/material';
import { UIText } from '../common/UIText';
import { Palette } from '../../../res/colors';
import React from 'react';

interface UISectionFooterProps {
    backgroundColor: string,
    title: string
    actions: React.ReactNode
    actionsHidden: boolean
}

export const UISectionFooter = ({ backgroundColor, title, actions, actionsHidden }: UISectionFooterProps) => {
    return <Box sx={{
        marginTop: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: backgroundColor
    }}>
        <Box sx={{ width: '100%', marginX: 2 }}>
            <UIText text={title} sx={{ color: Palette.white }} />
        </Box>
        {!actionsHidden && actions}
    </Box>;
};
