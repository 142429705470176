import { Organization, UserOrganizationRole } from '../domain';
import { OrganizationRoleEnum } from '../types/types';

export const getAllOrganizationsLabel = (organizations: Organization[]) => {
    return organizations.map(organization => organization.name).join(', ');
};

export const getUserRolesLabel = (userRoles: UserOrganizationRole[], organizations: Organization[]) => {
    return userRoles
        .filter(role => role.eOrganizationRole !== OrganizationRoleEnum.Member)
        .map(userRole => {
            const organization = organizations.find(o => o.id === userRole.organizationId);
            return `${OrganizationRoleEnum[userRole.eOrganizationRole]} ${
                organization?.name || userRole.organizationId
            }`;
        })
        .join(', ');
};

export const accountHelper = {
    getAllOrganizationsLabel,
    getUserRolesLabel
};
