import { MacroPack } from '../../../domain/macroPack/model/MacroPack';
import React from 'react';
import { Box, IconButton, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { UIText } from '../common/UIText';
import { Palette } from '../../../res/colors';
import Tooltip from '@mui/material/Tooltip';
import { getLocalizedString } from '../../../i18n/I18nHelper';
import { Delete, Edit } from '@mui/icons-material';
import { resourcesStyles } from '../resources';
import { useIntl } from 'react-intl';
import { macroHelper } from '../../../utils/macroHelper';
import { FontsResolver } from '../../../utils/resolver';

type MacroListItemProps = {
    item: MacroPack;
    isEditable: boolean;
    onEdit: (macro: MacroPack) => void;
    onDelete: (macro: MacroPack) => void;
};

export const MacroListItem: React.FC<MacroListItemProps> = ({ item, isEditable, onEdit, onDelete }) => {
    const intl = useIntl();

    const handleEdit = () => {
        onEdit(item);
    };

    const handleDelete = async () => {
        await onDelete(item);
    };

    const handleSelectItem = (event: React.MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
        handleEdit();
    };

    return (
        <ListItem component="a" href={item.id} onClick={handleSelectItem}>
            <ListItemText
                primary={<UIText text={item.name} sx={{ color: Palette.defaultPrimaryColor }} />}
                secondary={
                    <Box>
                        {item.description ? <UIText text={item.description} /> : null}
                        <UIText
                            sx={{
                                marginTop: 2,
                                fontSize: 14,
                                color: Palette.sonicSilver,
                                fontFamily: FontsResolver.defaultFontBold
                            }}
                            text={getLocalizedString(intl, 'MACRO.HOME.MACROS', {
                                count: item.macros.length,
                                values: macroHelper.formatMacros(item.macros)
                            })}
                        />
                    </Box>
                }
            />

            {isEditable && (
                <ListItemSecondaryAction>
                    <Tooltip title={getLocalizedString(intl, 'RESOURCES.LINKS.TOOLBAR.EDIT.TOOLTIP')}>
                        <IconButton onClick={handleEdit}>
                            <Edit style={resourcesStyles.image('small')} />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title={getLocalizedString(intl, 'RESOURCES.LINKS.TOOLBAR.DELETE.TOOLTIP')}>
                        <IconButton onClick={handleDelete}>
                            <Delete
                                style={{
                                    ...resourcesStyles.image('small'),
                                    color: Palette.red
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            )}
        </ListItem>
    );
};
