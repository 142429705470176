import {
    AgendaDesignRepository,
    AgendaDesignRepositorySpec,
    AuthRepository,
    AuthRepositorySpec,
    BrandingResourcesRepository,
    BrandingResourcesRepositorySpec,
    ColorShortcutRepository,
    ColorShortcutRepositorySpec,
    CredentialsPacksRepository,
    CredentialsPacksRepositorySpec,
    FeedbackRepository,
    FeedbackRepositorySpec,
    LanguagePackRepository,
    LanguagePackRepositorySpec,
    LearningLinkRepository,
    LearningLinkRepositorySpec,
    LibraryRepository,
    LibraryRepositorySpec,
    LinksRepository,
    LinksRepositorySpec,
    MacroPacksRepository,
    MacroPacksRepositorySpec,
    MasterOrganizationRepository,
    MasterOrganizationRepositorySpec,
    OrganizationRepository,
    OrganizationRepositorySpec,
    OrganizationRolesRepository,
    OrganizationRolesRepositorySpec,
    UserGuideRepository,
    UserGuideRepositorySpec,
    UserRepository,
    UserRepositorySpec,
    UserResourceRepository,
    UserResourceRepositorySpec
} from '.';
import { MasterLibraryRepository } from './library/MasterLibraryRepository';
import { MasterOrganizationRolesRepository } from './roles/MasterOrganizationRolesRepository';
import { MasterLinksRepository } from './resources/links/MasterLinksRepository';

export class RepositoryProvider {
    authRepository: AuthRepositorySpec;
    userRepository: UserRepositorySpec;
    organizationRepository: OrganizationRepositorySpec;
    masterOrganizationRepository: MasterOrganizationRepositorySpec;
    libraryRepository: LibraryRepositorySpec;
    masterLibraryRepository: LibraryRepositorySpec;
    userResourceRepository: UserResourceRepositorySpec;
    organizationRolesRepository: OrganizationRolesRepositorySpec;
    masterOrganizationRolesRepository: OrganizationRolesRepositorySpec;
    linksRepository: LinksRepositorySpec;
    masterLinksRepository: LinksRepositorySpec;
    languagePackRepository: LanguagePackRepositorySpec;
    userGuideRepository: UserGuideRepositorySpec;
    colorShortcutRepository: ColorShortcutRepositorySpec;
    brandingResourcesRepository: BrandingResourcesRepositorySpec;
    feedbackRepository: FeedbackRepositorySpec;
    learningLinkRepository: LearningLinkRepositorySpec;
    agendaDesignRepository: AgendaDesignRepositorySpec;
    credentialsPacksRepository: CredentialsPacksRepositorySpec;
    macroPacksRepository: MacroPacksRepositorySpec;

    constructor() {
        this.authRepository = new AuthRepository();
        this.organizationRepository = new OrganizationRepository();
        this.masterOrganizationRepository = new MasterOrganizationRepository();
        this.libraryRepository = new LibraryRepository();
        this.masterLibraryRepository = new MasterLibraryRepository();
        this.userRepository = new UserRepository();
        this.userResourceRepository = new UserResourceRepository();
        this.organizationRolesRepository = new OrganizationRolesRepository();
        this.linksRepository = new LinksRepository();
        this.masterLinksRepository = new MasterLinksRepository();
        this.languagePackRepository = new LanguagePackRepository();
        this.userGuideRepository = new UserGuideRepository();
        this.colorShortcutRepository = new ColorShortcutRepository();
        this.brandingResourcesRepository = new BrandingResourcesRepository();
        this.feedbackRepository = new FeedbackRepository();
        this.learningLinkRepository = new LearningLinkRepository();
        this.agendaDesignRepository = new AgendaDesignRepository();
        this.masterOrganizationRolesRepository = new MasterOrganizationRolesRepository();
        this.credentialsPacksRepository = new CredentialsPacksRepository();
        this.macroPacksRepository = new MacroPacksRepository();
    }

    public static getProvider() {
        return new RepositoryProvider();
    }
}
