import { LibraryFileData, LibraryNodeDocumentTypeOptions, LibraryNodeFileTypeSpec } from '../../../../domain';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { useForm } from '../../../../hooks/useForm';
import { Paper } from '@mui/material';
import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { ReplaceableFieldsContent } from './ReplaceableFieldsContent';
import { FormikProps } from 'formik/dist/types';
import { LibraryReplaceableField } from '../../../../domain/library/model/Library';
import { FilteringTagsContent } from './FilteringTagsContent';
import { Palette } from '../../../../res/colors';

export interface LibraryFileDetailsProps {
    isEditable: boolean;
    formRef: any;

    node: LibraryFileData;
    handleUpdate: (node: LibraryFileData) => void;
    handleError: (error: string) => void;
}

export const LibraryFileDetails = ({
    isEditable,
    handleUpdate,
    node,
    formRef,
    handleError
}: LibraryFileDetailsProps) => {
    const classes = useStyles();

    const replaceableFieldsFormRef = useRef<FormikProps<{ replaceableFields: LibraryReplaceableField[] }>>();
    const filteringTagsFormRef = useRef<FormikProps<{ filteringTags: string[] }>>();

    const intl = useIntl();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(getLocalizedString(intl, 'LIBRARY.VALIDATION.NAME.REQUIRED')),
        description: Yup.string(),
        eDocumentType: Yup.mixed().required(getLocalizedString(intl, 'LIBRARY.VALIDATION.CONTENT_TYPE.REQUIRED')),
        isValidated: Yup.boolean()
    });

    const fields = [
        {
            id: 'name',
            name: 'name',
            placeholder: getLocalizedString(intl, 'CONFIGURATION.AGENDA_DESIGN.FORM.VERSION.FIELD.PLACEHOLDER'),
            fieldType: 'textFieldItem',
            variant: 'standard',
            className: classes.textField
        },
        {
            id: 'eDocumentType',
            name: 'eDocumentType',
            placeholder: getLocalizedString(intl, 'LIBRARY.CONTENT_TYPES.FIELD.PLACEHOLDER'),
            fieldType: 'selectFieldItem',
            options: LibraryNodeDocumentTypeOptions,
            getOptionLabel: (item: LibraryNodeFileTypeSpec) => getLocalizedString(intl, item.name),
            value: node.eDocumentType,
            disabled: !isEditable
        },
        {
            id: 'description',
            name: 'description',
            placeholder: getLocalizedString(intl, 'LIBRARY.CREATE.FOLDER.DESCRIPTION.FIELD.PLACEHOLDER'),
            className: classes.textField,
            fieldType: 'textFieldItem',
            disabled: false,
            component: 'textarea',
            rows: 5,
            variant: 'outlined'
        },
        {
            id: 'isValidated',
            name: 'isValidated',
            labelId: getLocalizedString(intl, 'LIBRARY.UPDATE.MARKETING_VALIDATION'),
            fieldType: 'checkBoxFieldItem',
            disabled: !isEditable,
            customStyle: sx.checkbox,
            value: node.isValidated
        }
    ];

    const retrieveFilteringFields = (): LibraryReplaceableField[] | undefined => {
        const formik = replaceableFieldsFormRef.current
            ? (replaceableFieldsFormRef.current as FormikProps<{ replaceableFields: LibraryReplaceableField[] }>)
            : null;

        if (formik?.isValid) return formik?.values.replaceableFields;
        else {
            const error = formik?.errors?.replaceableFields ?? { error: 'Error retrieving replaceable fields' };
            throw new Error(JSON.stringify(error));
        }
    };

    const retrieveFilteringTags = (): string[] | undefined => {
        const formik = filteringTagsFormRef.current
            ? (filteringTagsFormRef.current as FormikProps<{ filteringTags: string[] }>)
            : null;

        if (formik?.isValid) {
            return formik?.values.filteringTags;
        } else {
            const error = formik?.errors ?? { error: 'Error retrieving filteringTags' };
            throw new Error(JSON.stringify(error));
        }
    };

    const onSubmit = async (values: LibraryFileData) => {
        try {
            await handleUpdate({
                ...values,
                replaceableFields: retrieveFilteringFields() ?? values.replaceableFields,
                filteringTags: retrieveFilteringTags() ?? values.filteringTags
            });
        } catch (e) {
            handleError((e as Error).message);
        }
    };

    const { RenderForm: LibraryDetailsForm } = useForm({
        initialValues: node,
        onSubmit: onSubmit,
        validationSchema: validationSchema
    });

    const sectionElevation = isEditable ? 2 : 0;
    return (
        <>
            <Paper elevation={3} sx={sx.container}>
                <LibraryDetailsForm innerFormRef={formRef} formFields={fields} sx={sx.content} />

                <Paper elevation={sectionElevation} sx={{ ...sx.section, mt: 2 }}>
                    <ReplaceableFieldsContent
                        isEditable={isEditable}
                        innerFormRef={replaceableFieldsFormRef}
                        replaceableFields={node.replaceableFields}
                    />
                </Paper>

                <Paper elevation={sectionElevation} sx={sx.section}>
                    <FilteringTagsContent
                        isEditable={isEditable}
                        innerFormRef={filteringTagsFormRef}
                        filteringTags={node.filteringTags ?? []}
                    />
                </Paper>
            </Paper>
        </>
    );
};

const useStyles = makeStyles({
    textField: {
        width: '80%'
    }
});

const sx = {
    container: {
        m: 2,
        p: 2
    },
    content: {
        flexDirection: 'column',
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'repeat(2, 1fr)',
        mt: 1,
        alignItems: 'center'
    },
    section: {
        paddingLeft: 2,
        paddingRight: 2,
        marginBottom: 2,
        paddingBottom: 2
    },
    checkbox: {
        color: Palette.primaryTextColor
    }
} as const;
