import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import { CredentialPackFileData } from '../../../../domain/credentials/model/initCredentialPack';
import { TableCellInputField } from '../../library';
import Checkbox from '@mui/material/Checkbox';

interface CredentialPackTableRowProps {
    disabled?: boolean;
    pack: CredentialPackFileData;
    index: number;
    fieldsProps: { description: string; name: string };
    isSelected: boolean;
    handleRowClick: (event: React.MouseEvent<unknown>, name: string) => void;
}

export const CredentialPackTableRow = ({
    pack,
    index,
    fieldsProps,
    isSelected,
    handleRowClick,
    disabled = false
}: CredentialPackTableRowProps) => {
    const labelId = `enhanced-table-checkbox-${index}`;

    return (
        <TableRow
            hover
            onClick={event => handleRowClick(event, pack.name)}
            role="checkbox"
            aria-checked={isSelected}
            tabIndex={-1}
            key={pack.name}
            selected={isSelected}>
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isSelected}
                    inputProps={{
                        'aria-labelledby': labelId
                    }}
                />
            </TableCell>

            <TableCell
                component="th"
                id={labelId}
                scope="row"
                sx={{
                    paddingY: 1,
                    paddingX: 2
                }}>
                <TableCellInputField
                    disabled={disabled}
                    id={fieldsProps.name}
                    name={fieldsProps.name}
                    value={pack.name}
                    extension={pack.fileExtension}
                />
            </TableCell>
            <TableCell
                align="right"
                sx={{
                    paddingY: 1,
                    paddingX: 5
                }}>
                <TableCellInputField
                    disabled={disabled}
                    id={fieldsProps.description}
                    name={fieldsProps.description}
                    value={pack.description}
                    extension={''}
                />
            </TableCell>
        </TableRow>
    );
};
