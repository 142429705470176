import { MasterOrganizationRoleEnum, OrganizationRoleEnum, RoleLevel } from '../../../types/types';

export const RoleCategory: { [key in RoleLevel]: (OrganizationRoleEnum | MasterOrganizationRoleEnum)[] } = {
    masterOrganizationAdmin: [MasterOrganizationRoleEnum.Admin],
    organizationAdmin: [OrganizationRoleEnum.Admin],
    standard: [OrganizationRoleEnum.Guest, OrganizationRoleEnum.Member, MasterOrganizationRoleEnum.Member]
};

export type AccessMode = 'AppAdmin' | 'Other';

export type UserAccess = {
    roleLevel: RoleLevel;
    accessMode: AccessMode;
};
