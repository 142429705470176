import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useFormikContext } from 'formik';
import { IconButton, TextField } from '@mui/material';
import { Check, Edit } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Palette } from '../../../res/colors';
import { UIText } from './UIText';

interface ValidateButtonProps {
    onValidate: (event: any) => Promise<void>;
    isEditMode: boolean;
}

const ValidateButton = ({ onValidate, isEditMode }: ValidateButtonProps) => {
    return (
        <IconButton sx={{ color: Palette.defaultPrimaryColor, marginHorizontal: 1 }} onClick={onValidate}>
            {isEditMode ? <Check /> : <Edit />}
        </IconButton>
    );
};

interface TableRowInputFieldProps {
    name: string;
    value: string;
    extension: string | null;
    id: string;
    disabled?: boolean;
    editable?: boolean;
}

export const TableCellInputField = ({
    name,
    extension,
    id,
    value,
    disabled = false,
    editable = true
}: TableRowInputFieldProps) => {
    const classes = useStyles();

    const { setFieldValue } = useFormikContext();

    const [updatedValue, setValue] = useState<string>(value);

    const [isEditMode, setEditMode] = useState<boolean>(false);
    const onChange = async (event: any) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        setValue(value);
    }, [value]);

    const onValidate = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        isEditMode && (await setFieldValue(id, updatedValue));
        setEditMode(!isEditMode);
    };

    const onKeyDown = async (e: any) => {
        if (e.keyCode === 13) {
            await onValidate(e);
        }
    };
    return (
        <Box sx={styles.container}>
            <Box sx={styles.nameContainer}>
                <TextField
                    onKeyDown={onKeyDown}
                    className={classes.textField}
                    name={name}
                    id={id}
                    value={updatedValue}
                    onChange={onChange}
                    disabled={!isEditMode || disabled || !editable}
                    variant="standard"
                />
                {editable && <ValidateButton isEditMode={isEditMode} onValidate={onValidate} />}
            </Box>
            {!!extension && <UIText text={extension} />}
        </Box>
    );
};

const useStyles = makeStyles({
    textField: {
        width: '80%'
    }
});

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    nameContainer: {
        flexDirection: 'row',
        display: 'flex',
        flex: 1
    }
};
