import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';

export const UICheckboxField = ({ id, toolTipLabelId, labelId, customStyle, color, disabled }: any) => {
    const { values, setFieldValue } = useFormikContext();
    // @ts-ignore
    const isChecked = values[id];

    const handleChange = async () => {
        // @ts-ignore
        await setFieldValue(id, !values[id]);
    };

    return (
        <>
            <Tooltip
                title={toolTipLabelId}
                children={
                    <FormControlLabel
                        control={
                            <Field
                                disabled={disabled}
                                component={Checkbox}
                                id={id}
                                name={id}
                                checked={isChecked}
                                onChange={handleChange}
                                color={color}
                            />
                        }
                        style={customStyle}
                        label={labelId}
                    />
                }
            />
        </>
    );
};
