import { RequestParam } from '../types/types';

const extractUrlParamValue = (paramName: string, url: string): string | null => {
    const queryParamValue = url.match(`(?<=${paramName}=)(.)*(?=[\\&])`);
    return queryParamValue && queryParamValue.length > 0 ? queryParamValue[0] : null;
};

const serializeUrlParams = (params: Record<string, RequestParam>, startDelimiter = '?'): string => {
    return Object.entries(params).reduce((acc, current, index, array) => {
        const [key, value] = current;
        const suffix = index === array.length - 1 ? '' : '&';
        return acc + `${key}=${encodeURIComponent(value)}` + suffix;
    }, startDelimiter);
};

export const NetworkHelpers = {
    serializeUrlParams,
    extractUrlParamValue
};
