import { UIActionButton } from '../../common/UIActionButton';
import { UISectionHeader } from '../../common/UISectionHeader';
import { AgendaDesign, AgendaDesignSpec } from '../../../../domain';
import { FilesMimeTypes } from '../../../../types/types';
import { UIDialog } from '../../common/UIDialog';
import { FormikProps } from 'formik/dist/types';
import { UIText } from '../../common/UIText';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { getLocalizedString } from '../../../../i18n/I18nHelper';
import { AppContext } from '../../../../context/AppContext';
import { useIntl } from 'react-intl';
import { Box, IconButton, Paper, Tooltip } from '@mui/material';
import { initAgendaDesign } from '../../../../domain/configuration/agendaDesign/model/AgendaDesign';
import { TreeView } from '@mui/lab';
import { FileCopy, FileCopySharp, GetApp } from '@mui/icons-material';
import { AgendaDesignTile } from './AgendaDesignTile';
import { UIFilePreview } from '../../common/UIFilePreview';
import { AgendaDesignForm } from './AgendaDesignForm';
import {
    getMasterOrganizationId,
    getOrganizationId,
    getScreenSubtitleByOrganizationType
} from '../../../../utils/organizationHelper';
import { getOverriddenAgenda } from '../../../../utils/overridingHelper';

interface AgendaDesignAreaProps {
    actionsHidden: boolean;
    agendaDesigns: AgendaDesign[] | null;
    handleUpdate: (agenda: AgendaDesignSpec) => Promise<boolean>;
    handleDownload: (agenda: AgendaDesign) => Promise<boolean>;
    fetchAgendaFile: (agenda: AgendaDesign) => Promise<Blob | null>;
    handleDelete: (ids: string[]) => void;
    handleCreate: (agenda: AgendaDesignSpec) => Promise<boolean>;
}

export const AgendaDesignArea = ({
    agendaDesigns,
    handleDelete,
    fetchAgendaFile,
    handleCreate,
    handleUpdate,
    handleDownload,
    actionsHidden
}: AgendaDesignAreaProps) => {
    const formRef = useRef();
    const intl = useIntl();
    const { currentOrganization, isCurrentOrganizationMaster } = useContext(AppContext);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selectedAgenda, setSelectedAgenda] = useState<AgendaDesign | null>(null);
    const [downloadedFile, setDownloadedFile] = useState<Blob | null>(null);
    const [openPreviewFile, setOpenPreviewFile] = useState<boolean>(false);

    const [currentAgendaDesign, setCurrentAgendaDesign] = useState<AgendaDesignSpec>(initAgendaDesign);
    const existingAgendaDesigns = useMemo(
        () => (!agendaDesigns ? [] : agendaDesigns?.sort((a, b) => Number(b.version) - Number(a.version))),
        [agendaDesigns]
    );

    const updatePreviewFileVisibility = () => {
        setOpenPreviewFile(!openPreviewFile);
    };

    const onEditRequested = (agenda: AgendaDesign) => {
        setCurrentAgendaDesign({
            ...agenda,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });
        setOpenDialog(!openDialog);
    };

    const onCreateRequested = () => {
        setCurrentAgendaDesign({
            ...initAgendaDesign,
            masterOrganizationId: getMasterOrganizationId(currentOrganization),
            organizationId: getOrganizationId(currentOrganization, isCurrentOrganizationMaster)
        });
        setOpenDialog(!openDialog);
    };

    const onCloseDialog = () => {
        setOpenDialog(!openDialog);
    };

    const doTriggerSubmit = () => {
        const formik = formRef.current ? (formRef.current as FormikProps<AgendaDesignSpec>) : null;
        formik?.handleSubmit();
    };

    const onPreview = async (agenda: AgendaDesign) => {
        const file = await fetchAgendaFile(agenda);
        setSelectedAgenda(agenda);
        setDownloadedFile(file);
        updatePreviewFileVisibility();
    };

    const onDownload = (agenda: AgendaDesign) => async () => {
        await handleDownload(agenda);
    };

    const onCreateSubmit = async (agenda: AgendaDesignSpec) => {
        const isSuccess = await handleCreate(agenda);
        setOpenDialog(!isSuccess);
    };

    const onUpdateSubmit = async (agenda: AgendaDesignSpec) => {
        const isSuccess = await handleUpdate(agenda);
        setOpenDialog(!isSuccess);
    };

    const handleSubmit = async (agendaDesign: AgendaDesignSpec) => {
        if (isCurrentOrganizationMaster) {
            if (agendaDesign.id) {
                await onUpdateSubmit({
                    ...agendaDesign,
                    id: agendaDesign.id
                });
            } else {
                await onCreateSubmit(agendaDesign);
            }
        } else {
            // Get the overridden agenda design version, if it exists
            const existingAgendaDesignVersion = getOverriddenAgenda(agendaDesign.version, existingAgendaDesigns || []);

            // If an existing version exists, perform an update and enforce the ID.
            // This handles the case where the user pressed the add button (dialogMode=create),
            // but it's ultimately an update (override at the organization level).
            if (existingAgendaDesignVersion) {
                await onUpdateSubmit({
                    ...agendaDesign,
                    id: existingAgendaDesignVersion?.id
                });
            } else {
                await onCreateSubmit(agendaDesign);
            }
        }
    };

    return (
        <>
            <UISectionHeader
                title={getLocalizedString(intl, 'CONFIGURATION.SIDE_BAR_MENU.AGENDA_DESIGN.ENTRY')}
                subtitle={getLocalizedString(intl, getScreenSubtitleByOrganizationType(isCurrentOrganizationMaster))}
                isCreateEnabled={!actionsHidden}
                onCreate={onCreateRequested}
            />

            <Paper elevation={3} sx={{ paddingY: 2 }}>
                <>
                    {!existingAgendaDesigns || existingAgendaDesigns?.length == 0 ? (
                        <UIText
                            text={getLocalizedString(intl, 'CONFIGURATION.AGENDA_DESIGN.EMPTY.MESSAGE')}
                            sx={{ margin: 2 }}
                        />
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <TreeView
                                aria-label="agenda-design-content"
                                defaultCollapseIcon={<FileCopy />}
                                defaultExpandIcon={<FileCopySharp />}
                                sx={{ flexGrow: 1, overflowY: 'auto' }}>
                                {existingAgendaDesigns?.map(agenda => (
                                    <AgendaDesignTile
                                        key={agenda.id}
                                        actionsHidden={actionsHidden}
                                        onDelete={handleDelete}
                                        onEditAsked={onEditRequested}
                                        onDownload={handleDownload}
                                        onPreview={onPreview}
                                        agendaDesign={agenda}
                                    />
                                ))}
                            </TreeView>
                        </Box>
                    )}
                </>
            </Paper>
            {existingAgendaDesigns && openDialog && (
                <UIDialog
                    open={openDialog}
                    onClose={onCloseDialog}
                    title={getLocalizedString(intl, 'CONFIGURATION.AGENDA_DESIGN.DIALOG.TITLE')}
                    actions={
                        <UIActionButton
                            title={getLocalizedString(intl, 'COMMON.ACTIONS.SAVE')}
                            onClick={doTriggerSubmit}
                        />
                    }>
                    <>
                        <AgendaDesignForm
                            formRef={formRef}
                            agendaDesign={currentAgendaDesign}
                            allAgendas={existingAgendaDesigns}
                            filesInputProps={{
                                filesLimit: 1,
                                acceptedFiles: FilesMimeTypes.PowerpointTemplate as string[],
                                showPreviews: true
                            }}
                            handleSubmit={handleSubmit}
                        />
                    </>
                </UIDialog>
            )}

            {selectedAgenda && downloadedFile && openPreviewFile && (
                <UIDialog
                    open={openPreviewFile}
                    toolbarActions={
                        <IconButton aria-label="download" onClick={onDownload(selectedAgenda)}>
                            <Tooltip title={getLocalizedString(intl, 'COMMON.ACTIONS.DOWNLOAD')}>
                                <GetApp color="primary" />
                            </Tooltip>
                        </IconButton>
                    }
                    onClose={updatePreviewFileVisibility}
                    title={getLocalizedString(intl, 'COMMON.DIALOG.PREVIEW.TITLE')}>
                    <UIFilePreview fileExtension={selectedAgenda.fileExtension} file={downloadedFile} />
                </UIDialog>
            )}
        </>
    );
};
