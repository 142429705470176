import { AuthSessionInfoSpec, HttpRoutes, RepositorySpec, User } from '..';

import { HttpResponse } from '../mazars-network-http-client';
import { Repository } from '../Repository';

export interface UserRepositorySpec extends RepositorySpec {
    fetch(email: string, sessionInfo: AuthSessionInfoSpec): Promise<User | null>;
}

export class UserRepository extends Repository implements UserRepositorySpec {
    async fetch(email: string, sessionInfo: AuthSessionInfoSpec): Promise<User | null> {
        const parameters = { email };
        const response = (await this.datasource.remote.get(
            HttpRoutes.UserInfo,
            sessionInfo.accessToken,
            undefined,
            parameters,
            this.refreshAccessToken(sessionInfo)
        )) as HttpResponse<User, any>;

        return response.data || null;
    }
}
