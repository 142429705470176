import React from 'react';
import { OverviewContainer } from '../components/account/overview/OverviewContainer';
import { Box } from '@mui/material';
import { RepositoryProvider, UserRepositorySpec } from '../../domain';
import { APIScaffold, APIScaffoldChildContext } from '../scaffolder/APIScaffold';
import { UIScaffold, UIScaffoldChildContext } from '../scaffolder/UIScaffold';

interface AccountContentProps {
    userRepository: UserRepositorySpec;
}

export const AccountContent = ({
                                   userRepository,
                                   setLoading,
                                   setToast,
                                   setSessionExpired
                               }: AccountContentProps & UIScaffoldChildContext & APIScaffoldChildContext) => {

    //TODO uncomment when license and sign in sections will be available

    /* const intl = useIntl();

   const [AccountSection, setAccountSection] = useState<React.ReactNode>(null);
   const [selectedSection, setSelectedSection] = useState<UISideMenuBarEntry>('overview');

  useEffect(() => {
        setAccountSection(accountSectionReducer(selectedSection));
        return () => {
            cancelRequest(userRepository);
        };
    }, [selectedSection]);*/

    /* const handleSectionChanged = (entryType: UISideMenuBarEntry) => () => {
         setSelectedSection(entryType);
     };

     const entries: UISideMenuBarEntryProps[] = [
         {
             title: getLocalizedString(intl, 'ACCOUNT.SIDE_BAR_MENU.OVERVIEW_ENTRY'),
             entryType: 'overview',
             id: 'OVERVIEW',
             onClick: handleSectionChanged
         },
         {
             title: getLocalizedString(intl, 'ACCOUNT.SIDE_BAR_MENU.LICENSE_ENTRY'),
             entryType: 'license',
             id: 'LICENSE',
             onClick: handleSectionChanged
         },
         {
             title: getLocalizedString(intl, 'ACCOUNT.SIDE_BAR_MENU.SIGN_INS_ENTRY'),
             entryType: 'sign_ins',
             id: 'SIGN_INS',
             onClick: handleSectionChanged
         }
     ];

     const accountSectionReducer = (entryType: UISideMenuBarEntry) => {

         switch (entryType) {
             case 'overview':
                 return <OverviewContainer setToast={setToast} setSessionExpired={setSessionExpired}
                                           setLoading={setLoading}
                                           userRepository={userRepository}
                                           devices={[]} />;
             case  'license':
                 return <LicenseContainer />;

             case 'sign_ins':
                 return <SignInsContainer />;

             default:
                 return null;
         }
     };*/

    return (
        <Box sx={styles.container}>
            {/* <UISideBarMenu entries={entries} selectedEntry={selectedSection} />*/}
            <Box sx={styles.section} className='container'>
                {/*AccountSection*/}
                <OverviewContainer setToast={setToast} setSessionExpired={setSessionExpired}
                                   setLoading={setLoading}
                                   userRepository={userRepository}
                                   devices={[]} />
            </Box>
        </Box>
    );
};


export const AccountScreen = () => {
    return (
        <APIScaffold>
            {({ cancelRequest }) => (
                <UIScaffold>
                    {({ setLoading, setSessionExpired, setToast }) => (
                        <AccountContent userRepository={RepositoryProvider.getProvider().userRepository}
                                        setToast={setToast} setLoading={setLoading}
                                        setSessionExpired={setSessionExpired} cancelRequest={cancelRequest} />
                    )}
                </UIScaffold>
            )}

        </APIScaffold>
    );
};

const styles = {
    container: {
        display: 'flex',
        alignItems: 'stretch',
        alignSelf: 'stretch',
        height: '85vh'
    },
    section: {
        display: 'flex',
        flexGrow: 1
    }
} as const;
