import crypto from 'crypto';

export const saveFileFromUrl = (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
};

export const saveFileFromBlob = (blob: Blob, fileName: string) => {
    const url = URL.createObjectURL(blob);
    saveFileFromUrl(url, fileName);
};

export const saveDownloadedFile = (data: any, fileName: string) => {
    saveFileFromBlob(data, fileName);
};

export const getFileHash = async (file: Blob): Promise<string> => {
    const fileBinary = new Uint8Array(await file.arrayBuffer());
    return crypto.createHash('sha512').update(fileBinary).digest('hex').toUpperCase();
};

export const flattenFileName = (fileName: string): { name: string; extension: string } => {
    const regExp = /(?:\.([^.]+))?$/;
    const result = regExp.exec(fileName);
    const extension = result ? result[0] : '';
    const name = fileName.substring(0, fileName.length - extension.length);
    return { name: name, extension: extension };
};
